import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Empty,
  Typography,
  Timeline,
  Avatar,
  Pagination,
} from "antd";
import "./Activity.css";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { getPublicActivityAction } from "../../Actions/publicActivityAction";

import { useDispatch, useSelector } from "react-redux";

// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import moment from "moment";

const Activity = () => {
  // const { IsXS,IsSM } = useBreakpoints();
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  const dispatch = useDispatch();

  
  const { allPublicActivity, currentUser, isAllPublicActivityLoading } =
    useSelector((state) => {
      const { allPublicActivity, isAllPublicActivityLoading } =
        state.publicActivity;
      const { currentUser } = state.authentication;
      return {
        allPublicActivity,
        currentUser,
        isAllPublicActivityLoading,
      };
    });
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(getPublicActivityAction(currentPage));
  }, [currentPage]);
  const height = window.innerHeight - 64;
  return (
    <div
    style={{
      height: height,
  
      overflow: "hidden",
      overflowY: "scroll",
      backgroundColor:"#FFFFFF",
      // "margin":"10px"
      
    }}
    className="customLayout"
  >
<div style={{ 
  // margin: "40px" 
  }}>
      <Spin size="large" spinning={isAllPublicActivityLoading}>
        <Row>
          <Col span={24}>
            
            <Timeline>
              {allPublicActivity?.public_activities?.map((val) => {
                const cud = val?.key.substring(val?.key.indexOf(".") + 1);
                const {
                  trackable_type,
                  created_at_with_formate,
                  created_at,
                  trackable,
                } = val;

                const userActivity = trackable_type.split(/(?=[A-Z])/);
                const secondColor =
                  "#" + Math.floor(Math.random() * 1677).toString(14);

                return (
                  <Timeline.Item
                    dot={
                      <div
                        style={{
                          border: "1px solid #E0E0E0",
                          width: "32px",
                         
                          height: "32px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {cud === "create" ? (
                          <PlusOutlined />
                        ) : cud === "update" ? (
                          <EditOutlined />
                        ) : cud === "destroy" ? (
                          <DeleteOutlined />
                        ) : (
                          ""
                        )}
                      </div>
                    }
                    className="ActivityTimelineCircle"
                  >
                    <Row
                      justify={IsXS ? "end" : "space-between"}
                      style={{ marginLeft: "11px" }}
                    >
                      <Col xl={20} lg={20} md={20} sm={20}   xs={24}>
                        <Row gutter={[18, 0]} 
                        style={{ 
                          marginTop: "-4px" 
                        }}>
                          <Col>
                            <Avatar
                              size={36}
                              style={{ background: secondColor }}
                           
                           
                           
                           >
                              {val?.owner?.first_name?.[0]}
                            </Avatar>
                          </Col>
                          <Col style={{ 
                            marginTop: "-5px" 
                            }} xs={16} xl={20} lg={20}>
                            {/* <Row gutter={[0, 7]}> */}
                              
                              {/* <Col xl={24} lg={24} md={24} sm={24}> */}
                                <Typography className="ActivityMainText">
                                  {currentUser?.users_role?.user_type ===
                                  "admin" ? val?.owner?.first_name: "You"}{currentUser?.users_role?.user_type ==="admin" ? " " + val?.owner?.last_name
                                    : ""}&nbsp;have&nbsp;{cud === "create"
                                    ? "created"
                                    : cud === "update"
                                    ? "updated"
                                    : cud === "destroy"
                                    ? "deleted"
                                    : ""}&nbsp;a&nbsp;{userActivity?.[0]}&nbsp;{userActivity?.[1]}
                                </Typography>
                              {/* </Col> */}
                              {/* <Col>
                                <Typography className="ActivitySubText">
                                  {trackable_type === "Family" &&
                                    `Family name is ${trackable?.last_name}`}
                                  {trackable_type === "Designation" &&
                                    `Designation name is ${trackable?.designation_title}`}
                                  {trackable_type === "Industry" &&
                                    `Industry name is ${trackable?.name}`}
                                  {trackable_type === "CommunityTrustee" &&
                                    `Trustee of ${
                                      trackable?.community?.name
                                    } community is ${
                                      trackable?.user?.first_name +
                                      trackable?.user?.last_name
                                    }`}
                                </Typography>
                              </Col> */}
                            {/* </Row> */}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Typography className="ActivityMainText">
                          {moment(created_at).startOf("minute").fromNow()}
                        </Typography>
                      </Col>
                    </Row>
                  </Timeline.Item>
                );
              })}
              <Row justify="end">
                <Pagination
                  total={allPublicActivity?.total_length}
                  onChange={(e) => setCurrentPage(e)}
                  showSizeChanger={false}
                />
              </Row>
            </Timeline>
    
          </Col>
        </Row>
      </Spin>
    </div>
 </div>
  );
};
export default Activity;
