import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Spin,
  Avatar,
  PageHeader,
  Descriptions,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { falseyValueCases, ImageEnv } from "../CommonLogics/CommonMethods";
import { history } from "../../Utils/history";

import {
  businessProfileById,
} from "../../Actions/businessAction";
const BusinessUserDetails = (props) => {
  const dispatch = useDispatch();

  const { setBusinessId, businessId, tabKey } = props;
  const {
    searchUsers,
    setCommunityId,
    allBusiness,
    isBusinessLoading,
    businessProfile,
  } = useSelector((state) => {
    const { searchUsers } = state.searchUser;
    const { setCommunityId } = state.communities;
    const {
      allBusiness,
      isUpdateBusinessLoading,
      isCreateBusinessLoading,
      isBusinessLoading,
      businessProfile,
    } = state.business;

    return {
      searchUsers,
      setCommunityId,
      allBusiness,
      isBusinessLoading,
      businessProfile,
    };
  });
  useEffect(() => {
    dispatch(businessProfileById(history?.location?.state?.id));
  }, []);
  const { business_profiles } = allBusiness;
  const {
    user,
    designation,
    company_name,
    business_details,
    business_location,
    service_provide,
    number_of_staff,
    experiance,
    working_here_since,
    is_wholesale,
    is_retail,
    is_business,
    job_title
  } = businessProfile || {};
  const handleCloseBusinessProfile = () => {
    // setBusinessId("");
    history.push("/communitybusiness");
  };
  return (
    <Spin spinning={isBusinessLoading}>
      <Row justify="space-around" style={{ 
        // margin: 20 
        }}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <ArrowLeftOutlined
            style={{ paddingBottom: "20px" }}
            onClick={() => handleCloseBusinessProfile()}
          />
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="profileDetails-card">
            <Row justify="space-between">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Row>
               
                  <Col span={4}>
                    <Avatar size={105} src={user?.imag && ImageEnv(user?.image)}>
                      <Typography style={{ fontSize: "40px", color: "white" }}>
                        {user?.first_name}
                      </Typography>
                    </Avatar>
                  </Col>
                  <Col span={20}>
                    <Row gutter={[16, 16]}>
                      <Typography className="detailsPage">
                        {" "}
                        {user?.first_name} {user?.last_name}
                      </Typography>
                    </Row>
                    <Row>
                      <Divider />
                    </Row>
                    <Row gutter={[16, 16]}>
                     
                      <Col span={12}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                           Phone No.&nbsp;:
                        </Typography>
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          &nbsp;-
                          {user?.phone ||
                            user?.phone_with_country_code}
                        </Typography>
                      </Col>
                      <Col span={12}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                         Email&nbsp;:
                        </Typography>
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          &nbsp;-{user?.email}
                        </Typography>
                      </Col>

                      <Col span={12}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Education&nbsp;:
                        </Typography>
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          &nbsp;-
                          {user?.education?.name}
                        </Typography>
                      </Col>
                      <Col span={12}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                         Industry&nbsp;:
                        </Typography>
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          &nbsp;-
                          {user?.industry?.name}
                        </Typography>
                      </Col>
                   
                   
                    </Row>
                  </Col>
               
                  <Row style={{ display: "contents" }}>
                    <Col
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ marginTop: 20 }}
                    >
                      <PageHeader
                        title="Details"
                        className="pageHeaderDetails"
                      ></PageHeader>
                    </Col>

                    <Descriptions column={1} style={{ padding: "2px 20px" }}>
                    <Descriptions.Item
                        label={
                          <Typography className="descriptionDetails">
                             Company Name&nbsp;
                          </Typography>
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                       
                          {company_name}
                        </Typography>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          is_business === false && (
                            <Typography  className="descriptionDetails"> Job Title&nbsp;</Typography>)
                         
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                        {job_title}
                        </Typography>
                        </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          
                          <Typography className="descriptionDetails">
                           Address&nbsp;
                          </Typography>
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                        {business_location}
                        </Typography>
                      </Descriptions.Item> 
                      <Descriptions.Item
                        label={
                          <Typography className="descriptionDetails">
                             Details:
                          </Typography>
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                          {business_details}
                        </Typography>
                      </Descriptions.Item> 
                      <Descriptions.Item
                        label={
                          is_business === true && (
                          <Typography className="descriptionDetails">
                           Service Provide&nbsp;
                          </Typography>)
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                        {service_provide}
                        </Typography>
                      </Descriptions.Item> 
                      <Descriptions.Item
                        label={
                          is_business === true && (
                          <Typography className="descriptionDetails">
                             Business Type&nbsp;
                          </Typography>)
                        }
                      >
                        {is_wholesale === true ? (
                  <Typography  className="pageHeaderSubDetails">Wholesale</Typography>):""}
                    {is_retail === true ? (
                  <Typography  className="pageHeaderSubDetails">Retail</Typography>):""}
                      </Descriptions.Item> 
                      <Descriptions.Item
                        label={
                          is_business === true && (
                          <Typography className="descriptionDetails">
                             Number of Staff&nbsp;
                          </Typography>)
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                      
                          {number_of_staff}
                        </Typography>
                      </Descriptions.Item> 
                      <Descriptions.Item
                        label={
                          is_business === false && (
                            <Typography  className="descriptionDetails"> experiance&nbsp;</Typography>)
                         
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                        {experiance}
                        </Typography>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          is_business === false && (
                            <Typography className="descriptionDetails"> working here since&nbsp;</Typography>)
                             
                         
                        }
                      >
                        <Typography className="pageHeaderSubDetails">
                        {working_here_since}
                        </Typography>
                      </Descriptions.Item>
                    </Descriptions>
                  </Row>

                 
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
export default BusinessUserDetails;


