import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTicketsAction,
  createTicketAction,
} from "../../../Actions/ticketAction";
import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  Drawer,
  Select,
  message,
} from "antd";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { ticketSchema } from "../../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSearch from "../../ReuseableComponents/CustomSearch/CustomSearch";
import { getSearchUser } from "../../../Actions/searchUserAction";

const CreateTicketDrawer = (props) => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);
  const [preloadedUserValue, setPreloadedUserValue] = useState("");
  const {
    isModalVisible,
    page,
    editId,
    setEditId,
    setIsModalVisible,
  } = props;
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: { preloadedUserValue },
    resolver: yupResolver(ticketSchema),
  });
  const the_tickets = _.filter(allTickets?.tickets, function (o) {
    return o.id === editId;
  });
  const newArray = the_tickets?.map((obj) => {
    return {
      ...obj,
      user_id: obj.user.first_name,
    };
  });
  useEffect(() => {
    if (editId) {
      setPreloadedUserValue(newArray[0]);
      reset(newArray[0]);
    }
  }, [the_tickets[0], reset]);

  const {
    searchUsers,
    isUpdateTrusteesLoading,
    isCreateTrusteesLoading,
    isSearchUserLoading,
    allTickets,
    setCommunityId

  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { searchUsers,isSearchUserLoading } = state.searchUser;
    const { allTickets} =
    state.tickets;
    return {
      isUpdateTrusteesLoading,
      isCreateTrusteesLoading,
      setCommunityId,
        searchUsers,
      isSearchUserLoading,allTickets
    };
  });
 
  const onUserSearch = (data) => {
    if (data) {
      setSelectedUserId(data?.id);
    } else {
      setSelectedUserId(null);
    }
  };
 
  const options = [
    { label: "Get verified issue", value: "Get verified issue" },
    { label: "Login related issue", value: "Login related issue" },
    { label: "Family related issue", value: "Family related issue" },
    { label: "Matrimonial related issue", value: "Matrimonial related issue" },
    { label: "Jobs related issue", value: "Jobs related issue" },
    { label: "Community related issue", value: "Community related issue" },
    { label: "Other", value: "Other" },
  ];
  const closeCreateDrawerMethod = () => {
    setIsModalVisible(false);
    setValue("name", "");
    setValue("user_id", "");
    reset();
  };
  const clearSearchUserRequiredMessageMethod = () => {
    setErrorUserId(null);
  };

  const createTicketMethod = (data) => {
    if (selectedUserId) {
      data.user_id = selectedUserId;
      data.community_id = setCommunityId;
      dispatch(createTicketAction(data, successCreateTicket,failureTicket));
    } else {
      setErrorUserId("User must be selected");
    }
  };
  const successCreateTicket = () => {
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("name", "");
    setValue("user_id", "");
    dispatch(getTicketsAction(`api/tickets?community_id=${setCommunityId}&page=${page}`));
    setTimeout(() => {
      message.success("Your Ticket Created Successfully");
    }, 1000);
  };

  const failureTicket = () => {
    setTimeout(() => {
      message.error(
        "Something went wrong."
      );
    }, 1000);
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
 
  return (
    <div className="DrawerBorderClass">
      <Drawer
        open={isModalVisible}
        placement="right"
        title={
          <Typography className="CreateTicketDrawerInputMainText">
           Create a new ticket
          </Typography>
        }
        onClose={closeCreateDrawerMethod}
        maskClassName="DrawerClass"
        className="DrawerBorderClass"
        width={600}
      >
          <form onSubmit={handleSubmit(createTicketMethod)}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Typography className="CreateTicketDrawerInputText">
                     Title
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Controller
                      as={
                        <Select
                          style={{ width: "100%" }}
                          options={options}
                          className="emailInput"
                          size="large"
                        />
                      }
                      name="name"
                      control={control}
                    />
                  </Col>
                  {errors.name && (
                    <p style={{ color: "red" }}>{errors.name.message}</p>
                  )}
                </Row>
              </Col>
           
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: "4px" }}>
                Subject
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="subject"
                control={control}
                className="inputLabel"
              />

             
            </Col>
            <Col span={24} className="formRows">
            {!editId && (
              <Typography className="details" style={{ paddingBottom: 9 }}>Search Community Member By Phone/Name</Typography>
            )}
            {!editId  && (
               <Col span={22}>
               <CustomSearch
                 size="large"
                 data={searchUsers?.users}
                 placeholder="Search User"
                 searchAction={getSearchUser}
                 isDrawer={isModalVisible}
                 searchActionLoading={isSearchUserLoading}
                 communityId={setCommunityId}
                 onSearch={onUserSearch}
                 clearRequiredMessageMethod={
                   clearSearchUserRequiredMessageMethod
                 }
               />
               {errorUserId && <p style={{ color: "red" }}>{errorUserId}</p>}
             </Col>
           )}
           </Col>
           
              <Col span={24}>
                <Button
                  //   onClick={() => setIsCreateTicketVisible(true)}
                  // className="buttonsAll"
                  type="primary"
                  style={{ borderRadius: "4px", }}
                  className="button-search"
                  htmlType="submit"
                >
                  Send
                </Button>
              </Col>
            </Row>
          </form>
      </Drawer>
    </div>
  );
};
export default CreateTicketDrawer;


