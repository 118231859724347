import constants from "../Utils/constants";
const initialState = {
  isMatrimonialLoading: false,
  isCreateMatrimonialLoading: false,
  isDeleteMatrimonialLoading: false,
  isUpdateMatrimonialLoading: false,
  isMatrimonialByIdLoading: false,
  allMatrimonial: [],
  matrimonialProfile: {},
  allMatrimonialsSuperAdmin: [],
  isMatrimonialSuperAdminLoading: false,
  matrimonialMatches: [],
  isMatrimonialMatchesLoading: false,
  isUpdateProfileStatusLoading: false,
};

export const matrimonialReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MATRIMONIAL_REQUEST:
      return {
        ...state,
        isMatrimonialLoading: true,
      };
    case constants.GET_MATRIMONIAL_SUCCESS:
      return {
        ...state,
        isMatrimonialLoading: false,
        allMatrimonial: action.payload,
      };
    case constants.GET_MATRIMONIAL_FAILURE:
      return {
        ...state,
        isMatrimonialLoading: false,
      };
    case constants.GET_MATRIMONIAL_SUPER_ADMIN_REQUEST:
      return {
        ...state,
        isMatrimonialSuperAdminLoading: true,
      };
    case constants.GET_MATRIMONIAL_SUPER_ADMIN_SUCCESS:
      return {
        ...state,
        isMatrimonialSuperAdminLoading: false,
        allMatrimonialsSuperAdmin: action.payload,
      };
    case constants.GET_MATRIMONIAL_SUPER_ADMIN_FAILURE:
      return {
        ...state,
        isMatrimonialSuperAdminLoading: false,
      };
    case constants.GET_MATRIMONIAL_MATCHES_REQUEST:
      return {
        ...state,
        matrimonialMatches: [],
        isMatrimonialMatchesLoading: true,
      };
    case constants.GET_MATRIMONIAL_MATCHES_SUCCESS:
      return {
        ...state,
        isMatrimonialMatchesLoading: false,
        matrimonialMatches: action.payload,
      };
    case constants.GET_MATRIMONIAL_MATCHES_FAILURE:
      return {
        ...state,
        isMatrimonialMatchesLoading: false,
      };
    case constants.MATRIMONIAL_BY_ID_REQUEST:
      return {
        ...state,
        isMatrimonialByIdLoading: true,
      };
    case constants.MATRIMONIAL_BY_ID_SUCCESS:
      return {
        ...state,
        isMatrimonialByIdLoading: false,
        matrimonialProfile: action.payload,
      };
    case constants.MATRIMONIAL_BY_ID_FAILURE:
      return {
        ...state,
        isMatrimonialByIdLoading: false,
      };

    case constants.CREATE_MATRIMONIAL_REQUEST:
      return {
        ...state,
        isCreateMatrimonialLoading: true,
      };
    case constants.CREATE_MATRIMONIAL_SUCCESS:
      return {
        ...state,
        isCreateMatrimonialLoading: false,
      };
    case constants.CREATE_MATRIMONIAL_FAILURE:
      return {
        ...state,
        isCreateMatrimonialLoading: false,
      };
    case constants.DELETE_MATRIMONIAL_REQUEST:
      return {
        ...state,
        isDeleteMatrimonialLoading: true,
      };
    case constants.DELETE_MATRIMONIAL_SUCCESS:
      return {
        ...state,
        isDeleteMatrimonialLoading: false,
      };
    case constants.DELETE_MATRIMONIAL_FAILURE:
      return {
        ...state,
        isDeleteMatrimonialLoading: false,
      };
    case constants.UPDATE_MATRIMONIAL_REQUEST:
      return {
        ...state,
        isUpdateMatrimonialLoading: true,
      };
    case constants.UPDATE_MATRIMONIAL_SUCCESS:
      return {
        ...state,
        isUpdateMatrimonialLoading: false,
      };
    case constants.UPDATE_MATRIMONIAL_FAILURE:
      return {
        ...state,
        isUpdateMatrimonialLoading: false,
      };

    case constants.UPDATE_PROFILE_STATUS_REQUEST:
      return {
        ...state,
        isUpdateProfileStatusLoading: true,
      };
    case constants.UPDATE_PROFILE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateProfileStatusLoading: false,
      };
    case constants.UPDATE_PROFILE_STATUS_FAILURE:
      return {
        ...state,
        isUpdateProfileStatusLoading: false,
      };
    case constants.RESET_MATRIMONIAL_BY_ID_DATA:
      return {
        ...state,
        matrimonialProfile: {},
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
