import API from "../Utils/API";
export const getMatrimonial = (url) => (dispatch) => {
  dispatch({ type: "GET_MATRIMONIAL_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_MATRIMONIAL_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_MATRIMONIAL_FAILURE",
        payload: error?.response?.data,
      });
    });
};
export const getMatrimonialSuperAdmin = (Page, CommunityId) => (dispatch) => {
  dispatch({ type: "GET_MATRIMONIAL_SUPER_ADMIN_REQUEST" });
  API.get(`api/matrimonials?community_id=${CommunityId}&page=${Page}`)
    .then((response) => {
      dispatch({
        type: "GET_MATRIMONIAL_SUPER_ADMIN_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_MATRIMONIAL_SUPER_ADMIN_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getMatrimonialMatchesAction =
  (UserId, Page, CommunityId) => (dispatch) => {
    dispatch({ type: "GET_MATRIMONIAL_MATCHES_REQUEST" });
    API.get(
      `api/my_matches?community_id=${CommunityId}&page=${Page}&user_id=${UserId}`
    )
      .then((response) => {
        dispatch({
          type: "GET_MATRIMONIAL_MATCHES_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MATRIMONIAL_MATCHES_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const matrimonialById = (matrimonialId) => (dispatch) => {
  dispatch({ type: "MATRIMONIAL_BY_ID_REQUEST" });
  API.get(`api/matrimonials/${matrimonialId}`)
    .then((response) => {
      dispatch({ type: "MATRIMONIAL_BY_ID_SUCCESS", payload: response.data });
      // dispatch(getMatrimonial());
    })
    .catch((error) => {
      dispatch({
        type: "MATRIMONIAL_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createMatrimonial =
  (data, successCreateMatrimonial, failureCreateMatrimonial, IsEmpty) =>
  (dispatch) => {
    dispatch({ type: "CREATE_MATRIMONIAL_REQUEST" });
    let formData = new FormData();

    data.files.map((image) => {
      formData.append(
        "matrimonial[matrimonial_user_images][]",
        image.originFileObj
      );
    });

    formData.append("matrimonial[user_id]", data.user_id);
    formData.append("matrimonial[height]", data.height);
    formData.append("matrimonial[weight]", data.weight);
    formData.append("matrimonial[description]", data.description);
    formData.append("matrimonial[marital_status]", data.marital_status);

    formData.append("matrimonial[birth_time]", data.birth_time);
    formData.append("matrimonial[birth_place]", data.birth_place);
    formData.append("matrimonial[current_city]", data.current_city);
    formData.append("birth_date", data.birth_date);
    formData.append("birth_month", data.birth_month);
    formData.append("birth_year", data.birth_year);
    formData.append("gender", data.gender);

    API.post("api/matrimonials", formData)

      .then((response) => {
        dispatch({
          type: "CREATE_MATRIMONIAL_SUCCESS",
          payload: response.data,
        });
        successCreateMatrimonial();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_MATRIMONIAL_FAILURE",
          payload: error.response.data,
        });
        failureCreateMatrimonial();
      });
  };

export const deleteMatrimonial =
  (matrimonialId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: "DELETE_MATRIMONIAL_REQUEST" });
    API.delete(`api/matrimonials/${matrimonialId}`)
      .then((response) => {
        dispatch({
          type: "DELETE_MATRIMONIAL_SUCCESS",
          payload: response.data,
        });
        const sDC = successDeleteCallback ? successDeleteCallback() : null;
        dispatch(getMatrimonial());
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_MATRIMONIAL_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateMatrimonial =
  (data, successUpdateCallBack, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_MATRIMONIAL_REQUEST" });
    let formData = new FormData();
    data?.files &&
      data?.files?.map((image) => {
        formData.append(
          "matrimonial[matrimonial_user_images][]",
          image.originFileObj
        );
      });
    formData.append("matrimonial[first_name]", data.first_name);
    formData.append("matrimonial[last_name]", data.last_name);
    formData.append("matrimonial[height]", data.height);
    formData.append("matrimonial[weight]", data.weight);
    formData.append("matrimonial[description]", data.description);
    formData.append("matrimonial[marital_status]", data.marital_status);
    formData.append("matrimonial[current_city]", data.current_city);
    formData.append("matrimonial[birth_place]", data.birth_place);

    formData.append("matrimonial[gender]", data.gender);
    data.birth_time &&
      formData.append("matrimonial[birth_time]", data.birth_time);
    API.put(`api/matrimonials/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_MATRIMONIAL_SUCCESS",
          payload: response.data,
        });
        successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_MATRIMONIAL_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateMatrimonialProfileStatusAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_PROFILE_STATUS_REQUEST" });
    API.put(`api/profile_status/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_PROFILE_STATUS_SUCCESS",
          payload: response.data,
        });

        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_PROFILE_STATUS_FAILURE",
          payload: error.response.data,
        });
      });
  };
