import API from "../Utils/API";
export const getCommunityCardsAction = (url) => (dispatch) => {
 
    dispatch({ type: "GET_COMMUNITY_CARDS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_COMMUNITY_CARDS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITY_CARDS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createCommunityCardsAction =
  (data, success, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_COMMUNITY_CARDS_REQUEST", payload: data });
    let formData = new FormData();
    IsEmpty
      ? formData.append("community_card_file", data.community_card_file)
      : formData.append(
          "community_card[community_card_file]",
          data.community_card_file
        );
        formData.append("community_id", data.community_id);
    formData.append("community_card[name]", data.name);
    formData.append("community_card[description]", data.description);
    formData.append("community_card[url]", data.url);
    formData.append("community_card[expiry_date]", data.expiry_date);
    formData.append("community_card[card_type]", data.card_type);
    // formData.append("community_card[published]", data.published);

    API.post("api/community_cards", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_COMMUNITY_CARDS_SUCCESS",
          payload: response.data,
        });
        // const sCT = success ? success() : null;
        success();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_COMMUNITY_CARDS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const deleteCommunityCardsAction =
  (Id, successCallback) => (dispatch) => {
    dispatch({ type: "DELETE_COMMUNITY_CARDS_REQUEST" });
    API.delete(`api/community_cards/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_COMMUNITY_CARDS_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_COMMUNITY_CARDS_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateCommunityCardsAction =
  (data, successCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_COMMUNITY_CARDS_REQUEST" });
    let formData = new FormData();

    IsEmpty
      ? formData.append("community_card_file", data?.community_card_file)
      : formData.append(
          "community_card[community_card_file]",
          data?.community_card_file
        );
        formData.append("community_id", data.community_id);
    formData.append("community_card[name]", data.name);
    formData.append("community_card[description]", data.description);
    formData.append("community_card[url]", data.url);
    formData.append("community_card[expiry_date]", data.expiry_date);
    formData.append("community_card[card_type]", data.card_type);
    formData.append("community_card[published]", data.published);
    API.put(`api/community_cards/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_COMMUNITY_CARDS_SUCCESS",
          payload: response.data,
        });

        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_COMMUNITY_CARDS_FAILURE",
          payload: error.response.data,
        });
        // failureCallback && failureCallback(error.response.data);
      });
  };
