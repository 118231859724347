import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Input,
  TimePicker,
} from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MaintenanceSchema } from "../../../Utils/Schema";
import {
  getUnderMaintenanceAction,
  createMaintenanceAction,
  updateMaintenanceAction,
} from "../../../Actions/maintenanceWindowAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
// dayjs.extend(customParseFormat);
const { TextArea } = Input;
const MaintenanceModalForm = (props) => {
  const {
    editId,
    setEditId,
    currentPage,
    setCurrentPage,
    isModalVisible,
    setIsModalVisible,
  } = props;
  const [preloadedMaintenanceValue, setPreloadedMaintenenceValue] =
    useState("");
  const [customTime, setCustomTime] = useState("");
  const [amPm, setAmPM] = useState("");
  const [onlyTimeSelectionState, setOnlyTimeSelectonState] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const dispatch = useDispatch();

  const {
    allMaintenanceWindows,
    isUpdateUnderMaintenanceLoading,
    isCreateUnderMaintenanceLoading,
    isUnderMaintenanceLoading,
  } = useSelector((state) => {
    const {
      allMaintenanceWindows,
      isUpdateUnderMaintenanceLoading,
      isCreateUnderMaintenanceLoading,
      isUnderMaintenanceLoading,
    } = state.maintenance;
    return {
      allMaintenanceWindows,
      isUpdateUnderMaintenanceLoading,
      isCreateUnderMaintenanceLoading,
      isUnderMaintenanceLoading,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: preloadedMaintenanceValue,
    resolver: yupResolver(MaintenanceSchema),
  });

  const the_maintenance = _.filter(allMaintenanceWindows, function (o) {
    return o.id === editId;
  });
  const newStartTime = moment(
    moment.utc(the_maintenance[0]?.start_time).format("LT"),
    "LT"
  );
  const newEndTime = moment(
    moment.utc(the_maintenance[0]?.end_time).format("LT"),
    "LT"
  );
  const newArray = the_maintenance?.map((obj) => {
    return {
      ...obj,
      start_time: newStartTime,
      end_time: newEndTime,
      description: obj.description,
      name: obj.name,
    };
  });
  useEffect(() => {
    if (editId) {
      setPreloadedMaintenenceValue(newArray[0]);
      reset(newArray[0]);
    }
  }, [the_maintenance[0], reset]);

  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("name", "");
    setValue("description", "");
    setValue("start_time", "");
    setValue("end_time", "");
    clearErrors();
  };
  const onTimeSelection = (time) => {
    const timeSplit = moment(time).format("HH.mm");
    setCustomTime(timeSplit > 11 ? timeSplit + "PM" : timeSplit + "AM");
    // const timeSplit = moment(time);
    // setCustomTime(timeSplit._d);
    setAmPM(moment(timeSplit._d).format("A"));
    setOnlyTimeSelectonState(true);
  };

  const handleCreateMaintenance = (data) => {
    data.start_time = moment(data.start_time).format("LT");
    data.end_time = moment(data.end_time).format("LT");
    dispatch(
      createMaintenanceAction(
        data,
        successCreateMaintenance,
        failureMaintenance
      )
    );
  };
  const successCreateMaintenance = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();

    dispatch(
      getUnderMaintenanceAction(`api/maintenance_windows?page=${currentPage}`)
    );
    setValue("name", "");
    setValue("description", "");
    setValue("start_time", "");
    setValue("end_time", "");
    clearErrors();
    setTimeout(() => {
      message.success("Your maintenance Created Successfully");
    }, 1000);
  };

  const failureMaintenance = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };

  const handleEditMaintenance = (data) => {
    data.id = editId;
    data.start_time = moment(data.start_time).format("LT");
    data.end_time = moment(data.end_time).format("LT");
    dispatch(updateMaintenanceAction(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId("");
    reset();
    setValue("name", "");
    setValue("description", "");
    setValue("start_time", "");
    setValue("end_time", "");
    dispatch(
      getUnderMaintenanceAction(`api/maintenance_windows?page=${currentPage}`)
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your maintenance Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);

  const getDisabledHours = () => {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };
  const getDisabledHours2 = (selectedHour) => {
    var hours = [];
    // if (setStartTime === moment().hour()) {
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
  // }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    // if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    // }
    return minutes;
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    setEndTime(null); 
    // Reset end time when start time changes
  };

  const disabledEndTime = () => {
    if (startTime) {
      const startHour = startTime.hours();
      const startMinute = startTime.minutes();
      const startSecond = startTime.seconds();
      return {
        disabledHours: () => generateDisabledHours2(startHour),
        disabledMinutes: (selectedHour) => generateDisabledMinutes(startHour, startMinute, selectedHour),
        disabledSeconds: (selectedHour, selectedMinute) =>
          generateDisabledSeconds(startHour, startMinute, startSecond, selectedHour, selectedMinute),
      };
    }
    return {};
  };
  const generateDisabledHours2 = (startHour) => {
    const disabledHours = [];
    for (let i = 0; i < startHour; i++) {
      disabledHours.push(i);
    }
    return disabledHours;
  };

  const generateDisabledHours = (startHour) => {
    const disabledHours = [];
    const currentHour = moment().hours();
    for (let i = 0; i < currentHour; i++) {
      disabledHours.push(i);
    }
    return disabledHours;
  };

  const generateDisabledMinutes = (startHour, startMinute, selectedHour) => {
    const disabledMinutes = [];
    if (selectedHour === startHour) {
      const currentMinute = moment().minutes();
      for (let i = 0; i < currentMinute; i++) {
        disabledMinutes.push(i);
      }
    }
    return disabledMinutes;
  };

  const generateDisabledSeconds = (startHour, startMinute, startSecond, selectedHour, selectedMinute) => {
    const disabledSeconds = [];
    if (selectedHour === startHour && selectedMinute === startMinute) {
      const currentSecond = moment().seconds();
      for (let i = 0; i < currentSecond; i++) {
        disabledSeconds.push(i);
      }
    }
    return disabledSeconds;
  };


  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Maintenance" : "Add Maintenance"}
    >
      <Spin
        spinning={
          editId
            ? isUpdateUnderMaintenanceLoading
            : isCreateUnderMaintenanceLoading
        }
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditMaintenance : handleCreateMaintenance
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: "4px" }}>
                Name
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="name"
                control={control}
                className="inputLabel"
              />

              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            {/* <TimePicker
        onChange={onChange}  
      />
      <TimePicker
        disabledDate={disabledDateEndPicker}  
      /> */}
            <Col span={12}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                From
              </Typography>
              <Controller
                as={
                  <TimePicker
                           value={startTime}
                           onChange={handleStartTimeChange}
                           format="HH:mm:ss"
                           showSecond={false}
                           disabledHours={() => generateDisabledHours()}
                           disabledMinutes={(selectedHour) => generateDisabledMinutes(selectedHour)}
                           disabledSeconds={(selectedHour, selectedMinute) =>
                             generateDisabledSeconds(selectedHour, selectedMinute)
                           }/>
                 }
                name="start_time"
                control={control}
                style={{ width: "100%" }}
              /> 

              {errors.start_time && (
                <p style={{ color: "red" }}>{errors.start_time.message}</p>
              )}
            </Col>
            <Col span={12}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                To
              </Typography>
              <Controller
                as={
                  <TimePicker
                          value={endTime}
                          onChange={setEndTime} 
                          format="HH:mm:ss"
                          showSecond={false}
                          disabledHours={() => generateDisabledHours()}
                           disabledMinutes={(selectedHour) => generateDisabledMinutes(selectedHour)}
                           disabledSeconds={(selectedHour, selectedMinute) =>
                             generateDisabledSeconds(selectedHour, selectedMinute)}
                          // {...disabledEndTime()}
                       />
                 }
                name="end_time"
                control={control}
                style={{ width: "100%" }}
              /> 
              {errors.end_time && (
                <p style={{ color: "red" }}>{errors.end_time.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="details" style={{ paddingBottom: "4px" }}>
                Description
              </Typography>
              <Controller
                as={<TextArea rows={3} />}
                name="description"
                control={control}
              />
              {errors.description && (
                <p style={{ color: "red" }}>{errors.description.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {editId ? (
                <Button size="large" className="editButton" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button size="large" className="editButton" htmlType="submit">
                  Add
                </Button>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className="cancelButton"
                onClick={handleClose}
                size="large"
                // style={{ margin: "0 8px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Spin>
    </Drawer>
  );
};

export default MaintenanceModalForm;


