import React, { useEffect } from "react";
import { Row, Col, Typography} from "antd"
import LandingPageHeader from "../LandingPage/LandingPageHeader"
import "../LandingPage/LandingPage.css"
import "./TermsAndConditions.css"
// import { useBreakpoints } from "react-responsive-hooks";
// import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

// import {ArrowRightOutlined , CalendarOutlined , ShoppingOutlined , FacebookFilled , TwitterOutlined , InstagramOutlined , PhoneOutlined , MailOutlined , EnvironmentOutlined} from "@ant-design/icons";


// import {history} from "../../Utils/history"
import Footer from "../LandingPage/Footer";
const TermsAndConditions = () => {

    
//   const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
    
    // const {IsMD , IsSM , IsXS} = useBreakpoints();
        
    
   useEffect(() => {
        window.scrollTo(0, 0)
   },[])
    return(  
      <div style={{"backgroundColor":"#FAFAFA"}}>
            
            <div className="TermsAndConditionsImageContainer" id="heading">

                <LandingPageHeader/>          
                <Row style={{"height":"100%"}} justify="center" align="middle">
                           <Typography className="TermsAndConditionsH1">
                            
                                Terms and Conditions
                    
                    </Typography>
                </Row>
           
            </div>
            <div style={{
                "marginTop":"90px"
                }}>
                <Row justify="center">
                    <Col span={18}>
                    
                    
                        <Row gutter={[0,30]}>
                            <Col>
                                <Typography className="TermsAndConditionsContentTitle1">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </Typography>
                            </Col>
                            <Col>
                                <Typography className="TermsAndConditionsContentTitle1">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, r<b>emaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.   </b>
                                </Typography>
                            </Col>
                       
                            <Col>
                                <Row justify="space-around">
                                    <Col span={23}>
                                        <Typography                         className="TermsAndConditionsContentTitle1">
                                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        </Typography>
                                    </Col>
                                    
                                    
                                    
                                    
                                    
                                    <Col span={23}>
                                        <Typography                         className="TermsAndConditionsContentTitle1">
                                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        </Typography>
                                    </Col>
                                    <Col span={23}>
                                        <Typography                         className="TermsAndConditionsContentTitle1">
                                       
                                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry</li>
                                        </Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Typography className="TermsAndConditionsContentTitle1">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. <b>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</b>
                                </Typography>
                            </Col>
                        
                        </Row>
                    </Col>


                </Row>
            </div>
            

 
<Footer/> 
            </div>

 )
}




export default TermsAndConditions;