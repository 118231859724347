import API from "../Utils/API";
export const getCommunitiesNative = (url) => (dispatch) => {
  dispatch({ type: "GET_COMMUNITIES_NATIVE_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_COMMUNITIES_NATIVE_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITIES_NATIVE_FAILURE",
        payload: error?.response?.data,
      });
    });
};

export const createCommunitiesNative =
  (data, successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_COMMUNITIES_NATIVE_REQUEST", payload: data });
    API.post("api/community_natives", data)

      .then((response) => {
        dispatch({
          type: "CREATE_COMMUNITIES_NATIVE_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_COMMUNITIES_NATIVE_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateCommunitiesNativeAction =
  (data, successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_COMMUNITIES_NATIVE_REQUEST" });
    API.put(`api/community_natives/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_NATIVE_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_NATIVE_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
  export const deleteCommunityNative = (Id, successDeleteCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_COMMUNITIES_NATIVE_REQUEST" });
    API.delete(`api/community_natives/${Id}`)
      .then((response) => {
        dispatch({ type: "DELETE_COMMUNITIES_NATIVE_SUCCESS", payload: response.data });
        // dispatch(getCommunitiesNative());
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_COMMUNITIES_NATIVE_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };