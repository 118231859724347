import constants from "../Utils/constants";
const initialState = {
  isCommunitiesNativeLoading: false,
  allCommunitiesNative: [],
  isCreateCommunitiesNativeLoading: false,

  setSelectedCommunityNativeId: "",
  isUpdateCommunitiesNativeLoading: false,
};

export const communitiesNativeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_COMMUNITIES_NATIVE_REQUEST:
      return {
        ...state,
        isCommunitiesNativeLoading: true,
      };
    case constants.GET_COMMUNITIES_NATIVE_SUCCESS:
      return {
        ...state,
        isCommunitiesNativeLoading: false,
        allCommunitiesNative: action.payload,
      };
    case constants.GET_COMMUNITIES_NATIVE_FAILURE:
      return {
        ...state,
        isCommunitiesNativeLoading: false,
      };
    case constants.CREATE_COMMUNITIES_NATIVE_REQUEST:
      return {
        ...state,
        isCreateCommunitiesNativeLoading: true,
      };

    case constants.CREATE_COMMUNITIES_NATIVE_SUCCESS:
      return {
        ...state,
        isCreateCommunitiesNativeLoading: false,
      };
    case constants.CREATE_COMMUNITIES_NATIVE_FAILURE:
      return {
        ...state,
        isCreateCommunitiesNativeLoading: false,
      };
    case constants.GET_SELECTED_COMMUNITY_NATIVE_ID:
      return {
        ...state,
        setSelectedCommunityNativeId: action.payload,
      };
    case constants.UPDATE_COMMUNITIES_NATIVE_REQUEST:
      return {
        ...state,
        isUpdateCommunitiesNativeLoading: true,
      };

    case constants.UPDATE_COMMUNITIES_NATIVE_SUCCESS:
      return {
        ...state,
        isUpdateCommunitiesNativeLoading: false,
      };
    case constants.UPDATE_COMMUNITIES_NATIVE_FAILURE:
      return {
        ...state,
        isUpdateCommunitiesNativeLoading: false,
      };
     
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
     
      };
     
    default:
      return state;
  }
};
