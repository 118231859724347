import constants from "../Utils/constants";
const initialState = {
  isUnderMaintenanceLoading: false,
  isCreateUnderMaintenanceLoading:false,
  isUpdateUnderMaintenanceLoading:false,
  isDeleteUserUnderMaintenanceLoading:false,
  allMaintenanceWindows: [],
  maintenance:{}
};

export const windowMaintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MAINTENANCE_WINDOWS_REQUEST:
      return {
        ...state,
        isUnderMaintenanceLoading: true,
      };
    case constants.GET_MAINTENANCE_WINDOWS_SUCCESS:
      return {
        ...state,
        isUnderMaintenanceLoading: false,
        allMaintenanceWindows: action.payload,
      };
    case constants.GET_MAINTENANCE_WINDOWS_FAILURE:
      return {
        ...state,
        isUnderMaintenanceLoading: false,
      };
      case constants.CREATE_MAINTENANCE_WINDOWS_REQUEST:
        return {
          ...state,
          isCreateUnderMaintenanceLoading: true,
        };
      case constants.CREATE_MAINTENANCE_WINDOWS_SUCCESS:
        return {
          ...state,
          isCreateUnderMaintenanceLoading: false,
        };
      case constants.CREATE_MAINTENANCE_WINDOWS_FAILURE:
        return {
          ...state,
          isCreateUnderMaintenanceLoading: false,
        };
        case constants.GET_MAINTENANCE_WINDOWS_BY_ID_REQUEST:
            return {
              ...state,
             isUnderMaintenanceLoading: true,
            };
          case constants.GET_MAINTENANCE_WINDOWS_BY_ID_SUCCESS:
            return {
              ...state,
             isUnderMaintenanceLoading: false,
              maintenance: action.payload,
            };
          case constants.GET_MAINTENANCE_WINDOWS_BY_ID_FAILURE:
            return {
              ...state,
             isUnderMaintenanceLoading: false,
            };
      case constants.UPDATE_MAINTENANCE_WINDOWS_REQUEST:
        return {
          ...state,
          isUpdateUnderMaintenanceLoading: true,
        };
      case constants.UPDATE_MAINTENANCE_WINDOWS_SUCCESS:
        return {
          ...state,
          isUpdateUnderMaintenanceLoading: false,
        };
      case constants.UPDATE_MAINTENANCE_WINDOWS_FAILURE:
        return {
          ...state,
          isUpdateUnderMaintenanceLoading: false,
        };
      case constants.DELETE_MAINTENANCE_WINDOWS_REQUEST:
        return {
          ...state,
          isDeleteUserUnderMaintenanceLoading: true,
        };
      case constants.DELETE_MAINTENANCE_WINDOWS_SUCCESS:
        return {
          ...state,
          isDeleteUserUnderMaintenanceLoading: false,
        };
      case constants.DELETE_MAINTENANCE_WINDOWS_FAILURE:
        return {
          ...state,
          isDeleteUserUnderMaintenanceLoading: false,
        };
        case constants.GET_SELECTED_MAINTENANCE_WINDOWS_ID:
          return {
            ...state,
            setId: action.payload,
          };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
