import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tabs, Typography, Card } from "antd";
import "./CommunityCard.css";
import ReactPlayer from "react-player";
import moment from "moment/moment";

const CommunityVideoCard = (props) => {
  const { mappedData, isCommunityCardModal } = props;
  const mappedDataDate = moment(mappedData?.created_at).format("MMM Do YYYY");
  const date = new Date(mappedData?.expiry_date);
  const typedDataDate = moment(date).format("MMM Do YYYY");
  return (
    <div>
      <Card className="communityCard">
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <Typography className="CommunityTextCardTitle">
                  {mappedData?.name}
                </Typography>
              </Col>
              <Col>
                <Typography className="communityCardDate">
                  {isCommunityCardModal ? typedDataDate : mappedDataDate}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className="player-wrapper">
              <ReactPlayer
                url={mappedData?.url}
                width="100%"
                height={98}
                className="react-player"
                // onReady={(e) => console.log(e ? "Loaded" : "Loading")}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CommunityVideoCard;
