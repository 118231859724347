import constants from "../Utils/constants";
const initialState = {
  isGetTemplatesElementsLoading: false,
  isCreateTemplatesElementsLoading: false,
  isDeleteTemplatesElementsLoading: false,
  isUpdateTemplatesElementsLoading: false,
  isTemplatesElementByIdLoading:false,
  allTemplatesElements: [],
  setTempleteElementId: "",
  templateElements: {},
};

export const templatesElementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TEMPLATES_ELEMENTS_REQUEST:
      return {
        ...state,
        isGetTemplatesElementsLoading: true,
        allTemplatesElements: [],
      };
    case constants.GET_TEMPLATES_ELEMENTS_SUCCESS:
      return {
        ...state,
        isGetTemplatesElementsLoading: false,
        allTemplatesElements: action.payload,
      };
    case constants.GET_TEMPLATES_ELEMENTS_FAILURE:
      return {
        ...state,
        isGetTemplatesElementsLoading: false,
      };
    case constants.CREATE_TEMPLATES_ELEMENTS_REQUEST:
      return {
        ...state,
        isCreateTemplatesElementsLoading: true,
      };
    case constants.CREATE_TEMPLATES_ELEMENTS_SUCCESS:
      return {
        ...state,
        isCreateTemplatesElementsLoading: false,
      };
    case constants.CREATE_TEMPLATES_ELEMENTS_FAILURE:
      return {
        ...state,
        isCreateTemplatesElementsLoading: false,
      };
    case constants.DELETE_TEMPLATES_ELEMENTS_REQUEST:
      return {
        ...state,
        isDeleteTemplatesElementsLoading: true,
      };
    case constants.DELETE_TEMPLATES_ELEMENTS_SUCCESS:
      return {
        ...state,
        isDeleteTemplatesElementsLoading: false,
      };
    case constants.DELETE_TEMPLATES_ELEMENTS_FAILURE:
      return {
        ...state,
        isDeleteTemplatesElementsLoading: false,
      };
    case constants.UPDATE_TEMPLATES_ELEMENTS_REQUEST:
      return {
        ...state,
        isUpdateTemplatesElementsLoading: true,
      };
    case constants.UPDATE_TEMPLATES_ELEMENTS_SUCCESS:
      return {
        ...state,
        isUpdateTemplatesElementsLoading: false,
      };
    case constants.UPDATE_TEMPLATES_ELEMENTS_FAILURE:
      return {
        ...state,
        isUpdateTemplatesElementsLoading: false,
      };
      case constants.GET_TEMPLATES_ELEMENTS_BY_ID_REQUEST:
        return {
          ...state,
          isTemplatesByIdLoading: true,
        };
      case constants.GET_TEMPLATES_ELEMENTS_BY_ID_SUCCESS:
        return {
          ...state,
          isTemplatesElementByIdLoading: false,
          templateElements: action.payload,
        };
      case constants.GET_TEMPLATES_ELEMENTS_BY_ID_FAILURE:
        return {
          ...state,
          isTemplatesElementByIdLoading: false,
        };
  
      case constants.GET_SELECTED_ELEMENTS_TEMPLATES_ID:
        return {
          ...state,
          setTempleteElementId: action.payload,
        };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
