import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  Tooltip,
  Popconfirm,
  Pagination,
  message,
} from "antd";
import _ from "lodash";
import {
  getDesignations,
  updateDesignation,
  deleteDesignation,
} from "../../../Actions/designationsAction";
import DesignationModalForm from "./DesignationModalForm ";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import { getCommunityById } from "../../../Actions/communitiesAction";
import { useForm } from "react-hook-form";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Edit2 from "../../../assets/Edit2.svg";
import Delete from "../../../assets/delete.svg";

const Designations = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [designationId, setDesignationId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedDesignation, setSelectedDesignation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { isGetDesignationsLoading, setCommunityId, allDesignations } =
    useSelector((state) => {
      const { setCommunityId } = state.communities;
      const { allDesignations, isGetDesignationsLoading } = state.designations;
      return {
        isGetDesignationsLoading,
        setCommunityId,
        allDesignations,
      };
    });
  let UnpublishData = {};
  let PublishData = {};
  const { total_length } = allDesignations || {};
  const { reset } = useForm({});
  useEffect(() => {
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [dispatch, setCommunityId, currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };

  const onClickDesignation = () => {
    setSelectedDesignation("");
    setValName("");
    reset();
  };
  const the_name = _.filter(allDesignations.designations, function (o) {
    return o.id === valName;
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteDesignation = (Id) => {
    dispatch(deleteDesignation(Id, successDelete, failureDelete));
  };

  const successDelete = () => {
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setTimeout(() => {
      message.success("Your Designation Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const handlePublishDesignationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateDesignation(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishdesignationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateDesignation(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Designation Published Successfully");
    }, 1000);
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Designation Unpublished Successfully");
    }, 1000);
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const Columns = [
    {
      title: "Designation Name",
      dataIndex: "designation_title",
      width: "80%",
      sorter: (a, b) => a.designation_title.localeCompare(b.designation_title),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
              {/* <EditColumnAction id={id} customMethod={handleEdit} /> */}
            </Col>
            <Col>
              {published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublishDesignationMethod(id)}
                    icon={<img src={PublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnpublishdesignationMethod(id)}
                    icon={<img src={UnpublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteDesignation(id)}
                  okText="Ok"
                  cancelText="cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isGetDesignationsLoading}>
      <Row gutter={[32, 32]} 
       className="community" 
      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickDesignation()}
              className="breadcrumUsers"
            >
              Designations
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.designation_title}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => setIsModalVisible(true)}
              >
                Add Designations
              </Button>
            </Col>
          </Row>
          <DesignationModalForm
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            designationId={designationId}
            setDesignationId={setDesignationId}
            setValName={setValName}
            valName={valName}
            setSelectedDesignation={setSelectedDesignation}
            selectedDesignation={selectedDesignation}
            isModalVisible={isModalVisible}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          ></DesignationModalForm>
        </Col>

        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable
                columns={Columns}
                data={allDesignations?.designations}
              />
            </Col>
          </Row>
        </Col>
        {allDesignations && total_length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allDesignations?.total_length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
        <Col xl={24} lg={24} md={24} sm={24} xs={24}></Col>
      </Row>
    </Spin>
  );
};

export default Designations;
