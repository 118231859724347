import API from "../Utils/API";
export const getDesignations = (url) => (dispatch) => {
  dispatch({ type: "GET_DESIGNATIONS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_DESIGNATIONS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_DESIGNATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createDesignation = (data, successCallback, failureCallback) => (dispatch) => {
  dispatch({ type: "CREATE_DESIGNATIONS_REQUEST", payload: data });
  API.post("api/designations", data)
    .then((response) => {
      dispatch({ type: "CREATE_DESIGNATIONS_SUCCESS", payload: response.data });
      successCallback && successCallback();
    })
    .catch((error) => {
      dispatch({ type: "CREATE_DESIGNATIONS_FAILURE", payload: error });
      failureCallback && failureCallback(error.response.data);
    });
   
};

export const updateDesignation = (data, successCallback, failureCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_DESIGNATIONS_REQUEST" });
  API.put(`api/designations/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_DESIGNATIONS_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_DESIGNATIONS_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);

    });
};

export const getDesignationById = (Id) => (dispatch) => {
  dispatch({ type: "GET_DESIGNATIONS_BY_ID_REQUEST" });

  API.get(`api/designations/${Id}`)
    .then((response) => {
      dispatch({
        type: "GET_DESIGNATIONS_BY_ID_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_DESIGNATIONS_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const deleteDesignation = (Id, successCallback, failureCallback) => (dispatch) => {
  dispatch({ type: "DELETE_DESIGNATIONS_REQUEST" });

  API.delete(`/api/designations/${Id}`)
      .then((response) => {
          dispatch({
              type: "DELETE_DESIGNATIONS_SUCCESS",
              payload: response.data,
          })
          successCallback();
      })
      .catch((error) => {
          dispatch({
              type: "DELETE_DESIGNATIONS_FAILURE",
              payload: error.response.data,
          })
      failureCallback();

      })
}

