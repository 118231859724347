import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Upload,
  Drawer,
} from "antd";
import {
  createJourneyAction,
  getJourney,
  updateJourneyAction,
  getJourneyById,
} from "../../../Actions/JourneyAction";
import "../Communities.css";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { JourneySchema } from "../../../Utils/Schema";
import _ from "lodash";
import { ImageEnv } from "../../CommonLogics/CommonMethods";

const JourneyDrawer = (props) => {
  const {
    setSelectedJourney,
    setValName,
    editId,
    setEditId,
    isModalVisible,
    setIsModalVisible,
    setIsDefaultImageVisible,
    isDefaultImageVisible,
    journeyDetailId,
    showUploadImage,
    setShowUploadImage,
    currentPage,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState([]);
  const IsEmpty = _.isEmpty(newFiles);
  const [preloadedJourneyValue, setPreloadedJourneyValue] = useState("");
  const {
    allJourney
  } = useSelector((state) => {
    const { allJourney} = state.journey;
    return {
      allJourney
    };
  });

  const { journey } = allJourney;
  const the_journey = _.filter(allJourney.journeys, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedJourneyValue,
    },
    resolver: yupResolver(JourneySchema),
  });

  useEffect(() => {
    if (editId) {
      setPreloadedJourneyValue(the_journey[0]);
      reset(the_journey[0]);
    }
  }, [the_journey[0], reset]);
  const handleClose = () => {
    setIsModalVisible(false);
    setEditId("");
    setValue("title", "");
    setValue("url", "");
    setValue("published", "");
    setValue("description", "");
    setValue("journey_image", "");
    // setValue("journey_image_key", "");

    
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };

  const hanleCreateJourney = (data) => {
    data.journey_image = newFiles;
    // data.published = true;
    dispatch(
      createJourneyAction(
        data,
        successCreateJourney,
        failureCreateJourney,
        IsEmpty
      )
    );
  };
  const successCreateJourney = () => {
    setCurrentPage(1);
    reset();
    setEditId("");
    setIsModalVisible(false);
    setIsDefaultImageVisible(false);
    setShowUploadImage(false);
    dispatch(getJourney(`api/journeys?page=${currentPage}`));
    setValue("title", "");
    setValue("url", "");
    setValue("published", "");
    setValue("description", "");
    setValue("journey_image", "");
    // setValue("journey_image_key", "");

    clearErrors();
    setTimeout(() => {
      message.success("Your Blog Created Successfully");
    }, 1000);
  };

  const failureCreateJourney = (failureResponse) => {
    setTimeout(() => {
      message.error(
        "Something went wrong."
      );
    }, 1000);
  };
  const handleEditJourney = (data) => {
    data.id = editId;
    data.journey_image = IsEmpty ? null : newFiles;
    dispatch(
      updateJourneyAction(data, UpdateCallBack, faliureUpdate, IsEmpty)
    );
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    reset();
    setIsModalVisible(false);
    setEditId("");
    setNewFiles([]);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
    setValue("title", "");
    setValue("url", "");
    setValue("published", "");
    setValue("description", "");
    setValue("journey_image", "");
    // setValue("journey_image_key", "");

    dispatch(getJourneyById(journeyDetailId));
    dispatch(getJourney(`api/journeys?page=${currentPage}`));

    clearErrors();
    setTimeout(() => {
      message.success("Your Blog Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.slug : "Something went wrong."
      );
    }, 1000);
  };

  const propsForUpload = {
    action: hanleCreateJourney || handleEditJourney,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    // setSelectedFileList(e.fileList.slice(-1));
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      title={editId ? "Edit Blog" : "Add Blog"}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
    >
      {/* <Spin
        style={{ height: "100%", maxHeight: "unset" }}
        // spinning={editId ? isUpdateCommunityLoading : isCreateCommunityLoading}
        size="large"
      > */}
        <form
          onSubmit={handleSubmit(
            editId ? handleEditJourney : hanleCreateJourney
          )}
        >
          {/* <Spin 
        //   spinning={isCommumitiesloading}
          > */}
            <Row gutter={[16, 16]}></Row>
            

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                 Title
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="title"
                  control={control}
                  className="inputLabel"
                />
                {errors.title && (
                  <p style={{ color: "red" }}>{errors.title.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Description
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="description"
                  control={control}
                  className="inputLabel"
                />
                {/* {errors.description && (
                  <p style={{ color: "red" }}>{errors.description.message}</p>
                )} */}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Route
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="url"
                  control={control}
                  className="inputLabel"
                />
                {errors.url && (
                  <p style={{ color: "red" }}>{errors.url.message}</p>
                )}
              </Col>
            </Row>
           
            <Row justify="center">
              <Col>
                {editId &&
                  isDefaultImageVisible &&
                  the_journey[0]?.journey_image_key && (
                    <Upload
                      listType="picture-card"
                      openFileDialogOnClick={false}
                    >
                      <img
                        src={the_journey[0]?.journey_image_key && ImageEnv(the_journey[0]?.journey_image_key)}
                        width={102}
                      ></img>
                    </Upload>
                  )}
              </Col>
              <Col>
                <Upload
                  // fileList={selectedFileList}
                  {...propsForUpload}
                  listType="picture-card"
                  maxCount={1}
                  showUploadList={showUploadImage}
                  onChange={(e) => handleUploadMethod(e)}
                  className="details"
                  accept="image/png,image/jpeg"
                >
                  <PlusOutlined size={44} />
                  Image
                </Upload>
              </Col>
            </Row>
            

         
            <Row gutter={[8, 8]} style={{ paddingBottom: "80px" }}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                {editId ? (
                  <Button size="large" className="editButton" htmlType="submit">
                    Update
                  </Button>
                ) : (
                  <Button size="large" className="editButton" htmlType="submit">
                    Add
                  </Button>
                )}
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button
                  className="cancelButton"
                  onClick={handleClose}
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          {/* </Spin> */}
        </form>
      {/* </Spin> */}
    </Drawer>
  );
};

export default JourneyDrawer;
