import React from "react";
import "antd/dist/antd.css";
import "./FindOtherPeople.css";
import PeopleTable from "./Table";
import { useSelector } from "react-redux";
const FindOtherPeople = () => {
  const { isAuthenticated } = useSelector((state) => {
    const { isAuthenticated } = state.authentication;

    return {
      isAuthenticated,
    };
  });
  return (
    <>
      {/* <Form2></Form2> */}
      {isAuthenticated && <PeopleTable />}
    </>
  );
};

export default FindOtherPeople;
