import constants from "../Utils/constants";
const initialState = {
  isGetTemplatesLoading: false,
  isCreateTemplatesLoading: false,
  isDeleteTemplatesLoading: false,
  isUpdateTemplatesLoading: false,
  allTemplates: [],
  setTempleteId: "",
  isTemplatesByIdLoading: false,
  template: {},
};

export const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        isGetTemplatesLoading: true,
        allTemplates: [],
      };
    case constants.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        isGetTemplatesLoading: false,
        allTemplates: action.payload,
      };
    case constants.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        isGetTemplatesLoading: false,
      };
    case constants.CREATE_TEMPLATES_REQUEST:
      return {
        ...state,
        isCreateTemplatesLoading: true,
      };
    case constants.CREATE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isCreateTemplatesLoading: false,
      };
    case constants.CREATE_TEMPLATES_FAILURE:
      return {
        ...state,
        isCreateTemplatesLoading: false,
      };
    case constants.DELETE_TEMPLATES_REQUEST:
      return {
        ...state,
        isDeleteTemplatesLoading: true,
      };
    case constants.DELETE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isDeleteTemplatesLoading: false,
      };
    case constants.DELETE_TEMPLATES_FAILURE:
      return {
        ...state,
        isDeleteTemplatesLoading: false,
      };
    case constants.UPDATE_TEMPLATES_REQUEST:
      return {
        ...state,
        isUpdateTemplatesLoading: true,
      };
    case constants.UPDATE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isUpdateTemplatesLoading: false,
      };
    case constants.UPDATE_TEMPLATES_FAILURE:
      return {
        ...state,
        isUpdateTemplatesLoading: false,
      };
      case constants.GET_TEMPLATES_BY_ID_REQUEST:
        return {
          ...state,
          isTemplatesByIdLoading: true,
        };
      case constants.GET_TEMPLATES_BY_ID_SUCCESS:
        return {
          ...state,
          isTemplatesByIdLoading: false,
          template: action.payload,
        };
      case constants.GET_TEMPLATES_BY_ID_FAILURE:
        return {
          ...state,
          isTemplatesByIdLoading: false,
        };
  
      case constants.GET_SELECTED_TEMPLATES_ID:
        return {
          ...state,
          setTempleteId: action.payload,
        };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
