import API from "../Utils/API";
export const getNativeVolunteerAction = (Id) => (dispatch) => {
  dispatch({ type: "GET_NATIVE_VOLUNTEER_REQUEST" });
  API.get(`api/natives_volunteers?community_native_id=${Id}`)
    .then((response) => {
      dispatch({
        type: "GET_NATIVE_VOLUNTEER_SUCCESS",
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: "GET_NATIVE_VOLUNTEER_FAILURE",

        payload: error.response.data,
      });
    });
};

export const createNativeVolunteerAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "CREATE_NATIVE_VOLUNTEER_REQUEST", payload: data });
    API.post("api/natives_volunteers", data)
      .then((response) => {
        dispatch({
          type: "CREATE_NATIVE_VOLUNTEER_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_NATIVE_VOLUNTEER_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const deleteNativeVolunteerAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "DELETE_NATIVE_VOLUNTEER_REQUEST" });
    API.delete(`api/natives_volunteers/${data}`)
      .then((response) => {
        dispatch({
          type: "DELETE_NATIVE_VOLUNTEER_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })

      .catch((error) => {
        dispatch({
          type: "DELETE_NATIVE_VOLUNTEER_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateVerifiedByVolunteerAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_VERIFIED_BY_VOLUNTEER_REQUEST" });
    API.put(`api/verified_by_volunteer/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: "UPDATE_VERIFIED_BY_VOLUNTEER_SUCCESS",
          payload: response.data,
        });

        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_VERIFIED_BY_VOLUNTEER_FAILURE",
          payload: error.response.data,
        });
      });
  };
