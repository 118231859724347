import React from "react";
import {  useSelector } from "react-redux";
// import {
//   getTicketsAction,
//   // createTicketAction,
// } from "../../../Actions/ticketAction";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  //   Button,
  //   Tabs,
  Typography,
  // Timeline,
  //   Table,
  //   Avatar,
  //   Input,
  //   Empty,
  //   Drawer,
  //   Select,
  //   message,
  Card,
} from "antd";
import "../../../FindThePeoplePage/FindOtherPeople.css";
// import { useForm } from "react-hook-form";
// import moment from "moment";

const ViewElements = (props) => {
  // const dispatch = useDispatch();
  //   const { allTemplatesElements,ticket, isGetTicketsLoading, isCreateTicketsLoading,setCommunityId,allArchiveTickets } =
  //     useSelector((state) => {
  //     //   const { allTickets,ticket, isGetTicketsLoading, isCreateTicketsLoading,allArchiveTickets } =
  //     //     state.tickets;
  //     const { setTempleteId, allTemplates } = state.templates;
  //         const { allTemplatesElements } = state.templatesElements;
  //       return {
  //         setTempleteId,
  //         allTemplatesElements,
  //         isGetTicketsLoading,
  //         isCreateTicketsLoading,
  //         allArchiveTickets,ticket
  //       };
  //     });
  const {
    // setTempleteElementId,
    // templateElements,
    // setTempleteId,
    // allTemplates,
    allTemplatesElements,
  } = useSelector((state) => {
    const { setTempleteId, allTemplates } = state.templates;
    const { setTempleteElementId, templateElements, allTemplatesElements } =
      state.templatesElements;

    return {
      setTempleteElementId,
      templateElements,
      setTempleteId,
      allTemplates,
      allTemplatesElements,
    };
  });
  // const {
    // handleSubmit,
    // formState: { errors },
    // control,
    // reset,
    // setValue,
    // watch,
  // } = useForm({
    // resolver: yupResolver(ticketSchema),
  // });
  // const watching = watch();
  // const { mappedData } = props;
  // const mappedDataDate = moment(mappedData?.date).format("MMM Do YYYY");
  // const date = new Date(mappedData?.date);
  // const typedDataDate = moment(date).format("MMM Do YYYY");
  //   const { tickets  } = allTemplatesElements;

  //   const { histories  } = tickets?.[0];
  const {  setEditId } = props;
  const handleCloseMatrimonialProfile = () => {
    setEditId("");
  };
  return (
    // <div className="DrawerBorderClass">
    <Row justify="space-around" 
    // style={{ margin: 20 }}
    >
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <ArrowLeftOutlined
          style={{ paddingBottom: "20px" }}
          onClick={() => handleCloseMatrimonialProfile()}
        />
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card className="profileDetails-card">
          <Row justify="space-between">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {allTemplatesElements?.map((val, i) => {
                return (
                  <div>
                    <Row>
                      <Col span={18}>
                        <Card className="communityCard3">
                          <Typography className="CommunityTextCardTitle3">
                            {" "}
                            {val.title}
                          </Typography>
                          <Typography className="CommunityTextCardTitle3">
                            {" "}
                            {val.element_name}
                          </Typography>
                          <Typography className="CommunityTextCardTitle3">
                            {" "}
                            {val.element_type}
                          </Typography>
                          <Typography className="communityCardDescription1">
                            {" "}
                            {val.description}
                          </Typography>
                          <Typography className="CommunityTextCardTitle3">
                            {" "}
                            {val.date}
                          </Typography>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    //</div>
  );
};
export default ViewElements;
