import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Input,
} from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DesignationsSchema } from "../../../Utils/Schema";
import {
  getDesignations,
  createDesignation,
  updateDesignation,
} from "../../../Actions/designationsAction";
import { useDispatch, useSelector } from "react-redux";
import { getCommunities } from "../../../Actions/communitiesAction";

const DesignationModalForm = (props) => {
  const {
    editId,
    setEditId,
    isModalVisible,
    setIsModalVisible,
    currentPage,
    setCurrentPage,
  } = props;

  const [preloadedDesignationValue, setPreloadedDesignationsValue] =
    useState("");
  const dispatch = useDispatch();
  const {
    isUpdateDesignationsLoading,
    isCreateDesignationsLoading,
    setCommunityId,
    allDesignations,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const {
      allDesignations,
      isUpdateDesignationsLoading,
      isCreateDesignationsLoading,
    } = state.designations;
    return {
      isUpdateDesignationsLoading,
      isCreateDesignationsLoading,
      setCommunityId,
      allDesignations,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedDesignationValue },
    resolver: yupResolver(DesignationsSchema),
  });

  const the_trustees = _.filter(allDesignations.designations, function (o) {
    return o.id === editId;
  });

  useEffect(() => {
    if (editId) {
      setPreloadedDesignationsValue(the_trustees[0]);
      reset(the_trustees[0]);
    }
  }, [the_trustees[0], reset]);

  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("designation_title", "");
    clearErrors();
  };
  const handleCreateDesignation = (data) => {
    data.community_id = setCommunityId;
    dispatch(
      createDesignation(
        data,
        successCreateDesignation,
        failureCreateDesignation
      )
    );
  };

  const successCreateDesignation = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("designation_title", "");
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    dispatch(getCommunities(`api/communities?community_id=${setCommunityId}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Designation Created Successfully");
    }, 1000);
  };

  const failureCreateDesignation = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.designation_title[0]
          : "Something went wrong."
      );
    }, 1000);
  };
  const handleEditDesignation = (data) => {
    data.id = editId;
    dispatch(updateDesignation(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setIsModalVisible(false);
    setEditId("");
    reset();
    setValue("designation_title", "");
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your Designation Updated Successfully");
    }, 1000);
  };

  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.designation_title[0]
          : "Something went wrong."
      );
    }, 1000);
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);

  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Designation" : "Add Designation"}
    >
      <Spin
        style={{ height: "100%", maxHeight: "unset" }}
        spinning={
          editId ? isUpdateDesignationsLoading : isCreateDesignationsLoading
        }
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditDesignation : handleCreateDesignation
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                Designation Title
              </Typography>
              <Controller
                as={<Input />}
                name="designation_title"
                control={control}
                className="inputLabel"
              />
              {errors.designation_title && (
                <p style={{ color: "red" }}>
                  {errors.designation_title.message}
                </p>
              )}
            </Col>
          </Row>

          <Row span={24}></Row>
          <Row span={24}></Row>
          <Row span={24}></Row>
          <Row span={24}></Row>

          <Row style={{ paddingTop: 20 }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {editId ? (
                <Button size="large" className="editButton" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button size="large" className="editButton" htmlType="submit">
                  Add
                </Button>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className="cancelButton"
                onClick={handleClose}
                size="large"
                // style={{ margin: "0 8px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Spin>
    </Drawer>
  );
};

export default DesignationModalForm;
