import API from "../Utils/API";
export const getBusiness = (url) => (dispatch) => {
  dispatch({ type: "GET_BUSINESS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_BUSINESS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_BUSINESS_FAILURE", payload: error.response.data });
    });
};
export const businessProfileById = (Id) => (dispatch) => {
  dispatch({ type: "BUSINESS_BY_ID_REQUEST" });
  API.get(`api/business_profiles/${Id}`)
    .then((response) => {
      dispatch({ type: "BUSINESS_BY_ID_SUCCESS", payload: response.data });
      // dispatch(getBusiness());
    })
    .catch((error) => {
      dispatch({
        type: "BUSINESS_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createBusiness =
  (data, successCreateBusiness, failureCreateBusiness) => (dispatch) => {
    dispatch({ type: "CREATE_BUSINESS_REQUEST" });
    API.post("api/business_profiles", data)
      .then((response) => {
        dispatch({ type: "CREATE_BUSINESS_SUCCESS", payload: response.data });
        successCreateBusiness && successCreateBusiness();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_BUSINESS_FAILURE",
          payload: error.response.data,
        });
        failureCreateBusiness && failureCreateBusiness();
      });
  };

export const deleteBusiness =
  (businessId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: "DELETE_BUSINESS_REQUEST" });
    API.delete(`api/business_profiles/${businessId}`)
      .then((response) => {
        dispatch({ type: "DELETE_BUSINESS_SUCCESS", payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_BUSINESS_FAILURE",
          payload: error.response.data,
        });
      });
  };

export const updateBusiness = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: "UPDATE_BUSINESS_REQUEST" });
  API.put(`api/business_profiles/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_BUSINESS_SUCCESS", payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_BUSINESS_FAILURE",
        payload: error.response.data,
      });
    });
};
