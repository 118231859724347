import API from "../Utils/API";
export const getUnderMaintenanceAction = (url) => (dispatch) => {
  dispatch({ type: "GET_MAINTENANCE_WINDOWS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({
        type: "GET_MAINTENANCE_WINDOWS_SUCCESS",
        payload: response.data,
      });
    //   SuccessCallback && SuccessCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "GET_MAINTENANCE_WINDOWS_FAILURE",
        payload: error.response?.data,
      });
    });
};

export const getMaintenanceById = (Id) => (dispatch) => {
    dispatch({ type: "GET_MAINTENANCE_WINDOWS_BY_ID_REQUEST" });
    API.get(`api/maintenance_windows/${Id}`)
      .then((response) => {
        dispatch({ type: "GET_MAINTENANCE_WINDOWS_BY_ID_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MAINTENANCE_WINDOWS_BY_ID_FAILURE",
          payload: error.response.data,
        });
      });
    };

  export const createMaintenanceAction = (data, successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_MAINTENANCE_WINDOWS_REQUEST", payload: data });
  
    API.post("api/maintenance_windows", data)
      .then((response) => {
        dispatch({ type: "CREATE_MAINTENANCE_WINDOWS_SUCCESS", payload: response.data });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_MAINTENANCE_WINDOWS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
  
      });
  };
  
  export const updateMaintenanceAction = (data, successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_MAINTENANCE_WINDOWS_REQUEST" });
    API.put(`api/maintenance_windows/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UPDATE_MAINTENANCE_WINDOWS_SUCCESS", payload: response.data });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_MAINTENANCE_WINDOWS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
  
      });
  };
  
  
  export const deleteUserMaintenanceAction =(Id, successCallback,failureCallback) => (dispatch) => {
      dispatch({ type: "DELETE_MAINTENANCE_WINDOWS_REQUEST" });
      API.delete(`api/maintenance_windows/${Id}`)
        .then((response) => {
          dispatch({
            type: "DELETE_MAINTENANCE_WINDOWS_SUCCESS",
            payload: response.data,
          });
          // dispatch(getEducationsAction());
          successCallback();
        })
        .catch((error) => {
          dispatch({
            type: "DELETE_MAINTENANCE_WINDOWS_FAILURE",
            payload: error.response.data,
          });
        failureCallback();
          
        });
    };
  