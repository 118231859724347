import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Empty,
  Typography,
  message,
  Card,
  Pagination,
  Tooltip,
  Breadcrumb,
  Popconfirm,
  Drawer,
  Input,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import CustomSearch from "../ReuseableComponents/CustomSearch/CustomSearch";
import { getSearchUser } from "../../Actions/searchUserAction";
import {
  createAddMemberAction,
  getFamilyById,
} from "../../Actions/familyAction";
const AddMemberDrawer = (props) => {
  const { setIsAddMemberDrawerVisible, isAddMemberDrawerVisible } = props;
  const dispatch = useDispatch();
  const {
    searchUsers,
    setCommunityId,
    isSearchUserLoading,
    Family,
    isCreateAddMemberLoading,
  } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const { setCommunityId } = state.communities;
    const { Family, isCreateAddMemberLoading } = state.family;

    return {
      searchUsers,
      setCommunityId,
      isSearchUserLoading,
      Family,
      isCreateAddMemberLoading,
    };
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    // resolver: yupResolver(
    //   tabKey === "2" && isEditBusinessId
    //     ? UpdateJobProfileSchema
    //     : tabKey === "2"
    //     ? JobProfileSchema
    //     : isEditBusinessId
    //     ? BusinessuserUpdateSchema
    //     : BusinessuserSchema
    // ),
  });

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);

  const closeAddMemberDrawer = () => {
    setIsAddMemberDrawerVisible(false);
    setErrorUserId(null);
    setSelectedUserId(null);
  };
  const handleAddMemberMethod = (data) => {
    if (selectedUserId) {
      data.family_id = Family?.id;
      data.user_id = selectedUserId;
      // dispatch
      dispatch(
        createAddMemberAction(data, SuccessCreateCallback, FailureCallback)
      );
    } else {
      setErrorUserId("User must be selected");
    }
  };
  const SuccessCreateCallback = () => {
    setIsAddMemberDrawerVisible(false);
    dispatch(getFamilyById(Family?.id));
    setTimeout(() => {
      message.success("Member Added Successfully");
    }, 1000);
  };
  const FailureCallback = (FailureResponse) => {
    setErrorUserId(FailureResponse?.family_id);
  };
  const onUserSearch = (data) => {
    if (data) {
      setSelectedUserId(data?.id);
    } else {
      setSelectedUserId(null);
    }
  };
  const clearSearchUserRequiredMessageMethod = () => {
    setErrorUserId(null);
  };
  return (
    <Drawer
      onClose={closeAddMemberDrawer}
      open={isAddMemberDrawerVisible}
      title="Add Member"
    >
      <form onSubmit={handleSubmit(handleAddMemberMethod)}>
        <Spin spinning={isCreateAddMemberLoading} size="large">
          <Typography style={{ paddingBottom: 10 }}>
            Search Community Member By Phone/Name
          </Typography>
          <Row gutter={[0, 10]}>
            <Col span={22}>
              <CustomSearch
                size="large"
                data={searchUsers?.users}
                placeholder="Search User"
                searchAction={getSearchUser}
                isDrawer={isAddMemberDrawerVisible}
                searchActionLoading={isSearchUserLoading}
                communityId={setCommunityId}
                onSearch={onUserSearch}
                clearRequiredMessageMethod={
                  clearSearchUserRequiredMessageMethod
                }
              />
              {errorUserId && <p style={{ color: "red" }}>{errorUserId}</p>}
            </Col>
            <Col span={24}>
              <Row gutter={[20, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => closeAddMemberDrawer()}>Cancel</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};
export default AddMemberDrawer;
