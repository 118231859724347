import React, { useState } from "react";
import {
  // Modal,
  // Input,
  Button,
  Typography,
  Row,
  Col,
  Select,
  // Divider,
  message,
  // Steps,
  // Upload,
  // TimePicker,
  // Checkbox,
  // DatePicker,
  Spin,
  Drawer,
  // Tag,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createUserRoleAction } from "../../Actions/usersAction";
import { getUserByIdAction } from "../../Actions/authenticationAction";
import { yupResolver } from "@hookform/resolvers/yup";
import { userRoleSchema } from "../../Utils/Schema";

const ChangeUserTypeDrawer = (props) => {
  const {
    isChangeUserTypeDrawerVisible,
    setIsChangeUserTypeDrawerVisible,
    // UserId,
  } = props;
  const dispatch = useDispatch();
  const { userById, isCreateUserRoleLoading } = useSelector((state) => {
    const { userById } = state.authentication;
    const { isCreateUserRoleLoading } = state.users;
    return {
      userById,
      isCreateUserRoleLoading,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    // reset,
    setValue,
    // trigger,
    // getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(userRoleSchema),
  });
  const [errorRequiredMessage, setErrorRequiredMessage] = useState("");

  const closeChangeUserTypeDrawer = () => {
    setIsChangeUserTypeDrawerVisible(false);
    setValue("user_type", "");
    setErrorRequiredMessage("");
    clearErrors();
  };
  const options = [
    {
      value: "admin",
      label: "Admin",
    },
  ];
  const handleChangeUserType = (data) => {
    data.user_id = userById?.id;
    dispatch(createUserRoleAction(data, SuccessCallback, FailureCallback));
  };
  const SuccessCallback = () => {
    setTimeout(() => {
      message.success("User role created successfully");
    }, 1000);
    setIsChangeUserTypeDrawerVisible(false);
    dispatch(getUserByIdAction(userById?.id));
  };
  const FailureCallback = (FailureResponse) => {
    setErrorRequiredMessage(FailureResponse.user_type[0]);
  };
  //   const handleOnClickMethod = () => {
  //     setErrorRequiredMessage("");
  //     setIsDropdownVisible(true);
  //   };
  //   const onSelectMethod = (Value) => {
  //     // setSelectedValue((prev) => [...prev, Value]);
  //     setIsDropdownVisible(false);
  //     setSelectedValue([Value]);

  //   };

  return (
    <Drawer
      open={isChangeUserTypeDrawerVisible}
      onClose={closeChangeUserTypeDrawer}
      title="Add User Type"
    >
      <form onSubmit={handleSubmit(handleChangeUserType)}>
        <Spin spinning={isCreateUserRoleLoading} size="large">
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                Select User Type
              </Typography>
              <Controller
                as={
                  <Select
                    placeholder="Select User type"
                    options={options}
                    style={{ width: "100%", borderRadius: "4px" }}
                    size="large"
                  />
                }
                name="user_type"
                control={control}
              />

              {errors.user_type && (
                <p style={{ color: "red" }}>{errors.user_type.message}</p>
              )}
              {errorRequiredMessage && (
                <p style={{ color: "red" }}>{errorRequiredMessage}</p>
              )}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => closeChangeUserTypeDrawer()}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};
export default ChangeUserTypeDrawer;
