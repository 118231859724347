import constants from "../Utils/constants";
const initialState = {
  allCountries: [],
  countries: {},
  setId: "",

  isCountriesLoading: false,
  isCreateCountriesLoading: false,
  isUpdateCountriesLoading: false,
  isDeleteUserCountriesLoading: false,
};

export const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isCountriesLoading: true,
      };

    case constants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isCountriesLoading: false,
        allCountries: action.payload,
      };
    case constants.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isCountriesLoading: false,
      };

    case constants.CREATE_COUNTRIES_REQUEST:
      return {
        ...state,
        isCreateCountriesLoading: true,
      };
    case constants.CREATE_COUNTRIES_SUCCESS:
      return {
        ...state,
        isCreateCountriesLoading: false,
      };
    case constants.CREATE_COUNTRIES_FAILURE:
      return {
        ...state,
        isCreateCountriesLoading: false,
      };

    case constants.UPDATE_COUNTRIES_REQUEST:
      return {
        ...state,
        isUpdateCountriesLoading: true,
      };
    case constants.UPDATE_COUNTRIES_SUCCESS:
      return {
        ...state,
        isUpdateCountriesLoading: false,
      };
    case constants.UPDATE_COUNTRIES_FAILURE:
      return {
        ...state,
        isUpdateCountriesLoading: false,
      };
    case constants.DELETE_COUNTRIES_REQUEST:
      return {
        ...state,
        isDeleteUserCountriesLoading: true,
      };
    case constants.DELETE_COUNTRIES_SUCCESS:
      return {
        ...state,
        isDeleteUserCountriesLoading: false,
      };
    case constants.DELETE_COUNTRIES_FAILURE:
      return {
        ...state,
        isDeleteUserCountriesLoading: false,
      };
    case constants.GET_SELECTED_COUNTRIES_ID:
      return {
        ...state,
        setId: action.payload,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
