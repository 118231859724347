import React, { useState, useEffect } from "react";
import {Button, Row, Col, Typography, Tabs , Card , Input} from "antd"
import LandingPageHeader from "./LandingPageHeader";
import LandingPageImage from "../../assets/LandingPageImage.png"
import "./LandingPage.css"
import {ArrowRightOutlined , CalendarOutlined , ShoppingOutlined , FacebookFilled , TwitterOutlined , InstagramOutlined , PhoneOutlined , MailOutlined , EnvironmentOutlined} from "@ant-design/icons";
import DocumentsImage from "../../assets/DocumentsImage.svg"
import LandingPageFamilyImage from "../../assets/LandingPageFamilyImage.svg"

import LandingPagePeopleImage from "../../assets/LandingPagePeopleImage.svg"
import ContactImage from "../../assets/ContactImage.png"
// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import ContactResponsiveImage from "../../assets/ContactResponsiveImage.png"

import {history} from "../../Utils/history"
const Footer = () => {
    const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();


    // const {IsMD , IsSM , IsXS , IsLG , IsXL} = useBreakpoints();
    return(
        <>
                        <div style={{"height":IsXS ? "302px" : "142px" ,
                         "marginTop":"10%",
                          "paddingTop":"3%"}}>
                    <Row justify={IsXS ? "space-around"             : "start"}>

                <Col xl={24} lg={24} xs={16} md={24} sm={24}>
       
                <Row justify={IsXS ?     "start" : "space-around"}>            
                    <Col>
                    <Row gutter={[0,10]}> 
                        <Col span={24}>
                        <Typography className="cardTitle">

                    Subscribe to Samaj
                    </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography className="cardTitle2">
                            Start following us   
                            </Typography>
                        </Col>
                    </Row>
                    
                    </Col>
                    <Col>
  
                    <Row gutter={[0,{xs:50}]}>
                   
                   
                   
                   
                    {IsXS &&
                    <Col span={24}/>
}
                    <Button className="contactSectionButton">Start Subscribe</Button>
                    </Row>
                    </Col>
                
                </Row>
                </Col>
                </Row>
           </div>

            <div className="footer1Section" style={{"height":(IsXS || IsSM) ? "700px" : "272px"}}>

           
           


<Row justify={IsXS ? "space-around" : "start"}>
     <Col xs={16} lg={24} xl={24}            sm={24}      md={24}>
     <Row justify="space-around" gutter={[0,{sm:50 , xs:50}]}>
 <Col xl={4} lg={4}   md={4}       sm={15} xs={24}>
     <Row gutter={[0,10]} justify="center">
         <Col span={24}>
             <Typography className="documentsTitle" style={{"textAlign":(IsSM) ? "center" : "start"}}>
             Company Info
             </Typography>
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
    <a onClick={() => history.push("/aboutUs")}>About Us</a>
        
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
<a href="#features" onClick={() => history.push("/")}>Features</a>
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
                 <a onClick={() =>       history.push("/Faqs")}>FAQs</a>





         </Col>
     </Row>
 </Col>

 <Col xl={4} lg={4}   md={4}       sm={15} xs={24}>
     <Row gutter={[0,10]} justify="center">
         <Col span={24}>
             <Typography className="documentsTitle" style={{"textAlign":(IsSM) ? "center" : "start"}}>
             Legal
             </Typography>
         </Col>

         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
                 

                         <a          onClick={() => history.push("/termsAndConditions")}>T&C</a>
         </Col>
 
 
 
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
             <a>Disclaimer</a>
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
             <a>Privacy Policy</a>
         </Col>
     </Row>
 </Col>

 {/* <Col span={4}>
     <Row gutter={[0,10]}>
         <Col span={24}>
             <Typography className="documentsTitle" style={{"textAlign":"start"}}>
             Features
             </Typography>
         </Col>
         <Col span={24}>
             <a>Business or Jobs</a>
         </Col>
         <Col span={24}>
             <a>Matrimonial</a>
         </Col>
         <Col span={24}>
             <a>Events</a>
         </Col>
     </Row>
 </Col>

 <Col span={4}>
    
     <Row gutter={[0,10]}>
         <Col span={24}>
             <Typography className="documentsTitle" style={{"textAlign":"start"}}>
             More
             </Typography>
         </Col>
         <Col span={24}>
         
             <a>Find Other People</a>                    
         </Col>
         <Col span={24}>
             <a>Add Your Family</a>
         </Col>
         <Col span={24}>
             <a>Documents</a>
         </Col>
    
     </Row>
 </Col> */}

 <Col xl={4} lg={4}   md={4}       sm={15} xs={24}>
     <Row gutter={[0,10]}>
         <Col span={24}>
                                      <Typography className="documentsTitle" style={{"textAlign":(IsSM) ? "center" : "start"}}>
                     Get In Touch
             </Typography>
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
                 <a style={{"display":"flex" , "height":"100%" , "textAlign":"start"}}><PhoneOutlined style={{"fontSize":"22px" , "marginRight":"4px" , "color":"#347AF0"}}/> (992) 555-0103</a>
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
             <a style={{"display":"flex" , "height":"100%" , "textAlign":"start"}}><EnvironmentOutlined style={{"fontSize":"22px" , "marginRight":"4px" , "color":"#347AF0"}}/> Samaj Ahmedabad.</a>
         </Col>
         <Col span={24} style={{"display":"flex","justifyContent":(IsSM) ? "center" : "start"}}>
             <a style={{"display":"flex" , "height":"100%" , "textAlign":"start"}}><MailOutlined style={{"fontSize":"22px" , "marginRight":"4px" , "color":"#347AF0"}}/> samaj@example.com</a>
         </Col>
     </Row>
 
 </Col>
 </Row>
</Col>
 </Row> 
</div>

<div       className="footer2Section"    style={{"height":(IsXS || IsSM) ? "207px" : "72px"}}>
     <Row justify="center" align="middle" style={{"height":"100%"}}>
<Col xl={17} lg={20} md={20} sm={24} xs={24}>

<Row justify={(IsXS || IsSM) ? "center" : "space-between"} gutter={[0,{xs:50 , sm:50 , md:0 , lg:0 , xl:0}]}>
 <Col>
     <Typography className="footer2SectionTitle">© Samaj.all rights reserved.</Typography>
 </Col>
 <Col xs={24} xl={4}   lg={4} md={4} sm={24}>
     <Row gutter={[22,0]} justify={(IsXS || IsSM) ? "center" : "start"}>
         <Col>
             <FacebookFilled style={{"color":"#347AF0" , "fontSize":"22px"}}/>
         </Col>
         <Col>
             <InstagramOutlined style={{"color":"#347AF0" , "fontSize":"22px"}}/>
         </Col>
         <Col>
             <TwitterOutlined style={{"color":"#347AF0","fontSize":"22px"}}/>
         </Col>
     </Row>
 </Col>
</Row>
</Col>
</Row>
</div>
        </>
    )
}

export default Footer;