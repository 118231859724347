import constants from "../Utils/constants";
const initialState = {
  allContactus: [],
  contactus: {},
  setId: "",

  isContactusLoading: false,
  isCreateContactusLoading: false,
  isUpdateContactusLoading: false,
  isDeleteUserContactusLoading: false,
};

export const contactusReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CONTACTUS_REQUEST:
      return {
        ...state,
        isContactusLoading: true,
      };

    case constants.GET_CONTACTUS_SUCCESS:
      return {
        ...state,
        isContactusLoading: false,
        allContactus: action.payload,
      };
    case constants.GET_CONTACTUS_FAILURE:
      return {
        ...state,
        isContactusLoading: false,
      };

    case constants.DELETE_CONTACTUS_REQUEST:
      return {
        ...state,
        isDeleteUserContactusLoading: true,
      };
    case constants.DELETE_CONTACTUS_SUCCESS:
      return {
        ...state,
        isDeleteUserContactusLoading: false,
      };
    case constants.DELETE_CONTACTUS_FAILURE:
      return {
        ...state,
        isDeleteUserContactusLoading: false,
      };
    case constants.GET_SELECTED_CONTACTUS_ID:
      return {
        ...state,
        setId: action.payload,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
