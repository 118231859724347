import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Drawer, message, Input } from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CountrySchema } from "../../../Utils/Schema";
import {
  getCountriesAction,
  createCountriesAction,
  updateCountriesAction,
} from "../../../Actions/ConutriesAction";
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
const CountriesModalForm = (props) => {
  const {
    editId,
    setEditId,
    currentPage,
    setCurrentPage,
    isModalVisible,
    setIsModalVisible,
  } = props;
  const [preloadedCountriesValue, setPreloadedCountriesValue] = useState("");
  const dispatch = useDispatch();
  const { allCountries } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { allCountries } = state.countries;
    return {
      currentUser,
      allCountries,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedCountriesValue },
    resolver: yupResolver(CountrySchema),
  });

  const the_country = _.filter(allCountries.countries, function (o) {
    return o.id === editId;
  });
  useEffect(() => {
    if (editId) {
      setPreloadedCountriesValue(the_country[0]);
      reset(the_country[0]);
    }
  }, [the_country[0], reset]);
  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("country_name", "");
    setValue("country_sort_name", "");
    setValue("country_code", "");
    clearErrors();
  };

  const handleCreateCountries = (data) => {
    dispatch(
      createCountriesAction(data, successCreateCountries, failureCountries)
    );
  };
  const successCreateCountries = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("country_name", "");
    setValue("country_sort_name", "");
    setValue("country_code", "");
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Country Created Successfully");
    }, 1000);
  };

  const failureCountries = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.country_name[0]
          : "Something went wrong."
      );
    }, 1000);
  };

  const handleEditCountries = (data) => {
    data.id = editId;
    dispatch(updateCountriesAction(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId("");
    reset();
    setValue("country_name", "");
    setValue("country_sort_name", "");
    setValue("country_code", "");
    dispatch(getCountriesAction(`api/countries?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Country Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.country_name[0]
          : "Something went wrong."
      );
    }, 1000);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Country" : "Add Country"}
    >
      <form
        onSubmit={handleSubmit(
          editId ? handleEditCountries : handleCreateCountries
        )}
      >
        <Row gutter={[0, 10]}>
          <Col span={24} className="formRows">
            <Typography className="details" style={{ paddingBottom: "4px" }}>
              Name
            </Typography>
            <Controller
              as={<Input size="large" />}
              name="country_name"
              control={control}
              className="inputLabel"
            />

            {errors.country_name && (
              <p style={{ color: "red" }}>{errors.country_name.message}</p>
            )}
          </Col>
        </Row>

        <Row gutter={[0, 10]}>
          <Col span={24} className="formRows">
            <Typography className="details" style={{ paddingBottom: "4px" }}>
              Country Short Name
            </Typography>
            <Controller
              as={<Input size="large" />}
              name="country_sort_name"
              control={control}
              className="inputLabel"
            />

            {errors.country_sort_name && (
              <p style={{ color: "red" }}>{errors.country_sort_name.message}</p>
            )}
          </Col>
        </Row>

        <Row gutter={[0, 10]}>
          <Col span={24} className="formRows">
            <Typography className="details" style={{ paddingBottom: "4px" }}>
              Country Code
            </Typography>
            <Controller
              as={<Input size="large" style={{height:40}} prefix={<PlusOutlined />} />}
              name="country_code"
              control={control}
              className="inputLabel"
            />
            {errors.country_code && (
              <p style={{ color: "red" }}>{errors.country_code.message}</p>
            )}
          </Col>
        </Row>

        <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {editId ? (
              <Button size="large" className="editButton" htmlType="submit">
                Update
              </Button>
            ) : (
              <Button size="large" className="editButton" htmlType="submit">
                Add
              </Button>
            )}
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              className="cancelButton"
              onClick={handleClose}
              size="large"
              // style={{ margin: "0 8px" }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
};

export default CountriesModalForm;
