import constants from "../Utils/constants";
const initialState = {
  isGetUsersLoading: false,
  isCreateUsersLoading: false,
  isDeleteUsersLoading: false,
  isUpdateUsersLoading: false,
  allUsers: [],
  user: {},
  setUserId: "",
  isCreateUserRoleLoading: false,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_REQUEST:
      return {
        ...state,
        isGetUsersLoading: true,
      };
    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetUsersLoading: false,
        allUsers: action.payload,
      };
    case constants.GET_USERS_FAILURE:
      return {
        ...state,
        isGetUsersLoading: false,
      };
    case constants.CREATE_USERS_REQUEST:
      return {
        ...state,
        isCreateUsersLoading: true,
      };
    case constants.CREATE_USERS_SUCCESS:
      return {
        ...state,
        isCreateUsersLoading: false,
      };
    case constants.CREATE_USERS_FAILURE:
      return {
        ...state,
        isCreateUsersLoading: false,
      };
    case constants.DELETE_USERS_REQUEST:
      return {
        ...state,
        isDeleteUsersLoading: true,
      };
    case constants.DELETE_USERS_SUCCESS:
      return {
        ...state,
        isDeleteUsersLoading: false,
      };
    case constants.DELETE_USERS_FAILURE:
      return {
        ...state,
        isDeleteUsersLoading: false,
      };
    case constants.UPDATE_USERS_REQUEST:
      return {
        ...state,
        isUpdateUsersLoading: true,
      };
    case constants.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        isUpdateUsersLoading: false,
      };
    case constants.UPDATE_USERS_FAILURE:
      return {
        ...state,
        isUpdateUsersLoading: false,
      };

    case constants.CREATE_USER_ROLE_REQUEST:
      return {
        ...state,
        isCreateUserRoleLoading: true,
      };
    case constants.CREATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        isCreateUserRoleLoading: false,
      };
    case constants.CREATE_USER_ROLE_FAILURE:
      return {
        ...state,
        isCreateUserRoleLoading: false,
      };

    case constants.GET_SELECTED_USERS_ID:
      return {
        ...state,
        setUserId: action.payload,
      };

    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
