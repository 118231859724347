import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Pagination,
  Tooltip,
  Popconfirm,
  message,
  Spin,
} from "antd";
import _ from "lodash";
import CommunityNativeModalForm from "./CommunityNativeModalForm";
import { history } from "../../../Utils/history";
import { getCommunityById } from "../../../Actions/communitiesAction";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Edit2 from "../../../assets/Edit2.svg";
import Delete from "../../../assets/delete.svg";
import {
  getCommunitiesNative,
  updateCommunitiesNativeAction,
  deleteCommunityNative,
} from "../../../Actions/communitiesNativeAction";
const CommunityMeetings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [communityNativeId, setCommunityNativeId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedNative, setSelectedNative] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { isCommunitiesNativeLoading, allCommunitiesNative, setCommunityId } =
    useSelector((state) => {
      const { isCommunitiesNativeLoading, allCommunitiesNative } =
        state.communitiesNative;
      const { setCommunityId } = state.communities;
      return {
        isCommunitiesNativeLoading,
        setCommunityId,
        allCommunitiesNative,
      };
    });
    const { total_length } = allCommunitiesNative || {};
  useEffect(() => {
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [dispatch, setCommunityId, currentPage]);
  let UnpublishData = {};
  let PublishData = {};
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };

  const the_name = _.filter(
    allCommunitiesNative.community_natives,
    function (o) {
      return o.id === valName;
    }
  );
  const onClickCommunityNative = () => {
    setSelectedNative("");
    setValName("");
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleDeleteNative = (Id) => {
    dispatch(deleteCommunityNative(Id, successDelete, faliureDelete));
  };
  const successDelete = () => {
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setTimeout(() => {
      message.success("your community native delete successful.");
    }, 1000);
  };

  const handlePublishCommunityMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.is_published = false;
    dispatch(
      updateCommunitiesNativeAction(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishCommunityMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.is_published = true;
    dispatch(
      updateCommunitiesNativeAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Community Published Successfully");
    }, 1000);
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Community Unpublished Successfully");
    }, 1000);
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const Columns = [
    {
      title: "Native Name",
      dataIndex: "name",
      width: "80%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { is_published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} alt=""/>}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              {is_published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublishCommunityMethod(id)}
                    icon={<img src={PublishedIcon} alt=""/>}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnpublishCommunityMethod(id)}
                    icon={<img src={UnpublishedIcon} alt=""/>}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteNative(id)}
                  okText="Ok"
                  cancelText="cancel"
                >
                  <Button icon={<img src={Delete} alt="" />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCommunitiesNativeLoading}>
      <Row gutter={[32, 32]} 
      className="community"
      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickCommunityNative()}
              className="breadcrumUsers"
            >
              Community Native
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => setIsModalVisible(true)}
              >
                Add Native
              </Button>
            </Col>
          </Row>
          <CommunityNativeModalForm
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            communityNativeId={communityNativeId}
            setCommunityNativeId={setCommunityNativeId}
            setValName={setValName}
            valName={valName}
            setSelectedNative={setSelectedNative}
            selectedNative={selectedNative}
            current={currentPage}
            setCurrentPage={setCurrentPage}
            isModalVisible={isModalVisible}
          ></CommunityNativeModalForm>
        </Col>
        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable
                columns={Columns}
                data={allCommunitiesNative?.community_natives}
              />
            </Col>
          </Row>
        </Col>
        {allCommunitiesNative && total_length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allCommunitiesNative?.total_length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
      </Row>
    </Spin>
  );
};

export default CommunityMeetings;
