import React from "react";
import {Button, Result } from "antd";
import { useSelector } from "react-redux";
import { history } from "../Utils/history";
const EmptyScreen = () => {
  const { isAuthenticated } = useSelector((state) => {
    const { isAuthenticated } = state.authentication;
    return {
      isAuthenticated,
    };
  });
  const HandleRouteMethod = () => {
    history.push(isAuthenticated ? "/addFamily" : "/");
  };
  return (
    <div
      
    style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Result
        status="403"
        title="404"
        subTitle="Page not found"
        extra={
          <Button type="primary" onClick={() => HandleRouteMethod()}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default EmptyScreen;
