import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tabs, Typography, Card } from "antd";
import "./CommunityCard.css";
import moment from "moment/moment";
const CommunityTextCard = (props) => {
  const { mappedData, isCommunityCardModal } = props;
  const mappedDataDate = moment(mappedData?.created_at).format("MMM Do YYYY");
  const date = new Date(mappedData?.expiry_date);
  const typedDataDate = moment(date).format("MMM Do YYYY");
  return (
    <div>
      <Card className="communityCard">
        <Row gutter={[0, 10]} justify="space-between">
          <Col>
            <Typography className="CommunityTextCardTitle">
              {mappedData?.name}
            </Typography>
          </Col>
          <Col>
            <Typography className="communityCardDate">
              {isCommunityCardModal ? typedDataDate : mappedDataDate}
            </Typography>
          </Col>
          <Col span={24}>
            <Typography className="communityCardDescription">
              {mappedData?.description}
            </Typography>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CommunityTextCard;
