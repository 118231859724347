import API from "../Utils/API";
export const getContactusAction = (url) => (dispatch) => {
  dispatch({ type: "GET_CONTACTUS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_CONTACTUS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_CONTACTUS_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getContactusById = (Id) => (dispatch) => {
  dispatch({ type: "GET_CONTACTUS_BY_ID_REQUEST" });
  API.get(`api/contacts/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_CONTACTUS_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_CONTACTUS_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const deleteUserContactusAction =
  (Id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_CONTACTUS_REQUEST" });
    API.delete(`api/contacts/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_CONTACTUS_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_CONTACTUS_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };
