import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Spin,
  Modal,
} from "antd";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import "./Ticket.css";
import "../../../index.css";
import { CloseOutlined } from "@ant-design/icons";
const DisplayImageModal = (props) => {
  const {
    isDisplayImageVisible,
    setIsDisplayImageVisible,
    selectedImagesState,
  } = props;
  const handleCloseModalMethod = () => {
    setIsDisplayImageVisible(false);
  };
  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={isDisplayImageVisible}
        footer={false}
        onCancel={handleCloseModalMethod}
        className="zeroPaddingModal"
        closeIcon={
          <CloseOutlined
            size={8}
            style={{
              background: "transparent",
              borderRadius: 20,
              background: "#FFFFFF",
              // padding: 5,
              color: "#2accff",
            }}
          />
        }
      >
        <Row>
          <Col span={24}>
            <ImageGallery
              items={selectedImagesState}
              additionalClass="ImageViewMatrimonial"
              // renderRightNav={renderRightNav}
              showNav={false}
              showBullets={true}
              showThumbnails={false}
              originalClass="ImageViewMatrimonial"
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default DisplayImageModal;
