import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Empty,
  Typography,
  message,
  Drawer,
  Input,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { getCommunitiesNative } from "../../Actions/communitiesNativeAction";
import { getSearchUser } from "../../Actions/searchUserAction";
import {
  createFamily,
  getFamilies,
  getFamilyById,
  updateFamily,
} from "../../Actions/familyAction";
import CustomSearch from "../ReuseableComponents/CustomSearch/CustomSearch";
import { FamilySchema } from "../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { falseyValueCases } from "../CommonLogics/CommonMethods";
const FamilyDrawer = (props) => {
  const {
    isFamilyDrawerVisible,
    setIsFamilyDrawerVisible,
    isUpdateFamily,
    FamilyId,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();
  const {
    setCommunityId,
    searchUsers,
    allFamilies,
    isCreateFamilyLoading,
    isFamiliesLoading,
    isUpdateFamilyLoading,
    allCommunitiesNative,
    Family,
    isSearchUserLoading,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const {
      allFamilies,
      isCreateFamilyLoading,
      isFamiliesLoading,
      isDeleteFamilyLoading,
      isUpdateFamilyLoading,
      Family,
    } = state.family;
    const { allCommunitiesNative } = state.communitiesNative;
    return {
      isSearchUserLoading,
      isCreateFamilyLoading,
      isFamiliesLoading,
      isDeleteFamilyLoading,
      isUpdateFamilyLoading,
      currentUser,
      community,
      allCommunities,
      setCommunityId,
      searchUsers,
      allFamilies,
      allCommunitiesNative,
      Family,
    };
  });
  useEffect(() => {
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}`
      )
    );
  }, [dispatch, setCommunityId]);
  const FamilyHead = _.filter(Family?.user_families, (val) => {
    return Family?.owner_id === val?.user?.id;
  });
  if (isUpdateFamily) {
    Family.community_native_id = Family?.community_native?.name;
    Family.user_id = FamilyHead[0]?.user?.first_name;
  }
  const [preloadedFamilyValue, setPreloadedFamilyValue] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    // defaultValues: isEditBusinessId && preloadedBusinessProfileValue,
    resolver: yupResolver(FamilySchema),
  });
  useEffect(() => {
    if (isUpdateFamily) {
      setPreloadedFamilyValue(Family);
      reset(Family);
    }
  }, [Family, reset]);
  const closeFamilyDrawer = () => {
    setIsFamilyDrawerVisible(false);
    setValue("community_native_id", "");
    setValue("last_name", "");
    setValue("user_id", "");
    clearErrors();
    setErrorUserId(null);
    setSelectedUserId(null);
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const handleAddFamilyMethod = (data) => {
    if (selectedUserId) {
      data.user_id = selectedUserId;
      dispatch(createFamily(data, SuccessCreateFamilyMethod));
    } else {
      setErrorUserId("User must be selected");
    }
  };
  const SuccessCreateFamilyMethod = () => {
    reset();
    setIsFamilyDrawerVisible(false);
    dispatch(getFamilies(`api/families?community_id=${setCommunityId}&page=1`));
    setValue("community_native_id", "");
    setValue("last_name", "");
    setValue("user_id", "");
    clearErrors();
    setTimeout(() => {
      message.success("Family Created Successfully");
    }, 1000);
  };
  const handleEditFamilyMethod = (data) => {
    data.id = FamilyId;
    data.community_native_id = containsNumber(data.community_native_id)
      ? data.community_native_id
      : Family?.community_native?.id;
    data.user_id = containsNumber(data.user_id)
      ? data.user_id
      : Family?.owner_id;
    dispatch(updateFamily(data, handleSuccessEditFamilyMethod));
  };
  const handleSuccessEditFamilyMethod = () => {
    setIsFamilyDrawerVisible(false);
    setTimeout(() => {
      message.success("Family Edited Successfully");
    }, 1000);
    dispatch(getFamilyById(Family?.id));
  };
  const closeEditFamilyDrawer = () => {
    setIsFamilyDrawerVisible(false);
    reset(Family);
    clearErrors();
  };
  const onUserSearch = (data) => {
    if (data) {
      setSelectedUserId(data?.id);
    } else {
      setSelectedUserId(null);
    }
  };
  const clearSearchUserRequiredMessageMethod = () => {
    setErrorUserId(null);
  };
  const changeFamilyHeadUsers = _.filter(Family?.user_families, (val) => {
    return (
      Family?.owner_id !== val?.user?.id && !falseyValueCases(val?.user?.phone)
    );
  });
  return (
    <Drawer
      open={isFamilyDrawerVisible}
      onClose={isUpdateFamily ? closeEditFamilyDrawer : closeFamilyDrawer}
      title={isUpdateFamily ? "Edit Family" : "Add Family"}
    >
      <form
        onSubmit={handleSubmit(
          isUpdateFamily ? handleEditFamilyMethod : handleAddFamilyMethod
        )}
      >
        <Spin
          spinning={
            isUpdateFamily ? isUpdateFamilyLoading : isCreateFamilyLoading
          }
          size="large"
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>Last Name</Typography>
              <Controller
                as={<Input size="large" />}
                name="last_name"
                control={control}
              />
              {errors.last_name && (
                <p style={{ color: "red" }}>{errors.last_name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                Community Native
              </Typography>
              <Controller
                name="community_native_id"
                as={
                  <Select
                    size="large"
                    placeholder="Select community native"
                    style={{ width: "100%", borderRadius: "4px" }}
                    showSearch={true}
                    options={
                      allCommunitiesNative &&
                      allCommunitiesNative?.total_length > 0 &&
                      allCommunitiesNative?.community_natives?.map((val, i) => {
                        return {
                          label: val.name,
                          value: val.id,
                        };
                      })
                    }
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  ></Select>
                }
                control={control}
              />
              {errors.community_native_id && (
                <p style={{ color: "red" }}>
                  {" "}
                  {errors.community_native_id.message}
                </p>
              )}
            </Col>
            {!isUpdateFamily && (
              <Typography>Search Community Member By Phone/Name</Typography>
            )}
            {!isUpdateFamily && (
              // <Col span={24}>
              //   <Typography style={{ paddingBottom: 10 }}>User name</Typography>
              //   <Controller
              //     name="user_id"
              //     as={
              //       <Select
              //         size="large"
              //         placeholder="Select user name"
              //         style={{ width: "100%", borderRadius: "4px" }}
              //         className="inputLabel"
              //         showSearch={true}
              //         options={
              //           searchUsers &&
              //           // total_length > 0 &&
              //           searchUsers?.total_length > 0 &&
              //           searchUsers?.users?.map((val, i) => {
              //             return {
              //               label: val.first_name,
              //               value: val.id,
              //             };
              //           })
              //         }
              //         filterOption={(input, option) =>
              //           option.label
              //             .toLowerCase()
              //             .indexOf(input.toLowerCase()) >= 0
              //         }
              //       ></Select>
              //     }
              //     control={control}
              //   />
              //   {errors.user_id && (
              //     <p style={{ color: "red" }}> {errors.user_id.message}</p>
              //   )}
              // </Col>

              <Col span={22}>
                <CustomSearch
                  size="large"
                  data={searchUsers?.users}
                  placeholder="Search User"
                  searchAction={getSearchUser}
                  isDrawer={isFamilyDrawerVisible}
                  searchActionLoading={isSearchUserLoading}
                  communityId={setCommunityId}
                  onSearch={onUserSearch}
                  clearRequiredMessageMethod={
                    clearSearchUserRequiredMessageMethod
                  }
                />
                {errorUserId && <p style={{ color: "red" }}>{errorUserId}</p>}
              </Col>
            )}
            {isUpdateFamily && (
              <Col span={24}>
                <Typography style={{ paddingBottom: 10 }}>
                  Change Family Head
                </Typography>
                <Controller
                  name="user_id"
                  as={
                    <Select
                      size="large"
                      options={
                        changeFamilyHeadUsers?.length > 0 &&
                        changeFamilyHeadUsers?.map((val, i) => {
                          return {
                            label: val.user.first_name,
                            value: val.user.id,
                          };
                        })
                      }
                      style={{ width: "100%", borderRadius: "4px" }}
                      // defaultValue={FamilyHead?.map((user) => {
                      //   return {
                      //     label: user.user.first_name,
                      //     value: user.user.id,
                      //   };
                      // })}
                    />
                  }
                  control={control}
                />
              </Col>
            )}
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {isUpdateFamily ? "Edit" : "Add"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      isUpdateFamily
                        ? closeEditFamilyDrawer()
                        : closeFamilyDrawer()
                    }
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};
export default FamilyDrawer;
