import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  // Avatar,
  PageHeader,
  Descriptions,
  Spin,
} from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
import { getEducationsAction } from "../../Actions/educationAction";
import { history } from "../../Utils/history";

import { useDispatch, useSelector } from "react-redux";
import {
  // deleteMatrimonial,
  matrimonialById,
  getMatrimonialSuperAdmin,
  getMatrimonial
} from "../../Actions/matrimonialAction";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import _ from "lodash";
import {
  // CountryViseFormat,
  ImageEnv,
} from "../../Screens/CommonLogics/CommonMethods";
const MatrimonialProfileDetails = (props) => {
  const { t } = useTranslation();

  const { setViewMatrimonialProfileId, viewMatrimonialProfileId } = props;
  const { matrimonialProfile, isMatrimonialByIdLoading,community } =
    useSelector((state) => {
      const {
        matrimonialProfile,
        isMatrimonialLoading,
        isMatrimonialByIdLoading,
      } = state.matrimonial;
      const { community } = state.communities;
      return {
        community,
        matrimonialProfile,
        isMatrimonialLoading,
        isMatrimonialByIdLoading,
      };
    });
  const { address, description, height, weight,birth_place,birth_time,current_city,marital_status, user } =
    matrimonialProfile || {};
  // const handleDeleteMatrimonial = (matrimonialId) => {
  //   dispatch(deleteMatrimonial(matrimonialId));
  //   setViewMatrimonialProfileId(false);
  // };

  const handleCloseMatrimonialProfile = () => {
    // setViewMatrimonialProfileId("");
    history.push("/matrimonial");
    dispatch(getMatrimonial(""));
    dispatch({
      type: "GET_SELECTED_MATRIMONIAL_ID",
      payload: "",
    });
    // dispatch({ type: "RESET_MATRIMONIAL_BY_ID_DATA" });
  };
  // const secondColor = "#" + Math.floor(Math.random() * 1677).toString(14);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(matrimonialById(viewMatrimonialProfileId));
    dispatch(getEducationsAction("api/educations"));
  }, []);
  // const images = [
  //   {
  //     original: "https://picsum.photos/id/400/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1018/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1019/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1019/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  // ];
  const Images = _.map(
    matrimonialProfile?.matrimonial_user_image_keys,
    (image, i) => {
      return { original: ImageEnv(image?.key) };
    }
  );
  // const isPhonePresent = _.isEmpty(user?.phone);
  // const isEmailPresent = _.isEmpty(user?.email);
  // const IsEmpty =
  //   user?.phone_with_country_code === "null"
  //     ? true
  //     : user?.phone_with_country_code === "undefined"
  //     ? true
  //     : _.isEmpty(user?.phone_with_country_code);
  return (
    <Spin spinning={isMatrimonialByIdLoading}>
      <Row justify="space-around">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <ArrowLeftOutlined
            style={{ paddingBottom: "20px", fontSize: "20px" }}
            onClick={() => handleCloseMatrimonialProfile()}
          />
        </Col>
        <Col span={24}>
          <Card className="profileDetails-card">
            {/* <Row justify="end"> 
             <Col span={4}>
                    <Avatar size={45} src={ImageEnv(matrimonialProfile?.matrimonial_user_image)}>
                      <Typography style={{ fontSize: "40px", color: "white" }}>
                        {user?.first_name}
                      </Typography>
                    </Avatar>
                  </Col></Row> */}
            <Row gutter={[20, { sm: 20, xl: 0, xxl: 0, md: 0, xs: 20 }]}>
              {matrimonialProfile?.matrimonial_user_image_keys?.length > 0 && (
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                 {/* <Avatar size={105}>
                      <Typography style={{ fontSize: "40px", color: "white" }}>
                        {user?.first_name}
                      </Typography>
                    </Avatar> */}
                  <ImageGallery
                    items={Images}
                    additionalClass="ImageViewMatrimonial"
                    // renderRightNav={renderRightNav}
                    showNav={false}
                    showBullets={true}
                    showThumbnails={false}
                    originalClass="ImageViewMatrimonial"
                  />
                </Col>
              )}
              <Col
              span={18}
              >
                {/* <Row gutter={[0, 17]} align="middle"> */}
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography className="profile-name">
                      {user?.first_name}&nbsp;{user?.last_name}
                    </Typography>
                  </Col>

                  {/* </Row> */}
                   <Divider/>
                   
                    <Row gutter={[16,16]}>
                   <Col span={12}>
                          <Typography  component="span"
                              className="UserDetailBirthDateText">
                            Phone No.&nbsp;:&nbsp;-
                          </Typography>
                          &nbsp;
                        <Typography component="span"
                              className="UserDetailBirthDateData">
                          {user?.phone ||
                            user?.phone_with_country_code}
                        </Typography>
                        </Col>
                     
                        <Col span={12}>
                          <Typography  component="span"
                              className="UserDetailBirthDateText">
                            Email.&nbsp;:&nbsp;-
                          </Typography>
                          &nbsp;
                        <Typography component="span"
                              className="UserDetailBirthDateData">
                          {user?.email}
                        </Typography>
                        </Col>
                     
                    
                     <Col span={12}>
                          <Typography component="span"
                              className="UserDetailBirthDateText">
                            Education&nbsp;:&nbsp;-
                          </Typography>
                          &nbsp;
                        <Typography component="span"
                              className="UserDetailBirthDateData">
                          {" "}
                          {user?.education?.name}
                        </Typography>
                        </Col>
                      
                         <Col span={12}>
                          <Typography component="span"
                              className="UserDetailBirthDateText">
                            Industry&nbsp;:&nbsp;-
                          </Typography>
                      
                        <Typography component="span"
                              className="UserDetailBirthDateData">
                          {" "}
                          {user?.industry?.name}
                        </Typography>
                        </Col>
                  
                      <Col span={12}>
                      <Typography  component="span"
                              className="UserDetailBirthDateText">Community&nbsp;:&nbsp;-</Typography>
                      <Typography  component="span"
                              className="UserDetailBirthDateData">{community?.name}</Typography>
                      </Col>
                      <Col span={12} >
                        <Typography component="span"
                              className="UserDetailBirthDateText">
                          {t("content.22")}&nbsp;:&nbsp;-
                        </Typography>
                        <Typography component="span"
                              className="UserDetailBirthDateData">
                          Hindu
                        </Typography>
                      </Col>
                    {/* </Row> */}
                  {/* </Col> */}
                </Row>
                <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                  <Typography className="profile-address">{address}</Typography>
                </Col>
              </Col>
            </Row>
            {/* <Divider /> */}

            <Row gutter={[24, 32]}>
              <Col span={24}></Col>
            
              {/* <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                {!(isEmailPresent && isPhonePresent) && (
                  <PageHeader
                    title={t("content.29")}
                    className="pageHeaderDetails"
                    extra={[
                      <Typography className="pageHeaderSubDetails">
                        {education?.name}
                      </Typography>,
                    ]}
                    style={{ padding: "7px 20px" }}
                  ></PageHeader>
                )}
                <Descriptions column={1} style={{ padding: "2px 20px" }}>
                  {!isPhonePresent && (
                    <Descriptions.Item
                      label={
                        <Typography className="descriptionDetails">
                          {t("content.34")}
                        </Typography>
                      }
                    >
                      <Typography className="pageHeaderSubDetails">
                        {IsEmpty === false &&
                          user?.phone_with_country_code &&
                          CountryViseFormat(user?.phone_with_country_code)}
                      </Typography>
                    </Descriptions.Item>
                  )}
                  {!isEmailPresent && (
                    <Descriptions.Item
                      label={
                        <Typography className="descriptionDetails">
                          {t("content.35")}
                        </Typography>
                      }
                    >
                      <Typography className="pageHeaderSubDetails">
                        {user?.email}
                      </Typography>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </Col> */}
            </Row>

            <Row justify="space-around" gutter={[24, 16]}>
              <Col span={24}></Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <PageHeader
                  title={t("content.30")}
                  className="pageHeaderDetails"
                  // style={{ padding: "7px 20px" }}
                ></PageHeader>
                <Descriptions column={1} 
                // style={{ padding: "2px 20px" }}
                >
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                        {t("content.36")}
                      </Typography>
                    }
                  >
                    <Typography className="pageHeaderSubDetails">
                      {height}&nbsp;cm
                    </Typography>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                        {t("content.37")}
                      </Typography>
                    }
                  >
                    <Typography className="pageHeaderSubDetails">
                      {weight}&nbsp;Kg
                    </Typography>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                  <Typography className="descriptionDetails">
                        Birth Date&nbsp;:
                      </Typography>}
                      >
                      {/* &nbsp; */}
                        <Typography className="pageHeaderSubDetails">
                          {moment(
                            user?.birth_month +
                              "/" +
                              user?.birth_date +
                              "/" +
                              user?.birth_year
                          ).format("Do MMMM YYYY")}
                        </Typography>
                        </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                       Birth Place
                      </Typography>
                    }
                  >
                    <Typography className="pageHeaderSubDetails">
                      {birth_place}
                    </Typography>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                       Birth Time
                      </Typography>
                    }
                  >
                    <Typography className="pageHeaderSubDetails">
                      {birth_time}
                    </Typography>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                       current City
                      </Typography>
                    }
                  >
                    <Typography className="pageHeaderSubDetails">
                      {current_city}
                    </Typography>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                       Marrital Status
                      </Typography>
                    }
                  >
                    <Typography className="pageHeaderSubDetails">
                      {marital_status}
                    </Typography>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Typography className="descriptionDetails">
                        {t("content.38")}
                      </Typography>
                    }
                  >
                    <Typography
                      className="pageHeaderSubDetails"
                      style={{ textAlign: "justify" }}
                    >
                      {description}
                    </Typography>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}></Col>
             
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
export default MatrimonialProfileDetails;
