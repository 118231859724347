import { Pagination } from "antd";

const CustomPagination = (props) => {
  const { length, setCurrentPage, currentPage } = props;

  const handlePaginationMethod = (Page) => {
    setCurrentPage(Page);
  };
  return (
    <Pagination
      total={length}
      onChange={(e) => handlePaginationMethod(e)}
      current={currentPage}
      responsive={true}
      size="large"
      showSizeChanger={false}
    />
  );
};

export default CustomPagination;
