import React from "react";
import { Row, Col, Typography, Card } from "antd";
// import "./CommunityCard.css";
import moment from "moment/moment";
const CongratulationsCard = (props) => {
  const {  mappedData, isCommunityCardModal } = props;
  const mappedDataDate = moment(mappedData?.date).format("MMM Do YYYY");
  const date = new Date(mappedData?.date);
  const typedDataDate = moment(date).format("MMM Do YYYY");
  return (
    <div>
      <Card className="communityCard2">
        <Row gutter={[0, 10]} justify="space-between">
          {/* <Col>
            <Typography className="CommunityTextCardTitle2">
              Congratulations!!!!!
            </Typography>
          </Col> */}
          <Col>
            <Typography className="CommunityTextCardTitle2">
              {mappedData?.title}
            </Typography>
          </Col>
          <Col>
            <Typography className="CommunityTextCardTitle2">
              {mappedData?.element_name}
            </Typography>
          </Col>
          <Col>
            <Typography className="CommunityTextCardTitle2">
              {mappedData?.element_type}
            </Typography>
          </Col>

          <Col span={24}>
            <Typography className="communityCardDescription1">
              {mappedData?.description}
            </Typography>
          </Col>
          <Col>
            <Typography className="CommunityTextCardTitle2">
              {/* {mappedData?.typedDataDate}  */}
              {isCommunityCardModal ? typedDataDate : mappedDataDate}
            </Typography>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CongratulationsCard;
