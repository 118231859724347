import API from "../Utils/API";
export const getTrustees = (url) => (dispatch) => {
  dispatch({ type: "GET_TRUSTEES_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_TRUSTEES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_TRUSTEES_FAILURE", payload: error.response.data });
    });
};

export const getTrusteeById = (Id) => (dispatch) => {
  dispatch({ type: "GET_TRUSTEES_BY_ID_REQUEST" });
  API.get(`api/community_trustees/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_TRUSTEES_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_TRUSTEES_BY_ID_FAILURE",
        payload: error?.response.data,
      });
    });
};

export const createTrustee = (data, success,failureCallback) => (dispatch) => {
  dispatch({ type: "CREATE_TRUSTEES_REQUEST", payload: data });
  API.post("api/community_trustees", data)
    .then((response) => {
      dispatch({ type: "CREATE_TRUSTEES_SUCCESS", payload: response.data });
      // const sCT = success ? success() : null;
     success && success();
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_TRUSTEES_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);
    });
};

export const deleteTrustee = (Id, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "DELETE_TRUSTEES_REQUEST" });
  API.delete(`api/community_trustees/${Id}`)
    .then((response) => {
      dispatch({ type: "DELETE_TRUSTEES_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_TRUSTEES_FAILURE",
        payload: error.response.data,
      });
      failureCallback();
    });
};

export const updateTrustee = (data, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_TRUSTEES_REQUEST" });
  API.put(`api/community_trustees/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_TRUSTEES_SUCCESS", payload: response.data });

      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_TRUSTEES_FAILURE",
        payload: error.response.data,
      });
      failureCallback();
    });
};

