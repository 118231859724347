import constants from "../Utils/constants";
const initialState = {
  isGetTrusteesLoading: false,
  isCreateTrusteesLoading: false,
  isDeleteTrusteesLoading: false,
  isUpdateTrusteesLoading: false,
  allTrustees: [],
  isTrusteeByIdLoading: true,
  trustee: {},
  setTrusteeId: "",
  trusteeError: "",
  trusteeError2:"",

};
export const trusteesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TRUSTEES_REQUEST:
      return {
        ...state,
        isGetTrusteesLoading: true,
      };
    case constants.GET_TRUSTEES_SUCCESS:
      return {
        ...state,
        isGetTrusteesLoading: false,
        allTrustees: action.payload,
      };
    case constants.GET_TRUSTEES_FAILURE:
      return {
        ...state,
        isGetTrusteesLoading: false,
      };
      case constants.GET_TRUSTEES_BY_ID_REQUEST:
        return {
          ...state,
          isTrusteeByIdLoading: true,
        };
      case constants.GET_TRUSTEES_BY_ID_SUCCESS:
        return {
          ...state,
          isTrusteeByIdLoading: false,
          trustee: action.payload,
        };
        
      case constants.GET_TRUSTEES_BY_ID_FAILURE:
        return {
          ...state,
          isTrusteeByIdLoading: false,
        };
    case constants.CREATE_TRUSTEES_REQUEST:
      return {
        ...state,
        isCreateTrusteesLoading: true,
      };
    case constants.CREATE_TRUSTEES_SUCCESS:
      return {
        ...state,
        isCreateTrusteesLoading: false,
      };
    case constants.CREATE_TRUSTEES_FAILURE:
      return {
        ...state,
        isCreateTrusteesLoading: false,
        trusteeError: action.payload?.designation_id,
        trusteeError2: action.payload?.user_id,

      };
    case constants.DELETE_TRUSTEES_REQUEST:
      return {
        ...state,
        isDeleteTrusteesLoading: true,
      };
    case constants.DELETE_TRUSTEES_SUCCESS:
      return {
        ...state,
        isDeleteTrusteesLoading: false,
      };
    case constants.DELETE_TRUSTEES_FAILURE:
      return {
        ...state,
        isDeleteTrusteesLoading: false,
      };
    case constants.UPDATE_TRUSTEES_REQUEST:
      return {
        ...state,
        isUpdateTrusteesLoading: true,
      };
    case constants.UPDATE_TRUSTEES_SUCCESS:
      return {
        ...state,
        isUpdateTrusteesLoading: false,
        trusteeError: action.payload?.designation_id,
        trusteeError2: action.payload?.user_id,

      };
    case constants.UPDATE_TRUSTEES_FAILURE:
     
    return {
        ...state,
        isUpdateTrusteesLoading: false,
      };
      case constants.RESET_APP_DATA:
        return {
          ...initialState
        }
        case constants.GET_SELECTED_TRUSTEES_ID:
          return {
            ...state,
            setTrusteeId: action.payload,
          };
        case constants.RESET_APP_DATA:
          return {
            ...initialState
          }
        default:
      return state;
  }
};
