import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Pagination,
  Avatar,
  Typography,
  message,
  Spin,
  Tooltip,
} from "antd";
import _ from "lodash";
import "../Communities.css";

import CommunityModalForm from "./CommunityModalForm";
import { useDispatch, useSelector } from "react-redux";
import CommunityDetails from "./CommunityDetails";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  getCommunityById,
  getCommunities,
  updatePublishCommunity,
} from "../../../Actions/communitiesAction";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import { falseyValueCases, ImageEnv } from "../../CommonLogics/CommonMethods";

const Communities = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [communityDetailId, setCommunityDetailId] = useState("");
  const [selectedCommunity, setSelectedCommunity] = useState(false);
  const [valName, setValName] = useState("");
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const { allCommunities, isCommumitiesloading } = useSelector((state) => {
    const { allCommunities, isCommumitiesloading } = state.communities;
    return {
      allCommunities,
      isCommumitiesloading,
    };
  });
  const { total_length } = allCommunities || {};
  const the_name = _.filter(allCommunities.communities, function (o) {
    return o.id === valName;
  });
  useEffect(() => {
    dispatch(getCommunities(`api/communities?page=${currentPage}`));
  }, [currentPage]);
  const handlePublish = (id) => {
    PublishData.id = id;
    PublishData.published = false;
    dispatch(updatePublishCommunity(PublishData, successPublishMethod));
  };
  const successPublishMethod = () => {
    setTimeout(() => {
      message.success("Your community Unpublished successfully");
    }, 1000);
    dispatch(getCommunities(`api/communities?page=${currentPage}`));
  };
  const handleUnPublish = (id) => {
    UnpublishData.id = id;
    UnpublishData.published = true;
    dispatch(updatePublishCommunity(UnpublishData, successUnPublishMethod));
  };
  const successUnPublishMethod = () => {
    setTimeout(() => {
      message.success("Your community Published successfully");
    }, 1000);
    dispatch(getCommunities(`api/communities?page=${currentPage}`));
  };

  const handleOpenCommunity = (Id) => {
    setCommunityDetailId(Id);
    dispatch(getCommunityById(Id));

    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: Id,
    });
  };
  const Columns = [
    {
      title: "Logo",
      dataIndex: "community_logo_key",
      width: "10%",
      render: (id, { community_logo_key, name }) => {
        return (
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Avatar
                shape="square"
                size={45}
                className="avtar"
                src={community_logo_key && ImageEnv(community_logo_key)}
              >
                
                <Typography style={{ fontSize: "20px", color: "white" }}>
                  {name?.[0]}
                </Typography>
              </Avatar>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Community Name",
      dataIndex: "name",
      width: "60%",
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Tooltip title="View Community Details">
                <Typography
                  className="community-Title"
                  onClick={() => handleOpenCommunity(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "15%",
      render: (id, { published }) => {
        return (
          <Row justify="space-between">
            <Col>
              {published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublish(id)}
                    icon={<img src={PublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnPublish(id)}
                    icon={<img src={UnpublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCommumitiesloading}>
      <Row gutter={[32, 32]} 
      className="community"
      style={{ 
        // margin: 10 
        }}>
        <Col span={24}></Col>

        <CommunityModalForm
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          editId={editId}
          setEditId={setEditId}
          communityDetailId={communityDetailId}
          setCommunityDetailId={setCommunityDetailId}
          setValName={setValName}
          valName={valName}
          setSelectedCommunity={setSelectedCommunity}
          selectedCommunity={selectedCommunity}
          setIsDefaultImageVisible={setIsDefaultImageVisible}
          isDefaultImageVisible={isDefaultImageVisible}
          setShowUploadImage={setShowUploadImage}
          showUploadImage={showUploadImage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></CommunityModalForm>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {communityDetailId ? (
            <CommunityDetails
              isModalVisible={isModalVisible}
              allCommunities={allCommunities}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              communityDetailId={communityDetailId}
              setCommunityDetailId={setCommunityDetailId}
              setValName={setValName}
              valName={valName}
              setIsDefaultImageVisible={setIsDefaultImageVisible}
              setSelectedCommunity={setSelectedCommunity}
              selectedCommunity={selectedCommunity}
              isDefaultImageVisible={isDefaultImageVisible}
              setShowUploadImage={setShowUploadImage}
            />
          ) : (
            <>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Row justify="end" gutter={[8, 8]}>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}></Col>

                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Button
                        className="editDesignationButton"
                        style={{ width: "100%" }}
                        onClick={() => setIsModalVisible(true)}
                      >
                        Add Community
                      </Button>
                    </Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[32, 32]}>
                    <Col span={24}>
                      <CustomTable
                        columns={Columns}
                        data={allCommunities?.communities}
                      />
                    </Col>
                  </Row>
                </Col>
                {allCommunities && total_length > 0 ? (
                <Col span={24}>
                  <Row justify="center" style={{ 
                    // margin: "20px 0px"
                     }}>
                    <Pagination
                      total={allCommunities?.total_length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size="large"
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
                ):""}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Communities;
