import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Breadcrumb,
  Drawer,
  message,
  Input,
  Select,
  DatePicker,
} from "antd";
import _ from "lodash";
import { getCommunityById } from "../../../Actions/communitiesAction";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MeetingSchema } from "../../../Utils/Schema";
import {
  getCommunityMeetings,
  updateCommunityMeeting,
  createCommunityMeeting,
  deleteCommunityMeeting,
  updatePublishCommunityMeeting,
} from "../../../Actions/communityMeetingsAction";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import moment from "moment";
import SelectedMeeting from "./SelectedMeeting";
import MeetingTable from "./MeetingTable";
const CommunityMeetingModalForm = (props) => {
  const {
    editId,
    setEditId,
    setMeetingId,
    setValName,
    valName,
    selectedMeeting,
    setSelectedMeeting,
    setDesinationsDetailId,
    currentPage,
  } = props;
  const [viewCommunitiesDetails, setViewCommunitiesDetails] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [preloadedUserValue, setPreloadedUserValue] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [DateCustom, setDateCustom] = useState("");

  const dispatch = useDispatch();

  const {
    setCommunityId,
    isSearchUserLoading,
    allMeetings,
    isCreateCommunityMeetingLoading,
    isEditCommunityMeetingLoading,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const {
      allMeetings,
      isCreateCommunityMeetingLoading,
      isEditCommunityMeetingLoading,
    } = state.communityMeetings;
    return {
      isSearchUserLoading,
      currentUser,
      community,
      allCommunities,
      setCommunityId,
      allMeetings,
      searchUsers,
      isCreateCommunityMeetingLoading,
      isEditCommunityMeetingLoading,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(MeetingSchema),
  });

  const the_meetings = _.filter(allMeetings.community_meetings, function (o) {
    return o.id === editId;
  });
  const newMeetingDate = moment(the_meetings[0]?.meeting_date, "DD-MM-YYYY");
  const newArray = the_meetings?.map((obj) => {
    return {
      ...obj,
      meeting_date: newMeetingDate,
      // year: Year,
    };
  });
  useEffect(() => {
    if (editId) {
      setPreloadedUserValue(newArray[0]);
      reset(newArray[0]);
    }
  }, [the_meetings[0], reset]);
  const { total_length } = allMeetings || {};

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId("");
    setValue("title", "");
    setValue("number", "");
    setValue("place", "");
    // setPreloadedUserValue("");
    setValue("meeting_date", "");
    setValue("description", "");
    // reset();
    // setSelectedFileList([]);
    setDateCustom("");

    clearErrors();
  };
  const handleCreatMeeting = (data) => {
    data.community_id = setCommunityId;
    data.meeting_date = moment(DateCustom._d).format("DD/MM/YYYY");
    data.year = data.meeting_date.split("/")[2];
    dispatch(
      createCommunityMeeting(data, successCreateMeeting, failureCreateMeeting)
    );
  };
  const successCreateMeeting = () => {
    reset();
    setIsModalVisible(false);
    setEditId("");

    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setValue("title", "");
    setValue("number", "");
    setValue("place", "");
    // setPreloadedUserValue("");
    setValue("meeting_date", "");
    setValue("description", "");
    clearErrors();
    setDateCustom("");
    setTimeout(() => {
      message.success("Your Community meeting Created Successfully");
    }, 1000);
  };

  const failureCreateMeeting = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const handleEditMeeting = (data) => {
    data.id = editId;
    data.meeting_date = moment(
      data.meeting_date._d ? data.meeting_date._d : data.meeting_date
    ).format("DD/MM/YYYY");
    data.year = data.meeting_date?.split("/")[2];
    dispatch(updateCommunityMeeting(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setIsModalVisible(false);
    setEditId("");
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setValue("title", "");
    setValue("number", "");
    setValue("place", "");
    // setPreloadedUserValue("");
    setValue("meeting_date", "");
    setValue("description", "");
    clearErrors();
    setTimeout(() => {
      message.success("Your Meeting Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  const handleDeleteMeeting = (meetingId) => {
    dispatch(
      deleteCommunityMeeting(
        meetingId,
        successDelete
        // failureDelete
      )
    );
  };
  const successDelete = () => {
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setTimeout(() => {
      message.success("Your Meeting Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  const handleOpenCommunity = (Id) => {
    setMeetingId(Id);
  };
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const handleSelectMeeting = (Id) => {
    setValName(Id);
    setSelectedMeeting(true);
  };
  const the_name = _.filter(allMeetings.community_meetings, function (o) {
    return o.id === valName;
  });
  const onClickMeeting = () => {
    setSelectedMeeting("");
    setValName("");
  };

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);

  return (
    <Row gutter={[32, 32]} 
    // style={{ margin: 10 }}
    >
      <Col span={24}></Col>
      <Col xl={16} lg={16} md={16} sm={20} xs={20}>
        <Breadcrumb 
        // style={{ margin: 10 }}
        >
          <Breadcrumb.Item
            onClick={() => onClickCommunity()}
            className="breadcrumCommunity"
          >
            Communities
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => onClickMeeting()}
            className="breadcrumUsers"
          >
            Community Meetings
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumUsers">
            {/* {community_meetings[0].title} */}
            {the_name[0]?.title}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>

      <Col xl={8} lg={8} md={8} sm={8} xs={8}>
        <Row justify="end" gutter={[8, 8]}>
          <Col xl={10} lg={10} md={10} sm={10} xs={10}>
            <Controller
              name="user_type"
              as={
                <Select
                  placeholder="Search Meetings"
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="inputLabel"
                  showSearch={true}
                  options={
                    allMeetings &&
                    total_length > 0 &&
                    allMeetings.community_meetings.map((val, i) => {
                      return {
                        label: val.title,
                        value: val.id,
                      };
                    })
                  }
                  onSelect={(Id) => handleSelectMeeting(Id)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                ></Select>
              }
              control={control}
              className="inputLabel"
            />
          </Col>
          <Col xl={14} lg={14} md={14} sm={14} xs={14}>
            <Button
              className="editDesignationButton"
              onClick={() => setIsModalVisible(true)}
            >
              Add community meetings
            </Button>
          </Col>
        </Row>
        <Drawer
          open={isModalVisible}
          maskClosable={false}
          onClose={handleClose}
          width={width > 370 ? "370px" : "100%"}
          className="drawer"
          title={editId ? "Edit meeting" : "Add meeting"}
        >
          <form
            onSubmit={handleSubmit(
              editId ? handleEditMeeting : handleCreatMeeting
            )}
          >
            <Spin
              spinning={
                isEditCommunityMeetingLoading
                  ? isEditCommunityMeetingLoading
                  : isCreateCommunityMeetingLoading
              }
              size="large"
            >
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Typography
                    className="details"
                    style={{ paddingBottom: "4px" }}
                  >
                    Title
                  </Typography>
                  <Controller
                    as={<Input size="large" />}
                    name="title"
                    control={control}
                    className="inputLabel"
                  />
                  {errors.title && (
                    <p style={{ color: "red" }}>{errors.title.message}</p>
                  )}
                </Col>

                <Col span={24}>
                  <Typography
                    className="details"
                    style={{ paddingBottom: "4px" }}
                  >
                    Number
                  </Typography>
                  <Controller
                    as={<Input size="large" type="number" />}
                    name="number"
                    control={control}
                    className="inputLabel"
                  />
                  {errors.number && (
                    <p style={{ color: "red" }}>{errors.number.message}</p>
                  )}
                </Col>
                <Col span={24}>
                  <Typography
                    className="details"
                    style={{ paddingBottom: "4px" }}
                  >
                    Place
                  </Typography>
                  <Controller
                    as={<Input size="large" />}
                    name="place"
                    control={control}
                    className="inputLabel"
                  />
                  {errors.place && (
                    <p style={{ color: "red" }}>{errors.place.message}</p>
                  )}
                </Col>
                <Col span={24}>
                  <Typography
                    className="details"
                    style={{ paddingBottom: "4px" }}
                  >
                    Description
                  </Typography>
                  <Controller
                    as={<Input size="large" />}
                    name="description"
                    control={control}
                    className="inputLabel"
                  />
                  {errors.description && (
                    <p style={{ color: "red" }}>{errors.description.message}</p>
                  )}
                </Col>

                <Col span={24} className="formRows">
                  <Typography className="details" style={{ paddingBottom: 9 }}>
                    Meeting Date
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder="Select Date"
                        className="inputLabel"
                        size="large"
                        onSelect={(e) => setDateCustom(e)}
                      />
                    }
                    name="meeting_date"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {errors.meeting_date && (
                    <p style={{ color: "red" }}>Meeting date is required</p>
                  )}
                </Col>

                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      size="large"
                      className="editButton"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      className="editButton"
                      htmlType="submit"
                    >
                      Add
                    </Button>
                  )}
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    className="cancelButton"
                    onClick={handleClose}
                    size="large"
                    // style={{ margin: "0 8px" }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Spin>
          </form>
        </Drawer>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {/* {selectedMeeting ? (
          <SelectedMeeting
            selectedMeeting={selectedMeeting}
            setSelectedMeeting={setSelectedMeeting}
            the_name={the_name}
          />
        ) : ( */}
        <MeetingTable
          setIsModalVisible={setIsModalVisible}
          allMeetings={allMeetings}
          editId={editId}
          setEditId={setEditId}
          viewCommunitiesDetails={viewCommunitiesDetails}
          setViewCommunitiesDetails={setViewCommunitiesDetails}
          currentPage={currentPage}
          // setCurrentPage={setCurrentPage}
          setMeetingId={setMeetingId}
          setDesinationsDetailId={setDesinationsDetailId}
          // currentPage={currentPage}
        />
        {/* )} */}
      </Col>
    </Row>
    // </Spin>
  );
};

export default CommunityMeetingModalForm;
