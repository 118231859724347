import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
// import Template from './Template/Template';
import FindOtherPeople from "./FindThePeoplePage/FindOtherPeople";
import Main from "./Main";
import { history } from "./Utils/history";
import Slider from "./FindThePeoplePage/Slider";
import Event from "./Events/Events";
import { Router, Route, Switch } from "react-router";
import Trustee from "./Screens/TrusteeView/Trustee";
import Familybtn from "./Screens/AddFamilymember/CreateFamily";
import MatrimonialPage from "./Screens/MatrimonialSuperAdmin/MatrimonialPage";
import BusinessProfile from "./FindThePeoplePage/BusinessProfiles/BusinessProfile";
import JobProfile from "./FindThePeoplePage/JobProfile/JobProfile";
import BusinessProfileDetails from "./FindThePeoplePage/BusinessProfiles/BusinessProfileDetails";
import OtpAuthentication from "./Screens/OtpAuthentication/OtpAuthentication";
import ViewMyMatches from "./FindThePeoplePage/Matrimonial/ViewMyMatches";
import Admin from "./Screens/Admin/Admin";
import VolunteerSideTable from "./Screens/Volunteer/VolunteerSideTable";
import { PrivateRoute } from "./Utils/PrivateRoute";
import Activity from "./Screens/Activity/Activity";
import EmptyScreen from "./Screens/EmptyScreen";
import TermsAndConditions from "./Screens/TermsAndConditions/TermsAndConditions";
import Faqs from "./Screens/LandingPage/Faqs";
import SelectCommunity from "./Screens/Community/SelectCommunity";
import AboutUs from "./Screens/LandingPage/AboutUs";
import Communities from "./Screens/Communities/Community/Communities";
// import Users from "./Screens/Communities/User/Users";
import Documents from "./Screens/Communities/Document/Documents";
import CommunityMatrimonial from "./Screens/Communities/CommunityMatrimonial";
import CommunityNative from "./Screens/Communities/Native/CommunityNative";
import CommunityTrustee from "./Screens/Communities/Trustee/CommunityTrustee";
import CommunityMeetings from "./Screens/Communities/Meeting/CommunityMeetings";
import Designations from "./Screens/Communities/Designation/Designations";
import Educations from "./Screens/Communities/Education/Educations";
import Industries from "./Screens/Communities/Industry/Industries";
import Business from "./Screens/BusinessSuperAdmin/Business";
import Users from "./Screens/UsersSuperAdmin/Users";
import UsersDetail from "./Screens/UsersSuperAdmin/UsersDetail";
import Events from "./Screens/EventsSuperAdmin/Events";
import EventDetail from "./Screens/EventsSuperAdmin/EventDetail.js";
import Family from "./Screens/FamilySuperAdmin/Family";
import FamilyDetail from "./Screens/FamilySuperAdmin/FamilyDetail";
import Ticket from "./Screens/Communities/Tickets/Ticket";
import MaintenanceWindow from "./Screens/Communities/Maintenance/MaintenanceWindow";
import NativeVolunteer from "./Screens/NativeVolunteerSuperAdmin/NativeVolunteer";
import CommunityCards from "./Screens/Communities/CommunityCards/CommunityCards";
import CommunityTempleteCard from "./Screens/Communities/CommunityCards/CommunityTempleteCard";
import Templates from "./Screens/Communities/Templates/Templates";
import BusinessUserDetails from "./Screens/BusinessSuperAdmin/BusinessUserDetails";
import Countries from "./Screens/Communities/Countries/Countries";
import Contactus from "./Screens/Communities/Contactus/Contactus";
import Journey from "./Screens/Communities/Blog/Journey";
import JourneyRoute from "./Screens/Communities/JourneyRoute/JourneyRoute";
import TicketHistory from "./Screens/Communities/Tickets/TicketHistory";
import CreateTicketDrawer from "./Screens/Communities/Tickets/CreateTicketDrawer";
import MatrimonialProfileDetails from "./Screens/MatrimonialSuperAdmin/MatrimonialProfileDetails";
import ChatDrawer from "./Screens/Communities/Tickets/ChatDrawer";
import ArchiveTicketHistory from "./Screens/Communities/Tickets/AchiveTicketHistory";

function App() {
  const token = localStorage.getItem("access_token");
  useEffect(() => {
    if (history.location.pathname === "/" && token) {
      // setAuthenticated(true);
      history.push("/communities");
    } else {
    }
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Switch>
            {/* <Route exact path="/" component={LandingPage}></Route> */}
            <Route exact path="/" component={Main}></Route>
            <Route
              path="/OtpVerification"
              component={OtpAuthentication}
            ></Route>
            <Route
              path="/termsAndConditions"
              component={TermsAndConditions}
            ></Route>
            <Route path="/Faqs" component={Faqs}></Route>
            <Route path="/SelectCommunity" component={SelectCommunity}></Route>
            <Route path="/aboutUs" component={AboutUs}></Route>
            {/* <Route exact path="/find-other-people" component={() => <Slider component={FindOtherPeople} />}></Route> */}

            <PrivateRoute
              exact
              path="/find-other-people"
              component={() => <Slider component={FindOtherPeople} />}
            ></PrivateRoute>
            {/* <Slider path="/trustee" component={Trustee} /> */}
            <PrivateRoute
              exact
              path="/trustee"
              component={() => <Slider component={Trustee} />}
            ></PrivateRoute>

            {/* <Slider path="/event" component={Event} /> */}
            <PrivateRoute
              exact
              path="/event"
              component={() => <Slider component={Event} />}
            ></PrivateRoute>

            {/* <Slider path="/matrimonial" component={MatrimonialPage} /> */}
            <PrivateRoute
              exact
              path="/matrimonial"
              component={() => <Slider component={MatrimonialPage} />}
            ></PrivateRoute>
             <PrivateRoute
              exact
              path="/matrimonialDetails/:id"
              component={() => <Slider component={MatrimonialProfileDetails} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communities"
              component={() => <Slider component={Communities} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/journey"
              component={() => <Slider component={Journey} />}
            ></PrivateRoute>
             <PrivateRoute
              exact
              path="/journeyroute/:id"
              component={() => <Slider component={JourneyRoute} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/users"
              component={() => <Slider component={Users} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/users/:id"
              component={() => <Slider component={UsersDetail} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/families"
              component={() => <Slider component={Family} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/families/:id"
              component={() => <Slider component={FamilyDetail} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/documents"
              component={() => <Slider component={Documents} />}
            ></PrivateRoute>

            <PrivateRoute
              exact
              path="/native_volunteers"
              component={() => <Slider component={NativeVolunteer} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communityevents"
              component={() => <Slider component={Events} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communityevents/:id"
              component={() => <Slider component={EventDetail} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communitybusiness"
              component={() => <Slider component={Business} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communitybusiness/:id"
              // path="/communities/users/:id"
              component={() => <Slider component={BusinessUserDetails} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communitymatrimonial"
              component={() => <Slider component={CommunityMatrimonial} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/businessProfile"
              component={() => <Slider component={BusinessProfile} />}
            ></PrivateRoute>

            <PrivateRoute
              exact
              path="/communitynative"
              component={() => <Slider component={CommunityNative} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communitytrustee"
              component={() => <Slider component={CommunityTrustee} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/ticketHistory/:id"
              component={() => <Slider component={TicketHistory} />}
            ></PrivateRoute>
             <PrivateRoute
              exact
              path="/chatticket/:id"
              component={() => <Slider component={ChatDrawer} />}
            ></PrivateRoute>
             <PrivateRoute
              exact
              path="/archieveticketHistory/:id"
              component={() => <Slider component={ArchiveTicketHistory} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/ticketcreate/:id"
              component={() => <Slider component={ChatDrawer} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communitymeetings"
              component={() => <Slider component={CommunityMeetings} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/designations"
              component={() => <Slider component={Designations} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/educations"
              component={() => <Slider component={Educations} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/maintenancewindow"
              component={() => <Slider component={MaintenanceWindow} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/communitycards"
              component={() => <Slider component={CommunityCards} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/templates"
              component={() => <Slider component={Templates} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/ticket"
              component={() => <Slider component={Ticket} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/industries"
              component={() => <Slider component={Industries} />}
            ></PrivateRoute>
            {/* <Slider path="/jobProfile" component={JobProfile} /> */}
            <PrivateRoute
              exact
              path="/jobProfile"
              component={() => <Slider component={JobProfile} />}
            ></PrivateRoute>

            {/* <Slider path="/viewMyMatches" component={ViewMyMatches} /> */}
            <PrivateRoute
              exact
              path="/viewMyMatches"
              component={() => <Slider component={ViewMyMatches} />}
            ></PrivateRoute>

            <PrivateRoute
              exact
              path="/businessProfileDetails"
              component={() => <Slider component={BusinessProfileDetails} />}
            ></PrivateRoute>

            <PrivateRoute
              exact
              path="/addfamily"
              component={() => <Slider component={Familybtn} />}
            ></PrivateRoute>

            <PrivateRoute
              exact
              path="/admin"
              component={() => <Slider component={Admin} />}
            ></PrivateRoute>

            <PrivateRoute
              exact
              path="/newPeople"
              component={() => <Slider component={VolunteerSideTable} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/countries"
              component={() => <Slider component={Countries} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/contactus"
              component={() => <Slider component={Contactus} />}
            ></PrivateRoute>
            <PrivateRoute
              exact
              path="/activity"
              component={() => <Slider component={Activity} />}
            ></PrivateRoute>
            <Route exact path="*" component={EmptyScreen}></Route>
          </Switch>
        </Router>

      </PersistGate>

    </Provider>
  );
}

export default App;


