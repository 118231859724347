import React, { useState } from "react";
import {Button, Row, Col, Typography, Drawer } from "antd"
import "./LandingPage.css"
import {ArrowRightOutlined , MenuFoldOutlined} from "@ant-design/icons";
// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import {history} from "../../Utils/history"
const LandingPageHeader = () => {
        // const {IsMD , IsSM , IsXS} = useBreakpoints();
        const { IsMD, IsSM, IsXS} = useBreakpoints();

        const [isDrawerVisible,setIsDrawerVisible] = useState(false)      
        const handleOpenDrawerMethod = () => {
                setIsDrawerVisible(true)
        }
  
     const redirectMethod = (url) => {
            history.push(url)    
   
            setIsDrawerVisible(false)
    }
const is_signup = true
    return(
          
   <div className="header">
            <Row justify={(IsMD || IsSM || IsXS) ? "space-between" : "space-around"} style={{"display":"flex" , "alignItems":"center"}}>    
                <Col>
 
                            <Typography className="samajTitle">Samaj</Typography>
                </Col>
                {!(IsMD || IsSM || IsXS)  ? 
                <>
                <Col xl={9} md={10} lg={10}>
                    
                   
 
                    <Row gutter={[21,0]}>                        
                        <Col><a className="aboutTitle" onClick={() => history.push("/")}>Home</a></Col> 
                        <Col><a className="aboutTitle" onClick={() => history.push("/aboutUs")}>About</a></Col>
    <Col><a className="aboutTitle" href="#features" onClick={() => history.push("/")}>Features</a></Col>
                        <Col><a className="aboutTitle" href="#contactSection" onClick={() => history.push("/")}>Contact</a></Col>
                    </Row>
                </Col>
               
                <Col>
                    <Row gutter={[45,0]}>
                                        <Col style={{"display":"flex", "alignItems":"center"}}>                         
                            <a className="loginButton" onClick={() => history.push("/login")}>Login</a>
                        
                        
                        
                        
                        
                        </Col>
                        <Col>
                            <Button className="becomeAMemberButton" icon={<ArrowRightOutlined/>} onClick={() => history.push("/login", {is_signup})}>Become a member</Button>
                        </Col>
                    </Row>
    
    
    
    
    
                </Col>
                </>
                
                
                
                
                
                :
            <Col>
                <MenuFoldOutlined onClick={() => handleOpenDrawerMethod()}/>
                <Drawer open={isDrawerVisible}       onClose={() => setIsDrawerVisible(false)} placement="top" title={<Typography className="samajTitle" style={{"color":"#252B42"}}>Samaj</Typography>}>
                            <Row justify="center" gutter={[0,10]}>



                            <Col span={24} style={{"display":"flex" , "justifyContent":"center"}}>
<a className="hamBurgerMenuSubTitle" onClick={() => redirectMethod("/")}>Home</a>
                            </Col>
                                    <Col span={24} style={{"display":"flex" , "justifyContent":"center"}}> 
 <a className="hamBurgerMenuSubTitle" onClick={() =>    redirectMethod("/aboutUs")}>About</a>
 
 
 
                            </Col>
                            <Col span={24} style={{"display":"flex" , "justifyContent":"center"}}>
<a className="hamBurgerMenuSubTitle" href="#features"    onClick={() => redirectMethod("/")}>
                                    Features
                                </a>
                            </Col>
                            <Col span={24} style={{"display":"flex" , "justifyContent":"center"}}>
                               
                                
                                
                                <a className="hamBurgerMenuSubTitle" href="#contactSection" onClick={() => redirectMethod("/")}>
                                    Contact
                                </a>
                            </Col>
                        </Row>
                </Drawer>
            </Col>
            
                }
            </Row>
        </div>
    )

}








export default LandingPageHeader;