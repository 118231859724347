import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  message,
  Pagination,
  Tooltip,
  Popconfirm,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import { getEducationsAction } from "../../../Actions/educationAction";
import { useForm } from "react-hook-form";
import EducationsModalForm from "./EducationsModalForm";
import { getCommunityById } from "../../../Actions/communitiesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  updateEducationAction,
  deleteUserEducationAction,
} from "../../../Actions/educationAction";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Delete from "../../../assets/delete.svg";
import Edit2 from "../../../assets/Edit2.svg";

const Educations = () => {
  const {
    isEducationsLoading,
    allEducations,
    isUpdateEducationLoading,
    isCreateEducationLoading,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const {
      allEducations,
      isEducationsLoading,
      isUpdateEducationLoading,
      isCreateEducationLoading,
    } = state.educations;
    return {
      isEducationsLoading,
      isUpdateEducationLoading,
      isCreateEducationLoading,
      currentUser,
      allEducations,
    };
  });
  const { total_length } = allEducations || {};

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [educationId, setEducationId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedEducation, setSelectedEducation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { reset } = useForm({});
  let UnpublishData = {};
  let PublishData = {};
  useEffect(() => {
    dispatch(getEducationsAction(`api/educations?page=${currentPage}`));
  }, [currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const onClickEducation = () => {
    setSelectedEducation("");
    setValName("");
    reset();
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteEducations = (Id) => {
    dispatch(deleteUserEducationAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Educations Deleted Successfully");
    }, 1000);
    dispatch(getEducationsAction(`api/educations?page=${currentPage}`));
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handlePublishEducationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateEducationAction(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishEducationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateEducationAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Education Published Successfully");
    }, 1000);
    dispatch(getEducationsAction(`api/educations?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Education Unpublished Successfully");
    }, 1000);
    dispatch(getEducationsAction(`api/educations?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const the_name = _.filter(allEducations.educations, function (o) {
    return o.id === valName;
  });
  const Columns = [
    {
      title: "Education Name",
      dataIndex: "name",
      width: "80%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              {published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublishEducationMethod(id)}
                    icon={<img src={PublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnpublishEducationMethod(id)}
                    icon={<img src={UnpublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteEducations(id)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isEducationsLoading}>
      <Row gutter={[32, 32]} 
      className="community"
      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickEducation()}
              className="breadcrumUsers"
            >
              Educations
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={0} lg={0} md={0} sm={6} xs={6}></Col>
            {/* <Col xl={12} lg={12} md={12} sm={9} xs={9}>
            <Controller
              name="user_type"
              as={
                <Select
                  placeholder="Search Educations"
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="inputLabel"
                  showSearch={true}
                  options={
                    allEducations &&
                    total_length > 0 &&
                    allEducations.educations.map((val, i) => {
                      return {
                        label: val.name,
                        value: val.id,
                      };
                    })
                  }
                  onSelect={(Id) => handleOpenEducation(Id)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                ></Select>
              }
              control={control}
              className="inputLabel"
            />
          </Col> */}
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => setIsModalVisible(true)}
              >
                Add Educations
              </Button>
            </Col>
          </Row>
          <Spin
            spinning={
              editId ? isUpdateEducationLoading : isCreateEducationLoading
            }
            size="large"
          >
            <EducationsModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              educationId={educationId}
              setEducationId={setEducationId}
              setValName={setValName}
              valName={valName}
              setSelectedEducation={setSelectedEducation}
              selectedEducation={selectedEducation}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></EducationsModalForm>
          </Spin>
        </Col>

        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable columns={Columns} data={allEducations?.educations} />
            </Col>
          </Row>
        </Col>
        {allEducations && total_length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allEducations?.total_length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
      </Row>
    </Spin>
  );
};

export default Educations;
