import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  message,
  Pagination,
  Tooltip,
  Popconfirm,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import {
  getUnderMaintenanceAction,
  deleteUserMaintenanceAction,
} from "../../../Actions/maintenanceWindowAction";
import moment from "moment";
import { useForm } from "react-hook-form";
import { getCommunityById } from "../../../Actions/communitiesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import Delete from "../../../assets/delete.svg";
import Edit2 from "../../../assets/Edit2.svg";
import MaintenanceModalForm from "./MaintenanceModalForm";

const MaintenanceWindow = () => {
  const {
    allMaintenanceWindows,
    isUpdateUnderMaintenanceLoading,
    isCreateUnderMaintenanceLoading,
    isUnderMaintenanceLoading,
  } = useSelector((state) => {
    const {
      allMaintenanceWindows,
      isUpdateUnderMaintenanceLoading,
      isCreateUnderMaintenanceLoading,
      isUnderMaintenanceLoading,
    } = state.maintenance;
    return {
      allMaintenanceWindows,
      isUpdateUnderMaintenanceLoading,
      isCreateUnderMaintenanceLoading,
      isUnderMaintenanceLoading,
    };
  });
  const { length } = allMaintenanceWindows || {};

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [valName, setValName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { reset } = useForm({});
  useEffect(() => {
    dispatch(
      getUnderMaintenanceAction(`api/maintenance_windows?page=${currentPage}`)
    );
  }, [currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteMaintenance = (Id) => {
    dispatch(deleteUserMaintenanceAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Maintenance Deleted Successfully");
    }, 1000);
    dispatch(
      getUnderMaintenanceAction(`api/maintenance_windows?page=${currentPage}`)
    );
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const the_name = _.filter(allMaintenanceWindows, function (o) {
    return o.id === valName;
  });

  const the_maintenance = _.filter(
    allMaintenanceWindows.maintenance,
    function (o) {
      return o.id === editId;
    }
  );

  const Columns = [
    {
      title: "Maintenance Name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
    },
    {
      title: "Start Time",
      width: "20%",
      render: (data) => {
        const Starttime = moment.utc(data.start_time).format("LT");
        return <>{Starttime}</>;
      },
    },

    {
      title: "End Time",
      width: "20%",
      render: (data) => {
        const Endtime = moment.utc(data.end_time).format("LT");
        return <>{Endtime}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>

            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteMaintenance(id)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isUnderMaintenanceLoading}>
      <Row gutter={[32, 32]} 
      className="community"

      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              Maintenance
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={0} lg={0} md={0} sm={6} xs={6}></Col>
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => setIsModalVisible(true)}
              >
                Add Maintenance
              </Button>
            </Col>
          </Row>
          <Spin
            spinning={
              editId
                ? isUpdateUnderMaintenanceLoading
                : isCreateUnderMaintenanceLoading
            }
            size="large"
          >
            <MaintenanceModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></MaintenanceModalForm>
          </Spin>
        </Col>

        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable columns={Columns} data={allMaintenanceWindows} />
            </Col>
          </Row>
        </Col>
        {allMaintenanceWindows && length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allMaintenanceWindows?.length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
      </Row>
    </Spin>
  );
};

export default MaintenanceWindow;
