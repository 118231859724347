import React from 'react';
import { Row, Col, Button, Spin, Table, Select, Typography } from 'antd';
import { getDocuments } from '../../Actions/documentsAction';

function DocumnetTable (props) {

    const { allDocuments } = props;
    
    const columns = [
        {
          title: 'Document Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Upload Date/Time',
          dataIndex: 'timestamp',
          key: 'timestamp',
        },
        {
            dataIndex:"id",
            render: () => <DocumentActionComponent/>
            , width:"15em"
        }
    ];

    // const allDocuments = [
    //     {
    //         key: '1',
    //         name: 'IT Return',
    //         timestamp: '04/01/2022 10:05:25',
    //       },
    //       {
    //         key: '1',
    //         name: 'Society Expense List',
    //         timestamp: '04/01/2022 10:05:25',
    //       },
    // ];

    const DocumentActionComponent = () => {
        return (
            <Row gutter={[30,0]}>
                <Col span={10}>
                    <Button className="editButton" type="primary">View</Button>
                </Col>
                <Col span={10}>
                    <Button className="deleteButton" type="primary">Remove</Button>
                </Col>
            </Row>
        )
    }

    const earliestYear = 2000;
    const options = [];
    const d = new Date();
    let y = d.getFullYear();

    for(let i = earliestYear; i <= y; i++)
    {
        options.push(<option value = {i}>{i}</option>)
    }

    return(
        <>
        <Row gullter = {[30, 10]}>
            <Col span={2}>
                <Typography className = 'content'>Select Year:</Typography>
            </Col>
            <Col span={2}>
                <Select 
                    placeholder = "Select financial year"
                    name="yearSelector"
                    defaultValue="2022"
                    style={{width: "100px",}}
                >
                    {options}
                </Select>
            </Col>
        </Row>

                {/* <Spin size="large"> */}
                    <Table dataSource={allDocuments} columns={columns} className="table" size="middle" style={{
                        marginTop: "10px"
                        }}/>
                {/* </Spin> */}
        </>
    )
}

export default DocumnetTable;