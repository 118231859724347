import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  // Spin,
  // Empty,
  // Typography,
  // message,
  // Card,
  // Pagination,
  // Tooltip,
  // Breadcrumb,
  // Drawer,
  Select,
  // Input,
  // Checkbox,
  // Popconfirm,
  // Dropdown,
} from "antd";
import { useDispatch } from "react-redux";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import _ from "lodash";
const CustomSearch = (props) => {
  const {
    placeholder,
    size,
    data,
    searchAction,
    searchActionLoading,
    isDrawer,
    communityId,
    onSearch,
    clearRequiredMessageMethod,
  } = props;
  useEffect(() => {
    clearInputState();
  }, [!isDrawer]);
  const dispatch = useDispatch();
  const [isOpenSelectDropdownVisible, setIsOpenSelectDropdownVisible] =
    useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [onSearchData, setOnSearchData] = useState(null);
  const [isInputChange, setIsInputChange] = useState(true);
  const [name, setName] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const handleSelectDropdownOption = (Val) => {
    setOnSearchData(Val.first_name + " " + Val.last_name);
    setName(Val.first_name);
    setIsSearchButtonClicked(false);
    setSelectedUserId(Val?.id);
    onSearch && onSearch(Val);
  };
  const onSearchMethod = (Value) => {
    setOnSearchData(Value);
    setIsInputChange(false);
    if (Value.toString().length === 2) {
      setName(null);
      setSelectedUserId(null);
      onSearch(null);
    }
    clearRequiredMessageMethod();
    // setErrorUserId(null);
  };

  const handleSearchButtonMethod = () => {
    const DataLength = onSearchData?.toString()?.length;
    setIsSearchButtonClicked(DataLength > 0);
    setIsOpenSelectDropdownVisible(DataLength > 0);
    setIsInputChange(true);
    const isDataPhoneNumber = containsNumber(onSearchData);
    if (DataLength > 0) {
      if (isDataPhoneNumber) {
        dispatch(
          searchAction(
            `api/user_search?community_id=${communityId}&phone=${onSearchData}`
          )
        );
      }
      if (!isDataPhoneNumber) {
        const FirstName = _.capitalize(onSearchData);
        dispatch(
          searchAction(
            `api/user_search?community_id=${communityId}&first_name=${FirstName}`
          )
        );
      }
    }
  };
  const clearInputState = () => {
    setOnSearchData(null);
    setName(null);
    setSelectedUserId(null);
  };
  return (
    <Row>
      <Col span={20}>
        <Select
          style={{ width: "100%", borderRadius: "4px" }}
          placeholder={placeholder}
          size={size}
          showSearch={true}
          options={data?.map((val, i) => {
            return {
              label: val.first_name + " " + val.last_name,
              value: val.id,
            };
          })}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownRender={(menu) => {
            return data?.map((val, i) => {
              return (
                <button
                  className="SearchUserDropdown"
                  key={i}
                  onClick={() => handleSelectDropdownOption(val)}
                >
                  {val.first_name + " " + val.last_name}
                  {"("}
                  {val?.phone}
                  {")"}
                </button>
              );
            });
          }}
          open={
            isOpenSelectDropdownVisible &&
            isSearchButtonClicked &&
            isDrawer &&
            isInputChange &&
            !searchActionLoading
          }
          onSearch={(e) => onSearchMethod(e)}
          loading={searchActionLoading}
          searchValue={onSearchData ? onSearchData : name}
          // onChange={(e) => console.log({ e })}
        />
      </Col>
      <Col span={4}>
        <Button
          size="large"
          onClick={() => handleSearchButtonMethod()}
          disabled={!onSearchData?.toString()?.length > 0}
        >
          Search
        </Button>
      </Col>
    </Row>
  );
};

export default CustomSearch;
