import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Divider, Spin, PageHeader } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
const CommunityMetingDetails = (props) => {
  const { setMeetingId, meetingId } = props;
  const { allMeetings, isCommunityMeetingsLoading, setCommunityId } =
    useSelector((state) => {
      const { setCommunityId } = state.communities;
      const { allMeetings, isCommunityMeetingsLoading } =
        state.communityMeetings;
      return {
        allMeetings,
        isCommunityMeetingsLoading,
        setCommunityId,
      };
    });

  const handleCloseMatrimonialProfile = () => {
    setMeetingId("");
  };

  return (
    <Row justify="space-around" 
    // style={{ margin: 20 }}
    >
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <ArrowLeftOutlined
          style={{ paddingBottom: "20px" }}
          onClick={() => handleCloseMatrimonialProfile()}
        />
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card className="profileDetails-card">
          <Row justify="space-between">
            {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}> */}
            {/* <Row> */}
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Col span={3}>
                  <Typography className="detailsPage">Title:</Typography>
                </Col>
                <Col span={20}>
                  <Typography className="detailsPage">
                    {meetingId.title}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Col span={3}>
                  <Typography className="detailsPage2">Number:</Typography>
                </Col>
                <Col span={20}>
                  <Typography className="detailsPage2">
                    {meetingId.number}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Col span={3}>
                  <Typography className="detailsPage2">Place:</Typography>
                </Col>
                <Col span={20}>
                  <Typography className="detailsPage2">
                    {meetingId.place}
                  </Typography>
                </Col>
              </Row>
            </Col>

            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Col span={3}>
                  <Typography className="detailsPage2">Year:</Typography>
                </Col>
                <Col span={20}>
                  <Typography className="detailsPage2">
                    {meetingId.year}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row>
                <Col span={3}>
                  <Typography className="detailsPage2">
                    Meeting Date:
                  </Typography>
                </Col>
                <Col span={20}>
                  <Typography className="detailsPage2">
                    {meetingId.meeting_date}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Row style={{ display: "contents" }}>
              <Col
                xl={24}
                lg={24}
                md={12}
                sm={24}
                xs={24}
                style={{ marginTop: 20 }}
              >
                <PageHeader
                  title="Details"
                  className="pageHeaderDetails"
                ></PageHeader>
              </Col>
              <Col
                xl={24}
                lg={24}
                md={12}
                sm={24}
                xs={24}
                // style={{ margin: 20 }}
              >
                <Typography className="detailsPage2">
                  Description: {meetingId.description}
                </Typography>
              </Col>
            </Row>
            <Divider />
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default CommunityMetingDetails;
