import React, { useState } from 'react';
import { Modal, Row, Col, Input, Button, Upload, Typography, message } from 'antd';
// import { Worker } from '@react-pdf-viewer/core';
// import { Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

function UploadDocument (props) {

    const { isUploadDocsVisible, setIsUploadDocsVisible } = props;

    const closeUploadDocsMethod = () => {
        setIsUploadDocsVisible(false);
    }

    const handleSubmit = () => {
        setIsUploadDocsVisible(false);
    }

    const [pdfFile, setPdfFile] = useState(null);
    const [pdfError, setPdfError] = useState('');

    const allowedFiles  = ['application/pdf'];
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if(selectedFile){
            if(selectedFile && allowedFiles.includes(selectedFile.type)){
                let reader = new FileReader();
                reader.readAsDataURL(selectedFile);
                reader.onloadend = (e) => {
                    setPdfError('');
                    setPdfFile(e.target.result);
                }
            }
            else{
                setPdfError("Not valid format, please select only PDF file");
            }
        }
        else{
        }
    }

    const inputSuffix = (
    <Upload 
        // onChange={(e)=> console.log({e})}
    >
        <Button className="allButtons">Upload</Button>
    </Upload>
    );

    const [url, setUrl] = React.useState('');

    // Handle the `onChange` event of the `file` input
    const onChange = (e) => {
        const files = e.target.files;
        files.length > 0 && setUrl(URL.createObjectURL(files[0]));
    };

    
    return (
        <Modal 
            title = "Upload Documents"
            visible = {isUploadDocsVisible}
            onCancel= {closeUploadDocsMethod} 
            okText="Save" 
            footer={null}>
            <Typography className = 'content'>Selected Year : 2022</Typography>
            <form onSubmit={() => handleSubmit()} style={{
                marginTop: "20px"
                }}>
                <Row gutter={[0,10]}>
                    <Col span= {24}>
                        <label className="label">File Name : </label>
                        <Input/>
                    </Col>
                </Row>
                {/* <Row gutter={[0,10]}>
                    <Col span= {24}>
                        <label className="label">Upload Document : </label><br/>
                        <Input suffix = {inputSuffix}></Input>
                        <Upload>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>                    
                    </Col>
                </Row> */}

                <Row>
                    <Col>
                        <label><h5>Upload PDF</h5></label><br/>
                        <input type='file' onChange={(e) => handleFile(e)}></input>
                        {pdfError && <span>{pdfError}</span>}
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <h5>View PDF</h5>
                        <div className='viewer'> 
                            {pdfFile && (
                                <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js'>
                                    <Viewer fileUrl={pdfFile}></Viewer>
                                </Worker>
                            )}

                            {!pdfFile && (
                                <div>No file selected yet</div>
                            )}
                        </div>
                    </Col>
                </Row> */}

                <Row gutter={[10,10]} justify="end">
                <Col>
                <Button className="allButtonsCancel" onClick={() => closeUploadDocsMethod()}>Cancel</Button>
                </Col>
                <Col>
                    <Button htmlType="submit" className="allButtons">Add</Button>
                </Col>
                </Row>
            </form>
        </Modal>
    )
}

export default UploadDocument;