import React, { useEffect } from "react";
import { Row, Col, Avatar, Spin } from "antd";
import "./App.css";
// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "./Screens/CommonLogics/ResponsiveBreakPoints"
import Login from "./Screens/Login";
import logo from "./assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
// import { getCommunityById } from "./Actions/communitiesAction";

function Main() {
  // const { IsXL, IsLG, IsMD, IsXS , IsXXL} = useBreakpoints();
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
  const {
    community,
    isLoginUserLoading,
    isCreateUserLoading,
    isCommunityByIdLoading,
  } = useSelector((state) => {
    const { community, isCommunityByIdLoading } = state.communities;
    const { isLoginUserLoading, isCreateUserLoading } = state.authentication;

    return {
      community,
      isLoginUserLoading,
      isCreateUserLoading,
      isCommunityByIdLoading,
    };
  });
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
  // }, [dispatch]);
  const height = window.innerHeight;
  return (
    <>
    
      <div
        style={{ overflow:"hidden" , height:(IsMD || IsLG || IsXL || IsXXL) && "100vh" }}
        className="MainLoadingIndicator"
      >
        
        <Spin
          spinning={
            isLoginUserLoading || isCreateUserLoading || isCommunityByIdLoading
          }
          size="large"
        >
          <Row gutter={[0, height / 16]}>
            {(IsXL || IsLG || IsMD || IsXXL) && <Col span={24}></Col>}
            <Col span={24}></Col>
            {(IsXL || IsLG || IsMD || IsXXL) && <Col span={24}></Col>}

            {/* <Col xl={2} lg={2} md={1} /> */}
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {/* <Row justify="center" align="middle" style={{ height: "90%" }}> */}
                {/* <Avatar
                  size={
                    community?.community_logo === null ? 300 : IsXS ? 50 : 150
                  }
                  src={
                    community?.community_logo === null
                      ? logo
                      : community?.community_logo
                  }
                  shape="square"
                /> */}
              {/* </Row> */}
            </Col>
           
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Row justify="center" align="middle">
              <Login />
              </Row>
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
}
export default Main;
