import React from "react";
import { Row, Col, Card, Typography, Divider, Avatar, Button } from "antd";
import "../FindOtherPeople.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";
const ViewMyMatches = (props) => {
  const { t } = useTranslation();
  const { setViewMatrimonialProfileId, setIsViewMyMatchesVisible } = props;
  const { allMatrimonial } = useSelector((state) => {
    const { allMatrimonial, isMatrimonialLoading } = state.matrimonial;
    return {
      allMatrimonial,
      isMatrimonialLoading,
    };
  });
  const handleClick = (matrimonialProfileId) => {
    setViewMatrimonialProfileId(matrimonialProfileId);
    setIsViewMyMatchesVisible(false);
  };
  const handleDeleteMatrimonial = (matrimonialId) => {
    setViewMatrimonialProfileId(false);
  };

  const handleCloseMatrimonialProfile = () => {
    setViewMatrimonialProfileId("");
  };
  return (
    <>
      <Row justify="space-between" gutter={[0, 8]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <ArrowLeftOutlined
            style={{ paddingBottom: "20px" }}
            onClick={() => handleCloseMatrimonialProfile()}
            handleDeleteMatrimonial={handleDeleteMatrimonial}
          />
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <Typography className="matches">{t("welcomeText.18")}</Typography>
        </Col>
        <Col span={24}>
          <Typography className="matchesDetail">
            {t("welcomeText.19")}
          </Typography>
        </Col>
      </Row>
      <Row
        gutter={[32, 32]}
        style={{ marginTop: "30px" }}
        justify="space-between"
      >
        {allMatrimonial?.map((val, i) => {
          return (
            <Col xl={12} lg={12} md={24} sm={24} xs={24} key={i}>
              <Card className="MatchesCard">
                <Row gutter={[0, 16]}>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Avatar
                      shape="square"
                      size={145}
                      className="avtar"
                      style={{ marginTop: "-40px" }}
                      src={val.matrimonial_user_image}
                    >
                      <Typography style={{ fontSize: "50px", color: "white" }}>
                        {val.first_name?.[0]}
                      </Typography>
                    </Avatar>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={18} xs={18}>
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Typography className="profile-name">
                          {val.first_name}
                        </Typography>
                      </Col>
                      <Col>
                        <Typography className="profile-birthdate">
                          {moment(val.birth_date).format("Do MMM YYYY")}
                          {/* {val.birth_date} */}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xl={4}
                    lg={12}
                    md={12}
                    sm={6}
                    xs={6}
                    style={{ textAlign: "end" }}
                  >
                    <Typography className="View-matchPercentage">
                      85%
                    </Typography>
                  </Col>
                </Row>
                <Row gutter={[0, 16]}>
                  <Divider />
                </Row>
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Typography className="profile-address">
                      {val.address}
                    </Typography>
                  </Col>
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ textAlign: "end" }}
                  >
                    <Button
                      className=" View-fullProfile"
                      onClick={() => handleClick(val.id)}
                    >
                      {t("btn.26")}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewMyMatches;
