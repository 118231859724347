import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  Modal,
  DatePicker,
  Checkbox,
  Spin,
  message,
  Upload,
  Select

} from "antd";
import React, { useState,useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  createFamilyMember,
  updateFamilyMember,
} from "../../Actions/familyMemberAction";
import { useDispatch, useSelector } from "react-redux";

import { getFamilies } from "../../Actions/familyAction";
import { addFamilyMemberSchema } from "../../Utils/Schema";
import { useTranslation } from "react-i18next";
import "./Addfamily.css";
import "./../../FindThePeoplePage/FindOtherPeople.css";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import { getUsers } from "../../Actions/usersAction";
import {getEducationsAction} from "../../Actions/educationAction"
import {getIndustriesAction} from "../../Actions/industryAction"
import PhoneInput from 'react-phone-input-international'
import 'react-phone-input-international/lib/style.css'
const AddFamilyMember = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isAddFamilyMemberModalVisible,
    setIsAddFamilyMemberModalVisible,
    familyId,
   
    isUpdateMemberModalVisible,
    setIsUpdateMemberModalVisible,
    the_member,
  } = props;
  const { isCreateFamilyMemberLoading, isUpdateFamilyMemberLoading,allEducations,allIndustries} =
    useSelector((state) => {
      const { isCreateFamilyMemberLoading, isUpdateFamilyMemberLoading } =
        state.familyMember;
        const {allEducations} = state.educations
        const {allIndustries} = state.industry
        return {
        isCreateFamilyMemberLoading,
        isUpdateFamilyMemberLoading,
        allEducations,
        allIndustries
      };
    });
    useEffect(() => {
      dispatch(getEducationsAction())
      dispatch(getIndustriesAction())
    },[])
  
    
    const onlyPublishedEducations = allEducations.filter((Educations) => Educations.published === true)
    const onlyPublishedIndustries = allIndustries.filter((Industries) => Industries.published === true)
    const { user } = the_member || {};
    const {  
    birth_date,
    birth_month,
    birth_year,
    id: memberUniqueId,
    education,
    industry
  } = user || {};

  const date = isUpdateMemberModalVisible
    ? moment(`${birth_month}-${birth_date}-${birth_year}`, "MM-DD-YYYY")
    : "";
  if (the_member) {
    user.date_of_birth = date ? date : moment();
    user.education_id = education?.name
  
    user.industry_id = industry?.name
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
 
 
 
  } = useForm({
    defaultValues:
      user?.birth_date === null
        ? {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
           
            phone: user?.phone,
            education_type: user?.education_type,
            industry: user?.industry,
          }
        
          : user,
   
        
          resolver: yupResolver(addFamilyMemberSchema),
  });
  const [DateCustom, setDateCustom] = useState("");
  const [gender, setGender] = useState("");
  const [gendervalue, setGendervalue] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const IsEmpty = _.isEmpty(newFiles);
  const [showUploadImage, setShowUploadImage] = useState(false);
 
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [genderRequiredMessage, setGenderRequiredMessage] = useState("");
  const [phoneRequiredMessage, setPhoneRequiredMessage] = useState("");
  const [countryCode,setCountryCode] = useState("")
  const [number,setNumber] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  
  const onDateSelection = (date) => {
    const Date = moment(date);
    setDateCustom(moment(Date._d).calendar());
  };
  const monthOfBirth = DateCustom.toString().slice(0, 2);
  const dateOfBirth = DateCustom.toString().slice(3, 5);
  const yearOfBirth = DateCustom.toString().slice(6, 10);
  const handleCancel = () => {
    setIsAddFamilyMemberModalVisible(false);
    reset();
    setGender({});
    setGendervalue("");
    
    setShowUploadImage(false);
    setNewFiles([]);
  };
  const isGenderValueEmpty = _.isEmpty(gendervalue);
  
  const createMem = (data) => {
    const Text = number
    const TextText = Text.replace(`+${countryCode}`,"") 
    const checkNumberLength = TextText.toString().length
    if (isGenderValueEmpty) {
      setGenderRequiredMessage("Gender is required");
    } else if (checkNumberLength === 10) {
      data.family_id = familyId;
      data.birth_date = dateOfBirth;
      data.birth_month = monthOfBirth;
      data.birth_year = yearOfBirth;
      data.gender = gendervalue;
      data.user_image = IsEmpty ? null : newFiles;

      data.phone = TextText ? TextText : user?.phone
      data.country_code =countryCode ? `+${countryCode}` : user?.country_code
     
     
     
      dispatch(createFamilyMember(data, successCreateMember, IsEmpty,failureCreateMethod));
    } else {
    
      setPhoneRequiredMessage("Contact number must be of 10 digits only");
    }
  };
  const successCreateMember = () => {
    setValue("first_name", "");
    setValue("last_name", "");
    setValue("email", "");
    setValue("phone", "");
    
    setValue("education_type", "");
    setValue("industry", "");
    setValue("date_of_birth", "");
    reset();
    setGender({});
    setGendervalue("");
    setIsAddFamilyMemberModalVisible(false);
    setTimeout(() => {
      message.success(t("content.131"));
    }, 1000);
    dispatch(getFamilies());
    setNewFiles([]);
    setShowUploadImage(false);
  };
  const failureCreateMethod = (failureResponse) => {
        setFailureMessage(failureResponse?.phone?.[0])
  }
  const containsNumber = (str) => {
    return /\d/.test(str);
  }
  const updateMem = (data) => {
    const Text = number
    const TextText = Text.replace(`+${countryCode}`,"")
    
    const checkNumberLength = TextText.toString().length
    if(checkNumberLength === 10){

    
    data.id = memberUniqueId;
    data.birth_date = dateOfBirth ? dateOfBirth : user.birth_date;
   
   
    data.birth_month = monthOfBirth ? monthOfBirth : user.birth_month;
    data.birth_year = yearOfBirth ? yearOfBirth : user.birth_year;
    
    data.gender = gendervalue ? gendervalue : user.gender;
    data.user_image = IsEmpty ? null : newFiles;
    data.education_id = containsNumber(data.education_id) ?  data.education_id :  education.id
    data.industry_id = containsNumber(data.industry_id) ? data.industry_id : industry.id
    data.phone = TextText ? TextText : user?.phone
    data.country_code =countryCode ? `+${countryCode}` : user?.country_code
    



    dispatch(updateFamilyMember(data, successUpdateMember, IsEmpty,failureUpdateMethod));
    }
    else{
      setErrorMessage("Contact number must be of 10 digits only");

    }
  };
  
  const successUpdateMember = () => {
    dispatch(getFamilies());
    setIsUpdateMemberModalVisible(false);

    dispatch(getUsers());
    setTimeout(() => {
      message.success(t("content.132"));
    }, 1000);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };
  const failureUpdateMethod = (failureResponse) => {
    setFailureMessage(failureResponse?.phone?.[0])
  }
  const CloseMemberModal = () => {
    setIsUpdateMemberModalVisible(false);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setValue("first_name", user?.first_name);

    setValue("last_name", user?.last_name);
    setValue("email", user?.email);
    setValue("phone", user?.phone);
    setValue("education_type", user?.education_type);
    setValue("industry", user?.industry);
    clearErrors();
  };
  const genderValue = (e) => {
    const { checked, value } = e.target;
    setGender({
      value,
      [value]: checked,
    });
    setGendervalue(value);
    setGenderRequiredMessage("");
  };

  if (isUpdateMemberModalVisible && gender?.Female) {
    user.gender = "Female";
  } else if (isUpdateMemberModalVisible && gender?.Male) {
    user.gender = "Male";
  } else {
  }
  const propsForUpload = {
    action: createMem,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const handleUploadMethod = () => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
  };
  const buttonBefore = <Typography>+91</Typography>;
  const phoneNumberMethod = (value , country , e , formattedValue) => {
    setCountryCode(country.dialCode)
    setNumber(formattedValue)
  }
  return (
    <div>
      {isAddFamilyMemberModalVisible ? (
        <Modal
          visible={isAddFamilyMemberModalVisible}
          onCancel={handleCancel}
          footer={null}
          title={t("btn.38")}
        >
          <Spin spinning={isCreateFamilyMemberLoading} size="large">
            <form onSubmit={handleSubmit(createMem)}>
              <Row justify="center">
                <Col>
                  <Upload
                    {...propsForUpload}
                    listType="picture-card"
                    maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => setShowUploadImage(true)}
                  >
                    {/* <Button icon={<PlusOutlined/>}></Button> */}
                    <PlusOutlined size={44} />
                    {t("content.154")}
                  </Upload>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography className="label" style={{ paddingBottom: 9 }}>
                    {t("content.77")}
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="first_name"
                    control={control}
                  />
                  {errors.first_name && (
                    <p style={{ color: "red" }}>{t("content.117")}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 9 }} className="label">
                    {t("placeholder.5")}
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="last_name"
                    control={control}
                  />
                  {errors.last_name && (
                    <p style={{ color: "red" }}>{t("content.121")}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("placeholder.6")}
                  
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="email"
                    control={control}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{t("content.122")}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.17")}
                  </Typography>
                  
                  {/* <Controller
                    as={
                      <Input
                        prefix={buttonBefore}
                        style={{ borderRadius: "4px" }}
                        onClick={() => setPhoneRequiredMessage("")}
                      />
                    }
                    name="phone"
                    control={control}
                  /> */}
                  <PhoneInput
                    country={'in'} 
                    onChange={(value, country , e , formattedValue) => phoneNumberMethod(value , country , e , formattedValue)}
                   

                    placeholder="Enter Your Contact Number"
                    onClick={() => setPhoneRequiredMessage("")}
                    inputStyle={{"width":"100%"}}                   
                    
                    
                    autoFormat={false}
                    onlyCountries={["in","ca","us","au","nz"]}
                    />
                  {errors.phone && (
                    <p style={{ color: "red" }}>{t("content.99")}</p>
                  )}
                  {phoneRequiredMessage && (
                    <p style={{ color: "red" }}>{phoneRequiredMessage}</p>
                  )}
                  {failureMessage && (
                   
                   <p style={{ color: "red" }}>{failureMessage}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.79")}
                  </Typography>
                  <Controller
                    name="education_id"
                    as={
                    // <Input className="inputLabel" />
                    <Select
                        placeholder={t("content.159")}
                        style={{ width: "100%", borderRadius: "4px" }}
                        className="inputLabel"
                        showSearch={true}
                        // options={SearchUsersByFirstName}
                        options={
                          onlyPublishedEducations &&
                          onlyPublishedEducations.length > 0 &&
                          onlyPublishedEducations.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                      </Select>
                  }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: "red" }}>{t("content.119")}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.80")}
                  </Typography>
                  <Controller
                   
                   as={
                    // <Input className="inputLabel" />
                    <Select
                    placeholder={t("content.160")}
                    style={{ width: "100%", borderRadius: "4px" }}
                    className="inputLabel"
                    showSearch={true}
                    // options={SearchUsersByFirstName}
                    options={
                      onlyPublishedIndustries &&
                      onlyPublishedIndustries.length > 0 &&
                      onlyPublishedIndustries.map((val, i) => {
                        return {
                          label: val.name,
                          value: val.id,
                        };
                      })
                    }
                    filterOption={(input, option) =>
                      option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                  </Select>
                  }
                    name="industry_id"
                    control={control}
                  />
                  {errors.industry_id && (
                    <p style={{ color: "red" }}>{t("content.120")}</p>
                  )}
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 9 }} className="label">
                    {t("content.124")}
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={t("placeholder.10")}
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        className="inputLabel"
                      />
                    }
                    name="date_of_birth"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {errors.date_of_birth && (
                    <p style={{ color: "red" }}>{t("content.118")}</p>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }} gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className="label">{t("content.81")}</Typography>
                </Col>
                <Col>
                  <Row>
                    <Col className="label">
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={"Female"}
                        checked={gender?.Female}
                      >
                        {t("content.83")}
                      </Checkbox>
                    </Col>
                    <Col className="label">
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={"Male"}
                        checked={gender?.Male}
                      >
                        {t("content.82")}
                      </Checkbox>
                    </Col>
                  </Row>
                  {genderRequiredMessage && (
                    <p style={{ color: "red", 
                    marginTop: "10px" 
                    }}>
                      {" "}
                      {genderRequiredMessage}{" "}
                    </p>
                  )}
                </Col>
              </Row>

              <Row
                gutter={[10, 0]}
                style={{ paddingTop: "10px" }}
                justify="end"
              >
                <Col>
                  <Button onClick={handleCancel} className="allButtonsCancel">
                    {t("btn.19")}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {t("btn.24")}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        </Modal>
      ) : (
        <Modal
          visible={isUpdateMemberModalVisible}
          onCancel={CloseMemberModal}
          footer={null}
          title={t("btn.40")}
        >
          <Spin spinning={isUpdateFamilyMemberLoading} size="large">
            <form onSubmit={handleSubmit(updateMem)}>
              <Col span={24}>
                <Row justify="center">
                  <Col>
                    {isUpdateMemberModalVisible &&
                      isDefaultImageVisible &&
                      user?.image && (
                        <Upload
                          listType="picture-card"
                          openFileDialogOnClick={false}
                        >
                          <img src={user?.image} width={102} alt=""></img>
                        </Upload>
                      )}
                  </Col>
                  <Col>
                    <Upload
                      {...propsForUpload}
                      listType="picture-card"
                      maxCount={1}
                      showUploadList={showUploadImage}
                      onChange={() => handleUploadMethod()}
                    >
                      <PlusOutlined size={44} />
                      Upload Image
                    </Upload>
                  </Col>
                </Row>
              </Col>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography className="label" style={{ paddingBottom: 9 }}>
                    {t("content.77")}
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="first_name"
                    control={control}
                  />
                  {errors.first_name && (
                    <p style={{ color: "red" }}>{errors.first_name.message}</p>
                  )}
                </Col>
                <Col
                  xl={12}
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 9 }} className="label">
                    Last Name
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="last_name"
                    control={control}
                  />
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    Email
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="email"
                    control={control}
                  />
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    Contact Number
                  </Typography>
                  {/* <Controller
                    as={
                      <Input
                        prefix={buttonBefore}
                        style={{ borderRadius: "4px" }}
                      />
                    }
                    name="phone"
                    control={control}
                  /> */}
                  <PhoneInput
                    country={'in'} 
                    onChange={(value, country , e , formattedValue) => phoneNumberMethod(value , country , e , formattedValue)}
                   

                    placeholder="Enter Your Contact Number"
                    onClick={() => setErrorMessage("")}
                    inputStyle={{"width":"100%"}}                   
                    
                    value={user?.phone_with_country_code}
                    

                    autoFormat={false}
                    onlyCountries={["in","ca","us","au","nz"]}
                    />
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}  
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.79")}
                  </Typography>
                  <Controller
                    name="education_id"
                    as={
                    // <Input className="inputLabel" />
                    <Select
                    placeholder="Select Education"
                    style={{ width: "100%", borderRadius: "4px" }}
                    className="inputLabel"
                    showSearch={true}
                    // options={SearchUsersByFirstName}
                    options={
                      onlyPublishedEducations &&
                      onlyPublishedEducations.length > 0 &&
                      onlyPublishedEducations.map((val, i) => {
                        return {
                          label: val.name,
                          value: val.id,
                        };
                      })
                    }
                    filterOption={(input, option) =>
                      option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                  </Select>
                  }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: "red" }}>
                      {errors.education_id.message}
                    </p>
                  )}
                </Col>
                <Col
                  xl={12}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 8 }} className="label">
                    {t("content.80")}
                  </Typography>
                  <Controller
                    as={
                    // <Input className="inputLabel" />
                    <Select
                   
                    placeholder="Select Industry"
                    style={{ width: "100%", borderRadius: "4px" }}
                    className="inputLabel"
                    showSearch={true}
                    // options={SearchUsersByFirstName}
                    options={
                      onlyPublishedIndustries &&
                      onlyPublishedIndustries.length > 0 &&
                      onlyPublishedIndustries.map((val, i) => {
                        return {
                          label: val.name,
                          value: val.id,
                        };
                      })
                    }
                    filterOption={(input, option) =>
                      option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                  </Select>
                  }
                    name="industry_id"
                    control={control}
                  />
                  {errors.industry_id && (
                    <p style={{ color: "red" }}>{errors.industry_id.message}</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  xs={14}
                  sm={10}
                  md={10}
                  style={{ paddingTop: "10px" }}
                >
                  <Typography style={{ paddingBottom: 9 }} className="label">
                    {t("content.124")}
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        className="inputLabel"
                      />
                    }
                    name="date_of_birth"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {errors.date_of_birth && (
                    <p style={{ color: "red" }}>
                      {errors.date_of_birth.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }} gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className="label">{t("content.81")}</Typography>
                </Col>
                <Col>
                  <Row>
                    <Col className="label">
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={"Female"}
                        checked={user?.gender === "Female"}
                      >
                        {t("content.83")}
                      </Checkbox>
                    </Col>
                    <Col className="label">
                      <Checkbox
                        onChange={(e) => genderValue(e)}
                        value={"Male"}
                        checked={user?.gender === "Male"}
                      >
                        {t("content.82")}
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row
                gutter={[10, 0]}
                style={{ paddingTop: "10px" }}
                justify="end"
              >
                <Col>
                  <Button
                    onClick={CloseMemberModal}
                    className="allButtonsCancel"
                  >
                    {t("btn.19")}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {t("btn.17")}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        </Modal>
      )}
    </div>
  );
};

export default AddFamilyMember;
