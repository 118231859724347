import React, { useEffect, useState } from "react";
import { Row, Col, Pagination } from "antd";
import _ from "lodash";
import { getCommunityMeetings } from "../../../Actions/communityMeetingsAction";
import { useDispatch, useSelector } from "react-redux";
import CommunityMetingDetails from "./CommunityMetingDetails";
import CommunityMeetingModalForm from "./CommunityMeetingModalForm";
const CommunityMeetings = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [meetingId, setMeetingId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedMeeting, setSelectedMeeting] = useState(false);

  const dispatch = useDispatch();
  const { setCommunityId, allMeetings } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const {
      allMeetings,
      isCreateCommunityMeetingLoading,
      isEditCommunityMeetingLoading,
    } = state.communityMeetings;
    return {
      currentUser,
      community,
      allCommunities,
      setCommunityId,
      allMeetings,
      isCreateCommunityMeetingLoading,
      isEditCommunityMeetingLoading,
      allMeetings,
    };
  });
  const { total_length } = allMeetings || {};

  useEffect(() => {
    dispatch(
      getCommunityMeetings(
        `api/community_meetings?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [dispatch, setCommunityId, currentPage]);

  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {meetingId ? (
          <CommunityMetingDetails
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            meetingId={meetingId}
            setMeetingId={setMeetingId}
            setSelectedMeeting={setSelectedMeeting}
            setValName={setValName}
            valName={valName}
          />
        ) : (
          <CommunityMeetingModalForm
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            meetingId={meetingId}
            setMeetingId={setMeetingId}
            setValName={setValName}
            valName={valName}
            setSelectedMeeting={setSelectedMeeting}
            selectedMeeting={selectedMeeting}
            currentPage={currentPage}
          ></CommunityMeetingModalForm>
        )}
      </Col>
      {allMeetings && total_length > 0 ? (
      <Col span={24}>
        <Row justify="center" 
        // style={{ margin: "20px 0px" }}
        >
          <Pagination
            total={allMeetings?.total_length}
            onChange={(e) => setCurrentPage(e)}
          />
        </Row>
      </Col>
      ):""}
    </Row>
  );
};

export default CommunityMeetings;
