import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Input,
} from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EducationSchema } from "../../../Utils/Schema";
import {
  getEducationsAction,
  createEducationAction,
  updateEducationAction,
} from "../../../Actions/educationAction";
import { useDispatch, useSelector } from "react-redux";
const EducationsModalForm = (props) => {
  const {
    editId,
    setEditId,
    currentPage,
    setCurrentPage,
    isModalVisible,
    setIsModalVisible,
  } = props;
  const [preloadedEducationValue, setPreloadedEducationValue] = useState("");
  const dispatch = useDispatch();
  const { allEducations} =
    useSelector((state) => {
      const {
        allEducations,
      } = state.educations;
      return {
        allEducations,
      };
    });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedEducationValue },
    resolver: yupResolver(EducationSchema),
  });

  const the_education = _.filter(allEducations.educations, function (o) {
    return o.id === editId;
  });

  useEffect(() => {
    if (editId) {
      setPreloadedEducationValue(the_education[0]);
      reset(the_education[0]);
    }
  }, [the_education[0], reset]);
  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("name", "");
    clearErrors();
  };

  const handleCreateEducations = (data) => {
    dispatch(
      createEducationAction(data, successCreateEducations, failureEducations)
    );
  };
  const successCreateEducations = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("name", "");
    dispatch(getEducationsAction(`api/educations?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Educations Created Successfully");
    }, 1000);
  };

  const failureEducations = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };

  const handleEditEducations = (data) => {
    data.id = editId;
    dispatch(updateEducationAction(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId("");
    reset();
    setValue("name", "");
    dispatch(getEducationsAction(`api/educations?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Educations Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Education" : "Add Education"}
    >
      <form
        onSubmit={handleSubmit(
          editId ? handleEditEducations : handleCreateEducations
        )}
      >
        <Row gutter={[0, 10]}>
          <Col span={24} className="formRows">
            <Typography className="details" style={{ paddingBottom: "4px" }}>
              Name
            </Typography>
            <Controller
              as={<Input size="large" />}
              name="name"
              control={control}
              className="inputLabel"
            />

            {errors.name && (
              <p style={{ color: "red" }}>{errors.name.message}</p>
            )}
          </Col>
        </Row>

        <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {editId ? (
              <Button size="large" className="editButton" htmlType="submit">
                Update
              </Button>
            ) : (
              <Button size="large" className="editButton" htmlType="submit">
                Add
              </Button>
            )}
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              className="cancelButton"
              onClick={handleClose}
              size="large"
              // style={{ margin: "0 8px" }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
};

export default EducationsModalForm;
