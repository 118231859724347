import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Drawer, message, Input } from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IndustrySchema } from "../../../Utils/Schema";
import {
  getIndustriesAction,
  createIndustryAction,
  updateIndustryAction,
} from "../../../Actions/industryAction";
import { useDispatch, useSelector } from "react-redux";
const IndustriesModalForm = (props) => {
  const {
    editId,
    setEditId,
    isModalVisible,
    setIsModalVisible,
    currentPage,
    setCurrentPage,
  } = props;
  const [preloadedUserValue, setPreloadedUserValue] = useState("");

  const dispatch = useDispatch();
  const { allIndustries} =
    useSelector((state) => {
      const {
        allIndustries,
      } = state.industry;
      return {
        allIndustries,
      };
    });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedUserValue },
    resolver: yupResolver(IndustrySchema),
  });

  const the_trustees = _.filter(allIndustries.industries, function (o) {
    return o.id === editId;
  });
  useEffect(() => {
    if (editId) {
      setPreloadedUserValue(the_trustees[0]);
      reset(the_trustees[0]);
    }
  }, [the_trustees[0], reset]);

  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("name", "");
    clearErrors();
  };
  const handleCreateIndustry = (data) => {
    dispatch(
      createIndustryAction(data, successCreateIndustry, failureCreateIndustry)
    );
  };
  const successCreateIndustry = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("name", "");
    dispatch(getIndustriesAction(`api/industries?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Industry Created Successfully");
    }, 1000);
  };

  const failureCreateIndustry = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };

  const handleEditIndustry = (data) => {
    data.id = editId;
    dispatch(updateIndustryAction(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId("");
    reset();
    setValue("name", "");
    dispatch(getIndustriesAction(`api/industries?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Industry Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);

  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Industry" : "Add Industry"}
    >
      <form
        onSubmit={handleSubmit(
          editId ? handleEditIndustry : handleCreateIndustry
        )}
      >
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Typography className="label" style={{ paddingBottom: "4px" }}>
              Name
            </Typography>
            <Controller
              as={<Input className="inputLabel" size="large" />}
              name="name"
              control={control}
            />
            {errors.name && (
              <p style={{ color: "red" }}>{errors.name.message}</p>
            )}
          </Col>
        </Row>

        <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {editId ? (
              <Button size="large" className="editButton" htmlType="submit">
                Update
              </Button>
            ) : (
              <Button size="large" className="editButton" htmlType="submit">
                Add
              </Button>
            )}
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Button
              className="cancelButton"
              onClick={handleClose}
              size="large"
              // style={{ margin: "0 8px" }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
};

export default IndustriesModalForm;
