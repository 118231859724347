import constants from "../Utils/constants";
const initialState = {
  isCreateFamilyMemberLoading: false,
  isDeleteFamilyMemberLoading: false,
  isUpdateFamilyMemberLoading: false,
};
export const familyMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isCreateFamilyMemberLoading: true,
      };
    case constants.CREATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isCreateFamilyMemberLoading: false,
      };
    case constants.CREATE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isCreateFamilyMemberLoading: false,
      };
    case constants.DELETE_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isDeleteFamilyMemberLoading: true,
      };
    case constants.DELETE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isDeleteFamilyMemberLoading: false,
      };
    case constants.DELETE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isDeleteFamilyMemberLoading: false,
      };
    case constants.UPDATE_FAMILY_MEMBER_REQUEST:
      return {
        ...state,
        isUpdateFamilyMemberLoading: true,
      };
    case constants.UPDATE_FAMILY_MEMBER_SUCCESS:
      return {
        ...state,
        isUpdateFamilyMemberLoading: false,
      };
    case constants.UPDATE_FAMILY_MEMBER_FAILURE:
      return {
        ...state,
        isUpdateFamilyMemberLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
