import React, { useEffect, useState } from "react";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getCommunitiesNative } from "../../Actions/communitiesNativeAction";
import { Spin, message, Select, Row, Col, Breadcrumb } from "antd";
import {
  createNativeVolunteerAction,
  deleteNativeVolunteerAction,
} from "../../Actions/nativeVolunteerAction";
import debounce from "lodash/debounce";
import { getSearchUser } from "../../Actions/searchUserAction";
import _ from "lodash";
import { history } from "../../Utils/history";
import CustomPagination from "../ReuseableComponents/CustomPagination/CustomPagination";

const NativeVolunteer = () => {
  const dispatch = useDispatch();
  const {
    setCommunityId,
    isDeleteNativeVolunteerLoading,
    allCommunitiesNative,
    // allCommunitiesNativeVolunteer,
    isCreateNativeVolunteerLoading,
    isCommunitiesNativeLoading,
    searchUsers,
    // isSearchUserLoading,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { searchUsers, isSearchUserLoading } = state.searchUser;

    const { allCommunitiesNative, isCommunitiesNativeLoading } =
      state.communitiesNative;
    const {
      allCommunitiesNativeVolunteer,
      isCreateNativeVolunteerLoading,
      isDeleteNativeVolunteerLoading,
    } = state.nativeVolunteers;
    return {
      setCommunityId,
      isCreateNativeVolunteerLoading,
      allCommunitiesNative,
      allCommunitiesNativeVolunteer,
      isCommunitiesNativeLoading,
      isDeleteNativeVolunteerLoading,
      searchUsers,
      isSearchUserLoading,
    };
  });
  const { total_length } = allCommunitiesNative || {};

  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    dispatch(
      getSearchUser(
        `api/user_search?community_id=${setCommunityId}&verified=true`,
        callbackFunctions
      )
    );
  }, [setCommunityId, currentPage]);

  const [volunteerArray, setVolunteerArray] = useState([]);
  // const [value, setValue] = useState([]);
  const [userName, setUserName] = useState(null);
  const [fetching, setFetching] = useState(false);
  // const [isMouseLeave, setIsMouseLeave] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [userDidEnterSomething, setUserDidEnterSomething] = useState(false);
  const [isRecordsFound, setIsRecordsFound] = useState(true);

  const searchUserData = _.map(searchUsers?.users, (val) => {
    return { label: val.first_name, value: val.id };
  });
  const [options, setOptions] = useState(searchUserData);
  // const fetchRef = useRef(0);

  // const DebounceSelect = ({
  //   fetchOptions,
  //   debounceTimeout = 800,
  //   ...props
  // }) => {
  //   const debounceFetcher = useMemo(() => {
  //     const loadOptions = (value) => {
  //       setValue([value]);
  //       fetchRef.current += 1;
  //       const fetchId = fetchRef.current;
  //       // setOptions([]);
  //       // setFetching(true);
  //       fetchOptions(value).then((newOptions) => {
  //         //   if (fetchId !== fetchRef.current) {
  //         //     // for fetch callback order
  //         //     return;
  //         //   }
  //         //   setOptions(newOptions);
  //         //   setFetching(false);
  //       });
  //     };
  //     return debounce(loadOptions, debounceTimeout);
  //   }, [fetchOptions, debounceTimeout]);
  //   return (
  //     <Select
  //       {...props}
  //       filterOption={true}
  //       onSearch={debounceFetcher}
  //       options={options}
  //       // value={value}
  //     />
  //   );
  // };
  const handleBreadCrumbMethod = () => {
    history.push("/communities");
    dispatch({ type: "GET_SELECTED_COMMUNITY_ID", payload: "" });
    dispatch({ type: "EMPTY_COMMUNITY_BY_ID_DATA" });
  };
  const MyDebounceMethod = debounce((username) => {
    setUserName(username);

    setUserDidEnterSomething(true);

    setFetching(true);
    if (username.toString().length > 0) {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&first_name=${username}`,
          callbackFunctions
        )
      );
      setIsFocus(true);
    } else {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&verified=true`,
          callbackFunctions
        )
      );
    }
  }, 800);

  const deleteVolunteerMethod = (VolunteerName, NativeId) => {
    const userVolunteer = volunteerArray?.filter((val) => {
      return val.user.id === VolunteerName;
    });
    dispatch(
      deleteNativeVolunteerAction(
        userVolunteer.length > 0 ? userVolunteer[0].id : VolunteerName,
        successDeleteNativeVolunteerMathod
      )
    );
  };
  const successDeleteNativeVolunteerMathod = () => {
    setTimeout(() => {
      message.success("Volunteer Deleted Successfully");
    }, 1000);
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  };
  let data = {};
  const createVolunteerMethod = (UserId, CommunityNative) => {
    data.community_native_id = CommunityNative;
    data.user_id = UserId;
    dispatch(
      createNativeVolunteerAction(data, successCreateNativeVolunteerMethod)
    );
  };
  const successCreateNativeVolunteerMethod = (SuccessResponse) => {
    setVolunteerArray([...volunteerArray, SuccessResponse]);
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=1`
      )
    );
    dispatch(
      getSearchUser(
        `api/user_search?community_id=${setCommunityId}&verified=true`,
        callbackFunctions
      )
    );
  };
  // const fetchUserList = async (username) => {
  //   dispatch(
  //     getSearchUser(
  //       `api/user_search?community_id=${setCommunityId}&first_name=${username}`,
  //       callbackFunctions
  //     )
  //   );
  //   // setValue(username);
  // };

  // const userNameLength = userName?.toString()?.length;
  const handleMouseLeave = () => {
    if (isFocus && userDidEnterSomething && isRecordsFound) {
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&verified=true`,
          callbackFunctions
        )
      );
    }
    setIsFocus(false);
    setUserName(null);
    setUserDidEnterSomething(false);
  };
  const handleFocus = () => {
    setIsFocus(true);
  };

  const callbackFunctions = (data) => {
    const searchedData = _.map(data, (val) => {
      return { label: val.first_name, value: val.id };
    });
    setOptions(searchedData);
    setFetching(false);
    if (data.length > 0) {
      setIsRecordsFound(false);
    } else {
      setIsRecordsFound(true);
    }
  };

  const nativeVolunteersColumns = [
    {
      title: "Native",
      dataIndex: "name",
      width: "20%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Native Volunteers",

      width: "30%",
      render: ({ id, natives_volunteers_users, native_users, ...p }) => {
        return (
          <div key={id}>
            {/* <Select
              mode="multiple"
              placeholder="Select volunteer"
              style={{
                width: "100%",
                borderRadius: "4px",
              }}
              className="inputLabel"
              showSearch={true}
              options={
                native_users &&
                native_users.length > 0 &&
                native_users.map((val, i) => {
                  return {
                    label: val.first_name + " " + val.last_name,
                    value: val.id,
                  };
                })
              }
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(e) => createVolunteerMethod(e, id)}
              showArrow={true}
              defaultValue={natives_volunteers_users.map((user) => {
                return {
                  label:
                    user.volunteer.first_name + " " + user.volunteer.last_name,
                  value: user.id,
                };
              })}
              onDeselect={(e) => deleteVolunteerMethod(e, id)}
            ></Select> */}
            {/* <DebounceSelect
              mode="multiple"
              placeholder="Select users"
              fetchOptions={fetchUserList}
              style={{
                width: "100%",
              }}
            /> */}
            <Select
              mode="multiple"
              placeholder="Select users"
              style={{
                width: "100%",
              }}
              onSearch={(e) => MyDebounceMethod(e)}
              options={options}
              filterOption={false}
              notFoundContent={"No users found"}
              onSelect={(e) => createVolunteerMethod(e, id)}
              onDeselect={(e) => deleteVolunteerMethod(e, id)}
              defaultValue={natives_volunteers_users.map((user) => {
                return {
                  label:
                    user.volunteer.first_name + " " + user.volunteer.last_name,
                  value: user.id,
                };
              })}
              onMouseLeave={() => handleMouseLeave()}
              onFocus={() => handleFocus()}
              dropdownRender={(menu) => {
                return (
                  <div>
                    {/* <Spin spinning={isSearchUserLoading}> */}
                      {menu}
                      {/* </Spin> */}
                  </div>
                );
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div style={{
       marginTop: 40
       }}>
      <Spin
        spinning={
          isCreateNativeVolunteerLoading ||
          isCommunitiesNativeLoading ||
          isDeleteNativeVolunteerLoading
        }
        size="large"
        style={{ height: "100vh" }}
      >
        <Row justify="space-around" gutter={[0, 20]}>
          <Col span={23}>
            <Breadcrumb style={{ marginBottom: 40 }}>
              <Breadcrumb.Item
                onClick={() => handleBreadCrumbMethod()}
                className="breadcrumCommunity"
              >
                Communities
              </Breadcrumb.Item>

              <Breadcrumb.Item className="breadcrumUsers">
                Volunteers
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={23}>
            <CustomTable
              columns={nativeVolunteersColumns}
              data={allCommunitiesNative?.community_natives}
            />
          </Col>
          {allCommunitiesNative && total_length > 0 ? (
          <Col span={23}>
            <Row justify="center">
              <CustomPagination
                currentPage={currentPage}
                length={allCommunitiesNative?.total_length}
                setCurrentPage={setCurrentPage}
              />
            </Row>
          </Col>
          ):""}
        </Row>
      </Spin>
    </div>
  );
};

export default NativeVolunteer;

