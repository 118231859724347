import {
  Input,
  Button,
  Row,
  Col,
  Typography,
  Modal,
  DatePicker,
  TimePicker,
  Spin,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import { yupResolver } from "@hookform/resolvers/yup";

import { eventSchema } from "../Utils/Schema";
import "./Events.css";
import { getEvents, updateEvents } from "../Actions/eventsAction";
import { useDispatch } from "react-redux";
import { createEvents } from "../Actions/eventsAction";
import { useTranslation } from "react-i18next";
import "../Screens/AddFamilymember/Addfamily.css";
import "./../FindThePeoplePage/FindOtherPeople.css";
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons";
const EventsModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isModalVisible,
    setIsModalVisible,
    updateModal,
    setUpdateModal,
    eventId,
    isCreateEventsLoading,
    isUpdateEventsLoading,
    eventname,
    eventlocation,
    cityname,
    event,
    updateDateTime,
   
    time,
    setIsUploadImageVisible,
    dateExample1,
    AmPm,
    eventdate,
    
  } = props;

  if (event) {
    event.dummydate = moment(updateDateTime, "MM-DD-YYYY");
  }
  if (event) {
    event.dummytime = moment(time, "HH:mm:ss");
  }
  const {
    handleSubmit,

    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: event,
    resolver: yupResolver(eventSchema),
  });

  const [eventDate, setEventDate] = useState("");
  const [customTime, setCustomTime] = useState("");
  const [amPm, setAmPM] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [onlyDateSelectionState, setOnlyDateSelectionState] = useState(false);
  const [onlyTimeSelectionState, setOnlyTimeSelectonState] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const IsEmpty = _.isEmpty(newFiles);
  const cancelModal = () => {
    setIsModalVisible(false);
    reset();
    setShowUploadImage(false);
    setNewFiles([]);
  };
  const onDateSelection = (date) => {
    const Date = moment(date);
    setEventDate(moment(Date._d).format("yyyy/MM/DD"));
    setOnlyDateSelectionState(true);
  };

  const onTimeSelection = (time) => {
    const timeSplit = moment(time);
    setCustomTime(timeSplit._d);
    setAmPM(moment(timeSplit._d).format("A"));
    setOnlyTimeSelectonState(true);
 
 
 
  };
  const timeAmPm = customTime.toString().slice(16, 21);
  const fullDate = eventDate + timeAmPm + amPm;
  const handleCreate = (data) => {
    data.community_id = process.env.REACT_APP_COMMUNITY_ID;
    data.event_datetime = fullDate;
    data.community_event_image = newFiles;
    dispatch(createEvents(data, successCreate, IsEmpty));
  };
  const successCreate = () => {
    setIsModalVisible(false);
    dispatch(getEvents(process.env.REACT_APP_COMMUNITY_ID));
    setNewFiles([]);
    setIsUploadImageVisible(false);
    setValue("event_name", "");
    setValue("city_name", "");
    setValue("event_location", "");
    setValue("event_detail", "");
    setValue("dummydate", "");
    setValue("dummytime", "");
    reset();
    setTimeout(() => {
      message.success(t("content.142"));
    }, 1000);
    setShowUploadImage(false);
  };
  const cancelUpdateModal = () => {
    setUpdateModal(false);
    setValue("event_name", eventname);
    setValue("city_name", cityname);
    setValue("event_location", eventlocation);
    setValue("event_detail", event.event_detail);
    clearErrors();
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };
  const onlyDateSelection = eventDate + time + AmPm;
  const onlyTimeSelection = eventdate + timeAmPm + AmPm;
  const handleUpdate = (data) => {
    let eventDateTimeValue = "";
    if (onlyDateSelectionState && onlyTimeSelectionState) {
      eventDateTimeValue = fullDate;
    } else if (onlyDateSelectionState === true) {
      eventDateTimeValue = onlyDateSelection;
    } else if (onlyTimeSelectionState === true) {
      eventDateTimeValue = onlyTimeSelection;
    } else if (fullDate) {
    } else {
      eventDateTimeValue = dateExample1;
    }
    data.id = eventId;
    // data.event_datetime =  onlyDateSelectionState ? onlyDateSelection :  onlyTimeSelectionState ? onlyTimeSelection : fullDate ? fullDate : dateExample1
    data.event_datetime = eventDateTimeValue;
    data.dummydate = updateDateTime;
    data.dummytime = time + timeAmPm;
    data.community_event_image = newFiles;
    data.community_id = process.env.REACT_APP_COMMUNITY_ID;

    dispatch(updateEvents(data, successUpdate, IsEmpty));
  };
  const successUpdate = () => {
    setUpdateModal(false);
    setNewFiles([]);
    dispatch(getEvents(process.env.REACT_APP_COMMUNITY_ID));
    setTimeout(() => {
      message.success(t("content.143"));
    }, 1000);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };
  const propsForUpload = {
    action: handleCreate || handleUpdate,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const handleUploadMethod = () => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
  };
  return (
    <Modal
      visible={updateModal ? updateModal : isModalVisible}
      footer={null}
      onCancel={() => (updateModal ? cancelUpdateModal() : cancelModal())}
      title={
        updateModal ? (
          <Typography className="title">{t("welcomeText.17")}</Typography>
        ) : (
          <Typography className="title">{t("welcomeText.16")}</Typography>
        )
      }
    >
      <Spin
        spinning={updateModal ? isUpdateEventsLoading : isCreateEventsLoading}
        size="large"
      >
        <form
          onSubmit={handleSubmit(updateModal ? handleUpdate : handleCreate)}
        >
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  {updateModal && isDefaultImageVisible && event?.image && (
                    <Upload
                      listType="picture-card"
                      openFileDialogOnClick={false}
                    >
                      <img src={event?.image} width={102} alt=""></img>
                      {/* <PlusOutlined size={44}/>Upload Image */}
                    </Upload>
                  )}
                </Col>
                <Col>
                  {/* <Input
                suffix={ButtonAfter}
                contentEditable={false}
                maxLength={0}
                value={newFiles?.name}
                placeholder={t("placeholder.14")}
                style={{"borderRadius":"4px"}}
             
             /> */}

                  <Upload
                    {...propsForUpload}
                    listType="picture-card"
                    // maxCount={1}
                    showUploadList={showUploadImage}
                    onChange={() => handleUploadMethod()}
                  >
                    <PlusOutlined size={44} />
                    {t("content.154")}
                  </Upload>
                </Col>
              </Row>
              <Row gutter={[10, { md: 15, sm: 15, lg: 15, xs: 0 }]}>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className="label"
                    // style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    {t("content.7")}
                  </Typography>
                  <Controller
                    as={<Input size="large" className="inputLabel" />}
                    name="event_name"
                    control={control}
                  />
                  {errors.event_name && (
                    <p style={{ color: "red" }}>{t("content.106")}</p>
                  )}
                </Col>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className="label"
                    // style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    {t("content.8")}
                  </Typography>
                  <Controller
                    as={<Input size="large" className="inputLabel" />}
                    name="city_name"
                    control={control}
                  />
                  {errors.city_name && (
                    <p style={{ color: "red" }}>{t("content.107")}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, { md: 15, sm: 15, lg: 15, xs: 0 }]}>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className="label"
                    // style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    {t("content.9")}
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        placeholder={t("placeholder.10")}
                        size="large"
                        className="inputLabel"
                      />
                    }
                    name="dummydate"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {errors.dummydate && (
                    <p style={{ color: "red" }}>{t("content.108")}</p>
                  )}
                </Col>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className="label"
                    // style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    {t("content.10")}
                  </Typography>
                  <Controller
                    as={
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        onSelect={(time) => onTimeSelection(time)}
                        size="large"
                        placeholder={t("placeholder.11")}
                        className="inputLabel"
                      />
                    }
                    name="dummytime"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {errors.dummytime && (
                    <p style={{ color: "red" }}>{t("content.109")}</p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, { md: 15, sm: 15, lg: 15, xs: 0 }]}>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className="label"
                    // style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    {t("content.11")}
                  </Typography>
                  <Controller
                    as={<Input size="large" className="inputLabel" />}
                    name="event_detail"
                    control={control}
                  />
                  {errors.event_detail && (
                    <p style={{ color: "red" }}>{t("content.110")}</p>
                  )}
                </Col>
                <Col xl={12} md={12} sm={12} xs={24}>
                  <Typography
                    className="label"
                    // style={{ paddingBottom: "5px", paddingTop: "10px" }}
                  >
                    {t("content.12")}
                  </Typography>
                  <Controller
                    as={<Input size="large" className="inputLabel" />}
                    name="event_location"
                    control={control}
                  />
                  {errors.event_location && (
                    <p style={{ color: "red" }}>{t("content.111")}</p>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[10, 15]} justify="end">
                <Col span={24} />
                {updateModal ? (
                  <Col>
                    <Button
                      onClick={cancelUpdateModal}
                      className="allButtonsCancel"
                    >
                      {t("btn.19")}
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      onClick={() => cancelModal()}
                      className="allButtonsCancel"
                    >
                      {t("btn.19")}
                    </Button>
                  </Col>
                )}
                {updateModal ? (
                  <Col>
                    <Button htmlType="submit" className="allButtons">
                      {t("btn.17")}
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button htmlType="submit" className="allButtons">
                      {t("btn.18")}
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </form>
      </Spin>
    </Modal>
  );
};
export default EventsModal;
