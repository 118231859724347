import constants from "../Utils/constants";
const initialState = {
  isGetDocumentLoading: false,
  isCreateDocumentLoading: false,
  isDeleteDocumentLoading: false,
  isUpdateDocumentLoading: false,
  allDocuments: [],
};

export const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        isGetDocumentLoading: true,
      };
    case constants.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isGetDocumentLoading: false,
        allDocuments: action.payload,
      };
    case constants.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        isGetDocumentLoading: false,
      };
      case constants.CREATE_DOCUMENT_REQUEST:
        return {
          ...state,
          isCreateDocumentLoading: true,
        };
      case constants.CREATE_DOCUMENT_SUCCESS:
        return {
          ...state,
          isCreateDocumentLoading: false,
        };
      case constants.CREATE_DOCUMENT_FAILURE:
        return {
          ...state,
          isCreateDocumentLoading: false,
        };
      case constants.DELETE_DOCUMENT_REQUEST:
        return {
          ...state,
          isDeleteDocumentLoading: true,
        };
      case constants.DELETE_DOCUMENT_SUCCESS:
        return {
          ...state,
          isDeleteDocumentLoading: false,
        };
      case constants.DELETE_DOCUMENT_FAILURE:
        return {
          ...state,
          isDeleteDocumentLoading: false,
        };
      case constants.UPDATE_DOCUMENT_REQUEST:
        return {
          ...state,
          isUpdateDocumentLoading: true,
        };
      case constants.UPDATE_DOCUMENT_SUCCESS:
        return {
          ...state,
          isUpdateDocumentLoading: false,
        };
      case constants.UPDATE_DOCUMENT_FAILURE:
        return {
          ...state,
          isUpdateDocumentLoading: false,
        };
      case constants.RESET_APP_DATA:
       
      return {
          ...initialState
        }
      default:
      return state;
  }
};

