import React from 'react';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { communityMeetingSchema } from '../../Utils/Schema'
import moment from 'moment';
import { getCommunityMeetings, updateCommunityMeeting,createCommunityMeeting } from '../../Actions/communityMeetingsAction';
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {Button, Modal, Col, Row, DatePicker, Input, Spin, message } from 'antd';
import { useTranslation } from "react-i18next";
const { TextArea } = Input;


const AddMeetingModal = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {isAddMeetingModalVisible, setIsAddMeetingModalVisible, iseditMeetingModalVisible, setIseditMeetingModalVisible, editMeetingId, setEditMeetingId, selectedMeeting, allMeetings} = props;
    
    const { 
        isCreateCommunityMeetingLoading, 
        isEditCommunityMeetingLoading,
    } = useSelector((state) => {
        const { isCreateCommunityMeetingLoading, isEditCommunityMeetingLoading } = state.communityMeetings;

        return {
            isCreateCommunityMeetingLoading,
            isEditCommunityMeetingLoading,
          };

        });

    const Year = moment(selectedMeeting[0]?.year);
    const newArray = selectedMeeting.map((obj) => {
        return {
            ...obj,
            year: Year
        }
    })
    
    const [meeting, setMeeting] = useState(null)
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
    } = useForm({
        // resolver: yupResolver(communityMeetingSchema),
        // defaultValues: meeting
    })

    useEffect(() => {
        setMeeting(newArray[0]);
        reset(newArray[0]);
    }, [selectedMeeting[0],reset])

    const closeAddMeetingModal = () => {
        setIsAddMeetingModalVisible(false);
        // setValue("title", "")
        // setValue("place", "")
        // setValue("number", "")
        // setValue("description" ,"")
        // setValue("year", "")
        // setEditMeetingId(null);
        // setMeeting(null);
        // reset();
    }

    const closeEditMeetingModal = () => {
        setIseditMeetingModalVisible(false);
        setValue("title", "")
        setValue("place", "")
        setValue("number", "")
        setValue("description" ,"")
        setValue("year", "")
        setEditMeetingId("");
        reset();
    }

    const createAddMeetingMethod = (data) => {
        data.community_id = process.env.REACT_APP_COMMUNITY_ID
        const Year = data.year._d;
        data.year = moment(Year).format('YYYY');
        // data.year = "2022"
        dispatch(createCommunityMeeting(data, successCreateAddMeetingMethod))
    }

    const successCreateAddMeetingMethod = (createSuccessResponse) => {
        setIsAddMeetingModalVisible(false)
        setValue("title", "")
        setValue("place", "")
        setValue("number", "")
        setValue("description" ,"")
        setValue("year", "")
        setEditMeetingId("");
        reset({});
        // setMeeting(null);
        setTimeout(() => {
            message.success("Your community meeting added successfully");
        }, 1000)
        dispatch(getCommunityMeetings(process.env.REACT_APP_COMMUNITY_ID, createSuccessResponse.year));
    }

    const editMeetingMethod = (data) => {
        data.id = editMeetingId
        const Year = data.year;
        data.year = moment(Year).format('YYYY');
        dispatch(updateCommunityMeeting(data, successEditMethod))
    }

    const successEditMethod = (editSuccessDataResponse) => {
        setIseditMeetingModalVisible(false)
        setValue("title", "")
        setValue("place", "")
        setValue("number", "")
        setValue("description" ,"")
        setValue("year", "")
        setEditMeetingId("");
        // setMeeting(null);
        reset({});
        setTimeout(() => {
            message.success("Your community meeting edited successfully");
          }, 1000)
        dispatch(getCommunityMeetings(process.env.REACT_APP_COMMUNITY_ID, editSuccessDataResponse.year));
    }

   
    useEffect(() => {

        if(!editMeetingId){
            setValue("title", "")
            setValue("place", "")
            setValue("number", "")
            setValue("description" ,"")
            setValue("year", "")
            setEditMeetingId("");
            // reset();
        }
      },[setValue,!editMeetingId])

    return (
        <Modal 
            title = {isAddMeetingModalVisible? t("content.212") : t("content.213")}
            visible={isAddMeetingModalVisible ? isAddMeetingModalVisible :  iseditMeetingModalVisible} 
            onCancel={isAddMeetingModalVisible ? closeAddMeetingModal : closeEditMeetingModal} 
            okText="Save" 
            footer={null}>
            <Spin spinning={isCreateCommunityMeetingLoading ? isCreateCommunityMeetingLoading : isEditCommunityMeetingLoading} size="large">
            <form onSubmit={ handleSubmit( iseditMeetingModalVisible ? editMeetingMethod : createAddMeetingMethod) }>
                <Row gutter={[0,10]}>
                    <Col span= {24}>
                        <label className="label">{t("content.214")} : </label>
                        <Controller 
                            as={ <Input/> }
                            name='title'
                            control={control}
                        />
                        {errors.title && (
                                <p style={{ color: "red" }}>{errors.title.message}</p>
                        )}
                    </Col>
                </Row>
                <Row gutter={[5,10]}>
                    {/* <Col span= {12}>
                        <label className="label">{t("content.215")} : </label>
                        <Controller 
                            as={ <Input /> }
                            name='number'
                            control={control}
                        />
                        {errors.number && (
                                <p style={{ color: "red" }}>{errors.number.message}</p>
                        )}
                    </Col> */}
                    <Col span= {24}>
                        <label className="label">{t("content.216")} : </label>
                        <Controller 
                            as={ <Input /> }
                            name='place'
                            control={control}
                        />
                        {errors.place && (
                                <p style={{ color: "red" }}>{errors.place.message}</p>
                        )}
                    </Col>
                </Row>
                <Row gutter={[0,10]}>
                    <Col span= {24}>
                        <label className="label">{t("content.217")} : </label>
                        <Controller 
                            as={ <TextArea rows={3}  /> }
                            name='description'
                            control={control}
                        />
                        {errors.description && (
                                <p style={{ color: "red" }}>{errors.description.message}</p>
                        )}
                    </Col>
                </Row>
                <Row gutter={[0,10]}>
                    <Col span= {24}>
                        <label className="label">{t("content.218")} : </label>
                        <Controller 
                            as={ <DatePicker picker="year" onChange={(date, dateString) => console.log({date, dateString})}/> }
                            name='year'
                            format="YYYY"
                            control={control}
                        />
                        {errors.year && (
                                <p style={{ color: "red" }}>{errors.year.message}</p>
                        )}
                    </Col>
                </Row>
                {/* <Row gutter={[0,10]}>
                    <Col span= {24}>
                        <label className="label">Published? : </label>
                        <Controller 
                            as={ 
                                <Radio.Group>
                                    <Radio name='publish' value={"yes"}>Yes</Radio>
                                    <Radio name='publish' value={"no"}>No</Radio>
                                </Radio.Group> 
                            }
                            name='publish'
                            control={control}
                        />
                    </Col>
                </Row> */}

                <Row gutter={[10,10]} justify="end">
                <Col>
                <Button onClick={() => {isAddMeetingModalVisible ? closeAddMeetingModal() : closeEditMeetingModal()}} className="allButtonsCancel">{t("btn.19")}</Button>
                </Col>
                <Col>
                    <Button htmlType="submit" className="allButtons" >{t("btn.22")}</Button>
                </Col>
                </Row>
            </form>
            </Spin>
        </Modal>
    )
}

export default AddMeetingModal;
