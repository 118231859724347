import API from "../Utils/API";
export const getIndustriesAction = (url) => (dispatch) => {
  dispatch({ type: "GET_INDUSTRIES_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_INDUSTRIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_INDUSTRIES_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getEducationById = (Id) => (dispatch) => {
  dispatch({ type: "GET_INDUSTRY_BY_ID_REQUEST" });
  API.get(`api/industries/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_INDUSTRY_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_INDUSTRY_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const createIndustryAction = (data, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "CREATE_INDUSTRIES_REQUEST", payload: data });

  API.post("api/industries", data)
    .then((response) => {
      dispatch({ type: "CREATE_INDUSTRIES_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_INDUSTRIES_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);
    });
};
export const updateIndustryAction = (data, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_INDUSTRIES_REQUEST" });
  API.put(`api/industries/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_INDUSTRIES_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_INDUSTRIES_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);
    });
};

export const deleteIndustryAction =(Id, successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_INDUSTRIES_REQUEST" });
    API.delete(`api/industries/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_INDUSTRIES_SUCCESS",
          payload: response.data,
        });
        // dispatch(getIndustriesAction());
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_INDUSTRIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };
