import API from "../Utils/API";
export const getEvents = (url) => (dispatch) => {
  dispatch({ type: "GET_EVENTS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_EVENTS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_EVENTS_FAILURE", payload: error.response.data });
    });
};
export const getEventById = (Id) => (dispatch) => {
  dispatch({ type: "GET_EVENTS_BY_ID_REQUEST" });
  API.get(`api/community_events/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_EVENTS_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_EVENTS_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createEvents =
  (data, successCreateEvent, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_EVENTS_REQUEST", payload: data });
    let formData = new FormData();
    // IsEmpty
    //   ? formData.append("community_event_image", data.community_event_image)
    //   : formData.append(
    //       "community_event[community_event_image]",
    //       data.community_event_image
    //     );
    if (!IsEmpty) {
      formData.append(
        "community_event[community_event_image]",
        data.community_event_image
      );
    }
    formData.append("community_id", data.community_id);
    formData.append("community_event[event_name]", data.event_name);
    formData.append("community_event[city_name]", data.city_name);
    formData.append("community_event[event_location]", data.event_location);
    formData.append("community_event[event_detail]", data.event_detail);
    formData.append("community_event[event_datetime]", data.event_datetime);
    API.post("api/community_events", formData)
      .then((response) => {
        dispatch({ type: "CREATE_EVENTS_SUCCESS", payload: response.data });
        successCreateEvent && successCreateEvent();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_EVENTS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback();
      });
  };

export const deleteEvents = (Id, successDeleteCallBack) => (dispatch) => {
  dispatch({ type: "DELETE_EVENTS_REQUEST" });
  API.delete(`api/community_events/${Id}`)
    .then((response) => {
      dispatch({ type: "DELETE_EVENTS_SUCCESS", payload: response.data });
      successDeleteCallBack && successDeleteCallBack();
    })
    .catch((error) => {
      dispatch({ type: "DELETE_EVENTS_FAILURE", payload: error.response.data });
    });
};
export const updateEvents =
  (data, successUpdate, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_EVENTS_REQUEST" });
    let formData = new FormData();
    // IsEmpty
    //   ? formData.append("community_event_image", data.community_event_image)
    //   : formData.append(
    //       "community_event[community_event_image]",
    //       data.community_event_image
    //     );

    // formData.append("community_id", data.community_id);
    if (!IsEmpty) {
      formData.append(
        "community_event[community_event_image]",
        data.community_event_image
      );
    }
    formData.append("community_event[event_name]", data.event_name);
    formData.append("community_event[city_name]", data.city_name);
    formData.append("community_event[event_location]", data.event_location);
    formData.append("community_event[event_detail]", data.event_detail);
    formData.append("community_event[event_datetime]", data.event_datetime);
    API.put(`api/community_events/${data.id}`, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_EVENTS_SUCCESS", payload: response.data });
        successUpdate && successUpdate();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_EVENTS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback();
      });
  };
