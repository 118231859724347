import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Input,
  message,
  Drawer,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  createCommunitiesNative,
  getCommunitiesNative,
  updateCommunitiesNativeAction,
} from "../../../Actions/communitiesNativeAction";
import { useDispatch, useSelector } from "react-redux";
import { nativeSchema } from "../../../Utils/Schema";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../AddFamilymember/Addfamily.css";
import _ from "lodash";

const CommunityNativeModalForm = (props) => {
  const {
    editId,
    setEditId,
    currentPage,
    setCurrentPage,
    isModalVisible,
    setIsModalVisible,
  } = props;
  const [preloadedCommunityValue, setPreloadedCommunityValue] = useState("");
  const dispatch = useDispatch();

  const {
    isCreateCommunitiesNativeLoading,
    isUpdateCommunitiesNativeLoading,
    allCommunitiesNative,
    setCommunityId,
  } = useSelector((state) => {
    const {
      isCreateCommunitiesNativeLoading,
      isUpdateCommunitiesNativeLoading,
      allCommunitiesNative,
    } = state.communitiesNative;
    const { setCommunityId } = state.communities;
    return {
      isCreateCommunitiesNativeLoading,
      isUpdateCommunitiesNativeLoading,
      setCommunityId,
      allCommunitiesNative,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedCommunityValue },
    resolver: yupResolver(nativeSchema),
  });
  const the_community = _.filter(
    allCommunitiesNative.community_natives,
    function (o) {
      return o.id === editId;
    }
  );
  useEffect(() => {
    if (editId) {
      setPreloadedCommunityValue(the_community[0]);
      reset(the_community[0]);
    }
  }, [the_community[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId("");
    setValue("name", "");
    clearErrors();
  };

  const createCommunityNative = (data) => {
    data.community_id = setCommunityId;
    dispatch(createCommunitiesNative(data, successCreateNative, failureCreate));
  };

  const successCreateNative = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();
    setValue("name", "");
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your community native added successfully");
    }, 1000);
  };
  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };
  const editNative = (data) => {
    data.id = editId;
    dispatch(
      updateCommunitiesNativeAction(
        data,
        successEditNativeMethod,
        faliureUpdate
      )
    );
  };

  const successEditNativeMethod = () => {
    setEditId("");
    reset();
    setIsModalVisible(false);
    setValue("name", "");
    dispatch(
      getCommunitiesNative(
        `api/community_natives?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your native edited successfully");
    }, 1000);
  };

  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, editId]);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Community Native" : "Add Community Native"}
    >
      <Spin
        spinning={
          editId
            ? isUpdateCommunitiesNativeLoading
            : isCreateCommunitiesNativeLoading
        }
        size="large"
      >
        <form
          onSubmit={handleSubmit(editId ? editNative : createCommunityNative)}
        >
          <Spin
            spinning={
              editId
                ? isUpdateCommunitiesNativeLoading
                : isCreateCommunitiesNativeLoading
            }
          >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Typography className="label" style={{ paddingBottom: "4px" }}>
                  Native Place
                </Typography>
                <Controller
                  as={<Input className="inputLabel" />}
                  name="name"
                  control={control}
                />
                {errors.name && (
                  <p style={{ color: "red" }}>{errors.name.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[8, 8]} style={{ paddingTop: "8px" }}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                {editId ? (
                  <Button size="large" className="editButton" htmlType="submit">
                    Update
                  </Button>
                ) : (
                  <Button size="large" className="editButton" htmlType="submit">
                    Add
                  </Button>
                )}
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button
                  className="cancelButton"
                  onClick={handleClose}
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </Drawer>
  );
};

export default CommunityNativeModalForm;
