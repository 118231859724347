import React, {  useEffect } from "react";
import {
  // Modal,
  // Input,
  Button,
  Typography,
  Row,
  Col,
  Select,
  // Divider,
  message,
  // Steps,
  // Upload,
  // TimePicker,
  // Checkbox,
  // DatePicker,
  Spin,
  Drawer,
  // Tag,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeUserCommunitySchema } from "../../Utils/Schema";
import {
  getCommunities,
  updateUserCommunityAction,
} from "../../Actions/communitiesAction";
import { history } from "../../Utils/history";
import _ from "lodash";
const ChangeUserCommunityDrawer = (props) => {
  const {
    isChangeUserCommunityDrawerVisible,
    setIsChangeUserCommunityDrawerVisible,
  } = props;
  const dispatch = useDispatch();
  const {
    userById,
    allCommunities,
    setCommunityId,
    isUpdateUserCommunityLoading,
  } = useSelector((state) => {
    const { userById } = state.authentication;
    const { allCommunities, setCommunityId, isUpdateUserCommunityLoading } =
      state.communities;

    return {
      userById,
      allCommunities,
      setCommunityId,
      isUpdateUserCommunityLoading,
    };
  });
  useEffect(() => {
    dispatch(getCommunities("api/communities"));
  }, [dispatch]);
  const userCommunity = _.filter(allCommunities?.communities, (val) => {
    return val.id === setCommunityId;
  });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    // trigger,
    // getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(changeUserCommunitySchema),
    defaultValues: { community_id: userCommunity[0]?.name },
  });
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const handleChangeUserCommunity = (data) => {
    data.user_id = userById?.id;
    data.community_id = containsNumber(data.community_id)
      ? data.community_id
      : userCommunity[0]?.id;
    dispatch(updateUserCommunityAction(data, SuccessCallback));
  };

  const SuccessCallback = () => {
    history.push("/users");
    setTimeout(() => {
      message.success("User community changed successfully");
    }, 1000);
  };

  const onCloseMethod = () => {
    setIsChangeUserCommunityDrawerVisible(false);
    setValue("community_id", userCommunity[0]?.name);
    clearErrors();
    reset();
  };

  return (
    <Drawer
      open={isChangeUserCommunityDrawerVisible}
      title="Change User Community"
      onClose={onCloseMethod}
    >
      <form onSubmit={handleSubmit(handleChangeUserCommunity)}>
        <Spin spinning={isUpdateUserCommunityLoading} size="large">
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                Select Community
              </Typography>
              <Controller
                as={
                  <Select
                    placeholder="Select Community"
                    options={
                      allCommunities?.communities &&
                      allCommunities?.communities?.length > 0 &&
                      allCommunities?.communities?.map((val, i) => {
                        return {
                          label: val.name,
                          value: val.id,
                        };
                      })
                    }
                    style={{ width: "100%", borderRadius: "4px" }}
                    size="large"
                  />
                }
                name="community_id"
                control={control}
              />
              {errors.community_id && (
                <p style={{ color: "red" }}>{errors.community_id.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit" disabled={!isDirty}>
                    Save
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => onCloseMethod()}>Cancel</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};

export default ChangeUserCommunityDrawer;
