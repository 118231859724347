import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { history } from "../../../Utils/history";
import { getArchiveTicketByIdAction } from "../../../Actions/ticketAction";
import {
  FileOutlined,
  CreditCardOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Row, Col, Typography, Timeline, Card } from "antd";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import "./Ticket.css";
import moment from "moment";
const ArchiveTicketHistory = (props) => {
  const dispatch = useDispatch();
  const { allArchiveTickets, archiveTicket } = useSelector((state) => {
    const {
      allTickets,
      archiveTicket,
      isGetTicketsLoading,
      isCreateTicketsLoading,
      allArchiveTickets,
    } = state.tickets;
    const { setCommunityId } = state.communities;
    return {
      setCommunityId,
      allTickets,
      isGetTicketsLoading,
      isCreateTicketsLoading,
      allArchiveTickets,
      archiveTicket,
    };
  });

  const onClickCommunity2 = () => {
    history.push("/ticket");
    dispatch(getArchiveTicketByIdAction(""));
    dispatch({
      type: "GET_SELECTED_ARCHIVETICKET_ID",
      payload: "",
    });
  };
  return (
    <div className="DrawerBorderClass">
      <Row justify="space-around" 
      // style={{ margin: 20 }}
      >
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <ArrowLeftOutlined
            style={{ paddingBottom: "20px" }}
            onClick={() => onClickCommunity2()}
          />
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="profileDetails-card">
            <Row justify="space-between">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Timeline>
                  {archiveTicket?.ticket_histories?.map((val, i) => {
                    const { created_at, attachable_type, activity } = val;
                    return (
                      <Timeline.Item
                        dot={
                          <div
                            style={{
                              border: "1px solid #E0E0E0",
                              width: "32px",

                              height: "32px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {activity === "Created" ? (
                              <CreditCardOutlined />
                            ) : activity === "ClosedByAdmin" ? (
                              <CloseOutlined />
                            ) : activity === "OpenByAdmin" ? (
                              <FileOutlined />
                            ) : activity === "Destroyed" ? (
                              <CloseOutlined />
                            ) : (
                              ""
                            )}
                          </div>
                        }
                        className="ActivityTimelineCircle"
                        key={i}
                      >
                        <Row>
                          <Col xl={18} lg={18} md={18} sm={8} xs={8}>
                            <Row>
                              <Typography>
                                {" "}
                                {attachable_type}{" "}
                                {activity === "Created"
                                  ? activity.replaceAll("Created", "Created")
                                  : activity === "ClosedByAdmin"
                                  ? activity.replaceAll(
                                      "ClosedByAdmin",
                                      "Closed By Admin"
                                    )
                                  : activity === "OpenByAdmin"
                                  ? activity.replaceAll(
                                      "OpenByAdmin",
                                      "Open By Admin"
                                    )
                                  : activity === "Destroyed"
                                  ? activity.replaceAll(
                                      "Destroyed",
                                      "Destroyed"
                                    )
                                  : ""}{" "}
                                {moment(created_at).format("LLL")}
                              </Typography>
                            </Row>
                          </Col>

                          <Col
                            xl={6}
                            lg={6}
                            md={6}
                            sm={8}
                            xs={8}
                            style={{ textAlign: "end" }}
                          >
                            <Typography className="ActivityMainText">
                              {moment(created_at).startOf("minute").fromNow()}
                            </Typography>
                          </Col>
                        </Row>
                      </Timeline.Item>
                    );
                  })}
                </Timeline>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ArchiveTicketHistory;
