import API from "../Utils/API";

export const getDocuments = (url) => (dispatch) => {
   
  dispatch({ type: "GET_DOCUMENT_REQUEST" });
    API.get(url)

    // API.get(url)   
    .then((response) => {
            dispatch({ type: "GET_DOCUMENT_SUCCESS", payload: response.data })
        })
        .catch((error) => {
            dispatch({ type: "GET_DOCUMENT_FAILURE", payload: error?.response?.data})
        });

};

export const createDocuments = (data, successCallback,failureCallback,IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_DOCUMENT_REQUEST" });
    let formData = new FormData();
    IsEmpty
      ? formData.append("document_file", data.document_file)
      : formData.append(
          "document[document_file]",
          data.document_file
        );
    formData.append("community_id", data.community_id);
    formData.append("document[file_name]", data.file_name);
    formData.append("document[year]", data.year);
    // formData.append("document[document_file]", data.document_file);
    formData.append("document[user_id]", data.user_id);
    API.post("api/documents", formData)
      .then((response) => {
        dispatch({ type: "CREATE_DOCUMENT_SUCCESS", payload: response.data });
        successCallback(response.data);
      })
      .catch((error) => {
        dispatch({ type: "CREATE_DOCUMENT_FAILURE", payload: error?.response?.data });
      failureCallback();
      });
  };
  
  export const deleteDocuments = (documentId,successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_DOCUMENT_REQUEST" });
    API.delete(`api/documents/${documentId}`)
      .then((response) => {
        dispatch({ type: "DELETE_DOCUMENT_SUCCESS", payload: response.data });
        // dispatch(getDocuments());
        successCallback();
      })
      
      .catch((error) => {
        dispatch({
          type: "DELETE_DOCUMENT_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };
  
  export const updateDocuments = (data, successUpdateCallBack,failureCallback,IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_DOCUMENT_REQUEST" });
    let formData = new FormData();
    IsEmpty
      ? formData.append("document_file", data.document_file)
      : formData.append(
          "document[document_file]",
          data.document_file
        );
    formData.append("community_id", data.community_id);
    formData.append("document[file_name]", data.file_name);
    formData.append("document[year]", data.year);
    // formData.append("document[document_file]", data.document_file);
    formData.append("document[user_id]", data.user_id);
    API.put(`api/documents/${data.id}`, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_DOCUMENT_SUCCESS", payload: response.data });
        successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_DOCUMENT_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };
  


