import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Spin,
  Pagination,
  message,
  Tooltip,
  Popconfirm,
} from "antd";
import _ from "lodash";
import IndustriesModalForm from "./IndustriesModalForm";
import { history } from "../../../Utils/history";
import { useDispatch, useSelector } from "react-redux";
import { getCommunityById } from "../../../Actions/communitiesAction";
import { useForm } from "react-hook-form";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  getIndustriesAction,
  updateIndustryAction,
  deleteIndustryAction,
} from "../../../Actions/industryAction";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Edit2 from "../../../assets/Edit2.svg";
import Delete from "../../../assets/delete.svg";
const Industries = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [valName, setValName] = useState("");
  const [IndustryId, setIndustryId] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { reset } = useForm({});
  let UnpublishData = {};
  let PublishData = {};
  const {
    allIndustries,
    isUpdateIndustryLoading,
    isCreateIndustryLoading,
    isIndustriesLoading,
  } = useSelector((state) => {
    const {
      allIndustries,
      isIndustriesLoading,
      isUpdateIndustryLoading,
      isCreateIndustryLoading,
    } = state.industry;
    return {
      allIndustries,
      isIndustriesLoading,
      isUpdateIndustryLoading,
      isCreateIndustryLoading,
    };
  });
  const { total_length } = allIndustries || {};

  useEffect(() => {
    dispatch(getIndustriesAction(`api/industries?page=${currentPage}`));
  }, [currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };

  const onClickIndustry = () => {
    setSelectedIndustry("");
    setValName("");
    reset();
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteIndustry = (Id) => {
    dispatch(deleteIndustryAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    dispatch(getIndustriesAction(`api/industries?page=${currentPage}`));
    setTimeout(() => {
      message.success("Your Industry Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handlePublishCommunityMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateIndustryAction(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishCommunityMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateIndustryAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Industry Published Successfully");
    }, 1000);
    dispatch(getIndustriesAction(`api/industries?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Industry Unpublished Successfully");
    }, 1000);
    dispatch(getIndustriesAction(`api/industries?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const the_name = _.filter(allIndustries.industries, function (o) {
    return o.id === valName;
  });
  const Columns = [
    {
      title: "Industry Name",
      dataIndex: "name",
      width: "80%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              {published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublishCommunityMethod(id)}
                    icon={<img src={PublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnpublishCommunityMethod(id)}
                    icon={<img src={UnpublishedIcon} />}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteIndustry(id)}
                  okText="Ok"
                  cancelText="cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isIndustriesLoading}>
      <Row gutter={[32, 32]}
      className="community"

      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickIndustry()}
              className="breadcrumUsers"
            >
              Industries
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={0} lg={0} md={0} sm={6} xs={6}></Col>
            {/* <Col xl={12} lg={12} md={12} sm={9} xs={9}>
            <Controller
              name="user_type"
              as={
                <Select
                  placeholder="Search Industries"
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="inputLabel"
                  showSearch={true}
                  options={
                    allIndustries &&
                    total_length > 0 &&
                    allIndustries.industries.map((val, i) => {
                      return {
                        label: val.name,
                        value: val.id,
                      };
                    })
                  }
                  onSelect={(Id) => handleSelectIndustry(Id)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                ></Select>
              }
              control={control}
              className="inputLabel"
            />
          </Col> */}
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => setIsModalVisible(true)}
              >
                Add Industry
              </Button>
            </Col>
          </Row>
          <Spin
            spinning={
              editId ? isUpdateIndustryLoading : isCreateIndustryLoading
            }
            size="large"
          >
            <IndustriesModalForm
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              IndustryId={IndustryId}
              setIndustryId={setIndustryId}
              setValName={setValName}
              valName={valName}
              setSelectedIndustry={setSelectedIndustry}
              selectedIndustry={selectedIndustry}
              isModalVisible={isModalVisible}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></IndustriesModalForm>
          </Spin>
        </Col>
        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable columns={Columns} data={allIndustries?.industries} />
            </Col>
          </Row>
        </Col>
        {allIndustries && total_length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allIndustries?.total_length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
        ):""}
      </Row>
    </Spin>
  );
};

export default Industries;
