import { falseyValueCases } from "../Screens/CommonLogics/CommonMethods";
import API from "../Utils/API";
export const getUsers = (url) => (dispatch) => {
  dispatch({ type: "GET_USERS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_USERS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_USERS_FAILURE", payload: error.response.data });
    });
};

export const usersById = (Id) => (dispatch) => {
  dispatch({ type: "USERS_BY_ID_REQUEST" });
  API.get(`api/users/${Id}`)
    .then((response) => {
      dispatch({ type: "USERS_BY_ID_SUCCESS", payload: response.data });
      dispatch(getUsers());
    })
    .catch((error) => {
      dispatch({
        type: "USERS_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const createUsers =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_USERS_REQUEST" });
    let formData = new FormData();
    if (!IsEmpty) {
      formData.append("user[user_image]", data.user_image);
    }

    formData.append("user[first_name]", data.first_name);
    formData.append("user[last_name]", data.last_name);
    if (!falseyValueCases(data.email)) {
      formData.append("user[email]", data.email);
    }

    formData.append("user[phone]", data.phone);
    formData.append("user_type", data.user_type);
    formData.append("community_id", data.community_id);
    formData.append("user[education_id]", data.education_id);
    formData.append("user[industry_id]", data.industry_id);
    formData.append("user[birth_date]", data.birth_date);
    formData.append("user[birth_month]", data.birth_month);
    formData.append("user[birth_year]", data.birth_year);
    formData.append("user[country_code]", data.country_code);
    formData.append("user[gender]", data.gender);

    // user[country_code]
    // formData.append("user[is_poused]", data.is_poused);
    // {
    //   IsEmpty
    //     ? formData.append("user_image", data.user_image)
    //     : formData.append("user[user_image]", data.user_image);
    // }
    API.post("api/users", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_USERS_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_USERS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateUsers =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_USERS_REQUEST" });
    let formData = new FormData();

    data.first_name && formData.append("user[first_name]", data.first_name);
    data.last_name && formData.append("user[last_name]", data.last_name);
    data.email && formData.append("user[email]", data.email);

    data.phone && formData.append("user[phone]", data.phone);
    data.user_type && formData.append("user[user_type]", data.user_type);
    // formData.append("user[is_poused]", data.is_poused);
    formData.append("user[education_id]", data.education_id);
    formData.append("user[industry_id]", data.industry_id);
    // formData.append("community_id", data.community_id);
    {
      IsEmpty
        ? formData.append("user_image", data.user_image)
        : formData.append("user[user_image]", data.user_image);
    }
    API.put(`api/users/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_USERS_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_USERS_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };

export const deleteUsers = (Id, successDeleteCallback) => (dispatch) => {
  dispatch({ type: "DELETE_USERS_REQUEST" });
  API.delete(`api/users/${Id}`)
    .then((response) => {
      dispatch({ type: "DELETE_USERS_SUCCESS", payload: response.data });
      // dispatch(getUsers());
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_USERS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createUserRoleAction =
  (data, successCreateCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_USER_ROLE_REQUEST" });
    API.post("api/add_user_role", data)
      .then((response) => {
        dispatch({ type: "CREATE_USER_ROLE_SUCCESS", payload: response.data });
        successCreateCallback && successCreateCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_USER_ROLE_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
