import constants from "../Utils/constants";

const initialState = {
  isBusinessLoading: false,
  isCreateBusinessLoading: false,
  isDeleteBusinessLoading: false,
  isUpdateBusinessLoading: false,
  isBusinessByIdLoading: false,
  allBusiness: [],
  businessProfile: {},
  setTrusteeId: "",
};

export const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_BUSINESS_REQUEST:
      return {
        ...state,
        isBusinessLoading: true,
      };
    case constants.GET_BUSINESS_SUCCESS:
      return {
        ...state,
        isBusinessLoading: false,
        allBusiness: action.payload,
      };
    case constants.GET_BUSINESS_FAILURE:
      return {
        ...state,
        isBusinessLoading: false,
      };

    case constants.BUSINESS_BY_ID_REQUEST:
      return {
        ...state,
        isBusinessByIdLoading: true,
      };
    case constants.BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        isBusinessByIdLoading: false,
        businessProfile: action.payload,
      };
    case constants.BUSINESS_BY_ID_FAILURE:
      return {
        ...state,
        isBusinessByIdLoading: false,
      };

    case constants.CREATE_BUSINESS_REQUEST:
      return {
        ...state,
        isCreateBusinessLoading: true,
      };
    case constants.CREATE_BUSINESS_SUCCESS:
      return {
        ...state,
        isCreateBusinessLoading: false,
      };
    case constants.CREATE_BUSINESS_FAILURE:
      return {
        ...state,
        isCreateBusinessLoading: false,
      };
    case constants.DELETE_BUSINESS_REQUEST:
      return {
        ...state,
        isDeleteBusinessLoading: true,
      };
    case constants.DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        isDeleteBusinessLoading: false,
      };
    case constants.DELETE_BUSINESS_FAILURE:
      return {
        ...state,
        isDeleteBusinessLoading: false,
      };
    case constants.UPDATE_BUSINESS_REQUEST:
      return {
        ...state,
        isUpdateBusinessLoading: true,
      };
    case constants.UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        isUpdateBusinessLoading: false,
      };
    case constants.UPDATE_BUSINESS_FAILURE:
      return {
        ...state,
        isUpdateBusinessLoading: false,
      };
      case constants. GET_SELECTED_BUSINESS_ID:
        return {
          ...state,
          setTrusteeId: action.payload,
        };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
   
   
   
    return state;
  }
};

