import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Modal,
  Input,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import {
  createCommunitiesNative,
  getCommunitiesNative,
  updateCommunitiesNativeAction,
} from "../../Actions/communitiesNativeAction";
import { useDispatch, useSelector } from "react-redux";

import { nativeSchema } from "../../Utils/Schema";
import "../../FindThePeoplePage/FindOtherPeople.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "../AddFamilymember/Addfamily.css";
import { useTranslation } from "react-i18next";


const AddNativeModal = (props) => {
  const { t } = useTranslation();
  const {
    isAddNativeModalVisible,
    setIsAddNativeModalVisible,
    isEditNativeModalVisible,
    setIsEditNativeModalVisible,
    editNativeId,
    selectedNative,
    setEditNativeId,
  } = props;

  const {
    isCreateCommunitiesNativeLoading,

    isUpdateCommunitiesNativeLoading,
  } = useSelector((state) => {
    const {
      isCreateCommunitiesNativeLoading,
      isUpdateCommunitiesNativeLoading,
    } = state.communitiesNative;

    return {
      isCreateCommunitiesNativeLoading,
      isUpdateCommunitiesNativeLoading,
    };
  });

  const [native, setNative] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(nativeSchema),
    defaultValues: native,
  });
  useEffect(() => {
    setNative(selectedNative);
    reset(selectedNative);
  }, [selectedNative, reset]);

  const dispatch = useDispatch();

  const closeOpenAddNativeMethod = () => {
    setIsAddNativeModalVisible(false);
    setValue("name", "");
    setEditNativeId("");
    reset();
  };

  const createAddNativeMethod = (data) => {
    data.published = true;
    data.community_id = process.env.REACT_APP_COMMUNITY_ID;
    dispatch(createCommunitiesNative(data, successCreateAddNativeMethod));
  };

  const successCreateAddNativeMethod = () => {
    setIsAddNativeModalVisible(false);
    setTimeout(() => {
      message.success("Your native added successfully");
    }, 1000);
    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
    setValue("name", "");

    reset();
  };
  const closeEditNativeMethod = () => {
    setIsEditNativeModalVisible(false);
    setValue("name", "");
    setEditNativeId("");
    setNative("");
    reset();
  };

  const editNativeMethod = (data) => {
    data.id = editNativeId;
    dispatch(updateCommunitiesNativeAction(data, successEditNativeMethod));
  };

  const successEditNativeMethod = () => {
    setIsEditNativeModalVisible(false);
    setValue("name", "");
    reset();
    setTimeout(() => {
      message.success("Your native edited successfully");
    }, 1000);

    dispatch(getCommunitiesNative(process.env.REACT_APP_COMMUNITY_ID));
  };

  useEffect(() => {
    if (isAddNativeModalVisible) {
      setValue("name", "");
    }
  }, [setValue, isAddNativeModalVisible]);
  return (
    <Modal
      title={isEditNativeModalVisible ? t("content.168") : t("content.166")}
      visible={
        isEditNativeModalVisible
          ? isEditNativeModalVisible
          : isAddNativeModalVisible
      }
      onCancel={
        isEditNativeModalVisible
          ? closeEditNativeMethod
          : closeOpenAddNativeMethod
      }
      footer={false}
      width={400}
    >
      <Spin
        spinning={
          isEditNativeModalVisible
            ? isUpdateCommunitiesNativeLoading
            : isCreateCommunitiesNativeLoading
        }
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            isEditNativeModalVisible ? editNativeMethod : createAddNativeMethod
          )}
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                {t("content.167")}
              </Typography>
              <Controller
                as={<Input className="inputLabel" />}
                name="name"
                control={control}
              />
              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    onClick={
                      isEditNativeModalVisible
                        ? closeEditNativeMethod
                        : closeOpenAddNativeMethod
                    }
                    className="allButtonsCancel"
                  >
                    {t("btn.19")}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {isEditNativeModalVisible ? t("btn.17") : t("btn.22")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Spin>
    </Modal>
  );
};

export default AddNativeModal;
