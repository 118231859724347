import constants from "../Utils/constants";
const initialState = {
  isGetCommnunityCardsLoading: false,
  isCreateCommunityCardsLoading: false,
  isDeleteCommunityCardsLoading: false,
  isUpdateCommunityCardsLoading: false,
  allCommunityCards: [],
};

export const communityCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_COMMUNITY_CARDS_REQUEST:
      return {
        ...state,
        isGetCommnunityCardsLoading: true,
        allCommunityCards: [],
      };
    case constants.GET_COMMUNITY_CARDS_SUCCESS:
      return {
        ...state,
        isGetCommnunityCardsLoading: false,
        allCommunityCards: action.payload,
      };
    case constants.GET_COMMUNITY_CARDS_FAILURE:
      return {
        ...state,
        isGetCommnunityCardsLoading: false,
      };
    case constants.CREATE_COMMUNITY_CARDS_REQUEST:
      return {
        ...state,
        isCreateCommunityCardsLoading: true,
      };
    case constants.CREATE_COMMUNITY_CARDS_SUCCESS:
      return {
        ...state,
        isCreateCommunityCardsLoading: false,
      };
    case constants.CREATE_COMMUNITY_CARDS_FAILURE:
      return {
        ...state,
        isCreateCommunityCardsLoading: false,
      };
    case constants.DELETE_COMMUNITY_CARDS_REQUEST:
      return {
        ...state,
        isDeleteCommunityCardsLoading: true,
      };
    case constants.DELETE_COMMUNITY_CARDS_SUCCESS:
      return {
        ...state,
        isDeleteCommunityCardsLoading: false,
      };
    case constants.DELETE_COMMUNITY_CARDS_FAILURE:
      return {
        ...state,
        isDeleteCommunityCardsLoading: false,
      };
    case constants.UPDATE_COMMUNITY_CARDS_REQUEST:
      return {
        ...state,
        isUpdateCommunityCardsLoading: true,
      };
    case constants.UPDATE_COMMUNITY_CARDS_SUCCESS:
      return {
        ...state,
        isUpdateCommunityCardsLoading: false,
      };
    case constants.UPDATE_COMMUNITY_CARDS_FAILURE:
      return {
        ...state,
        isUpdateCommunityCardsLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
