import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Button,
  Typography,
  Divider,
  Popover,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  MoreOutlined,
  HomeOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import MemberCard from "./MemberCard";
import AddFamilyMember from "./addFamilyMemberModal";
import { deleteFamily, getFamilies } from "../../Actions/familyAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getFamilyAddress,
  deleteFamilyAddressAction,
} from "../../Actions/familyAddressAction";
import AddAddressModal from "./AddAddressModal";
import _ from "lodash";

import "./Addfamily.css";
// import { useBreakpoints } from "react-responsive-hooks";
import { useBreakpoints } from "../../Screens/CommonLogics/ResponsiveBreakPoints";

const FamilyCard = (props) => {
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

  const {
    family,
    setSelectedFamilyId,
    setSelectedMemberId,
    setIsViewMemberDetails,
    setEditFamilyId,
    setIsEditFamilyModalVisible,
    setValue,

    secondColor,
  } = props;
  const {
    id: familyIdFromObject,
    last_name,
    community_native,
    family_addresses,
    user_families,
  } = family;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { isCreateFamilyAddressLoading, isUpdateFamilyAddressLoading } =
    useSelector((state) => {
      const {
        allFamilyAddress,
        isCreateFamilyAddressLoading,
        isUpdateFamilyAddressLoading,
      } = state.familyAddresses;
      return {
        allFamilyAddress,
        isCreateFamilyAddressLoading,
        isUpdateFamilyAddressLoading,
      };
    });
  useEffect(() => {
    dispatch(getFamilyAddress(familyIdFromObject));
  }, [dispatch, familyIdFromObject]);
  // const { IsXS } = useBreakpoints();
  const [isAddFamilyMemberModalVisible, setIsAddFamilyMemberModalVisible] =
    useState(false);
  const [isAddAddressModalVisible, setIsAddAddressModalVisible] =
    useState(false);
  const [editFamilyAddressId, setEditFamilyAddressId] = useState("");
  const [isEditAddressModalVisible, setIsEditAddressModalVisible] =
    useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isEditPopoverVisible, setIsEditPopoverVisible] = useState(false);
  const [idForPopover, setIdForPopover] = useState("");
  const [myObj, setMyObj] = useState({});
  const FamilyAddress = _.filter(family_addresses, (address) => {
    return address.id === editFamilyAddressId;
  });
  const handleFamilyMemberModal = () => {
    setIsAddFamilyMemberModalVisible(true);
  };
  const showMemberDetails = (memberId, familyId) => {
    setSelectedMemberId(memberId);
    setSelectedFamilyId(familyId);
    setIsViewMemberDetails(true);
  };
  const handleEditFamily = () => {
    setIsEditFamilyModalVisible(true);
    setEditFamilyId(familyIdFromObject);
    setValue("last_name", last_name);
    setValue("community_native_id", community_native?.name);
  };
  const handleDeleteFamily = (familyIdFromObject) => {
    dispatch(deleteFamily(familyIdFromObject, deleteFamilyCallBack));
  };
  const deleteFamilyCallBack = () => {
    setTimeout(() => {
      message.success(t("content.127"));
    }, 1000);
  };
  const openAddAddressModal = () => {
    setIsAddAddressModalVisible(true);
    setIsPopoverVisible(false);
  };
  const openEditAddressModal = (familyIdFromVal, val) => {
    setEditFamilyAddressId(familyIdFromVal);
    setIsEditAddressModalVisible(true);
    setMyObj(val);
    setIsEditPopoverVisible(false);
  };
  const deleteFamilyAddressMethod = (FamilyAddressId) => {
    dispatch(
      deleteFamilyAddressAction(FamilyAddressId, successDeleteAddressMethod)
    );
  };
  const successDeleteAddressMethod = () => {
    dispatch(getFamilies());
    setTimeout(() => {
      message.success(t("content.130"));
    }, 1000);
  };
  const openEditPopover = (Id) => {
    setIsEditPopoverVisible(true);
    setIdForPopover(Id);
  };
  return (
    <Col xl={12} lg={15} md={18} sm={24} xs={24}>
      <Row>
        <Col xl={23} lg={23} md={22} sm={22} xs={24}>
          <Card
            style={{
              borderRadius: "10px",
              padding: "0px",
              boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.12)",
            }}
          >
            <Row justify="space-between">
              <Col>
                <Row gutter={[11, 0]}>
                  <Col>
                    <Typography className="title">
                      {family.last_name}
                    </Typography>
                  </Col>
                  <Col>
                    {!IsXS && (
                      <Typography className="memberStyle">
                        {t("welcomeText.9")} {user_families.length}{" "}
                        {t("content.75")}
                      </Typography>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Tooltip title={t("btn.27")}>
                      <Button
                        icon={<EditOutlined />}
                        type="text"
                        onClick={() => handleEditFamily()}
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title={t("btn.28")}>
                      <Popconfirm
                        title={t("btn.29")}
                        onConfirm={() => handleDeleteFamily(familyIdFromObject)}
                        okText={t("btn.28")}
                        cancelText={t("btn.19")}
                      >
                        <Button icon={<DeleteOutlined />} type="text" />
                      </Popconfirm>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Popover
                      trigger="click"
                      visible={isPopoverVisible}
                      onVisibleChange={(visible) =>
                        setIsPopoverVisible(visible)
                      }
                      content={
                        <>
                          <Button
                            icon={<PlusCircleTwoTone />}
                            onClick={() => openAddAddressModal()}
                            type="link"
                          >
                            {t("btn.39")}
                          </Button>
                          <AddAddressModal
                            isAddAddressModalVisible={isAddAddressModalVisible}
                            setIsAddAddressModalVisible={
                              setIsAddAddressModalVisible
                            }
                            isCreateFamilyAddressLoading={
                              isCreateFamilyAddressLoading
                            }
                            familyIdFromObject={familyIdFromObject}
                          />
                        </>
                      }
                    >
                      <MoreOutlined
                        style={{ fontSize: "15px" }}
                        onClick={() => setIsPopoverVisible(true)}
                      />
                    </Popover>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            {family_addresses?.map((val, i) => {
              return (
                <Row key={i}>
                  <Col span={4}>
                    <Typography>
                      {IsXS ? <HomeOutlined /> : <b>{t("content.19")} :</b>}{" "}
                    </Typography>
                  </Col>
                  <Col span={19}>
                    <Typography className="addressLine">
                      {val.home_number} {val.address_line_1}{" "}
                      {val.address_line_2} {val.city} {val.landmark} {val.state}
                      ,{val.country} {val.zip}
                    </Typography>
                  </Col>
                  <Col span={1}>
                    <Popover
                      trigger="click"
                      visible={idForPopover === val.id && isEditPopoverVisible}
                      onVisibleChange={(visible) =>
                        setIsEditPopoverVisible(visible)
                      }
                      content={
                        <>
                          <Tooltip title={t("btn.27")}>
                            <EditOutlined
                              onClick={() => openEditAddressModal(val.id, val)}
                            />
                          </Tooltip>
                          <AddAddressModal
                            editFamilyAddressId={editFamilyAddressId}
                            isEditAddressModalVisible={
                              isEditAddressModalVisible
                            }
                            setIsEditAddressModalVisible={
                              setIsEditAddressModalVisible
                            }
                            familyAddressObject={FamilyAddress[0]}
                            familyIdFromObject={familyIdFromObject}
                            myObj={myObj}
                            isUpdateFamilyAddressLoading={
                              isUpdateFamilyAddressLoading
                            }
                            allFamilyAddress={family_addresses}
                          />
                          <Popconfirm
                            title={t("btn.29")}
                            onConfirm={() => deleteFamilyAddressMethod(val.id)}
                            okText={t("btn.28")}
                            cancelText={t("btn.19")}
                            placement="topLeft"
                          >
                            <Tooltip title={t("btn.28")}>
                              <DeleteOutlined style={{ paddingLeft: "10px" }} />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      }
                    >
                      <MoreOutlined
                        style={{ fontSize: "15px" }}
                        onClick={() => openEditPopover(val.id)}
                      />
                    </Popover>
                  </Col>

                  <Divider />
                </Row>
              );
            })}
            <Row>
              {user_families?.map((member) => {
                return (
                  <MemberCard
                    onClickMethod={showMemberDetails}
                    member={member}
                    familyId={family.id}
                    setIsViewMemberDetails={setIsViewMemberDetails}
                    secondColor={secondColor}
                  />
                );
              })}
            </Row>
            <Col>
              <Row
                justify="center"
                style={{ backgroundColor: "#ECF6FC",
                 marginTop: "10px" 
                }}
              >
                {/* <Col span={24} /> */}
                <Col>
                  <Button
                    type="link"
                    onClick={() => handleFamilyMemberModal()}
                    icon={<UserAddOutlined />}
                  >
                    {t("btn.38")}
                  </Button>
                  <AddFamilyMember
                    isAddFamilyMemberModalVisible={
                      isAddFamilyMemberModalVisible
                    }
                    setIsAddFamilyMemberModalVisible={
                      setIsAddFamilyMemberModalVisible
                    }
                    familyId={family.id}
                  />
                </Col>
              </Row>
            </Col>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
export default FamilyCard;
