import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Spin,
  Avatar,
  Button,
  PageHeader,
  Breadcrumb,
  Divider,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getJourneyById } from "../../../Actions/JourneyAction";
import { ArrowLeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import Edit from "../../../assets/Edit.svg";
import { history } from "../../../Utils/history";
import { ImageEnv } from "../../CommonLogics/CommonMethods";

const JourneyDetails = (props) => {
  const {
    setJourneyDetailId,
    journeyDetailId,
    editId,
    setEditId,
    setIsModalVisible,
    setIsDefaultImageVisible,
    setShowUploadImage,
    currentPage,
  } = props;

  const {
    allJourney,journey
  } = useSelector((state) => {
    const { journey,allJourney} = state.journey;
    return {
      allJourney,journey
    };
  });
  const {
    title,
    description,
    url,
    journey_image,
    journey_image_key,
  } = journey || {};

  const dispatch = useDispatch();

  const handleCloseJourney = () => {
    setJourneyDetailId("");
    history.push("/journey");
    dispatch(getJourneyById(""));
    dispatch({
      type: "GET_SELECTED_JOURNEY_ID",
      payload: "",
    });
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
  };

  return (
    // <Spin spinning={isCommumitiesloading}>
    <>
      <Row justify="space-around" style={{ 
        // margin: 20
         }}>
        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
          <ArrowLeftOutlined
            style={{ paddingBottom: "20px" }}
            onClick={() => handleCloseJourney()}
          />
        </Col>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => handleCloseJourney()}
              className="breadcrumCommunity"
            >
              All Journey
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
                      {title}
                      </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="profileDetails-card">
            <Row justify="space-between">
              {/* <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={
                  community?.published
                    ? "true"
                      ? "unpublishedColor"
                      : "false"
                    : "publishedColor"
                }
              >
                {community.published
                  ? "true"
                    ? "Published"
                    : "false"
                  : "Unpblished"}
              </Col> */}
              <Divider />
              <Col xl={1} lg={1} md={2} sm={4} xs={4}>
                <Avatar
                  shape="square"
                  size={85}
                  className="avtar"
                  src={journey_image_key &&  ImageEnv(journey_image_key)}
                >
                  <Typography style={{ fontSize: "40px", color: "white" }}>
                    {title?.[0]}
                  </Typography>
                </Avatar>
                {journey_image_key}
              </Col>
              <Col xl={0} lg={0} md={2} sm={4} xs={4}></Col>
              <Col xl={14} lg={14} md={14} sm={8} xs={8}>
                <Row>
                  <Typography className="detailsPage">{title}</Typography>
                </Row>
                <Row>
                <Typography
                              component="span"
                              className="UserDetailType"
                            >
                              Route&nbsp;:&nbsp;-
                            </Typography>
                            &nbsp;
                            <Typography
                              component="span"
                              className="UserDetailType"
                            >
                              {url}
                            </Typography>
                </Row>
                <Row>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>

                
                </Row>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={8}
                xs={8}
                style={{ textAlign: "end" }}
              >
                <Button
                  icon={<img src={Edit} />}
                  type="text"
                  onClick={() => handleEdit(journeyDetailId,journey)}
                />
              </Col>
              <Row style={{ display: "contents" }}>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ marginTop: 20 }}
                >
                  <PageHeader
                    title="Details"
                    className="pageHeaderDetails"
                  ></PageHeader>
                </Col>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ 
                    // margin: 20
                   }}
                >
                  <Typography className="detailsPage2">
                    {description}
                  </Typography>
                </Col>
              </Row>

           
            </Row>

          </Card>
        </Col>
      </Row>
      </>
    //  </Spin> 
  );
};
export default JourneyDetails;
