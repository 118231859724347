import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tabs, Typography, Card, Avatar } from "antd";
import "./CommunityCard.css";
// import SamajIconSvg from "../../assets/SamajIconSvg.svg";
import moment from "moment/moment";
import { ImageEnv } from "../../CommonLogics/CommonMethods";
import _ from "lodash";
const CommunityImageCard = (props) => {
  const { mappedData, isCommunityCardModal, imgUrl } = props;
  const mappedDataDate = moment(mappedData?.created_at).format("MMM Do YYYY");
  const date = new Date(mappedData?.expiry_date);
  const typedDataDate = moment(date).format("MMM Do YYYY");
  return (
    <div>
      <Card className="communityCard">
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Row justify="space-between">
                  <Col>
                    <Typography className="CommunityTextCardTitle">
                      {mappedData?.name}
                    </Typography>
                  </Col>
                  <Col>
                    <Typography className="communityCardDate">
                      {isCommunityCardModal ? typedDataDate : mappedDataDate}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Typography className="communityCardDescription">
                  {mappedData?.description}
                </Typography>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <img

              // src={imgUrl ? imgUrl : mappedData?.card_file}
              src={
                _.startsWith(imgUrl, "data")
                  ? imgUrl
                  : ImageEnv(imgUrl ? imgUrl : mappedData?.card_file_key)
              }

              style={{
                width: "100%",
                height: "190px",
                borderRadius: "4px",
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CommunityImageCard;
