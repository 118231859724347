import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTicketByIdAction } from "../../../Actions/ticketAction";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Input,
  Drawer,
  Upload,
  Spin,
} from "antd";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import "./Ticket.css";
import {
  PictureOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { createMessageAction } from "../../../Actions/messageAction";
import _ from "lodash";
import moment from "moment";
import DisplayImageModal from "./DisplayImageModal";
const ChatArchieveDrawer = (props) => {
  const dispatch = useDispatch();
  const {
    isChatDrawerVisible,
    createTicketSuccessData,
    setIsChatDrawerVisible,

    setTicketByIdArchieveState,
    ticketByIdState,
    backgroundColor,
  } = props;
  const { ticket, archiveTicket, currentUser, isCreateMessageLoading } =
    useSelector((state) => {
      const { ticket, archiveTicket } = state.tickets;
      const { currentUser } = state.authentication;
      const { isCreateMessageLoading } = state.message;
      return {
        ticket,
        currentUser,
        isCreateMessageLoading,
        archiveTicket,
      };
    });
  useEffect(() => {
    setTicketByIdArchieveState(ticket);
  }, [ticket, setTicketByIdArchieveState, dispatch]);
  const { messages } = ticketByIdState || {};
  const [inputData, setInputData] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [isDisplayImageVisible, setIsDisplayImageVisible] = useState(false);
  const [selectedImagesState, setSelectedImagesState] = useState([]);
  const closeChatDrawerMethod = () => {
    setIsChatDrawerVisible(false);
    setTicketByIdArchieveState({});
    setShowUploadImage(false);
  };
  let data = {};
  const createMessageMethod = (e) => {
    e?.preventDefault();
    data.ticket_id = createTicketSuccessData?.id
      ? createTicketSuccessData?.id
      : ticketByIdState?.id;
    data.description = inputData;
    data.files = newFiles;
    dispatch(
      createMessageAction(
        data,
        createMessageSuccessMethod,
        createMessageFailureMethod,
        false
      )
    );
    e?.preventDefault();
  };
  const createMessageSuccessMethod = (SuccessResponse) => {
    dispatch(
      getTicketByIdAction(
        createTicketSuccessData?.id
          ? createTicketSuccessData?.id
          : SuccessResponse?.ticket?.id,
        getTicketByIdSuccessMethod
      )
    );
    setInputData("");
    setShowUploadImage(false);
  };
  const createMessageFailureMethod = () => {};
  const getTicketByIdSuccessMethod = (SuccessResponse) => {
    setTicketByIdArchieveState(SuccessResponse);
  };

  document.getElementById("linkToDiv")?.scrollIntoView();

  const propsForUpload = {
    action: createMessageMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(...new file());
      return false;
    },
    multiple: false,
  };
  const handleChangeUploadMethod = (e) => {
    setNewFiles(e.fileList);
    setShowUploadImage(true);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#4593EF",
      }}
    />
  );
  const handleOpenDisplayImageMethod = (Images) => {
    setIsDisplayImageVisible(true);
    const selectedImages = Images?.map((val, i) => {
      return {
        original: val.url,
      };
    });
    setSelectedImagesState(selectedImages);
  };
  return (
    <div>
      <Drawer
        open={isChatDrawerVisible}
        title={
          <Row>
            <Col span={10}>
              <Typography className="IdTextChatDrawer">
                {createTicketSuccessData
                  ? createTicketSuccessData?.name
                  : ticketByIdState?.name}
              </Typography>
            </Col>
            <Col span={6}>
              <Typography className="IdTextChatDrawer">
                {createTicketSuccessData
                  ? createTicketSuccessData?.ticket_status
                  : ticketByIdState?.ticket_status}
              </Typography>
            </Col>
            <Col span={8}>
              <Row justify="end">
                <Typography className="IdTextChatDrawer">
                  {createTicketSuccessData
                    ? createTicketSuccessData?.ticket_number
                    : ticketByIdState?.ticket_number}
                </Typography>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        }
        width={600}
        onClose={closeChatDrawerMethod}
        maskClassName="DrawerClass"
        className="DrawerBorderClass"
      >
        <Row gutter={[0, 39]} style={{ marginBottom: "50px" }}>
          {messages &&
            messages?.length > 0 &&
            messages?.map((val, i) => {
              const { attachable_records } = val;
              const filteredRecords = _.filter(
                attachable_records,
                (records) => {
                  return !_.endsWith(records.url, "doc");
                }
              );
              const recordsWithoutPdfAndDoc = _.filter(
                filteredRecords,
                (records) => {
                  return !_.endsWith(records.url, "pdf");
                }
              );
              return val?.user?.id === currentUser?.id ? (
                <Col span={24} key={i} id={i}>
                  <Row gutter={[4, 0]}>
                    <Col span={2} />
                    <Col
                      span={20}
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <div className="UserChatBox">
                        <Typography className="UserChatBoxText">
                          {val?.description}{" "}
                          {recordsWithoutPdfAndDoc.length > 0 &&
                            recordsWithoutPdfAndDoc.map((val, i) => {
                              return (
                                <Avatar
                                  src={val?.url}
                                  size={40}
                                  style={{
                                    // margin: "0px 10px",
                                    cursor: "pointer",
                                  }}
                                  shape="square"
                                  key={i}
                                  onClick={() =>
                                    handleOpenDisplayImageMethod(
                                      recordsWithoutPdfAndDoc
                                    )
                                  }
                                />
                              );
                            })}
                        </Typography>
                      </div>
                    </Col>
                    <Col span={2}>
                      <Row
                        align="bottom"
                        justify="end"
                        style={{ height: "100%" }}
                      >
                        <Avatar
                          size={37}
                          src={ticketByIdState?.ticket_creator?.image}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              !ticketByIdState?.ticket_creator?.image &&
                              backgroundColor,
                          }}
                        >
                          {ticketByIdState?.ticket_creator?.first_name[0]}
                        </Avatar>
                      </Row>
                    </Col>
                    <Col span={22} style={{ 
                      marginTop: "7px"
                       }}>
                      <Row justify="end">
                        <Typography className="DateAndTime">
                          {moment(val.created_at).format("dddd LT")}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <Col span={24} key={i}>
                  <Row gutter={[4, 0]}>
                    <Col span={2}>
                      <Row align="bottom" style={{ height: "100%" }}>
                        <Avatar
                          size={37}
                          src={val?.user?.image}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              !val?.user?.image && backgroundColor,
                          }}
                        >
                          {val?.user?.first_name[0]}
                        </Avatar>
                      </Row>
                    </Col>
                    <Col span={20}>
                      <div className="AdminChatBox">
                        {recordsWithoutPdfAndDoc?.length > 0 &&
                          recordsWithoutPdfAndDoc?.map((val, i) => {
                            return (
                              <Avatar
                                src={val?.url}
                                size={100}
                                style={{
                                  // margin: "0px 0px",
                                  cursor: "pointer",
                                }}
                                shape="square"
                                key={i}
                                onClick={() =>
                                  handleOpenDisplayImageMethod(
                                    recordsWithoutPdfAndDoc
                                  )
                                }
                              />
                            );
                          })}
                        <Typography className="AdminChatBoxText">
                          {val?.description}
                        </Typography>
                      </div>
                    </Col>
                    <Col span={2} />
                    <Col span={24} style={{ 
                      marginTop: "7px"
                       }}>
                      <Row>
                        <Col span={2} />
                        <Typography className="DateAndTime">
                          {moment(val.created_at).format("dddd LT")}
                        </Typography>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>
        <form className="ChatBoxMessager" onSubmit={createMessageMethod}>
          <Input
            onChange={(e) => setInputData(e.target.value)}
            value={inputData}
            addonBefore={
              <Upload
                {...propsForUpload}
                listType="picture-card"
                // listType='picture'
                maxCount={1}
                onChange={(e) => handleChangeUploadMethod(e)}
                showUploadList={showUploadImage}
                isImageUrl={(image) => {
                  setImageUrl(image?.thumbUrl);
                  return <img src={image?.thumbUrl} width={40} alt="" />;
                }}
                className="helpAndSupportUpload"
                accept="image/png"
              >
                <PictureOutlined style={{ color: "#4593EF" }} />
              </Upload>
            }
            addonAfter={
              isCreateMessageLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                <SendOutlined
                  style={{
                    color: "#4593EF",
                    pointerEvents: inputData.toString().length < 1 && "none",
                  }}
                  onClick={() => createMessageMethod()}
                />
              )
            }
            placeholder="Start a New Message"
            size="large"
          />
        </form>
        <div id="linkToDiv" />
        <DisplayImageModal
          isDisplayImageVisible={isDisplayImageVisible}
          setIsDisplayImageVisible={setIsDisplayImageVisible}
          selectedImagesState={selectedImagesState}
        />
      </Drawer>
    </div>
  );
};
export default ChatArchieveDrawer;
