import API from "../Utils/API";
export const getJourneyRoute = (url) => (dispatch) => {
  dispatch({ type: "GET_JOURNEY_ROUTE_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_JOURNEY_ROUTE_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_JOURNEY_ROUTE_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getJourneyRouteById = (Id) => (dispatch) => {
  dispatch({ type: "GET_JOURNEY_ROUTE_BY_ID_REQUEST" });
  API.get(`api/journey_routes/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_JOURNEY_ROUTE_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_JOURNEY_ROUTE_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createJourneyRouteAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_JOURNEY_ROUTE_REQUEST" });
    let formData = new FormData();
    formData.append("journey_route[title]", data.title);
    // formData.append("journey_route[url]", data.url);
    formData.append("journey_route[description]", data.description);
    // formData.append("journey_route[published]", data.published);
    formData.append("journey_route[journey_id]", data.journey_id);

    {
      IsEmpty
        ? formData.append("journey_route_image", data.journey_route_image)
        : formData.append("journey_route[journey_route_image]", data.journey_route_image);
    }
    API.post("api/journey_routes", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_JOURNEY_ROUTE_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_JOURNEY_ROUTE_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateJourneyRouteAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_JOURNEY_ROUTE_REQUEST" });
    let formData = new FormData();
    formData.append("journey_route[title]", data.title);
    // formData.append("journey_route[url]", data.url);
    formData.append("journey_id", data.journey_id);
    formData.append("journey_route[description]", data.description);
    // formData.append("journey_route[published]", data.published);
    formData.append("journey_route[journey_id]", data.journey_id);

    {
      IsEmpty
        ? formData.append("journey_route_image", data.journey_route_image)
        : formData.append("journey_route[journey_route_image]", data.journey_route_image);
    }
    API.put(`api/journey_routes/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_JOURNEY_ROUTE_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_JOURNEY_ROUTE_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const deleteJourneyRoute =
  (Id, successDeleteCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_JOURNEY_ROUTE_REQUEST" });
    API.delete(`api/journey_routes/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_JOURNEY_ROUTE_SUCCESS",
          payload: response.data,
        });
        // dispatch(getJourneyRoute());
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_JOURNEY_ROUTE_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };

// export const updatePublishJourney = (data, successCallback) => (dispatch) => {
//   dispatch({ type: "UPDATE_PUBLISH_JOURNEY_ROUTE_REQUEST" });

//   API.put(`/api/published_community/${data.id}`, data)
//     .then((response) => {
//       dispatch({
//         type: "UPDATE_PUBLISH_JOURNEY_ROUTE_SUCCESS",
//         payload: response.data,
//       });
//       successCallback(response.data);
//     })
//     .catch((error) => {
//       dispatch({
//         type: "UPDATE_PUBLISH_JOURNEY_ROUTE_FAILURE",
//         payload: error.response.data,
//       });
//       successCallback();
//     });
// };

// export const updateUserCommunityAction =
//   (data, successCallback) => (dispatch) => {
//     dispatch({ type: "UPDATE_USER_JOURNEY_ROUTE_REQUEST" });

//     API.put("/api/change_user_community/", data)
//       .then((response) => {
//         dispatch({
//           type: "UPDATE_USER_JOURNEY_ROUTE_SUCCESS",
//           payload: response.data,
//         });
//         successCallback && successCallback(response.data);
//       })
//       .catch((error) => {
//         dispatch({
//           type: "UPDATE_USER_JOURNEY_ROUTE_FAILURE",
//           payload: error.response.data,
//         });
//       });
//   };
