import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  Pagination,
  Typography,
  Select,
  message,
  Modal,
  Tooltip,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import { useForm } from "react-hook-form";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import {
  getDocuments,
  deleteDocuments,
} from "../../../Actions/documentsAction";
import _ from "lodash";
import Delete from "../../../assets/delete.svg";
import PdfViewer from "./PdfViewer";
import DocumentsModalForm from "./DocumentsModalForm";
import { getCommunityById } from "../../../Actions/communitiesAction";
import Edit2 from "../../../assets/Edit2.svg";

const Documents = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [valName, setValName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editDocumentId, setEditDocumentId] = useState("");
  const [isDocumentVisible, setIsDocumentVisible] = useState(false);
  const [fileAllowed, setFileAllowed] = useState(false);

  const dispatch = useDispatch();

  const earliestYear = 2000;
  const options = [];
  const d = new Date();
  let y = d.getFullYear();
  const [year, setYear] = useState(y);
  for (let i = earliestYear; i <= y; i++) {
    options.push({
      label: i,
      value: i,
    });
  }
  const { setCommunityId, allDocuments, isGetDocumentLoading } = useSelector(
    (state) => {
      const { setCommunityId } = state.communities;
      const { allDocuments, isGetDocumentLoading } = state.documents;

      return {
        setCommunityId,
        allDocuments,
        isGetDocumentLoading,
      };
    }
  );
  const { total_length } = allDocuments || {};

  useEffect(() => {
    dispatch(
      getDocuments(
        `api/documents?community_id=${setCommunityId}&page=${currentPage}&year=${year}`
      )
    );
  }, [dispatch, setCommunityId, currentPage, year]);
  const {
    formState: { errors },
  } = useForm({});
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const selectedDocument = _.filter(allDocuments.documents, function (o) {
    return o.id === editDocumentId;
  });
  const the_name = _.filter(allDocuments.documents, function (o) {
    return o.id === valName;
  });

  const handleDocumentMethod = (year) => {
    dispatch(getDocuments(year));
    setYear(year);
  };
  const handleDeleteDocuments = (documentId) => {
    dispatch(deleteDocuments(documentId, successDelete, failureDelete));
  };
  const successDelete = () => {
    dispatch(
      getDocuments(
        `api/documents?community_id=${setCommunityId}&page=${currentPage}&year=${year}`
      )
    );
    setTimeout(() => {
      message.success("Your Document Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setFileAllowed(false);
    // setFileAllowed("");
  };
  const Columns = [
    {
      title: "Document File",
      dataIndex: "file_name",
      width: "60%",
      sorter: (a, b) => a.file_name.localeCompare(b.file_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      dataIndex: "id",
      render: (id) => (
        <DocumentActionComponent
          setEditDocumentId={setEditDocumentId}
          id={id}
          setIsDocumentVisible={setIsDocumentVisible}
        />
      ),
      width: "15em",
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteDocuments(id)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  const DocumentActionComponent = (props) => {
    const { setEditDocumentId, id, setIsDocumentVisible } = props;
    const handleViewMethod = (Id) => {
      setEditDocumentId(Id);
      setIsDocumentVisible(true);
    };

    return (
      <Row gutter={[30, 0]}>
        <Col span={10}>
          <Button
            className="editButton"
            type="primary"
            onClick={() => handleViewMethod(id)}
          >
            view
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Spin
        style={{ height: "100%", maxHeight: "unset" }}
        spinning={isGetDocumentLoading}
      >
        <>
          <Row gutter={[32, 32]} 
           className="community" 
          // style={{ margin: 10 }}
          >
            <Col span={24}></Col>
            <Col xl={16} lg={16} md={16} sm={20} xs={20}>
              <Breadcrumb 
              // style={{ margin: 10 }}
              >
                <Breadcrumb.Item
                  onClick={() => onClickCommunity()}
                  className="breadcrumCommunity"
                >
                  Communities
                </Breadcrumb.Item>
                <Breadcrumb.Item className="breadcrumUsers">
                  Documents
                </Breadcrumb.Item>
                <Breadcrumb.Item className="breadcrumUsers">
                  {the_name[0]?.file_name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24}>
              <Row justify="end" gutter={[8, 8]}>
                <Col xl={12} lg={12} md={18} sm={24} xs={24}>
                  <Button
                    className="editDesignationButton"
                    style={{ width: "100%" }}
                    onClick={() => setIsModalVisible(true)}
                  >
                    Add Documents
                  </Button>
                </Col>
              </Row>

              <DocumentsModalForm
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                editId={editId}
                setEditId={setEditId}
                documentId={documentId}
                setDocumentId={setDocumentId}
                setValName={setValName}
                valName={valName}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setYear={setYear}
                year={year}
                setFileAllowed={setFileAllowed}
                fileAllowed={fileAllowed}
              ></DocumentsModalForm>
            </Col>
            <Col xl={6} xxl={6} lg={6} sm={8} md={8} xs={24}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Typography className="content">Select Year:</Typography>
                </Col>
                <Col span={24}>
                  <Select
                    placeholder="Select financial year"
                    name="year"
                    defaultValue={y}
                    style={{ width: "100%" }}
                    onChange={(e) => handleDocumentMethod(e)}
                    size="large"
                    options={options}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[32, 32]}>
                <Col span={24}>
                  <CustomTable
                    columns={Columns}
                    data={allDocuments?.documents}
                  />
                </Col>
              </Row>
            </Col>
            {allDocuments && total_length > 0 ? (
            <Col span={24}>
              <Row justify="center" 
              // style={{ margin: "20px 0px" }}
              >
                <Pagination
                  total={allDocuments?.total_length}
                  onChange={(e) => setCurrentPage(e)}
                  responsive={true}
                  size="large"
                  current={currentPage}
                  showSizeChanger={false}
                />
              </Row>
            </Col>
            ):""}
          </Row>
        </>
      </Spin>
      <Modal
        open={isDocumentVisible}
        onCancel={() => setIsDocumentVisible(false)}
        width={800}
        footer={null}
      >
        <div>
          <PdfViewer selectedDocument={selectedDocument[0]} />
        </div>
      </Modal>
    </>
  );
};

export default Documents;
