import React, { useState,useEffect } from "react";
import { Button, Row, Col, Typography, Spin } from "antd";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import OtpInput from "react-otp-input";
import { Controller, useForm } from "react-hook-form";
import "../../App.css";
import "./OtpStyle.css";
import {
  verifyOtpAction,
  loginUserAction,
} from "../../Actions/authenticationAction";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../Utils/history";
import { useTranslation } from "react-i18next";
import { getCommunityById } from "../../Actions/communitiesAction";

const OtpAuthentication = (props) => {
  const { IsMD, IsLG, IsXL, IsXXL } = useBreakpoints();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isVerifyOtpLoading,loginOtp} =
    useSelector((state) => {
      const { isVerifyOtpLoading,loginOtp } = state.authentication;
      const { setSelectedCommunityNativeId } = state.communitiesNative;

      const { community } = state.communities;
      return {
        loginOtp,
        isVerifyOtpLoading,
        setSelectedCommunityNativeId,
        community,
      };
    });
  const { handleSubmit, control,setError } = useForm({});
  const [seconds, setSeconds] = useState(60);
  const [failureMessage, setFailureMessage] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  // let FamilyData = {};
  useEffect(() => {
    setOtpValue(loginOtp);
  }, [loginOtp]);
  
  const handleOtpInput = (otpValue) => {
    setOtpValue(otpValue);
  };
  const handleVerifyOtpMethod = (data) => {
    data.phone = props?.location?.state?.PhoneNumber;
    data.country_code = props?.location?.state?.LoginSuccessCountryCode;
    data.code=otpValue;
    dispatch(verifyOtpAction(data, successVerifyOtp, failureVerifyOtp));
  };
  const successVerifyOtp = (verifyOtpResponse) => {
    history.push("/communities");
    verifyOtpResponse?.user_community?.id &&
      dispatch({
        type: "GET_SELECTED_COMMUNITY_ID",
        payload: verifyOtpResponse?.user_community?.id,
      });
    verifyOtpResponse?.user_community?.id &&
      dispatch(getCommunityById(verifyOtpResponse?.user_community?.id));
  };
  const failureVerifyOtp = () => {
    setError("code", { type: "custom", message: "Otp is invalid" });
  };

  const data = {
    phone: "",
  };

  const handleResendMethod = () => {
    data.phone = props?.location?.state?.PhoneNumber;
    dispatch(loginUserAction(data));
  };

  setTimeout(() => {
    if (seconds >= 0) {
      setSeconds(seconds - 1);
    } else {
      setSeconds(-1);
    }
  }, 1000);
 
  const height = window.innerHeight;
  return (
    
  
      <div>
        <Spin spinning={isVerifyOtpLoading} size="large">
         
            <Row justify="center" align="middle" gutter={[0, height / 16]}>
            {(IsXL || IsLG || IsMD || IsXXL) && <Col span={24}></Col>}
            <Col span={24}></Col>
            {(IsXL || IsLG || IsMD || IsXXL) && <Col span={24}></Col>}

          <Col xs={{ span: 10, offset: 1 }} xl={10} md={10} sm={10}>
            <Row>
                <Col span={24}>
                <Typography className="headerTitle">
                  {t("welcomeText.4")}
                </Typography>
                </Col>
              </Row>
              <Row gutter={[0, 16]} justify='center'>
              <Col span={24}></Col>
              <Col xl={24} sm={12} lg={24} md={24}>
              <Typography className="pageContent">
                    {t("content.3")}
                  </Typography>
              </Col>
            </Row>
             
              <form onSubmit={handleSubmit(handleVerifyOtpMethod)}>
                <Row gutter={[0, 23]}>
                  <Col span={24} />
                  <Col
                    xl={20}
                    lg={20}
                    xs={22}
                    md={22}
                    sm={15}
                    // style={{ padding: "0px" }}
                  >
                        <OtpInput
                        inputStyle={{
                          width: "100%",
                          height: "56px",
                          background: "#F5F6F8",
                          border: "1px solid #DEDEDE",
                          boxSizing: "border-box",
                          borderRadius: "4px",
                        }}
                        separator={<span style={{ marginLeft: "15px" }}></span>}
                        numInputs={4}
                        value={otpValue}
                        onChange={handleOtpInput}
                        shouldAutoFocus={true}
                        ></OtpInput>
                    {seconds >= 0 ? (
                      <p style={{ color: "red", 
                      marginTop: "10px"
                       }}>
                        {t("content.100")} {seconds} s
                      </p>
                    ) : (
                      <p style={{ color: "red",
                       marginTop: "10px" 
                       }}>
                        {t("content.101")}
                      </p>
                    )}
                    {failureMessage && (
                      <p style={{ color: "red",
                       marginTop: "10px"
                        }}>
                        {t("content.102")}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row justify="start">
                  <Col span={24} />
                  <Col>
                    <Button
                      type="link"
                      className="resendButton"
                      onClick={() => handleResendMethod()}
                      disabled={seconds !== -1}
                    >
                      {t("btn.13")}
                    </Button>
                  </Col>
                </Row>
                <Row gutter={[0, 19]}>
                  <Col span={24} />
                  <Col 
                  // style={{ padding: "0px" }}
                  >
                    <Button
                      type="primary"
                      className="loginBtn"
                      htmlType="submit"
                    >
                      {t("btn.14")}
                    </Button>
                  </Col>
                </Row>
              </form>
             
             </Col>
              </Row>
        </Spin>
      </div>
    
  );
};
export default OtpAuthentication;

