import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Modal,
  Input,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { industrySchema } from "../../Utils/Schema";
import "../../FindThePeoplePage/FindOtherPeople.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "../AddFamilymember/Addfamily.css";
import {
  getIndustriesAction,
  createIndustryAction,
  updateIndustryAction,
} from "../../Actions/industryAction";
import { useTranslation } from "react-i18next";

const AddIndustryModal = (props) => {
  const {
    isEditIndustryModalVisible,
    isAddIndustryModalVisible,
    setIsEditIndustryModalVisible,
    setIsAddIndustryModalVisible,
    editIndustryId,
    selectedIndustry,
    setEditIndustryId,
    isCreateIndustryLoading,
    isUpdateIndustryLoading,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [preloadedIndustryValues, setPreloadedIndustryValues] = useState("");
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(industrySchema),

    defaultValues: preloadedIndustryValues,
  });
  useEffect(() => {
    setPreloadedIndustryValues(selectedIndustry);
    reset(selectedIndustry);
  }, [selectedIndustry, reset]);
  const closeAddIndustryMethod = () => {
    setIsAddIndustryModalVisible(false);
    setValue("name", "");
    setEditIndustryId("");
    reset();
  };
  const closeEditIndustryMethod = () => {
    setIsEditIndustryModalVisible(false);
    setValue("name", "");
    setEditIndustryId("");

    reset();
  };
  const handleAddIndustryMethod = (data) => {
    data.published = true;
    dispatch(createIndustryAction(data, successCreateIndustryMethod));
  };
  const successCreateIndustryMethod = () => {
    setIsAddIndustryModalVisible(false);

    setTimeout(() => {
      message.success("Your Industry Created Successfully");
    }, 1000);
    setValue("name", "");
    dispatch(getIndustriesAction());
    reset();
  };
  const handleEditIndustryMethod = (data) => {
    data.id = editIndustryId;
    dispatch(updateIndustryAction(data, successUpdateIndustryMethod));
  };

  const successUpdateIndustryMethod = () => {
    setIsEditIndustryModalVisible(false);
    setTimeout(() => {
      message.success("Your Industry Edited Successfully");
    }, 1000);
    setValue("name", "");
    reset();
    dispatch(getIndustriesAction());
  };
  useEffect(() => {
   
   
   
    if (isAddIndustryModalVisible) {
      setValue("name", "");
    }
  }, [setValue, isAddIndustryModalVisible]);
  return (
    <Modal
      title={isEditIndustryModalVisible ? t("content.204") : t("content.202")}
      visible={
        isEditIndustryModalVisible
          ? isEditIndustryModalVisible
          : isAddIndustryModalVisible
      }
      width={400}
      footer={null}
      onCancel={
        isEditIndustryModalVisible
          ? closeEditIndustryMethod
          : closeAddIndustryMethod
      }
    >
      <form
        onSubmit={handleSubmit(
          isEditIndustryModalVisible
            ? handleEditIndustryMethod
            : handleAddIndustryMethod
        )}
      >
        <Spin
          spinning={
            isEditIndustryModalVisible
              ? isUpdateIndustryLoading
              : isCreateIndustryLoading
          }
          size="large"
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                {t("content.203")}
              </Typography>
              <Controller
                as={<Input className="inputLabel" />}
                name="name"
                control={control}
              />
              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    className="allButtonsCancel"
                    onClick={
                      isEditIndustryModalVisible
                        ? closeEditIndustryMethod
                        : closeAddIndustryMethod
                    }
                  >
                    {t("btn.19")}
                  </Button>
                </Col>

                <Col>
                  <Button htmlType="submit" className="allButtons">
                    {isEditIndustryModalVisible ? t("btn.27") : t("btn.22")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Modal>
  );
};

export default AddIndustryModal;
