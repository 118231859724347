import API from "../Utils/API";
export const getCommunities = (url) => (dispatch) => {
  dispatch({ type: "GET_COMMUNITIES_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_COMMUNITIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITIES_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getCommunityById = (Id) => (dispatch) => {
  dispatch({ type: "GET_COMMUNITY_BY_ID_REQUEST" });
  API.get(`api/communities/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_COMMUNITY_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COMMUNITY_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createCommunitiesAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_COMMUNITIES_REQUEST" });
    let formData = new FormData();
    formData.append("community[name]", data.name);
    formData.append("community[slug]", data.slug);
    formData.append("community[description]", data.description);
    // formData.append("community[community_url]",data.community_url)
    formData.append("community[show_matrimonial]", data.show_matrimonial);
    formData.append("community[show_trustee]", data.show_trustee);
    formData.append("community[published]", data.published);
    // formData.append("community[community_logo]",data.community_logo)
    formData.append(
      "community[show_find_other_people]",
      data.show_find_other_people
    );
    formData.append("community[show_event]", data.show_event);
    formData.append(
      "community[show_business_profile]",
      data.show_business_profile
    );
    {
      IsEmpty
        ? formData.append("community_logo", data.community_logo)
        : formData.append("community[community_logo]", data.community_logo);
    }
    API.post("api/communities", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_COMMUNITIES_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_COMMUNITIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateCommunitiesAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_COMMUNITIES_REQUEST" });
    let formData = new FormData();
    formData.append("community[name]", data.name);
    formData.append("community[slug]", data.slug);
    formData.append("community[description]", data.description);
    // formData.append("community[community_url]",data.community_url)
    formData.append("community[show_matrimonial]", data.show_matrimonial);
    formData.append("community[show_trustee]", data.show_trustee);
    // formData.append("community[published]", data.published);
    // formData.append("community[community_logo]",data.community_logo)
    formData.append(
      "community[show_find_other_people]",
      data.show_find_other_people
    );
    formData.append("community[show_event]", data.show_event);
    formData.append(
      "community[show_business_profile]",
      data.show_business_profile
    );

    IsEmpty
      ? formData.append("community_logo", data.community_logo)
      : formData.append("community[community_logo]", data.community_logo);
    API.put(`api/communities/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_COMMUNITIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const deleteCommunity =
  (Id, successDeleteCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_COMMUNITIES_REQUEST" });
    API.delete(`api/communities/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_COMMUNITIES_SUCCESS",
          payload: response.data,
        });
        dispatch(getCommunities());
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_COMMUNITIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };

export const updatePublishCommunity = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_PUBLISH_COMMUNITY_REQUEST" });

  API.put(`/api/published_community/${data.id}`, data)
    .then((response) => {
      dispatch({
        type: "UPDATE_PUBLISH_COMMUNITY_SUCCESS",
        payload: response.data,
      });
      successCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_PUBLISH_COMMUNITY_FAILURE",
        payload: error.response.data,
      });
      successCallback();
    });
};

export const updateUserCommunityAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_USER_COMMUNITY_REQUEST" });

    API.put("/api/change_user_community/", data)
      .then((response) => {
        dispatch({
          type: "UPDATE_USER_COMMUNITY_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_USER_COMMUNITY_FAILURE",
          payload: error.response.data,
        });
      });
  };
