import React from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Table,
  message,
  Empty,
  Typography,
} from "antd";
import "../../FindThePeoplePage/FindOtherPeople.css";
import "./Admin.css";
import { useDispatch } from "react-redux";

import {
  updateDesignation,
  getDesignations,
} from "../../Actions/designationsAction";
import DesignationEmptyScreen from "../../assets/DesignationEmptyScreen.svg";

import { useTranslation } from "react-i18next";
const DesignationTable = (props) => {
  const { t } = useTranslation();

  const {
    setIsEditDesignationModalVisible,
    setEditDesignationId,
    allDesignations,
    isGetDesignationsLoading,
  } = props;

  const columns = [
    {
      title: `${t("content.5")} (${allDesignations?.length})`,
      dataIndex: "designation_title",
      width: "80%",
      render: (designation_title, allDesignationsList) => {
        return allDesignationsList.published ? (
          designation_title
        ) : (
          <div className="hidedRow">{designation_title}</div>
        );
      },

      //    sortDirections: ['ascend'],

      //    sorter: (a, b) => a.designation_title.localeCompare(b.designation_title)
    },
    {
      dataIndex: "id",
      render: (id, designation_title) => (
        <ActionComponent
          id={id}
          setIsEditDesignationModalVisible={setIsEditDesignationModalVisible}
          setEditDesignationId={setEditDesignationId}
          designation_title={designation_title}
        />
      ),
      width: "15em",
    },
  ];

  return (
    <div>
      <Spin spinning={isGetDesignationsLoading} size="large">
        {allDesignations.length <= 0 ? (
          <Row
            
          justify="center"
            gutter={[0, 10]}
            className="emptyScreenAdminCenter"
          >
            <Empty
              description={
                <Typography className="emptyScreenAdmin">
                  No Designation added yet
                </Typography>
              }
              image={DesignationEmptyScreen}
              imageStyle={{
                height: 38,
              }}
            />
          </Row>
        ) : (
          <div className="AdminTable">
            <Table
              columns={columns}
              dataSource={allDesignations}
              size="middle"
              className="table"
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default DesignationTable;

const ActionComponent = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    setIsEditDesignationModalVisible,
    setEditDesignationId,
    designation_title,
  } = props;
  let UnpublishData = {};
  let PublishData = {};
  const handleEditDesignationMethod = (id) => {
    setIsEditDesignationModalVisible(true);
    setEditDesignationId(id);
  };
  const handlePublishDesignationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(updateDesignation(PublishData, successUpdatePublishMethod));
  };
  const handleUnpublishDesignationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = false;
    dispatch(updateDesignation(UnpublishData, successUpdateUnpublishMethod));
  };
  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Designation Unpublished Successfully");
    }, 1000);
    dispatch(getDesignations(process.env.REACT_APP_COMMUNITY_ID));
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Designation Published Successfully");
    }, 1000);
    dispatch(getDesignations(process.env.REACT_APP_COMMUNITY_ID));
  };
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={10}>
          <Button
            onClick={() => handleEditDesignationMethod(id)}
            type="primary"
            className="editDesignationButton"
          >
            {t("btn.27")}
          </Button>
        </Col>

        <Col span={10}>
          {designation_title.published ? (
            <Button
              type="primary"
              className="unPublishButton"
              onClick={() => handleUnpublishDesignationMethod(id)}
            >
              {t("content.164")}
            </Button>
          ) : (
            <Button
              type="primary"
              className="publishButton"
              onClick={() => handlePublishDesignationMethod(id)}
            >
              {t("content.163")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};
