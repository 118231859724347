import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getArchiveTicketsAction,
  getArchiveTicketByIdAction,
} from "../../../Actions/ticketAction";
import { Row, Col, Typography, Tooltip } from "antd";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import "../../../FindThePeoplePage/FindOtherPeople.css";
import { history } from "../../../Utils/history";
import ChatArchieveDrawer from "./ChatArchieveDrawer";
import ArchiveTicketHistory from "./AchiveTicketHistory";
import { MessageFilled, HistoryOutlined } from "@ant-design/icons";

const ArchieveData = () => {
  const [isChatDrawerVisible, setIsChatDrawerVisible] = useState(false);
  const [createTicketSuccessData, setCreateTicketSuccessData] = useState("");
  const [ticketByIdState, setTicketByIdArchieveState] = useState({});
  const [setEditId] = useState("");
  const { allArchiveTickets, setCommunityId } = useSelector((state) => {
    const { allArchiveTickets, isGetTicketsLoading, isCreateTicketsLoading } =
      state.tickets;
    const { setCommunityId } = state.communities;
    return {
      setCommunityId,
      allArchiveTickets,
      isGetTicketsLoading,
      isCreateTicketsLoading,
    };
  });
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [trusteeId, setTrusteeId] = useState("");

  useEffect(() => {
    dispatch(
      getArchiveTicketsAction(
        `api/archive_tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
  }, [dispatch, setCommunityId, page]);

  const handleOpenTicketHistory = (Id) => {
    setTrusteeId(Id);
    history.push(`/archieveticketHistory/${Id}`, { Id: Id });
    dispatch(getArchiveTicketByIdAction(Id));
    dispatch({
      type: "GET_SELECTED_ARCHIVETICKET_ID",
      payload: Id,
    });
  };
  const openEditDrawer = (Id) => {
    setEditId(Id);
    history.push(`/users/${Id}`, { id: Id });
  };
  const columns2 = [
    {
      title: "Ticket ID",
      dataIndex: "ticket_number",
      width: "5%",
      sorter: (a, b) => a.ticket_number.localeCompare(b.ticket_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "User",
      dataIndex: "id",
      width: "15%",
      render: (id, { ticket_creator }) => {
        return (
          <Row>
            <Col xl={16} lg={16} md={16} sm={24} xs={24}>
              <Tooltip title="View Profile">
                <Typography onClick={() => openEditDrawer(ticket_creator.id)}>
                  {ticket_creator?.first_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
      sorter: (a, b) => a.user.first_name.localeCompare(b.user.first_name),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Issue",
      dataIndex: "name",
      width: "20%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (id) => (
        <Row justify="space-around">
          <Col>
            <ActionComponent
              id={id}
              setIsChatDrawerVisible={setIsChatDrawerVisible}
              // getTicketByIdAction={getTicketByIdAction}
              getArchiveTicketByIdAction={getArchiveTicketByIdAction}
              setCreateTicketSuccessData={setCreateTicketSuccessData}
            />
          </Col>
          <Col>
            <Tooltip title="View History">
              <HistoryOutlined
                onClick={() => handleOpenTicketHistory(id)}
                style={{ fontSize: 18 }}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
      width: "5%",
    },
  ];

  const backgroundColor = "#" + Math.floor(Math.random() * 1677).toString(14);

  return (
    // <div style={{marginTop:"27px"}}>
    // <Spin spinning={isGetTicketsLoading}>

    <Row gutter={[32, 32]} 
    // style={{ margin: 10 }}
    >
      {trusteeId ? (
        <ArchiveTicketHistory
          trusteeId={trusteeId}
          setTrusteeId={setTrusteeId}
        />
      ) : (
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row justify="space-around">
            <ChatArchieveDrawer
              isChatDrawerVisible={isChatDrawerVisible}
              createTicketSuccessData={createTicketSuccessData}
              setIsChatDrawerVisible={setIsChatDrawerVisible}
              page={page}
              setTicketByIdArchieveState={setTicketByIdArchieveState}
              ticketByIdState={ticketByIdState}
              backgroundColor={backgroundColor}
            />
            <Col span={24}>
              <CustomTable
                data={allArchiveTickets?.tickets}
                columns={columns2}
              />
            </Col>
          </Row>
          {/* </div> */}
        </Col>
      )}
    </Row>
  );
};
export default ArchieveData;

const ActionComponent = (props) => {
  const {
    id,
    setIsChatDrawerVisible,
    getArchiveTicketByIdAction,
    setCreateTicketSuccessData,
  } = props;

  const dispatch = useDispatch();
  const viewDiscussionMethod = (Id) => {
    dispatch(getArchiveTicketByIdAction(Id));
    setIsChatDrawerVisible(true);
    setCreateTicketSuccessData("");
  };
  return (
    <div>
      <Tooltip title="View Chat">
        <MessageFilled
          onClick={() => viewDiscussionMethod(id)}
          style={{ fontSize: 18 }}
        />
      </Tooltip>
    </div>
  );
};
