import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin, Typography, Tooltip, Breadcrumb } from "antd";
import FamilyDrawer from "./FamilyDrawer";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { getFamilies } from "../../Actions/familyAction";
import { history } from "../../Utils/history";
import CustomPagination from "../ReuseableComponents/CustomPagination/CustomPagination";
import { getCommunityById } from "../../Actions/communitiesAction";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";

const Family = (props) => {
  const { setValName, valName, setSelectedFamily } = props;
  const dispatch = useDispatch();
  const { setCommunityId, allFamilies, isFamiliesLoading } = useSelector(
    (state) => {
      const { setCommunityId } = state.communities;
      const { allFamilies, isFamiliesLoading } = state.family;
      return {
        isFamiliesLoading,
        setCommunityId,
        allFamilies,
      };
    }
  );

  const [currentPage, setCurrentPage] = useState(1);
  const { families } = allFamilies;
  const {
    formState: { errors },
    reset,
  } = useForm({});
  useEffect(() => {
    dispatch(
      getFamilies(
        `api/families?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [setCommunityId, currentPage]);
  const [isFamilyDrawerVisible, setIsFamilyDrawerVisible] = useState(false);
  const { total_length } = allFamilies || {};

  const openFamilyByIdMethod = (Id) => {
    history.push(`/families/${Id}`, { id: Id });
  };
  const familyColumns = [
    {
      title: "Family Number",
      dataIndex: "id",
      width: "15%",
      render: (id, { family_number }) => {
        return (
          <Row>
            {/* <EditColumnAction id={id} customMethod={openFamilyByIdMethod} /> */}
            <Tooltip title="View Full Family Detail">
              <Typography onClick={() => openFamilyByIdMethod(id)}>
                {family_number}
              </Typography>
            </Tooltip>
          </Row>
        );
      },
      sorter: (a, b) => a.family_number.localeCompare(b.family_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Family Name",
      dataIndex: "last_name",
      width: "15%",
      // render: (id,{last_name}) => {
      //   return (
      //     <Row>
      //       {/* <EditColumnAction id={id} customMethod={openFamilyByIdMethod} /> */}
      //       <Tooltip title="View Full Family Detail">
      //         <Typography onClick={() => openFamilyByIdMethod(id)} >{last_name}</Typography>
      //       </Tooltip>
      //     </Row>
      //   )
      // },
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Family Native",
      dataIndex: "community_native",
      width: "15%",
      render: (community_native) => {
        return community_native?.name;
      },
      sorter: (a, b) =>
        a.community_native?.name.localeCompare(b.community_native?.name),
      sortDirections: ["descend", "ascend"],
    },
  ];
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const handleOpenFamily = (Id) => {
    setValName(Id);
    setSelectedFamily(true);
  };
  const the_name = _.filter(families, function (o) {
    return o.id === valName;
  });

  const onClickFamilies = () => {
    setSelectedFamily("");
    setValName("");
    reset();
  };
  return (
    <Spin spinning={isFamiliesLoading} size="large">
      <Row gutter={[32, 32]} 
         className="community" 
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickFamilies()}
              className="breadcrumUsers"
            >
              Families
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.last_name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Col span={23}>
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => setIsFamilyDrawerVisible(true)}
              >
                Add Family
              </Button>
              <FamilyDrawer
                setIsFamilyDrawerVisible={setIsFamilyDrawerVisible}
                isFamilyDrawerVisible={isFamilyDrawerVisible}
                setCurrentPage={setCurrentPage}
              />
            </Row>
          </Col>
        </Col>
        <Col span={24}>
          <CustomTable columns={familyColumns} data={allFamilies?.families} />
        </Col>
        {allFamilies && total_length > 0 ? (
        <Col span={23}>
          <Row justify="center">
            <CustomPagination
              length={allFamilies?.total_length}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </Row>
        </Col>
        ):""}
      </Row>
    </Spin>
  );
};
export default Family;
