import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Select,
  DatePicker,
} from "antd";
import _ from "lodash";
import { getTrustees } from "../../../Actions/trusteesAction";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { trusteeSchema } from "../../../Utils/Schema";
import { getSearchUser } from "../../../Actions/searchUserAction";
import { createTrustee, updateTrustee } from "../../../Actions/trusteesAction";
import { useDispatch, useSelector } from "react-redux";
import CustomSearch from "../../ReuseableComponents/CustomSearch/CustomSearch";

import moment from "moment";
const CommunityTrusteeModalForm = (props) => {
  const d = new Date();
  const earliestYear = 2000;
  let y = d.getFullYear();
  const {
    editId,
    setEditId,
    currentPage,
    setCurrentPage,
    isModalVisible,
    setIsModalVisible,
  } = props;
  const [preloadedUserValue, setPreloadedUserValue] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);
  const dispatch = useDispatch();

  const {
    setCommunityId,
    searchUsers,
    allTrustees,
    allDesignations,
    isUpdateTrusteesLoading,
    isCreateTrusteesLoading,
    isSearchUserLoading,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const {
      allTrustees,
      isUpdateTrusteesLoading,
      isCreateTrusteesLoading,
      isGetTrusteesLoading,
    } = state.trustees;
    const { designation, allDesignations } = state.designations;
    return {
      isUpdateTrusteesLoading,
      isCreateTrusteesLoading,
      isGetTrusteesLoading,
      setCommunityId,
      designation,
      allDesignations,
      searchUsers,
      allTrustees,
      isSearchUserLoading,
    };
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedUserValue },
    resolver: yupResolver(trusteeSchema),
  });

  const options = [];
  for (let i = earliestYear; i <= y; i++) {
    options.push({
      label: i,
      value: i,
    });
  }
  const the_trustees = _.filter(allTrustees.community_trustees, function (o) {
    return o.id === editId;
  });
  const newFromYear = moment(the_trustees[0]?.from_year, "yyyy");
  const newToYear = moment(the_trustees[0]?.to_year, "yyyy");
  const newArray = the_trustees?.map((obj) => {
    return {
      ...obj,
      from_year: newFromYear,
      to_year: newToYear,
      designation_id: obj.designation.designation_title,
      user_id: obj.user.first_name,
    };
  });
  useEffect(() => {
    if (editId) {
      setPreloadedUserValue(newArray[0]);
      reset(newArray[0]);
    }
  }, [the_trustees[0], reset]);

  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    // setNewFiles({});
    setValue("user_id", "");
    setValue("designation_id", "");
    setValue("from_year", "");
    setValue("to_year", "");
    setErrorUserId(null);
    setSelectedUserId(null);
    clearErrors();
  };
  const handleCreateTrustee = (data) => {
    if (selectedUserId) {
      data.user_id = selectedUserId;
      data.community_id = setCommunityId;
      const FromDate = moment(data?.from_year);
      data.from_year = moment(FromDate._d).format("yyyy");
      const ToDate = moment(data?.to_year);
      data.to_year = moment(ToDate._d).format("yyyy");
      dispatch(createTrustee(data, successCreatetrustee, failureCreatetrustee));
    } else {
      setErrorUserId("User must be selected");
    }
  };

  const successCreatetrustee = () => {
    setCurrentPage(1);
    reset();
    setIsModalVisible(false);
    setEditId("");
    dispatch(
      getTrustees(`api/community_trustees?community_id=${setCommunityId}`)
    );
    setValue("user_id", "");
    setValue("designation_id", "");
    setValue("from_year", "");
    setValue("to_year", "");
    clearErrors();
    setTimeout(() => {
      message.success("Your Trustee Created Successfully");
    }, 1000);
  };

  const failureCreatetrustee = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.designation_id[0]
          : "Something went wrong."
      );
    }, 1000);
  };

  const handleEditTrustee = (data) => {
    data.id = editId;
    data.user_id = _.isEmpty(data.user_id)
      ? the_trustees[0]?.user?.id
      : data.user_id;
    data.designation_id = _.isEmpty(data.designation_id)
      ? the_trustees[0]?.designation?.id
      : data.designation_id;
    const FromDate = moment(data?.from_year);
    data.from_year = moment(FromDate._d).format("yyyy");
    const ToDate = moment(data?.to_year);
    data.to_year = moment(ToDate._d).format("yyyy");
    dispatch(updateTrustee(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setIsModalVisible(false);
    setEditId("");
    setValue("user_id", "");
    setValue("designation_id", "");
    setValue("from_year", "");
    setValue("to_year", "");
    dispatch(
      getTrustees(
        `api/community_trustees?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your Trustee Updated Successfully");
    }, 1000);
  };

  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.designation.designation_id[0]
          : "Something went wrong."
      );
    }, 1000);
  };

  const [width, setWidth] = useState(window.innerWidth);

  const onUserSearch = (data) => {
    if (data) {
      setSelectedUserId(data?.id);
    } else {
      setSelectedUserId(null);
    }
  };
  const clearSearchUserRequiredMessageMethod = () => {
    setErrorUserId(null);
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Trustee" : "Add trustee"}
    >
      <Spin
        spinning={editId ? isUpdateTrusteesLoading : isCreateTrusteesLoading}
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditTrustee : handleCreateTrustee
          )}
        >
          <Row gutter={[8, 8]}>
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: 9 }}>
                Community Designation
              </Typography>
              <Controller
                name="designation_id"
                as={
                  <Select
                    placeholder="select Designation"
                    style={{ width: "100%", borderRadius: "4px" }}
                    className="inputLabel"
                    showSearch={true}
                    size="large"
                    options={
                      allDesignations &&
                      allDesignations.total_length > 0 &&
                      allDesignations.designations.map((val, i) => {
                        return {
                          label: val.designation_title,
                          value: val.id,
                        };
                      })
                    }
                    defaultValue={
                      the_trustees[0]?.designation.designation_title
                    }
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  ></Select>
                }
                control={control}
              />
              {errors.designation_id && (
                <p style={{ color: "red" }}>{errors.designation_id.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24} className="formRows">
              {!editId && (
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Search Community Member By Phone/Name
                </Typography>
              )}
              {!editId && (
                <Col span={22}>
                  <CustomSearch
                    size="large"
                    data={searchUsers?.users}
                    placeholder="Search User"
                    searchAction={getSearchUser}
                    isDrawer={isModalVisible}
                    searchActionLoading={isSearchUserLoading}
                    communityId={setCommunityId}
                    onSearch={onUserSearch}
                    clearRequiredMessageMethod={
                      clearSearchUserRequiredMessageMethod
                    }
                  />
                  {errorUserId && <p style={{ color: "red" }}>{errorUserId}</p>}
                </Col>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                From
              </Typography>
              <Controller
                as={
                  <DatePicker
                    placeholder="Select Year"
                    className="inputLabel"
                    picker="year"
                    size="large"
                  />
                }
                name="from_year"
                control={control}
                style={{ width: "100%" }}
              />
              {errors.from_year && (
                <p style={{ color: "red" }}>{errors.from_year.message}</p>
              )}
            </Col>
            <Col span={12}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                To
              </Typography>
              <Controller
                as={
                  <DatePicker
                    placeholder="Select Year"
                    className="inputLabel"
                    picker="year"
                    size="large"
                  />
                }
                name="to_year"
                control={control}
                style={{ width: "100%" }}
              />
              {errors.to_year && (
                <p style={{ color: "red" }}>{errors.to_year.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {editId ? (
                <Button size="large" className="editButton" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button size="large" className="editButton" htmlType="submit">
                  Add
                </Button>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className="cancelButton"
                onClick={handleClose}
                size="large"
                // style={{ margin: "0 8px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Spin>
    </Drawer>
  );
};

export default CommunityTrusteeModalForm;
