import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Typography,
  Avatar,
  Divider,
  Breadcrumb,
  Popconfirm,
  message,
  Spin,
  Tooltip,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateVerifyUserAction } from "../../Actions/searchUserAction";
import CustomTable from "../ReuseableComponents/CustomTable/CustomTable";
import { useTranslation } from "react-i18next";
import UsersDrawer from "./UsersDrawer";
import { history } from "../../Utils/history";
import moment from "moment";
import {
  getUserByIdAction,
  deleteUserAction,
  updateUserAction,
} from "../../Actions/authenticationAction";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckCircleOutlined,
  UserSwitchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import "./Users.css";
import { getIndustriesAction } from "../../Actions/industryAction";
import { getEducationsAction } from "../../Actions/educationAction";
import { deleteUserFamily } from "../../Actions/userFamilyAction";
import ChangeUserTypeDrawer from "./ChangeUserTypeDrawer";
import ChangeUserCommunityDrawer from "./ChangeUserCommunityDrawer";
import { falseyValueCases, ImageEnv } from "../CommonLogics/CommonMethods";
import { MessageFilled } from "@ant-design/icons";
import ChatDrawer from "../Communities/Tickets/ChatDrawer";
import {
  deleteFamily,
  getFamilies,
  getFamilyById,
} from "../../Actions/familyAction";
import {
  getTicketsAction,
  updateTicketAction,
} from "../../Actions/ticketAction";
import FamilyDetail from "../FamilySuperAdmin/FamilyDetail";
const UsersDetail = () => {
  const dispatch = useDispatch();
  // const [trusteeId, setTrusteeId] = useState("");
  const [createTicketSuccessData, setCreateTicketSuccessData] = useState("");
  const [isChatDrawerVisible, setIsChatDrawerVisible] = useState(false);
  const [ticketByIdState, setTicketByIdState] = useState({});
  const [page, setPage] = useState(1);

  const { t } = useTranslation();
  const {
    setCommunityId,
    userById,
    isGetUserByIdLoading,
    Family,
    isDeleteFamilyMemberLoading,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { userById, isGetUserByIdLoading } = state.authentication;
    const { Family } = state.family;
    const { isDeleteFamilyMemberLoading } = state.familyMember;
    return {
      setCommunityId,
      userById,
      isGetUserByIdLoading,
      Family,
      isDeleteFamilyMemberLoading,
    };
  });
  useEffect(() => {
    dispatch(getUserByIdAction(history?.location?.state?.id));
    dispatch(getIndustriesAction("api/industries"));
    dispatch(getEducationsAction("api/educations"));
    dispatch(
      getTicketsAction(
        `api/tickets?community_id=${setCommunityId}&page=${page}`
      )
    );
    // dispatch(getFamilyById(history?.location?.state?.id));
  }, [dispatch, setCommunityId, page]);
  const [isUsersDrawerVisible, setIsUsersDrawerVisible] = useState(false);
  const [isChangeUserTypeDrawerVisible, setIsChangeUserTypeDrawerVisible] =
    useState(false);

  const [
    isChangeUserCommunityDrawerVisible,
    setIsChangeUserCommunityDrawerVisible,
  ] = useState(false);
  const isUpdateFamilyMember = history?.location?.state?.isUpdateFamilyMember;
  const deleteMemberId = history?.location?.state?.deleteUserFamilyId;
  const handleDeleteUserMethod = () => {
    if (isUpdateFamilyMember) {
      dispatch(deleteUserFamily(deleteMemberId, SuccessDeleteCallbackMethod));
    } else {
      dispatch(deleteUserAction(userById?.id, SuccessDeleteCallbackMethod));
    }
  };
  const SuccessDeleteCallbackMethod = () => {
    setTimeout(() => {
      message.success(
        isUpdateFamilyMember
          ? "Member deleted successfully"
          : "User deleted successfully"
      );
    }, 1000);
    if (isUpdateFamilyMember) {
      history.push(`/families/${Family?.id}`, { id: Family?.id });
    } else {
      history.push("/users");
    }
  };
  const handleBreadCrumbUsersMethod = () => {
    history.push("/users");
  };
  const handleBlockMethod = () => {
    userById.is_poused = true;
    userById.education_id = userById?.education?.id;
    userById.industry_id = userById?.industry?.id;
    dispatch(updateUserAction(userById, true, SuccessUserBlockMethod, true));
  };
  const SuccessUserBlockMethod = () => {
    setTimeout(() => {
      message.success("User blocked successfully");
    }, 1000);
    dispatch(getUserByIdAction(userById?.id));
  };
  const handleUnBlockMethod = () => {
    userById.is_poused = false;
    userById.education_id = userById?.education?.id;
    userById.industry_id = userById?.industry?.id;
    dispatch(updateUserAction(userById, true, SuccessUserUnBlockMethod, true));
  };
  const SuccessUserUnBlockMethod = () => {
    setTimeout(() => {
      message.success("User Unblocked successfully");
    }, 1000);
    dispatch(getUserByIdAction(userById?.id));
  };

  const verifyUserMethod = () => {
    dispatch(updateVerifyUserAction(userById?.id, SuccessVerifyUserMethod));
  };
  const SuccessVerifyUserMethod = () => {
    setTimeout(() => {
      message.success("User verified successfully");
    }, 1000);
    dispatch(getUserByIdAction(userById?.id));
  };
  const userTypes = _.map(userById?.users_types, (val) => {
    return _.capitalize(val);
  }).join(",");
  const family = _.map(userById?.families?.user_families?.user, (val) => {
    return val;
  }).join(",");
  // console.log(userById?.families?.[0]?.user_families?.[0]?.user)
  // const brandName = _.map(userById?.user_device_info, (val) => {
  //   return (val.device_brand);
  // });
  // console.log({brandName})
  const openChangeUserTypeDrawer = () => {
    setIsChangeUserTypeDrawerVisible(true);
  };

  let UnpublishData = {};
  let PublishData = {};

  // const handleOpenTicketHistory = (Id) => {
  //   setTrusteeId(Id);
  //   dispatch(getTicketByIdAction(Id));
  //   dispatch({
  //     type: "GET_SELECTED_TICKET_ID",
  //     payload: Id,
  //   });
  // };
  const handleCloseTicket = (EditId) => {
    PublishData.id = EditId;
    PublishData.ticket_status = "Closed";
    dispatch(
      updateTicketAction(PublishData, successUpdateCloseMethod, faliureDelete)
    );
  };
  const handleOpenTicket = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.ticket_status = "Open";
    dispatch(
      updateTicketAction(UnpublishData, successUpdateOoenMethod, faliureDelete)
    );
  };

  const successUpdateOoenMethod = () => {
    setTimeout(() => {
      message.success("Your Ticket Open Successfully");
    }, 1000);
    // dispatch(
    //   getTicketsAction(
    //     `api/tickets?community_id=${setCommunityId}&page=${page}`
    //   )
    // );
    dispatch(getUserByIdAction(history?.location?.state?.id));
  };

  const successUpdateCloseMethod = () => {
    setTimeout(() => {
      message.success("Your Ticket Closed Successfully");
    }, 1000);
    // dispatch(
    //   // getTicketsAction(
    //   //   `api/tickets?community_id=${setCommunityId}&page=${page}`
    //   // )
    //   dispatch(getUserByIdAction(history?.location?.state?.id))
    // );
    dispatch(getUserByIdAction(history?.location?.state?.id));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const columns = [
    {
      title: "Ticket ID",
      dataIndex: "ticket_number",
      width: "10%",
      sorter: (a, b) => a.ticket_number.localeCompare(b.ticket_number),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Issue",
      dataIndex: "name",
      width: "20%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "id",
      width: "10%",
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ["descend", "ascend"],
      render: (id, { ticket_status }) => {
        return (
          <Row>
            <Col>
              {ticket_status === "Closed" ? (
                <Tooltip title="Click for Re-opening the ticket">
                  <Popconfirm
                    title="Are you sure you want to Re-Open the ticket?"
                    onConfirm={() => handleOpenTicket(id)}
                    okText="Re-Open"
                    cancelText="Cancel"
                  >
                    <Button
                      className="OpenStatus"
                      // onClick={() => handleCloseTicket(id)}

                      type="text"
                    >
                      Reopen
                    </Button>
                  </Popconfirm>
                </Tooltip>
              ) : (
                <Tooltip title="Click for closing the ticket">
                  <Popconfirm
                    title="Are you sure you want to Close the ticket?"
                    onConfirm={() => handleCloseTicket(id)}
                    okText="Close"
                    cancelText="Cancel"
                  >
                    <Button
                      className="ClosedStatus"
                      // onClick={() => handleOpenTicket(id)}
                      type="text"
                    >
                      Close
                    </Button>
                  </Popconfirm>
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id) => (
        <Row justify="space-around">
          <Col>
            <Tooltip title="View Chat">
              <MessageFilled
                onClick={() => setIsChatDrawerVisible(true)}
                style={{ fontSize: 18 }}
              />
            </Tooltip>
            <ChatDrawer
              isChatDrawerVisible={isChatDrawerVisible}
              setIsChatDrawerVisible={setIsChatDrawerVisible}
              ticketByIdState={ticketByIdState}
              createTicketSuccessData={createTicketSuccessData}
              setTicketByIdState={setTicketByIdState}
            />
          </Col>
        </Row>
      ),
      width: "12%",
    },
  ];
  const userBirthDate = `${userById?.birth_date}/${userById?.birth_month}/${userById?.birth_year}`;
  return (
    <div 
    style={{ marginTop: "40px" }}
    >
      <Spin
        spinning={isGetUserByIdLoading || isDeleteFamilyMemberLoading}
        size="large"
        style={{ height: "100vh" }}
      >
        {!isGetUserByIdLoading && (
          <Row justify="space-around" gutter={[0, 35]}>
            <Col span={23}>
              <Breadcrumb 
              // style={{ margin: 10 }}
              >
                <Breadcrumb.Item
                  onClick={() => handleBreadCrumbUsersMethod()}
                  className="breadcrumCommunity"
                >
                  Users
                </Breadcrumb.Item>

                <Breadcrumb.Item className="breadcrumUsers">
                  {userById?.first_name + " " + userById?.last_name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            <Col span={23}>
              <Card>
                <Row>
                  <Col span={24}>
                    <Row justify="end" gutter={[29, 0]}>
                      <Col>
                        <EditOutlined
                          onClick={() => setIsUsersDrawerVisible(true)}
                        />
                        <UsersDrawer
                          isUsersDrawerVisible={isUsersDrawerVisible}
                          setIsUsersDrawerVisible={setIsUsersDrawerVisible}
                          isUpdateUser={true}
                          userById={userById}
                        />
                      </Col>
                      {userById?.verified === false && (
                        <Col>
                          <Tooltip title="Verify User ?">
                            <Popconfirm
                              title="Verify User ?"
                              onConfirm={() => verifyUserMethod()}
                              okText="Verify"
                              cancelText={t("btn.19")}
                              placement="topLeft"
                            >
                              <CheckCircleOutlined />
                            </Popconfirm>
                          </Tooltip>
                        </Col>
                      )}
                      <Col>
                        <Tooltip title="Block/Unblock User ?">
                          <Popconfirm
                            title={
                              userById?.is_poused
                                ? "Unblock User ?"
                                : "Block User ?"
                            }
                            onConfirm={() =>
                              userById?.is_poused
                                ? handleUnBlockMethod()
                                : handleBlockMethod()
                            }
                            okText={userById?.is_poused ? "Unblock" : "Block"}
                            cancelText={t("btn.19")}
                            placement="topLeft"
                          >
                            {userById?.is_poused ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </Popconfirm>
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tooltip title="Add User Type?">
                          <UserSwitchOutlined
                            onClick={() => openChangeUserTypeDrawer()}
                          />
                        </Tooltip>
                        <ChangeUserTypeDrawer
                          isChangeUserTypeDrawerVisible={
                            isChangeUserTypeDrawerVisible
                          }
                          setIsChangeUserTypeDrawerVisible={
                            setIsChangeUserTypeDrawerVisible
                          }
                          UserId={userById?.id}
                        />
                      </Col>
                      {/* <SwapOutlined /> */}
                      <Col>
                        <Tooltip title="Change User Community?">
                          <SwapOutlined
                            onClick={() =>
                              setIsChangeUserCommunityDrawerVisible(true)
                            }
                          />
                        </Tooltip>
                        <ChangeUserCommunityDrawer
                          isChangeUserCommunityDrawerVisible={
                            isChangeUserCommunityDrawerVisible
                          }
                          setIsChangeUserCommunityDrawerVisible={
                            setIsChangeUserCommunityDrawerVisible
                          }
                        />
                      </Col>
                      <Col>
                        <Popconfirm
                          title={t("btn.29")}
                          onConfirm={() => handleDeleteUserMethod()}
                          okText={t("btn.28")}
                          cancelText={t("btn.19")}
                          placement="topLeft"
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={4}>
                        <Avatar
                          size={125}
                          src={userById?.user_image?.image &&ImageEnv(userById?.user_image?.image)}
                        >
                          {userById?.first_name?.[0]}
                        </Avatar>
                      </Col>
                      <Col span={20}>
                        <Row gutter={[0, 17]}>
                          <Col span={24}>
                            <Typography className="UserDetailName">
                              {userById?.first_name + " " + userById?.last_name}
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Typography
                              component="span"
                              className="UserDetailType"
                            >
                              Type&nbsp;:&nbsp;-
                            </Typography>
                            &nbsp;
                            <Typography
                              component="span"
                              className="UserDetailType"
                            >
                              {userTypes}
                            </Typography>
                          </Col>
                          <Divider className="UserDivider" />
                          <Col span={12}>
                            <Typography
                              component="span"
                              className="UserDetailBirthDateText"
                            >
                              Birth Date&nbsp;:&nbsp;-
                            </Typography>
                            &nbsp;
                            {userBirthDate && (
                              <Typography
                                component="span"
                                className="UserDetailBirthDateData"
                              >
                                 {/* {userById?.date} */}
                                {userBirthDate}
                              </Typography>
                            )}
                          </Col>
                          <Col span={12}>
                            <Typography
                              component="span"
                              className="UserDetailBirthDateText"
                            >
                              Created &nbsp;:&nbsp;-
                            </Typography>
                            &nbsp;
                            <Typography
                              component="span"
                              className="UserDetailBirthDateData"
                            >
                              {moment(userById?.created_at)
                                .startOf("minute")
                                .fromNow()}{" "}
                              ({moment.utc(userById?.created_at).format("L")})
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Typography
                              component="span"
                              className="UserDetailIndustryText"
                            >
                              Ip address&nbsp;:
                            </Typography>
                            <Typography
                              component="span"
                              className="UserDetailIndustryTextData"
                            >
                              &nbsp;-
                              {userById?.ip_address}
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Typography
                              component="span"
                              className="UserDetailIndustryText"
                            >
                              Last Location&nbsp;:
                            </Typography>
                            <Typography
                              component="span"
                              className="UserDetailIndustryTextData"
                            >
                              &nbsp;-
                              {userById?.last_location}
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[0, 21]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24}>
                        <div
                          style={{
                            height: "56px",
                            background: "#F5F6F8",
                            // padding: "16px",
                          }}
                        >
                          <Typography className="UserDetailDetailsText">
                            Details
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[0, 21]}>
                      <Col span={24} />
                      <Col span={24}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Education&nbsp;:
                        </Typography>
                        &nbsp;
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          {falseyValueCases(userById?.education?.name)
                            ? "--------"
                            : userById?.education?.name}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Industry&nbsp;:
                        </Typography>
                        &nbsp;
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          {falseyValueCases(userById?.industry?.name)
                            ? "--------"
                            : userById?.industry?.name}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Email&nbsp;:
                        </Typography>
                        &nbsp;
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          {falseyValueCases(userById?.email)
                            ? "--------"
                            : userById?.email}
                        </Typography>
                      </Col>
                      <Col span={24}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Contact Number&nbsp;:
                        </Typography>
                        &nbsp;
                        <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          {falseyValueCases(userById?.phone_with_country_code)
                            ? "--------"
                            : userById?.phone_with_country_code}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  {/* <FamilyDetail/>  */}
                  <Col span={24}>
                    <Row gutter={[0, 21]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24}>
                        <div
                          style={{
                            height: "56px",
                            background: "#F5F6F8",
                            // padding: "16px",
                          }}
                        >
                          <Typography className="UserDetailDetailsText">
                            Family Details
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Row gutter={[16,16]}>
                    <Col span={24}></Col>
                    <Col span={24}></Col>
                  {userById?.families?.map((val) => (
                  <Col span={12}>
                    <Card className="FamilyCard">
                      <Row justify="space-between" align="middle">
                        <Col>
                          <Row gutter={[12, 0]}>
                            <Col>
                              <Typography className="title">
                                {val?.last_name}
                              </Typography>
                            </Col>
                            <Col>
                              <Typography className="memberStyle">
                                Total&nbsp;
                                {val?.user_families?.length}
                                &nbsp;Member
                              </Typography>
                            </Col>
                            <Col>
                              <Typography className="memberStyle">
                                {
                                  val?.user_families
                                    ?.family_number
                                }
                              </Typography>
                            </Col>
                            <Divider />
                            <Row gutter={[15, 15]}>
                              {val?.user_families?.map(
                                (val, i) => {
                                  return (
                                    <Col
                                      // xl={8}
                                      key={i}
                                      // onClick={() =>
                                      //   handleMemberView(val?.user?.id, val?.id)
                                      // }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <MemberCard
                                        member={val?.user}
                                        // ownerId={owner_id}
                                        user_image={val.user_image}
                                      />
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                
                  </Col>
                 
                  ))}
                   </Row>
                  <Col span={24}>
                    <Row gutter={[0, 21]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24}>
                        <div
                          style={{
                            height: "56px",
                            background: "#F5F6F8",
                            // padding: "16px",
                          }}
                        >
                          <Typography className="UserDetailDetailsText">
                            Tickets
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <CustomTable data={userById?.tickets} columns={columns} />
                  </Col>
                  <Col span={24}>
                    <Row gutter={[0, 21]}>
                      <Col span={24} />
                      <Col span={24} />
                      <Col span={24}>
                        <div
                          style={{
                            height: "56px",
                            background: "#F5F6F8",
                            // padding: "16px",
                          }}
                        >
                          <Typography className="UserDetailDetailsText">
                            Device Info
                          </Typography>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[0, 21]}>
                      <Col span={24} />
                      <Col span={24}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Device Name&nbsp;:
                        </Typography>
                        &nbsp;
                        {userById?.user_device_info?.map((val) => (
                          <Typography
                            component="span"
                            className="UserDetailIndustryTextData"
                          >
                            {val.device_modelName}
                          </Typography>
                        ))}
                        {/* <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        >
                          {falseyValueCases(
                            userById?.user_device_info?.[0]?.device_modelName
                          )
                            ? "--------"
                            : userById?.user_device_info?.[0]?.device_modelName}
                        </Typography> */}
                      </Col>
                      <Col span={24}>
                        <Typography
                          component="span"
                          className="UserDetailIndustryText"
                        >
                          Device Brand&nbsp;:
                        </Typography>
                        &nbsp;
                        {/* <Typography
                          component="span"
                          className="UserDetailIndustryTextData"
                        > */}
                        {userById?.user_device_info?.map((val) => (
                          <Typography
                            component="span"
                            className="UserDetailIndustryTextData"
                          >
                            {val.device_brand}
                          </Typography>
                        ))}
                        {/* {falseyValueCases(
                            userById?.user_device_info?.[0]?.device_brand
                          )
                            ? "--------"
                            : userById?.user_device_info?.[0]?.device_brand}
                        </Typography> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};
const MemberCard = (props) => {
  const { member, ownerId, user_image } = props;
  const userOldDays =
    (member?.birth_year === null
      ? "Edit your data"
      : moment(
          member.birth_year + "-" + member.birth_month + "-" + member.birth_date
        ).fromNow("years old")) === "Invalid date"
      ? "Few days"
      : member?.birth_year === null
      ? "Edit your data"
      : moment(
          member.birth_year + "-" + member.birth_month + "-" + member.birth_date
        ).fromNow("years old");
  // const FamilyHead = _.filter(Family?.user_families, (val) => {
  //   return ownerId === member.id;
  // })

  return (
    <Row gutter={[8, 8]}>
      <Col span={4}>
        <Avatar size={35} src={user_image?.image && ImageEnv(user_image?.image)}>
          {member?.first_name[0]}
        </Avatar>
      </Col>
      <Col span={1} />
      <Col span={19}>
        <Row>
          <Col span={24}>
            <Typography className="memberName TextEllipses">
              {member?.first_name}&nbsp;
              {!falseyValueCases(member?.gender) &&
                (member?.gender === "male" ? "(M)" : "(F)")}
              &nbsp;
              {ownerId === member?.id && (
                <Typography component="span" style={{ color: "#27AE60" }}>
                  (Head)
                </Typography>
              )}
            </Typography>
          </Col>
          <Col span={24}>
            <Typography className="memberStyle">
              {userOldDays}&nbsp;
              {member?.birth_year !== null && "old"}
            </Typography>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UsersDetail;

