import { render } from "@testing-library/react";
import {Button, Row, Col, Typography, Tabs , Card , Input , Table , Spin} from "antd"
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommunities } from "../../Actions/communitiesAction";
import "./Community.css"
import "../../FindThePeoplePage/FindOtherPeople.css"


import "../Admin/Admin.css"
const SelectCommunity = () => {
    const dispatch = useDispatch();


useEffect(() =>{ 
    dispatch(getCommunities())
 
},[dispatch])
 
  const {allCommunities,isCommumitiesloading } = useSelector((state) => {
    const  {allCommunities,isCommumitiesloading} = state.communities;
    return {
      allCommunities,
      isCommumitiesloading
    }
 
})
  const columns = [ 
    {
        title:"Communities",        
        dataIndex:"name"
        
    
    
    },
    {
        dataIndex:"id",
                       render:(id,Community) => (
                        <SelectCommunityActionComponent 
                        id={id}
                        Community={Community}
                        />)
    }
]
  return(
           <div style={{
            "marginTop":"20px"
            }}>         




                          <div style={{"display":"flex" , "justifyContent":"center" , "marginBottom":"20px"}}>
            <Typography className="communityTitle1">Select your community</Typography>
                    </div>
                    <div>
<Spin spinning={isCommumitiesloading}               size="large">
                        <Row justify="space-around">
                            <Col span={23}>
                        <Table dataSource={allCommunities} columns={columns} className="table" size="middle"/>
                            </Col>
                        </Row>
 </Spin>
 
 
                    </div>
                </div>
            )

        }







    


export default SelectCommunity;







const SelectCommunityActionComponent = (props) => {
const {id , Community} = props;    


const SelectCommunityMethod = (SelectedCommunity) => {
}
return(
                <Row justify="end">
              
       
       


                    <Col>
                <Button type="primary" onClick={() => SelectCommunityMethod(Community)} className="editDesignationButton">
                                    Select
                            </Button>
                    </Col>
                </Row>
            )
}