import React from "react";
import { Table } from "antd";
const CustomTable = (props) => {
  const {  columns, data } = props;
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={(record) => {
        return record?.id;
      }}
    />
  );
};
export default CustomTable;
