import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Typography,
  Row,
  Col,
  Select,
  message,
  Upload,
  Checkbox,
  DatePicker,
  Spin,
  Drawer,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-international";
import "./Users.css";
import _ from "lodash";
import moment from "moment";
import { createUsers } from "../../Actions/usersAction";
import {
  updateUserAction,
  getUserByIdAction,
} from "../../Actions/authenticationAction";
import { PlusOutlined } from "@ant-design/icons";
import {
  createUserSchema,
  editUserSchema,
  addFamilyMemberSchema,
  updateFamilyMemberSchema,
} from "../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
// import { getIndustriesAction } from "../../Actions/industryAction";
import { getSearchUser } from "../../Actions/searchUserAction";
import {
  createFamilyMember,
  updateFamilyMember,
} from "../../Actions/familyMemberAction";
import { getFamilyById } from "../../Actions/familyAction";
import { history } from "../../Utils/history";
import { falseyValueCases } from "../CommonLogics/CommonMethods";
import { ImageEnv } from "../CommonLogics/CommonMethods";

const UsersDrawer = (props) => {
  const {
    isUsersDrawerVisible,
    // editId,
    setIsUsersDrawerVisible,
    setEditId,
    isUpdateUser,
    userById,
    currentPage,
    isAddFamilyMember,
  } = props;
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const {
    setCommunityId,
    // searchUsers,
    allEducations,
    allIndustries,
    isCreateUsersLoading,
    isUpdateUserLoading,
    Family,
    isCreateFamilyMemberLoading,
    isUpdateFamilyMemberLoading,
  } = useSelector((state) => {
    const { isUpdateUserLoading } = state.authentication;
    const { setCommunityId } = state.communities;
    const { allEducations } = state.educations;
    const { allIndustries } = state.industry;
    const { searchUsers } = state.searchUser;
    const { isCreateUsersLoading } = state.users;
    const { Family } = state.family;
    const { isCreateFamilyMemberLoading, isUpdateFamilyMemberLoading } =
      state.familyMember;
    return {
      setCommunityId,
      searchUsers,
      allEducations,
      allIndustries,
      isCreateUsersLoading,
      isUpdateUserLoading,
      Family,
      isCreateFamilyMemberLoading,
      isUpdateFamilyMemberLoading,
    };
  });

  const isUpdateFamilyMember = history?.location?.state?.isUpdateFamilyMember;
  const [preloadedUserValue, setPreloadedUserValue] = useState("");
  // const the_user = _.filter(searchUsers.users, (val) => {
  //   return val.id === editId;
  // });
  const date = userById
    ? moment(
        `${userById?.birth_month}-${userById?.birth_date}-${userById?.birth_year}`,
        "MM-DD-YYYY"
      )
    : "";

  if (userById) {
    userById.education_id = _.isEmpty(userById.education)
      ? ""
      : userById.education.name;
    userById.industry_id = _.isEmpty(userById.industry)
      ? ""
      : userById.industry.name;
    userById.date_of_birth = date ? date : moment();
  }
  //   const date = editId
  //     ? moment(
  //         `${the_user[0]?.birth_month}-${the_user[0]?.birth_date}-${the_user[0]?.birth_year}`,
  //         "MM-DD-YYYY"
  //       )
  //     : "";
  //   //
  //   //   Hello
  //   const newArray = the_user?.map((obj) => {
  //     return {
  //       ...obj,
  //       date_of_birth: date,
  //       education_id: _.isEmpty(obj.education) ? "" : obj.education.name,
  //       industry_id: _.isEmpty(obj.industry) ? "" : obj.industry.name,
  //     };
  //   });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    // trigger,
    // getValues,
    clearErrors,
  } = useForm({
    defaultValues: 
    userById?.birth_date === null
    ? {
        first_name: preloadedUserValue?.first_name,
        last_name: preloadedUserValue?.last_name,
        email: preloadedUserValue?.email,

        phone: preloadedUserValue?.phone,
        education_type: preloadedUserValue?.education_type,
        industry: preloadedUserValue?.industry,
      }
    :  preloadedUserValue,
    resolver: yupResolver(
      isUpdateFamilyMember
        ? updateFamilyMemberSchema
        : isAddFamilyMember
        ? addFamilyMemberSchema
        : isUpdateUser
        ? editUserSchema
        : createUserSchema
    ),
  });
  useEffect(() => {
    if (isUpdateUser) {
      setPreloadedUserValue(userById);
      reset(userById);
    }
  }, [userById, reset]);
  const [countryCode, setCountryCode] = useState("");
  const [number, setNumber] = useState("");
  const [DateCustom, setDateCustom] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [gendervalue, setGendervalue] = useState("");
  const [gender, setGender] = useState("");
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [displayNumber, setDisplayNumber] = useState("");
  const [genderRequiredMessage, setGenderRequiredMessage] = useState("");
  const monthOfBirth = DateCustom.toString().slice(0, 2);
  const dateOfBirth = DateCustom.toString().slice(3, 5);
  const yearOfBirth = DateCustom.toString().slice(6, 10);
  const isGenderValueEmpty = _.isEmpty(gendervalue);
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country.dialCode);
    setNumber(formattedValue);
    setFailureMessage("");
    setDisplayNumber(formattedValue);
  };
  const IsEmpty = _.isEmpty(newFiles) ? true : false;
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const handleAddUsersMethod = (data) => {
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, "");

    const checkNumberLength = TextText.toString().length;
    const capitalizeFirstName = _.capitalize(data.first_name);

    if (isGenderValueEmpty) {
      setGenderRequiredMessage("Gender is required");
    } else {
      if (isAddFamilyMember) {

        data.family_id = Family?.id;
        data.last_name = Family?.last_name;
        data.first_name = capitalizeFirstName;
        data.phone = TextText;
        data.country_code = `+${countryCode}`;
        // data.birth_date = moment(data.date_of_birth)
        //   .format("DD/MM/YYYY")
        //   .split("/")[0];
        // data.birth_month = moment(data.date_of_birth)
        //   .format("DD/MM/YYYY")
        //   .split("/")[1];
        // data.birth_year = moment(data.date_of_birth)
        //   .format("DD/MM/YYYY")
        //   .split("/")[2];

        data.birth_date = dateOfBirth;
        data.birth_month = monthOfBirth;
        data.birth_year = yearOfBirth;
        data.user_image = _.isEmpty(newFiles) ? null : newFiles;
        
        data.gender = gendervalue;
        data.community_id = setCommunityId;
        dispatch(createFamilyMember(data, SuccessCreateUserCallback, IsEmpty));
      }
      if (!isAddFamilyMember) {
        if (checkNumberLength === 10) {
          const capitalizeLastName = _.capitalize(data.last_name);
          data.first_name = capitalizeFirstName;
          data.last_name = capitalizeLastName;
          data.phone = TextText;
          data.country_code = `+${countryCode}`;
          data.birth_date = moment(data.date_of_birth)
            .format("DD/MM/YYYY")
            .split("/")[0];
          data.birth_month = moment(data.date_of_birth)
            .format("DD/MM/YYYY")
            .split("/")[1];
          data.birth_year = moment(data.date_of_birth)
            .format("DD/MM/YYYY")
            .split("/")[2];
          // data.date_of_birth = date ;
          data.community_id = setCommunityId;
          data.user_image = _.isEmpty(newFiles) ? null : newFiles;
          data.gender = gendervalue;
          dispatch(
            createUsers(
              data,
              SuccessCreateUserCallback,
              FailureCreateUserCallback,
              IsEmpty
            )
          );
        } else {
          setFailureMessage("The number you entered must be of 10 digits only");
        }
      }
    }
  };
  const SuccessCreateUserCallback = () => {
    reset();
    setIsUsersDrawerVisible(false);
    if (!isAddFamilyMember) {
      setEditId("");
      dispatch(
        getSearchUser(
          `api/user_search?community_id=${setCommunityId}&page=${currentPage}`
        )
      );
    }
    if (isAddFamilyMember) {
      dispatch(getFamilyById(Family?.id));
    }
    setValue("first_name", "");
    setValue("last_name", "");
    setValue("email", "");
    setValue("education_id", "");
    setValue("industry_id", "");
    setValue("date_of_birth", "");
    setValue("user_type", "");
    clearErrors();
    setNewFiles([]);
    setFileList([]);
    setGender("");
    setGendervalue("");
    setFailureMessage("");
    setDisplayNumber("+91");
    setCountryCode("");
    setNumber("");
    setTimeout(() => {
      message.success(
        isAddFamilyMember
          ? "Member Added Successfully"
          : "User created successfully"
      );
    }, 1000);
  };
  const FailureCreateUserCallback = (FailureResponse) => {
    setFailureMessage(FailureResponse.phone[0]);
  };
  const closeDrawerMethod = () => {
    setIsUsersDrawerVisible(false);
    // setEditId("");

    setValue("first_name", "");
    setValue("last_name", "");
    setValue("email", "");
    setValue("education_id", "");
    setValue("industry_id", "");
    setValue("date_of_birth", "");
    setValue("user_type", "");
    clearErrors();
    setGender("");
    setGendervalue("");
    setFailureMessage("");
    setNewFiles([]);
    setFileList([]);
    setDisplayNumber("+91");
    setCountryCode("");
    setNumber("");
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const isPhoneNumberAbsent =
    userById?.phone_with_country_code === "null"
      ? false
      : userById?.phone_with_country_code === "undefined"
      ? false
      : _.isEmpty(userById?.phone_with_country_code)
      ? false
      : true;
  const handleEditMethod = (data) => {
    const Text = number;
    const TextText = Text.replace(`+${countryCode}`, "");
    const checkNumberLength = TextText.toString().length;
    data.id = userById?.id;
    // data.date_of_birth = date ? date :userById?.date_of_birth;
    data.birth_date = dateOfBirth ? dateOfBirth : userById.birth_date;
    data.birth_month = monthOfBirth ? monthOfBirth : userById.birth_month;
    data.birth_year = yearOfBirth ? yearOfBirth : userById.birth_year;
    data.education_id = containsNumber(data.education_id)
      ? data.education_id
      : userById?.education?.id;
    data.industry_id = containsNumber(data.industry_id)
      ? data.industry_id
      : userById?.industry?.id;
    data.phone = TextText ? TextText : userById?.phone;
    if (!falseyValueCases(data.phone)) {
      data.country_code = countryCode
        ? `+${countryCode}`
        : userById?.country_code;
    }
    data.gender = gendervalue ? gendervalue : userById.gender;
    data.user_image = _.isEmpty(newFiles) ? null : newFiles;
    if (!isPhoneNumberAbsent && isUpdateFamilyMember && TextText) {
      if (checkNumberLength === 10) {
        data.family_id = Family?.id;
        data.last_name = Family?.last_name;
        dispatch(updateFamilyMember(data, UpdateUserSuccessCallback, IsEmpty));
      } else {
        setFailureMessage("The number you entered must be of 10 digits only");

        ///Error message here
      }
    } else if (isUpdateFamilyMember) {
      data.family_id = Family?.id;
      data.last_name = Family?.last_name;
      ///Update Family Member Api
      dispatch(updateFamilyMember(data, UpdateUserSuccessCallback, IsEmpty));
    } else {
      // data.is_poused = "";
      dispatch(
        updateUserAction(
          data,
          IsEmpty,
          UpdateUserSuccessCallback,
          false,
          FailureCreateUserCallback
        )
      );
    }
  };
  const UpdateUserSuccessCallback = () => {
    setIsUsersDrawerVisible(false);
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    setTimeout(() => {
      message.success(
        isUpdateFamilyMember
          ? "Member edited successfully"
          : "User edited successfully"
      );
    }, 1000);
    dispatch(getUserByIdAction(userById?.id));
  };
  const closeUpdateDrawerMethod = () => {
    setIsUsersDrawerVisible(false);
    // setValue("first_name", userById?.first_name);

    // setValue("last_name", userById?.last_name);
    // setValue("email", userById?.email);
    // // setValue("phone", userById?.phone);
    // setValue(
    //   "education_id",
    //   _.isEmpty(userById.education) ? "" : userById.education.name
    // );
    // setValue(
    //   "industry_id",
    //   _.isEmpty(userById.industry) ? "" : userById.industry.name
    // );
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    reset(userById);
    clearErrors();
  };
  const options = [
    {
      value: "regular",
      label: "Regular",
    },

    {
      value: "admin",
      label: "Admin",
    },

    {
      value: "super_admin",
      label: "Super Admin",
    },
  ];
  const onDateSelection = (date) => {
    const Date = moment(date);
    setDateCustom(moment(Date._d).calendar());
  };
 
  const propsForUpload = {
    action: handleEditMethod || handleAddUsersMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const genderValue = (e) => {
    const { checked, value } = e.target;
    setGender({
      value,
      [value]: checked,
    });
    setGendervalue(value);
    setGenderRequiredMessage("");
  };

  if (isUpdateUser && gender?.female && userById) {
    userById.gender = "female";
  } else if (isUpdateUser && gender?.male && userById) {
    userById.gender = "male";
  } else {
  }
  const handleUploadMethod = (Event) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    // setNewFiles(Event.fileList);
    setFileList(Event.fileList);
  };
  return (
    <Drawer
      open={isUsersDrawerVisible}
      onClose={isUpdateUser ? closeUpdateDrawerMethod : closeDrawerMethod}
      title={
        isUpdateFamilyMember
          ? "Edit Family Member"
          : isAddFamilyMember
          ? "Add Family Member"
          : isUpdateUser
          ? "Edit User"
          : "Add User"
      }
    >
      <form
        onSubmit={handleSubmit(
          isUpdateUser ? handleEditMethod : handleAddUsersMethod
        )}
      >
        <Spin
          spinning={
            isUpdateFamilyMember
              ? isUpdateFamilyMemberLoading
              : isAddFamilyMember
              ? isCreateFamilyMemberLoading
              : isUpdateUser
              ? isUpdateUserLoading
              : isCreateUsersLoading
          }
          size="large"
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  {isUpdateUser && isDefaultImageVisible && userById?.image && (
                    <Upload
                      listType="picture-card"
                      openFileDialogOnClick={false}
                    >
                      <img src={userById?.user_image?.image && ImageEnv(userById?.user_image?.image)} width={102} alt=""></img>
                    </Upload>
                  )}
                </Col>
                <Col>
                  <Upload
                    listType="picture-card"
                    // onChange={() => setIsDefaultImageVisible(false)}
                    onChange={(e) => handleUploadMethod(e)}
                    {...propsForUpload}
                    maxCount={1}
                    showUploadList={showUploadImage}
                    accept="image/png,image/jpeg,image/webp"
                    fileList={fileList}
                  >
                    <PlusOutlined size={44} />
                    Upload Image
                  </Upload>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={isAddFamilyMember || isUpdateFamilyMember ? 24 : 12}>
                  <Typography style={{ paddingBottom: 10 }}>
                    {/* {t("content.67")} */}
                    First Name*
                  </Typography>
                  <Controller
                    as={<Input size="large" />}
                    name="first_name"
                    control={control}
                  />
                  {errors.first_name && (
                    <p style={{ color: "red" }}>{errors.first_name.message}</p>
                  )}
                </Col>
                {!isAddFamilyMember && !isUpdateFamilyMember && (
                  <Col span={12}>
                    <Typography style={{ paddingBottom: 10 }}>
                      Last Name*
                    </Typography>
                    <Controller
                      as={<Input size="large" />}
                      name="last_name"
                      control={control}
                    />
                    {errors.last_name && (
                      <p style={{ color: "red" }}>{errors.last_name.message}</p>
                    )}
                  </Col>
                )}
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography style={{ paddingBottom: 10 }}>
                    {/* {t("content.67")} */}
                    Email
                  </Typography>
                  <Controller
                    as={<Input size="large" />}
                    name="email"
                    control={control}
                  />
                  {errors.email && (
                    <p style={{ color: "red" }}>{errors.email.message}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography style={{ paddingBottom: 10 }}>
                    {/* {t("content.67")} */}
                    Phone Number
                    {!(isAddFamilyMember || isUpdateFamilyMember) && "*"}
                  </Typography>

                  <PhoneInput
                    size="large"
                    country={"in"}
                    autoFormat={false}
                    onlyCountries={["in", "ca", "us", "au", "nz"]}
                    onChange={(value, country, e, formattedValue) =>
                      phoneNumberMethod(value, country, e, formattedValue)
                    }
                    containerClass="UsersPhoneInput"
                    disabled={isUpdateUser && isPhoneNumberAbsent}
                    value={
                      isUpdateUser
                        ? userById?.phone_with_country_code
                        : displayNumber
                    }
                  />
                  {failureMessage && (
                    <Typography style={{ color: "red" }}>
                      {failureMessage}
                    </Typography>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[10, 0]}>
                {!isUpdateUser && (
                  <Col span={12}>
                    <Typography style={{ paddingBottom: 10 }}>
                      User Type*
                    </Typography>
                    <Controller
                      name="user_type"
                      as={
                        <Select
                          placeholder="Select User type"
                          options={options}
                          style={{ width: "100%", borderRadius: "4px" }}
                          size="large"
                        ></Select>
                      }
                      control={control}
                    />

                    {errors.user_type && (
                      <p style={{ color: "red" }}>{errors.user_type.message}</p>
                    )}
                  </Col>
                )}
                <Col span={isUpdateUser ? 24 : 12}>
                  <Typography style={{ paddingBottom: 10 }}>
                    {/* {t("content.67")} */}
                    Educations
                  </Typography>
                  <Controller
                    name="education_id"
                    as={
                      <Select
                        placeholder={t("content.159")}
                        style={{ width: "100%", borderRadius: "4px" }}
                        showSearch={true}
                        options={
                          allEducations?.educations &&
                          allEducations?.educations?.length > 0 &&
                          allEducations?.educations?.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                      ></Select>
                    }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: "red" }}>{t("content.119")}</p>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={12}>
                  <Typography style={{ paddingBottom: 10 }}>
                    {/* {t("content.67")} */}
                    Industries
                  </Typography>
                  <Controller
                    name="industry_id"
                    as={
                      <Select
                        placeholder="Select Industry"
                        style={{ width: "100%", borderRadius: "4px" }}
                        showSearch={true}
                        options={
                          allIndustries.industries &&
                          allIndustries?.industries?.length > 0 &&
                          allIndustries?.industries?.map((val, i) => {
                            return {
                              label: val.name,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                      ></Select>
                    }
                    control={control}
                  />
                  {errors.education_id && (
                    <p style={{ color: "red" }}>{t("content.119")}</p>
                  )}
                </Col>
                <Col span={12}>
                  <Typography style={{ paddingBottom: 10 }}>
                    {/* {t("content.67")} */}
                    Birth Date*
                  </Typography>
                  <Controller
                    as={
                      <DatePicker
                        placeholder={t("placeholder.10")}
                        onSelect={(date, dateString) =>
                          onDateSelection(date, dateString)
                        }
                        size="large"
        //                 value={selectedDate ? moment(selectedDate) : null} // Set a default value or handle null value manually
        // onChange={handleDateChange}
                      />
                    }
                    name="date_of_birth"
                    control={control}
                    style={{ width: "100%" }}
                  />
                  {/* {errors.date_of_birth && (
                    <p style={{ color: "red" }}>{t("content.118")}</p>
                  )} */}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {isUpdateUser ? (
                <Row>
                  <Col className="label">
                    <Checkbox
                      onChange={(e) => genderValue(e)}
                      value={"female"}
                      checked={userById?.gender === "female"}
                    >
                      <Typography className="SignUpTerms">
                        {t("content.83")}
                      </Typography>
                    </Checkbox>
                  </Col>
                  <Col className="label">
                    <Checkbox
                      onChange={(e) => genderValue(e)}
                      value={"male"}
                      checked={userById?.gender === "male"}
                    >
                      <Typography className="SignUpTerms">
                        {t("content.82")}
                      </Typography>
                    </Checkbox>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="label">
                    <Checkbox
                      onChange={(e) => genderValue(e)}
                      value={"female"}
                      checked={gender?.female}
                    >
                      <Typography className="SignUpTerms">
                        {t("content.83")}
                      </Typography>
                    </Checkbox>
                  </Col>
                  <Col className="label">
                    <Checkbox
                      onChange={(e) => genderValue(e)}
                      value={"male"}
                      checked={gender?.male}
                    >
                      <Typography className="SignUpTerms">
                        {t("content.82")}
                      </Typography>
                    </Checkbox>
                  </Col>
                </Row>
              )}
              {genderRequiredMessage && (
                <p style={{ color: "red", 
                marginTop: "10px" 
                }}>
                  {" "}
                  {genderRequiredMessage}{" "}
                </p>
              )}
            </Col>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {isUpdateUser ? "Edit" : "Add"}
                  </Button>
                </Col>
                <Col>
                  <Button>Cancel</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};
export default UsersDrawer;
