import API from "../Utils/API";
export const getTicketsAction = (url) => (dispatch) => {
  dispatch({ type: "GET_TICKETS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_TICKETS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_TICKETS_FAILURE",
        payload: error.response.data,
      });
    });
};

export const getArchiveTicketsAction = (url) => (dispatch) => {
  dispatch({ type: "GET_ARCHIVE_TICKETS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_ARCHIVE_TICKETS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_ARCHIVE_TICKETS_FAILURE",
        payload: error.response.data,
      });
    });
};
export const updateTicketAction =
  (data, success, failureCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_TICKETS_REQUEST", payload: data });
  API.put(`api/tickets/${data.id}`, data)
   
    // API.post("api/tickets", data)
      .then((response) => {
        dispatch({ type: "UPDATE_TICKETS_SUCCESS", payload: response.data });
        // const sCT = success ? success(response.data) : null;
        success();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_TICKETS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const getTicketByIdAction = (Id, SuccessCallback) => (dispatch) => {
  dispatch({ type: "GET_TICKET_BY_ID_REQUEST" });
  API.get(`api/tickets/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_TICKET_BY_ID_SUCCESS", payload: response.data });
      SuccessCallback && SuccessCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "GET_TICKET_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getArchiveTicketByIdAction = (Id, SuccessCallback) => (dispatch) => {
  dispatch({ type: "GET_ARCHIVETICKET_BY_ID_REQUEST" });
  API.get(`api/show_archive_ticket/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_ARCHIVETICKET_BY_ID_SUCCESS", payload: response.data });
      SuccessCallback && SuccessCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "GET_ARCHIVETICKET_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const deleteTicketAction =(Id, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "DELETE_TICKETS_REQUEST" });
  API.delete(`api/tickets/${Id}`)
    .then((response) => {
      dispatch({
        type: "DELETE_TICKETS_SUCCESS",
        payload: response.data,
      });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "DELETE_TICKETS_FAILURE",
        payload: error.response.data,
      });
    failureCallback();
      
    });
};
export const createTicketAction =
  (data, success, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_TICKETS_REQUEST", payload: data });
    API.post(`api/tickets?community_id=${data.community_id}`, data)
      .then((response) => {
        dispatch({ type: "CREATE_TICKETS_SUCCESS", payload: response.data });
        const sCT = success ? success(response.data) : null;
        // success();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_TICKETS_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
