import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Checkbox,
  Upload,
  Drawer,
} from "antd";
import {
  createCommunitiesAction,
  getCommunities,
  updateCommunitiesAction,
  getCommunityById,
} from "../../../Actions/communitiesAction";
import "../Communities.css";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CommunitySchema } from "../../../Utils/Schema";
import _ from "lodash";
import { ImageEnv } from "../../CommonLogics/CommonMethods";

const CommunityModalForm = (props) => {
  const {
    setSelectedCommunity,
    setValName,
    editId,
    setEditId,
    isModalVisible,
    setIsModalVisible,
    setIsDefaultImageVisible,
    isDefaultImageVisible,
    communityDetailId,
    showUploadImage,
    setShowUploadImage,
    currentPage,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState([]);
  const IsEmpty = _.isEmpty(newFiles);
  const [preloadedCommunityValue, setPreloadedCommunityValue] = useState("");
  const {
    allCommunities,
    isCommumitiesloading,
    isUpdateCommunityLoading,
    isCreateCommunityLoading,
  } = useSelector((state) => {
    const {
      allCommunities,
      setCommunityId,
      isCommumitiesloading,
      isCommunityByIdLoading,
      isUpdateCommunityLoading,
      isCreateCommunityLoading,
    } = state.communities;
    return {
      allCommunities,
      setCommunityId,
      isCommumitiesloading,
      isCommunityByIdLoading,
      isUpdateCommunityLoading,
      isCreateCommunityLoading,
    };
  });

  const { communities } = allCommunities;
  const the_community = _.filter(communities, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCommunityValue,
      published: false,
      show_trustee: false,
      show_business_profile: false,
      show_matrimonial: false,
      show_event: false,
      show_find_other_people: false,
    },
    resolver: yupResolver(CommunitySchema),
  });

  useEffect(() => {
    if (editId) {
      setPreloadedCommunityValue(the_community[0]);
      reset(the_community[0]);
    }
  }, [the_community[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId("");
    setValue("name", "");
    setValue("slug", "");
    setValue("show_matrimonial", "");
    setValue("show_trustee", "");
    setValue("published", "");
    setValue("community_logo", "");
    setValue("show_find_other_people", "");
    setValue("show_event", "");
    setValue("show_business_profile", "");
    setValue("description", "");
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    clearErrors();
  };

  const handleCreateCommunities = (data) => {
    data.community_logo = newFiles;
    // data.published = true;
    dispatch(
      createCommunitiesAction(
        data,
        successCreateCommunities,
        failureCreateCommunities,
        IsEmpty
      )
    );
  };
  const successCreateCommunities = () => {
    setCurrentPage(1);
    reset();

    setEditId("");
    setIsModalVisible(false);
    setIsDefaultImageVisible(false);
    setShowUploadImage(false);
    dispatch(getCommunities(`api/communities?page=${currentPage}`));
    setValue("name", "");
    setValue("slug", "");
    setValue("show_matrimonial", "");
    setValue("show_trustee", "");
    setValue("published", "");
    setValue("community_logo", "");
    setValue("show_find_other_people", "");
    setValue("show_event", "");
    setValue("show_business_profile", "");
    setValue("description", "");
    clearErrors();
    setTimeout(() => {
      message.success("Your community Created Successfully");
    }, 1000);
  };

  const failureCreateCommunities = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.slug : "Something went wrong."
      );
    }, 1000);
  };
  const handleEditCommunitiesMethod = (data) => {
    data.id = editId;
    data.community_logo = IsEmpty ? null : newFiles;
    dispatch(
      updateCommunitiesAction(data, UpdateCallBack, faliureUpdate, IsEmpty)
    );
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    reset();

    setIsModalVisible(false);
    setEditId("");
    setNewFiles([]);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
    setValue("name", "");
    setValue("slug", "");
    setValue("show_matrimonial", "");
    setValue("show_trustee", "");
    setValue("published", "");
    setValue("community_logo", "");
    setValue("show_find_other_people", "");
    setValue("show_event", "");
    setValue("show_business_profile", "");
    setValue("description", "");
    dispatch(getCommunityById(communityDetailId));
    dispatch(getCommunities(`api/communities?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success("Your Community Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.slug : "Something went wrong."
      );
    }, 1000);
  };

  const propsForUpload = {
    action: handleCreateCommunities || handleEditCommunitiesMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    // setSelectedFileList(e.fileList.slice(-1));
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
  const [width, setWidth] = useState(window.innerWidth);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      title={editId ? "Edit Community" : "Add Community"}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
    >
      <Spin
        style={{ height: "100%", maxHeight: "unset" }}
        spinning={editId ? isUpdateCommunityLoading : isCreateCommunityLoading}
        size="large"
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditCommunitiesMethod : handleCreateCommunities
          )}
        >
          <Spin spinning={isCommumitiesloading}>
            <Row gutter={[16, 16]}></Row>
            <Row justify="center">
              <Col>
                {editId &&
                  isDefaultImageVisible &&
                  the_community[0]?.community_logo && (
                    <Upload
                      listType="picture-card"
                      openFileDialogOnClick={false}
                    >
                      <img
                        src={the_community[0]?.community_logo_key && ImageEnv(the_community[0]?.community_logo_key)}
                        width={102}
                      ></img>
                    </Upload>
                  )}
              </Col>
              <Col>
                <Upload
                  // fileList={selectedFileList}
                  {...propsForUpload}
                  listType="picture-card"
                  maxCount={1}
                  showUploadList={showUploadImage}
                  onChange={(e) => handleUploadMethod(e)}
                  className="details"
                  accept="image/png,image/jpeg"
                >
                  <PlusOutlined size={44} />
                  Upload Logo
                </Upload>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Community Name
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="name"
                  control={control}
                  className="inputLabel"
                />
                {errors.name && (
                  <p style={{ color: "red" }}>{errors.name.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  slug
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="slug"
                  control={control}
                  className="inputLabel"
                />
                {errors.slug && (
                  <p style={{ color: "red" }}>{errors.slug.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col>
                <Controller
                  name="show_find_other_people"
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
              <Col>
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Find other people
                </Typography>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col>
                <Controller
                  name="show_event"
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
              <Col>
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Event
                </Typography>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Controller
                  name="show_matrimonial"
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
              <Col>
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Matrimonial
                </Typography>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col>
                <Controller
                  name="show_business_profile"
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
              <Col>
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Business Profile
                </Typography>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Controller
                  name="show_trustee"
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              </Col>
              <Col>
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Trustee
                </Typography>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24} className="formRows">
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  {/* {t("content.67")} */}
                  Description
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="description"
                  control={control}
                  className="inputLabel"
                />
                {errors.description && (
                  <p style={{ color: "red" }}>{errors.description.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ paddingBottom: "80px" }}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                {editId ? (
                  <Button size="large" className="editButton" htmlType="submit">
                    Update
                  </Button>
                ) : (
                  <Button size="large" className="editButton" htmlType="submit">
                    Add
                  </Button>
                )}
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Button
                  className="cancelButton"
                  onClick={handleClose}
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </Drawer>
  );
};

export default CommunityModalForm;
