import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import "./Admin.css";
import "./ViewMeetings.css"
import { useTranslation } from "react-i18next";

function ViewMeetings (props) {

    const { t } = useTranslation();
    const { selectedMeeting } = props;
    return(
        <div className="site-card-border-less-wrapper">
            <Row gutter={[0, 15]}>
                <Col xl={10} lg={10} md={10} sm={22} xs={22}>
                <Card
                    className='MeetingCard'
                    title={selectedMeeting?.title}
                    bordered={false}
                    style={{
                        width: 300,
                        fontSize: "0.9rem",
                        fontFamily: "", 
                        marginTop: "30px"
                    }}
                >
                    <Typography><b>{t("content.215")} :  </b>{selectedMeeting?.number}</Typography>
                    <Typography><b>{t("content.214")} :  </b>{selectedMeeting?.title}</Typography>
                    <Typography><b>{t("content.216")} :  </b>{selectedMeeting?.place}</Typography>
                    <Typography><b>{t("content.217")} :  </b>{selectedMeeting?.description}</Typography>
                    <Typography><b>{t("content.225")} :  </b>{selectedMeeting?.year}</Typography>
                </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ViewMeetings
