import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Empty,
  Typography,
  message,
  Card,
  Pagination,
  Tooltip,
  Breadcrumb,
  Popconfirm,
  Divider,
  Avatar,
  Popover,
} from "antd";
import FamilyDrawer from "./FamilyDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFamily,
  getFamilies,
  getFamilyById,
} from "../../Actions/familyAction";
import { history } from "../../Utils/history";
import {
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusCircleTwoTone,
  HomeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "../../Screens/AddFamilymember/Addfamily.css";
import AddAddressModal from "../AddFamilymember/AddAddressModal";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "../CommonLogics/ResponsiveBreakPoints";
import { deleteFamilyAddressAction } from "../../Actions/familyAddressAction";
import UsersDrawer from "../UsersSuperAdmin/UsersDrawer";
import AddMemberDrawer from "./AddMemberDrawer";
import { falseyValueCases, ImageEnv } from "../CommonLogics/CommonMethods";
const FamilyDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { IsXS } = useBreakpoints();

  const {
    setCommunityId,
    Family,
    isCreateFamilyAddressLoading,
    isUpdateFamilyAddressLoading,
    isGetFamilyByIdLoading,
    isDeleteFamilyAddressLoading,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { Family, isGetFamilyByIdLoading } = state.family;

    const {
      // allFamilyAddress,
      isCreateFamilyAddressLoading,
      isUpdateFamilyAddressLoading,
      isDeleteFamilyAddressLoading,
    } = state.familyAddresses;
    return {
      setCommunityId,
      Family,
      isCreateFamilyAddressLoading,
      isUpdateFamilyAddressLoading,
      isGetFamilyByIdLoading,
      isDeleteFamilyAddressLoading,
    };
  });
  useEffect(() => {
    dispatch(getFamilyById(history?.location?.state?.id));
  }, []);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isAddAddressModalVisible, setIsAddAddressModalVisible] =
    useState(false);
  const [isEditPopoverVisible, setIsEditPopoverVisible] = useState(false);
  const [idForPopover, setIdForPopover] = useState("");
  const [editFamilyAddressId, setEditFamilyAddressId] = useState("");
  const [isFamilyDrawerVisible, setIsFamilyDrawerVisible] = useState(false);
  const [isUsersDrawerVisible, setIsUsersDrawerVisible] = useState(false);
  const [isAddMemberDrawerVisible, setIsAddMemberDrawerVisible] =
    useState(false);
  const { user_families, family_addresses, owner_id } = Family || {};
  const openAddAddressModal = () => {
    setIsAddAddressModalVisible(true);
    setIsPopoverVisible(false);
  };
  const openEditPopover = (Id) => {
    setIsEditPopoverVisible(true);
    setIdForPopover(Id);
  };
  const deleteFamilyAddressMethod = (FamilyAddressId) => {
    dispatch(
      deleteFamilyAddressAction(FamilyAddressId, successDeleteAddressMethod)
    );
  };
  const successDeleteAddressMethod = () => {
    dispatch(getFamilyById(Family?.id));
    setTimeout(() => {
      message.success("Address deleted successfully");
    }, 1000);
  };
  const openEditAddressModal = (familyIdFromVal) => {
    setIsAddAddressModalVisible(true);
    setEditFamilyAddressId(familyIdFromVal);
    setIsEditPopoverVisible(false);
  };
  const handleMemberView = (Id, UserFamilyId) => {
    history.push(`/users/${Id}`, {
      id: Id,
      isUpdateFamilyMember: true,
      deleteUserFamilyId: UserFamilyId,
    });
  };
  const handleFamilyBreadCrumbMethod = () => {
    history.push("/families");
  };
  const handleDeleteFamilyMethod = () => {
    dispatch(deleteFamily(Family?.id, SuccessDeleteFamilyMethod));
  };
  const SuccessDeleteFamilyMethod = () => {
    handleFamilyBreadCrumbMethod();
    setTimeout(() => {
      message.success("Family deleted successfully");
    }, 1000);
  };
  return (
    <div style={{
      marginTop: "40px" 
      }}>
      <Spin
        spinning={isGetFamilyByIdLoading || isDeleteFamilyAddressLoading}
        size="large"
        style={{ height: "100vh" }}
      >
        {!isGetFamilyByIdLoading && !isDeleteFamilyAddressLoading && (
          <>
            <Row gutter={[0, 35]}>
              <Col span={1} />
              <Col span={13}>
                <Breadcrumb 
                // style={{ margin: 10 }}
                >
                  <Breadcrumb.Item
                    onClick={() => handleFamilyBreadCrumbMethod()}
                    className="breadcrumCommunity"
                  >
                    Families
                  </Breadcrumb.Item>

                  <Breadcrumb.Item className="breadcrumUsers">
                    {Family?.last_name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={24} />
            </Row>
            <Row>
              <Col span={1} />
              <Col span={14}>
                <Card className="FamilyCard">
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Row gutter={[12, 0]}>
                        <Col>
                          <Typography className="title">
                            {Family?.last_name}
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className="memberStyle">
                            Total&nbsp;{Family?.user_families?.length}
                            &nbsp;Member
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row gutter={[10, 0]} align="middle">
                        <Col>
                          <Tooltip title={t("btn.27")}>
                            <Button
                              icon={
                                <EditOutlined
                                  onClick={() => setIsFamilyDrawerVisible(true)}
                                />
                              }
                              type="text"
                            />
                          </Tooltip>
                          <FamilyDrawer
                            isFamilyDrawerVisible={isFamilyDrawerVisible}
                            setIsFamilyDrawerVisible={setIsFamilyDrawerVisible}
                            isUpdateFamily={true}
                            FamilyId={Family?.id}
                          />
                        </Col>
                        <Col>
                          <Popconfirm
                            title={t("btn.29")}
                            onConfirm={() => handleDeleteFamilyMethod()}
                            okText={t("btn.28")}
                            cancelText={t("btn.19")}
                            placement="topLeft"
                          >
                            <Tooltip title={t("btn.28")}>
                              <Button icon={<DeleteOutlined />} type="text" />
                            </Tooltip>
                          </Popconfirm>
                        </Col>
                        <Col>
                          {/* <MoreOutlined /> */}
                          <Popover
                            trigger="click"
                            open={isPopoverVisible}
                            onOpenChange={(visible) =>
                              setIsPopoverVisible(visible)
                            }
                            content={
                              <>
                                <Button
                                  icon={<PlusCircleTwoTone />}
                                  onClick={() => openAddAddressModal()}
                                  type="link"
                                >
                                  {/* {t("btn.39")} */}
                                  Add Address
                                </Button>
                                <AddAddressModal
                                  isAddAddressModalVisible={
                                    isAddAddressModalVisible
                                  }
                                  setIsAddAddressModalVisible={
                                    setIsAddAddressModalVisible
                                  }
                                  isCreateFamilyAddressLoading={
                                    isCreateFamilyAddressLoading
                                  }
                                  familyIdFromObject={Family?.id}
                                  setEditFamilyAddressId={
                                    setEditFamilyAddressId
                                  }
                                />
                              </>
                            }
                          >
                            <MoreOutlined
                              style={{ fontSize: "15px" }}
                              onClick={() => setIsPopoverVisible(true)}
                            />
                          </Popover>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="end">
                    <Typography className="memberName">
                      ({Family?.family_number})
                    </Typography>
                  </Row>
                  <Divider />
                  {family_addresses?.map((val, i) => {
                    return (
                      <Row key={i}>
                        <Col span={3}>
                          <Typography>
                            {IsXS ? (
                              <HomeOutlined />
                            ) : (
                              <b>{t("content.19")} :</b>
                            )}{" "}
                          </Typography>
                        </Col>
                        <Col span={20}>
                          <Typography className="addressLine">
                            {val.home_number} {val.address_line_1}{" "}
                            {val.address_line_2} {val.city} {val.landmark}{" "}
                            {val.state},{val.country} {val.zip}
                          </Typography>
                        </Col>
                        <Col span={1}>
                          <Popover
                            trigger="click"
                            visible={
                              idForPopover === val.id && isEditPopoverVisible
                            }
                            onVisibleChange={(visible) =>
                              setIsEditPopoverVisible(visible)
                            }
                            content={
                              <>
                                <Tooltip title={t("btn.27")}>
                                  <EditOutlined
                                    onClick={() =>
                                      openEditAddressModal(val?.id)
                                    }
                                  />
                                </Tooltip>
                                <AddAddressModal
                                  editFamilyAddressId={editFamilyAddressId}
                                  familyIdFromObject={Family?.id}
                                  isUpdateFamilyAddressLoading={
                                    isUpdateFamilyAddressLoading
                                  }
                                  isAddAddressModalVisible={
                                    isAddAddressModalVisible
                                  }
                                  allFamilyAddress={family_addresses}
                                  setIsAddAddressModalVisible={
                                    setIsAddAddressModalVisible
                                  }
                                  setEditFamilyAddressId={
                                    setEditFamilyAddressId
                                  }
                                />
                                <Popconfirm
                                  title={t("btn.29")}
                                  onConfirm={() =>
                                    deleteFamilyAddressMethod(val?.id)
                                  }
                                  okText={t("btn.28")}
                                  cancelText={t("btn.19")}
                                  placement="topLeft"
                                >
                                  <Tooltip title={t("btn.28")}>
                                    <DeleteOutlined
                                      style={{ paddingLeft: "10px" }}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              </>
                            }
                          >
                            <MoreOutlined
                              style={{ fontSize: "15px" }}
                              onClick={() => openEditPopover(val.id)}
                            />
                          </Popover>
                        </Col>

                        <Divider />
                      </Row>
                    );
                  })}
                  <Row gutter={[15, 15]}>
                    {user_families?.map((val, i) => {
                      return (
                        <Col
                          xl={8}
                          key={i}
                          onClick={() =>
                            handleMemberView(val?.user?.id, val?.id)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <MemberCard
                            member={val?.user}
                            ownerId={owner_id}
                            user_image={val.user_image}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row gutter={[10, 15]}>
                    <Col span={24} />
                    <Col span={12}>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        onClick={() => setIsUsersDrawerVisible(true)}
                      >
                        Add a new member to the Family
                      </Button>
                      <UsersDrawer
                        isAddFamilyMember={true}
                        isUsersDrawerVisible={isUsersDrawerVisible}
                        setIsUsersDrawerVisible={setIsUsersDrawerVisible}
                      />
                    </Col>

                    <Col span={12}>
                      <Button
                        type="primary"
                        style={{ width: "100%" }}
                        onClick={() => setIsAddMemberDrawerVisible(true)}
                      >
                        Add an Existing Member to the Family
                      </Button>
                      <AddMemberDrawer
                        setIsAddMemberDrawerVisible={
                          setIsAddMemberDrawerVisible
                        }
                        isAddMemberDrawerVisible={isAddMemberDrawerVisible}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Spin>
    </div>
  );
};

const MemberCard = (props) => {
  const { member, ownerId, user_image } = props;
  const userOldDays =
    (member?.birth_year === null
      ? "Edit your data"
      : moment(
          member.birth_year + "-" + member.birth_month + "-" + member.birth_date
        ).fromNow("years old")) === "Invalid date"
      ? "Few days"
      : member?.birth_year === null
      ? "Edit your data"
      : moment(
          member.birth_year + "-" + member.birth_month + "-" + member.birth_date
        ).fromNow("years old");
  // const FamilyHead = _.filter(Family?.user_families, (val) => {
  //   return ownerId === member.id;
  // })

  return (
    <Row gutter={[8,8]}>
      <Col span={4}>
        <Avatar size={35} src={user_image?.image && ImageEnv(user_image?.image)}>
          {member?.first_name[0]}
        </Avatar>
      </Col>
      <Col span={1} />
      <Col span={19}>
        <Row>
          <Col span={24}>
            <Typography className="memberName TextEllipses">
              {member?.first_name}&nbsp;
              {!falseyValueCases(member?.gender) &&
                (member?.gender === "male" ? "(M)" : "(F)")}
              &nbsp;
              {ownerId === member?.id && (
                <Typography component="span" style={{ color: "#27AE60" }}>
                  (Head)
                </Typography>
              )}
            </Typography>
          </Col>
          <Col span={24}>
            <Typography className="memberStyle">
              {userOldDays}&nbsp;
              {member?.birth_year !== null && "old"}
            </Typography>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FamilyDetail;
