import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  message,
  Drawer,
  Input,
  DatePicker,
  TimePicker,
  Upload,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import {
  createEvents,
  getEventById,
  getEvents,
  updateEvents,
} from "../../Actions/eventsAction";
import { PlusOutlined } from "@ant-design/icons";
import { eventSchema } from "../../Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
const EventsDrawer = (props) => {
  const {
    isEventsDrawerVisible,
    setIsEventsDrawerVisible,
    currentPage,
    isUpdateEvent,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();
  const {
    setCommunityId,
    event,
    isCreateEventsLoading,
    isUpdateEventsLoading,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { event, isCreateEventsLoading, isUpdateEventsLoading } =
      state.events;

    return {
      setCommunityId,
      event,
      isCreateEventsLoading,
      isUpdateEventsLoading,
    };
  });
  const [preloadedEventValue, setPreloadedEventValue] = useState("");
  const EventDate = event?.event_datetime?.split("_");
  const newEventDate = moment(EventDate?.[0], "DD-MM-YYYY");
  const newEventTime = moment(EventDate?.[1], "LT");
  if (isUpdateEvent) {
    event.dummy_date = newEventDate;
    event.dummy_time = newEventTime;
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: preloadedEventValue,
    resolver: yupResolver(eventSchema),
  });
  useEffect(() => {
    if (isUpdateEvent) {
      setPreloadedEventValue(event);
      reset(event);
    }
  }, [event, reset]);
  const { t } = useTranslation();
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const IsEmpty = _.isEmpty(newFiles) ? true : false;

  const closeAddEventMethod = () => {
    setIsEventsDrawerVisible(false);
    setValue("event_name", "");
    setValue("city_name", "");
    setValue("event_location", "");
    setValue("event_detail", "");
    setValue("dummy_date", "");
    setValue("dummy_time", "");
    clearErrors();
    setNewFiles([]);
    setFileList([]);
  };
  const handleAddEventMethod = (data) => {
    data.community_id = setCommunityId;
    data.dummy_date = moment(data.dummy_date).format("DD/MM/YYYY");
    data.dummy_time = moment(data.dummy_time).format("LT");
    data.event_datetime = data.dummy_date + "_" + data.dummy_time;
    data.community_event_image = _.isEmpty(newFiles) ? null : newFiles;
    dispatch(
      createEvents(data, SuccessEventCallback, FailureEventCallback, IsEmpty)
    );
  };
  const SuccessEventCallback = () => {
    reset();
    setIsEventsDrawerVisible(false);
    setCurrentPage(1);
    // dispatch(
    //   getEvents(`api/community_events?community_id=${setCommunityId}&page=1`)
    // );
    setValue("event_name", "");
    setValue("city_name", "");
    setValue("event_location", "");
    setValue("event_detail", "");
    setValue("dummy_date", "");
    setValue("dummy_time", "");
    clearErrors();
    setNewFiles([]);
    setFileList([]);
    setTimeout(() => {
      message.success("Event created successfully");
    }, 1000);
  };
  const FailureEventCallback = () => {};
  const handleEditEvent = (data) => {
    data.id = event?.id;
    data.dummy_date = moment(data.dummy_date).format("DD/MM/YYYY");
    data.dummy_time = moment(data.dummy_time).format("LT");
    data.event_datetime = data.dummy_date + "_" + data.dummy_time;
    data.community_event_image = _.isEmpty(newFiles) ? null : newFiles;
    dispatch(
      updateEvents(
        data,
        handleEditSuccessCallback,
        handleFailureEditCallback,
        IsEmpty
      )
    );
  };
  const handleEditSuccessCallback = () => {
    setIsEventsDrawerVisible(false);
    setTimeout(() => {
      message.success("Event edited successfully");
    }, 1000);
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    dispatch(getEventById(event?.id));
  };
  const closeEditDrawerMethod = () => {
    setIsEventsDrawerVisible(false);
    reset(event);
    clearErrors();
    setNewFiles([]);
    setFileList([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };

  const handleUploadMethod = (Event) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    setFileList(Event.fileList);
  };
  const propsForUpload = {
    action: handleEditEvent || handleAddEventMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleFailureEditCallback = () => {};
  return (
    <Drawer
      open={isEventsDrawerVisible}
      onClose={isUpdateEvent ? closeEditDrawerMethod : closeAddEventMethod}
      title={isUpdateEvent ? "Edit Event" : "Add Event"}
    >
      <form
        onSubmit={handleSubmit(
          isUpdateEvent ? handleEditEvent : handleAddEventMethod
        )}
      >
        <Spin
          spinning={
            isUpdateEvent ? isUpdateEventsLoading : isCreateEventsLoading
          }
          size="large"
        >
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  {isUpdateEvent && isDefaultImageVisible && event?.image && (
                    <Upload
                      listType="picture-card"
                      openFileDialogOnClick={false}
                    >
                      <img src={event?.image} width={102} alt=""></img>
                    </Upload>
                  )}
                </Col>
                <Col>
                  <Upload
                    listType="picture-card"
                    // onChange={() => setIsDefaultImageVisible(false)}
                    onChange={(e) => handleUploadMethod(e)}
                    {...propsForUpload}
                    maxCount={1}
                    showUploadList={showUploadImage}
                    accept="image/png,image/jpeg,image/webp"
                    fileList={fileList}
                  >
                    <PlusOutlined size={44} />
                    Upload Image
                  </Upload>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>Event Name</Typography>
              <Controller
                as={<Input size="large" />}
                name="event_name"
                control={control}
              />
              {errors.event_name && (
                <p style={{ color: "red" }}>{errors.event_name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>Event City</Typography>
              <Controller
                as={<Input size="large" />}
                name="city_name"
                control={control}
              />
              {errors.city_name && (
                <p style={{ color: "red" }}>{errors.city_name.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                Event Location
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="event_location"
                control={control}
              />
              {errors.event_location && (
                <p style={{ color: "red" }}>{errors.event_location.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                Event Detail
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="event_detail"
                control={control}
              />
              {errors.event_detail && (
                <p style={{ color: "red" }}>{errors.event_detail.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>Event Date</Typography>

              <Controller
                as={
                  <DatePicker
                    placeholder="Select Date"
                    className="inputLabel"
                    size="large"
                    //   onSelect={(e) => setDateCustom(e)}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current < moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                }
                name="dummy_date"
                control={control}
                style={{ width: "100%" }}
              />
              {errors.dummy_date && (
                <p style={{ color: "red" }}>Event Date is required</p>
              )}
            </Col>
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>Event Time</Typography>
              <Controller
                as={
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    //   onSelect={(time) => onTimeSelection(time)}
                    size="large"
                    //   placeholder={t("placeholder.11")}
                  />
                }
                name="dummy_time"
                control={control}
                style={{ width: "100%" }}
              />
              {errors.dummy_time && (
                <p style={{ color: "red" }}>Event Time is required</p>
              )}
            </Col>
            <Col span={24}>
              <Row gutter={[20, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {isUpdateEvent ? "Edit" : "Add"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() =>
                      isUpdateEvent
                        ? closeEditDrawerMethod()
                        : closeAddEventMethod()
                    }
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};
export default EventsDrawer;
