import constants from "../Utils/constants";
const initialState = {
  isGetDesignationsLoading: false,
  allDesignations: [],
  designation:{},
  isCreateDesignationsLoading: false,
  isUpdateDesignationsLoading: false,
  designationById: {},
};

export const designationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_DESIGNATIONS_REQUEST:
      return {
        ...state,
        isGetDesignationsLoading: true,
      };
    case constants.GET_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        isGetDesignationsLoading: false,
        allDesignations: action.payload,
      };
    case constants.GET_DESIGNATIONS_FAILURE:
      return {
        ...state,
        isGetDesignationsLoading: false,
      };
    case constants.CREATE_DESIGNATIONS_REQUEST:
      return {
        ...state,
        isCreateDesignationsLoading: true,
      };
    case constants.CREATE_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        isCreateDesignationsLoading: false,
      };
    case constants.CREATE_DESIGNATIONS_FAILURE:
      return {
        ...state,
        isCreateDesignationsLoading: false,
      };
    case constants.UPDATE_DESIGNATIONS_REQUEST:
      return {
        ...state,
        isUpdateDesignationsLoading: true,
      };
    case constants.UPDATE_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        isUpdateDesignationsLoading: false,
      };
    case constants.UPDATE_DESIGNATIONS_FAILURE:
      return {
        ...state,
        isUpdateDesignationsLoading: false,
      };
    case constants.GET_DESIGNATIONS_BY_ID_REQUEST:
      return {
        ...state,
        isGetDesignationsLoading: true,
      };
    case constants.GET_DESIGNATIONS_BY_ID_SUCCESS:
      return {
        ...state,
        isGetDesignationsLoading: false,
        designationById: action.payload,
      };
    case constants.GET_DESIGNATIONS_BY_ID_FAILURE:
      return {
        ...state,
        isGetDesignationsLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
