import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Input,
  Upload,
} from "antd";
import {
  createDocuments,
  updateDocuments,
} from "../../../Actions/documentsAction";
import { getCommunityById } from "../../../Actions/communitiesAction";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DocumentsSchema } from "../../../Utils/Schema";
import { getDocuments } from "../../../Actions/documentsAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { history } from "../../../Utils/history";
import { getSearchUser } from "../../../Actions/searchUserAction";
import CustomSearch from "../../ReuseableComponents/CustomSearch/CustomSearch";

const DocumentsModalForm = (props) => {
  const {
    editId,
    setEditId,
    setValName,
    valName,
    currentPage,
    setCurrentPage,
    setYear,
    year,
    isModalVisible,
    setIsModalVisible,
    fileAllowed,
    setFileAllowed,
  } = props;
  const d = new Date();
  let y = d.getFullYear();
  const [newFiles, setNewFiles] = useState([]);
  const [preloadedDocumentValue, setPreloadedDocumentValue] = useState("");
  // const [fileAllowed, setFileAllowed] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const IsEmpty = _.isEmpty(newFiles);
  const {
    setCommunityId,
    searchUsers,
    allDocuments,
    isCreateDocumentLoading,
    isUpdateDocumentLoading,
    isGetDocumentLoading,
    isSearchUserLoading,
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { community, allCommunities, setCommunityId } = state.communities;
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const {
      allDocuments,
      isCreateDocumentLoading,
      isUpdateDocumentLoading,
      isGetDocumentLoading,
    } = state.documents;

    return {
      isSearchUserLoading,
      currentUser,
      setCommunityId,
      searchUsers,
      allDocuments,
      isCreateDocumentLoading,
      isUpdateDocumentLoading,
      isGetDocumentLoading,
    };
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedDocumentValue },

    resolver: yupResolver(DocumentsSchema),
  });

  const the_community = _.filter(allDocuments.documents, function (o) {
    return o.id === editId;
  });

  useEffect(() => {
    if (editId) {
      setPreloadedDocumentValue(the_community[0]);
      reset(the_community[0]);
    }
  }, [the_community[0], reset]);

  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setFileAllowed(false);
    setValue("file_name", "");
    setValue("year", "");
    setValue("document_file", "");
    setValue("user_id", "");
    setNewFiles([]);
    setErrorUserId(null);
    setSelectedUserId(null);
    clearErrors();
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  const handleCreateDocument = (data) => {
    // if (!newFiles || newFiles.type !== "application/pdf") {
    //   setFileAllowed("File must be required having type pdf only");
    // } else
    if (selectedUserId) {
      data.user_id = selectedUserId;
      data.document_file = newFiles;
      data.year = year;
      data.community_id = setCommunityId;
      dispatch(
        createDocuments(data, successCreateDocument, faliureCreate, IsEmpty)
      );
    } else {
      setErrorUserId("User must be selected");
    }
  };

  const successCreateDocument = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    reset();
    // setFileAllowed(false);
    setFileAllowed(false);
    setValue("file_name", "");
    setValue("year", "");
    setValue("document_file", "");
    setValue("user_id", "");
    dispatch(
      getDocuments(
        `api/documents?community_id=${setCommunityId}&page=${currentPage}&year=${year}`
      )
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your Document Create Successfully");
    }, 1000);
  };
  const faliureCreate = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleEditDocument = (data) => {
    data.id = editId;
    data.document_file = newFiles;
    data.year = year;
    data.user_id = _.isEmpty(data.user_id)
      ? the_community[0]?.user?.id
      : data.user_id;

    dispatch(updateDocuments(data, UpdateCallBack, faliureUpdate, IsEmpty));
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId("");
    reset();
    setNewFiles([]);
    setFileAllowed(false);
    setValue("file_name", "");
    setValue("year", "");
    setValue("document_file", "");
    setValue("user_id", "");
    dispatch(
      getDocuments(
        `api/documents?community_id=${setCommunityId}&page=${currentPage}&year=${year}`
      )
    );
    clearErrors();
    setTimeout(() => {
      message.success("Your Document Update Successfully");
    }, 1000);
  };
  const faliureUpdate = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const propsForUpload = {
    action: handleCreateDocument || handleEditDocument,
    // action: handleCreateDocument,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };

  const the_name = _.filter(allDocuments.documents, function (o) {
    return o.id === valName;
  });
  const onClickDocument = () => {
    // setSelectedDocument("");
    setValName("");
    reset();
  };
  const onUserSearch = (data) => {
    if (data) {
      setSelectedUserId(data?.id);
    } else {
      setSelectedUserId(null);
    }
  };
  const clearSearchUserRequiredMessageMethod = () => {
    setErrorUserId(null);
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);

  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Documents" : "Add Documents"}
    >
      <Typography className="content">Selected Year : {year}</Typography>
      <form
        onSubmit={handleSubmit(
          editId ? handleEditDocument : handleCreateDocument
        )}
      >
        <Spin
          style={{ height: "100%", maxHeight: "unset" }}
          spinning={editId ? isUpdateDocumentLoading : isCreateDocumentLoading}
          size="large"
        >
          {/* <Row gutter={[8, 8]}>
                  <Typography className="addCommunity">
                    {editId ? "Edit Documents" : "Add Documents"}
                  </Typography>
                </Row> */}
          <Row gutter={[8, 8]}>
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: 9 }}>
                File Name
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="file_name"
                control={control}
                className="inputLabel"
              />
              {errors.file_name && (
                <p style={{ color: "red" }}>{errors.file_name.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography className="details" style={{ paddingBottom: 9 }}>
                Select File
              </Typography>
            </Col>
            <Col span={24}>
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  // display: "flex",
                  padding: "3px",
                }}
              >
                <Row justify="space-between">
                  <Col>
                    <Typography
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      name="document_file"
                    >
                      {newFiles?.name}
                    </Typography>
                  </Col>
                  <Col>
                    <Upload
                      {...propsForUpload}
                      showUploadList={false}
                      // onChange={() => setFileAllowed(false)}
                    >
                      <Button type="primary">{t("btn.25")}</Button>
                    </Upload>
                  </Col>
                </Row>
              </div>
              {fileAllowed && <p style={{ color: "red" }}>{fileAllowed}</p>}
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={24} className="formRows">
              {!editId && (
                <Typography className="details" style={{ paddingBottom: 9 }}>
                  Search Community Member By Phone/Name
                </Typography>
              )}
              {!editId && (
                <Col span={22}>
                  <CustomSearch
                    size="large"
                    data={searchUsers?.users}
                    placeholder="Search User"
                    searchAction={getSearchUser}
                    isDrawer={isModalVisible}
                    searchActionLoading={isSearchUserLoading}
                    communityId={setCommunityId}
                    onSearch={onUserSearch}
                    clearRequiredMessageMethod={
                      clearSearchUserRequiredMessageMethod
                    }
                  />
                  {errorUserId && <p style={{ color: "red" }}>{errorUserId}</p>}
                </Col>
              )}
            </Col>
          </Row>

          <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {editId ? (
                <Button size="large" className="editButton" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button size="large" className="editButton" htmlType="submit">
                  Add
                </Button>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className="cancelButton"
                onClick={handleClose}
                size="large"
                // style={{ margin: "0 8px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};

export default DocumentsModalForm;
