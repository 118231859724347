import API from "../Utils/API";
export const createFamily =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_FAMILY_REQUEST" });
    API.post("api/families", data)
      .then((response) => {
        dispatch({ type: "CREATE_FAMILY_SUCCESS", payload: response.data });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_FAMILY_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback();
      });
  };

export const getFamilyById = (Id) => (dispatch) => {
  dispatch({ type: "GET_FAMILY_BY_ID_REQUEST" });
  API.get(`api/families/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_FAMILY_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_FAMILY_BY_ID_FAILURE",
        payload: error?.response?.data,
      });
    });
};

export const getFamilies = (url) => (dispatch) => {
  dispatch({ type: "GET_FAMILIES_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_FAMILIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "GET_FAMILIES_FAILURE", payload: error.response.data });
    });
};

export const deleteFamily =
  (familyId, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_FAMILIES_REQUEST" });
    API.delete(`api/families/${familyId}`)
      .then((response) => {
        dispatch({ type: "DELETE_FAMILIES_SUCCESS", payload: response.data });
        // dispatch(getFamilies());
        successCallback && successCallback();
      })

      .catch((error) => {
        dispatch({
          type: "DELETE_FAMILIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback();
      });
  };

export const updateFamily =
  (data, successUpdateCallBack, failureCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_FAMILIES_REQUEST" });
    API.put(`api/families/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UPDATE_FAMILIES_SUCCESS", payload: response.data });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_FAMILIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback();
      });
  };

export const createAddMemberAction =
  (data, successCallback, faliureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_ADD_MEMBER_REQUEST" });
    API.post("api/add_member", data)
      .then((response) => {
        dispatch({
          type: "CREATE_ADD_MEMBER_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_ADD_MEMBER_FAILURE",
          payload: error.response.data,
        });
        faliureCallback && faliureCallback(error.response.data);
      });
  };
