import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  message,
  Drawer,
  Input,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUser } from "../../Actions/searchUserAction";
import {
  createBusiness,
  getBusiness,
  updateBusiness,
} from "../../Actions/businessAction";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  BusinessuserSchema,
  BusinessuserUpdateSchema,
  JobProfileSchema,
  UpdateJobProfileSchema,
} from "../.././Utils/Schema";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../FindThePeoplePage/FindOtherPeople.css";
import CustomSearch from "../ReuseableComponents/CustomSearch/CustomSearch";
const BusinessDrawer = (props) => {
  const {
    isBusinessDrawerVisible,
    setIsBusinessDrawerVisible,
    currentPage,
    isEditBusinessId,
    setIsEditBusinessId,
    tabKey,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    searchUsers,
    setCommunityId,
    allBusiness,
    isUpdateBusinessLoading,
    isCreateBusinessLoading,
    isSearchUserLoading,
  } = useSelector((state) => {
    const { searchUsers, isSearchUserLoading } = state.searchUser;
    const { setCommunityId } = state.communities;
    const { allBusiness, isUpdateBusinessLoading, isCreateBusinessLoading } =
      state.business;
    return {
      searchUsers,
      setCommunityId,
      allBusiness,
      isUpdateBusinessLoading,
      isCreateBusinessLoading,
      isSearchUserLoading,
    };
  });

  const the_business = _.filter(allBusiness?.business_profiles, function (o) {
    return o.id === isEditBusinessId;
  });
  const [preloadedBusinessProfileValue, setPreloadedBusinessProfileValue] =
    useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: isEditBusinessId && preloadedBusinessProfileValue,
    resolver: yupResolver(
      tabKey === "2" && isEditBusinessId
        ? UpdateJobProfileSchema
        : tabKey === "2"
        ? JobProfileSchema
        : isEditBusinessId
        ? BusinessuserUpdateSchema
        : BusinessuserSchema
    ),
  });

  useEffect(() => {
    if (isEditBusinessId) {
      setPreloadedBusinessProfileValue(the_business[0]);
      reset(the_business[0]);
    }
  }, [the_business[0], reset]);

  const [checkedValue, setCheckedValue] = useState(
    the_business[0]?.is_wholesale ? true : false
  );
  const [isOpenSelectDropdownVisible, setIsOpenSelectDropdownVisible] =
    useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [onSearchData, setOnSearchData] = useState(null);
  const [isInputChange, setIsInputChange] = useState(true);
  const [name, setName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [errorUserId, setErrorUserId] = useState(null);
  const closeAddBusinessDrawer = () => {
    setIsBusinessDrawerVisible(false);
    setIsEditBusinessId("");
    setValue("company_name", "");
    setValue("user_id", "");
    setValue("service_provide", "");
    setValue("business_details", "");
    setValue("business_location", "");
    setValue("number_of_staff", "");
    setValue("job_title", "");
    setValue("working_here_since", "");
    setValue("experiance", "");
    setCheckedValue("");
    setOnSearchData(null);
    setName(null);
    setSelectedUserId(null);
    clearErrors();
  };
  const handleAddBusinessMethod = (data) => {
    if (selectedUserId) {
      data.user_id = selectedUserId;
      data.is_business = true;
      data.is_retail = checkedValue === "Retail" ? true : false;
      data.is_wholesale = checkedValue === "Wholesale" ? true : false;
      dispatch(createBusiness(data, SuccessCreateCallback));
    } else {
      setErrorUserId("User must be selected");
    }
  };
  const SuccessCreateCallback = () => {
    reset();
    setIsBusinessDrawerVisible(false);
    setIsEditBusinessId("");
    dispatch(
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setValue("company_name", "");
    setValue("user_id", "");
    setValue("service_provide", "");
    setValue("business_details", "");
    setValue("business_location", "");
    setValue("number_of_staff", "");
    setCheckedValue("");
    setTimeout(() => {
      message.success("Business Profile Created Successfully");
    }, 1000);
    setOnSearchData(null);
    setName(null);
    setSelectedUserId(null);
    clearErrors();
  };
  const handleEditBusinessMethod = (data) => {
    data.id = isEditBusinessId;

    data.is_wholesale =
      checkedValue === "Wholesale"
        ? true
        : checkedValue === "Retail"
        ? false
        : the_business[0]?.is_wholesale;
    data.is_retail =
      checkedValue === "Retail"
        ? true
        : checkedValue === "Wholesale"
        ? false
        : the_business[0]?.is_retail;
    dispatch(updateBusiness(data, SuccessUpdateBusinessCallback));
  };
  const SuccessUpdateBusinessCallback = () => {
    reset();
    setIsBusinessDrawerVisible(false);
    setIsEditBusinessId("");
    dispatch(
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setValue("company_name", "");
    setValue("user_id", "");
    setValue("service_provide", "");
    setValue("business_details", "");
    setValue("business_location", "");
    setValue("number_of_staff", "");
    setCheckedValue("");
    setTimeout(() => {
      message.success("Business Profile Edited Successfully");
    }, 1000);
    clearErrors();
  };
  const handleAddJobProfileMethod = (data) => {
    if (selectedUserId) {
      data.user_id = selectedUserId;
      data.is_business = false;
      dispatch(createBusiness(data, SuccessCreateJobCallback));
    } else {
      setErrorUserId("User must be selected");
    }
  };
  const SuccessCreateJobCallback = () => {
    reset();
    setIsBusinessDrawerVisible(false);
    setIsEditBusinessId("");
    dispatch(
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setValue("company_name", "");
    setValue("user_id", "");
    setValue("business_details", "");
    setValue("business_location", "");
    setValue("job_title", "");
    setValue("working_here_since", "");
    setValue("experiance", "");
    clearErrors();
    setOnSearchData(null);
    setName(null);
    setSelectedUserId(null);
    setTimeout(() => {
      message.success("Job Profile Created Successfully");
    }, 1000);
  };
  const handleEditJobProfileMethod = (data) => {
    data.id = isEditBusinessId;
    dispatch(updateBusiness(data, SuccessUpdateJobProfileCallback));
  };
  const SuccessUpdateJobProfileCallback = () => {
    reset();
    setIsBusinessDrawerVisible(false);
    setIsEditBusinessId("");
    dispatch(
      getBusiness(
        `api/business_profiles?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    setValue("company_name", "");
    setValue("user_id", "");
    setValue("business_details", "");
    setValue("business_location", "");
    setValue("job_title", "");
    setValue("working_here_since", "");
    setValue("experiance", "");
    setCheckedValue("");
    setTimeout(() => {
      message.success("Job Profile Edited Successfully");
    }, 1000);
    clearErrors();
  };
  const handledChecked = (event) => {
    const { value } = event.target;
    setCheckedValue(value);
  };
  useEffect(() => {
    if (!isEditBusinessId) {
      setIsEditBusinessId("");
    }
  }, [setValue, !isEditBusinessId]);
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const handleSearchButtonMethod = () => {
    const DataLength = onSearchData?.toString()?.length;
    setIsSearchButtonClicked(DataLength > 0);
    setIsOpenSelectDropdownVisible(DataLength > 0);
    setIsInputChange(true);
    const isDataPhoneNumber = containsNumber(onSearchData);
    if (DataLength > 0) {
      if (isDataPhoneNumber) {
        dispatch(
          getSearchUser(
            `api/user_search?community_id=${setCommunityId}&phone=${onSearchData}`
          )
        );
      }
      if (!isDataPhoneNumber) {
        dispatch(
          getSearchUser(
            `api/user_search?community_id=${setCommunityId}&first_name=${onSearchData}`
          )
        );
      }
    }
  };
  const onSearchMethod = (data) => {
    setOnSearchData(data);
    setIsInputChange(false);
    if (data.toString().length === 2) {
      setName(null);
      setSelectedUserId(null);
    }
    setErrorUserId(null);
  };

  const handleSelectDropdownOption = (Val) => {
    setOnSearchData(Val.first_name + " " + Val.last_name);
    setName(Val.first_name);
    setIsSearchButtonClicked(false);
    setSelectedUserId(Val?.id);
  };
  const onUserSearch = (data) => {
    if (data) {
      setSelectedUserId(data?.id);
    } else {
      setSelectedUserId(null);
    }
  };
  const clearSearchUserRequiredMessageMethod = () => {
    setErrorUserId(null);
  };
  return (
    <Drawer
      open={isBusinessDrawerVisible}
      onClose={closeAddBusinessDrawer}
      title={
        tabKey === "2" && isEditBusinessId
          ? "Edit Job Profile for" +
            " " +
            the_business[0].user.first_name +
            " " +
            the_business[0].user.last_name
          : tabKey === "2"
          ? "Add Job Profile"
          : isEditBusinessId
          ? "Edit Business Profile for" +
            " " +
            the_business[0].user.first_name +
            " " +
            the_business[0].user.last_name
          : "Add Business"
      }
    >
      <form
        onSubmit={handleSubmit(
          tabKey === "2" && isEditBusinessId
            ? handleEditJobProfileMethod
            : tabKey === "2"
            ? handleAddJobProfileMethod
            : isEditBusinessId
            ? handleEditBusinessMethod
            : handleAddBusinessMethod
        )}
      >
        <Spin
          spinning={
            isEditBusinessId ? isUpdateBusinessLoading : isCreateBusinessLoading
          }
          size="large"
        >
          {!isEditBusinessId && (
            <Typography style={{ paddingBottom: 10 }}>
              Search Community Member By Phone/Name
            </Typography>
          )}

          <Row gutter={[0, 10]}>
            {!isEditBusinessId && (
              // <Col span={22}>
              //   <Row align="bottom">
              //     <Col span={20}>
              //       <Typography style={{ paddingBottom: 10 }}>
              //         Search Community User
              //       </Typography>
              //       {/* <Controller
              //         name="user_id"
              //         as={ */}
              //       <Select
              //         placeholder="Select user"
              //         style={{ width: "100%", borderRadius: "4px" }}
              //         size="large"
              //         showSearch={true}
              //         options={searchUsers?.users?.map((val, i) => {
              //           return {
              //             label: val.first_name + " " + val.last_name,
              //             value: val.id,
              //           };
              //         })}
              //         filterOption={(input, option) =>
              //           option.label
              //             .toLowerCase()
              //             .indexOf(input.toLowerCase()) >= 0
              //         }
              //         dropdownRender={(menu) => {
              //           return searchUsers?.users?.map((val, i) => {
              //             return (
              //               <button
              //                 className="SearchUserDropdown"
              //                 key={i}
              //                 onClick={() => handleSelectDropdownOption(val)}
              //               >
              //                 {val.first_name + " " + val.last_name}
              //                 {"("}
              //                 {val?.phone}
              //                 {")"}
              //               </button>
              //             );
              //           });
              //         }}
              //         open={
              //           isOpenSelectDropdownVisible &&
              //           isSearchButtonClicked &&
              //           isBusinessDrawerVisible &&
              //           isInputChange &&
              //           !isSearchUserLoading
              //         }
              //         onSearch={(e) => onSearchMethod(e)}
              //         loading={isSearchUserLoading}
              //         searchValue={onSearchData ? onSearchData : name}
              //       ></Select>
              //       {/* }
              //         control={control} */}
              //       {/* /> */}
              //       {/* )} */}
              //       {/* {errors.user_id && (
              //         <p style={{ color: "red" }}>{errors.user_id.message}</p>
              //       )} */}
              //     </Col>
              //     <Col span={4}>
              //       <Button
              //         size="large"
              //         onClick={() => handleSearchButtonMethod()}
              //         disabled={!onSearchData?.toString()?.length > 0}
              //       >
              //         Search
              //       </Button>
              //     </Col>
              //   </Row>
              // </Col>
              <Col span={22}>
                <CustomSearch
                  size="large"
                  data={searchUsers?.users}
                  placeholder="Search User"
                  searchAction={getSearchUser}
                  isDrawer={isBusinessDrawerVisible}
                  searchActionLoading={isSearchUserLoading}
                  communityId={setCommunityId}
                  onSearch={onUserSearch}
                  clearRequiredMessageMethod={
                    clearSearchUserRequiredMessageMethod
                  }
                />
              </Col>
            )}
            {errorUserId && <p style={{ color: "red" }}>{errorUserId}</p>}
            {/* )} */}
            {tabKey === "2" && (
              <Col span={24}>
                <Typography style={{ paddingBottom: 10 }}>Job Title</Typography>
                <Controller
                  as={<Input size="large" />}
                  name="job_title"
                  control={control}
                />
                {errors.job_title && (
                  <p style={{ color: "red" }}>Job title is required</p>
                )}
              </Col>
            )}

            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                {t("content.48")}
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="company_name"
                control={control}
              />
              {errors.company_name && (
                <p style={{ color: "red" }}>{t("content.55")}</p>
              )}
            </Col>
            {tabKey === "2" && (
              <Col span={24}>
                <Typography style={{ paddingBottom: 10 }}>
                  {/* {t("content.67")} */}
                  Working Here Since
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="working_here_since"
                  control={control}
                />
                {errors.working_here_since && (
                  <p style={{ color: "red" }}>Working here since is required</p>
                )}
              </Col>
            )}
            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                {tabKey === "2" ? "Job Details" : t("content.50")}
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="business_details"
                control={control}
              />
              {errors.business_details && (
                <p style={{ color: "red" }}>
                  {errors.business_details.message}
                </p>
              )}
            </Col>

            <Col span={24}>
              <Typography style={{ paddingBottom: 10 }}>
                {tabKey === "2" ? t("content.68") : t("content.51")}
              </Typography>
              <Controller
                as={<Input size="large" />}
                name="business_location"
                control={control}
              />
              {errors.business_location && (
                <p style={{ color: "red" }}>
                  {errors.business_location.message}
                </p>
              )}
            </Col>
            {tabKey === "1" && (
              <Col span={24}>
                <Typography style={{ paddingBottom: 10 }}>
                  Service Provide
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="service_provide"
                  control={control}
                />
                {errors.service_provide && (
                  <p style={{ color: "red" }}>
                    {errors.service_provide.message}
                  </p>
                )}
              </Col>
            )}
            {tabKey === "1" && (
              <Col span={24}>
                <Typography style={{ paddingBottom: 10 }}>
                  Number of staff
                </Typography>
                <Controller
                  as={<Input size="large" type="number" />}
                  name="number_of_staff"
                  control={control}
                />
                {errors.number_of_staff && (
                  <p style={{ color: "red" }}>
                    {"Number of staff is required"}
                  </p>
                )}
              </Col>
            )}
            {tabKey === "2" && (
              <Col span={24}>
                <Typography style={{ paddingBottom: 9 }}>
                  {t("content.69")}
                </Typography>
                <Controller
                  as={<Input size="large" />}
                  name="experiance"
                  control={control}
                />
                {errors.experiance && (
                  <p style={{ color: "red" }}>{errors.experiance.message}</p>
                )}
              </Col>
            )}
            {tabKey === "1" && (
              <Col span={24}>
                <Row gutter={[8, 10]}>
                  <Col span={24} />
                  <Col>
                    <Checkbox
                      onChange={handledChecked}
                      checked={
                        checkedValue
                          ? checkedValue === "Wholesale" && true
                          : the_business[0]?.is_wholesale === true && true
                      }
                      value={"Wholesale"}
                    >
                      Wholesale
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox
                      onChange={handledChecked}
                      checked={
                        checkedValue
                          ? checkedValue === "Retail" && true
                          : the_business[0]?.is_retail === true && true
                      }
                      value={"Retail"}
                    >
                      Retail
                    </Checkbox>
                    {/* {wholesaleRetailRequired && (
                  <p style={{ color: "red" }}>Any one must be checked</p>
                )} */}
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={24}>
              <Row gutter={[20, 0]}>
                <Col>
                  <Button type="primary" htmlType="submit">
                    {!isEditBusinessId ? "Add" : "Edit"}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => closeAddBusinessDrawer()}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </form>
    </Drawer>
  );
};
export default BusinessDrawer;
