import constants from "../Utils/constants";
const initialState = {
  isjourneyloading: false,
  allJourney: [],
  journey: {},
  setjourneyId: "",
  isjourneyByIdLoading: false,
  isUpdatejourneyLoading: false,
  isCreatejourneyLoading: false,
  journeyError: "",
  isUpdateUserjourneyLoading: false,
};

export const journeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_JOURNEY_REQUEST:
      return {
        ...state,
        isjourneyloading: true,
      };
    case constants.GET_JOURNEY_SUCCESS:
      return {
        ...state,
        isjourneyloading: false,
        allJourney: action.payload,
      };
    case constants.GET_JOURNEY_FAILURE:
      return {
        ...state,
        isjourneyloading: false,
      };
    case constants.GET_JOURNEY_BY_ID_REQUEST:
      return {
        ...state,
        isjourneyByIdLoading: true,
      };
    case constants.GET_JOURNEY_BY_ID_SUCCESS:
      return {
        ...state,
        isjourneyByIdLoading: false,
        journey: action.payload,
      };
    case constants.GET_JOURNEY_BY_ID_FAILURE:
      return {
        ...state,
        isjourneyByIdLoading: false,
      };

    // case constants.EMPTY_COMMUNITY_BY_ID_DATA:
    //   return {
    //     ...state,
    //     journey: {},
    //   };
    case constants.UPDATE_JOURNEY_REQUEST:
      return {
        ...state,
        isUpdatejourneyLoading: true,
      };
    case constants.UPDATE_JOURNEY_SUCCESS:
      return {
        ...state,
        isUpdatejourneyLoading: false,
      };
    case constants.UPDATE_JOURNEY_FAILURE:
      return {
        ...state,
        isUpdatejourneyLoading: false,
        journeyError: action.payload?.slug,
      };

    // case constants.UPDATE_USER_COMMUNITY_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateUserjourneyLoading: true,
    //   };
    // case constants.UPDATE_USER_COMMUNITY_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateUserjourneyLoading: false,
    //   };
    // case constants.UPDATE_USER_COMMUNITY_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateUserjourneyLoading: false,
    //   };
    case constants.CREATE_JOURNEY_REQUEST:
      return {
        ...state,
        isCreatejourneyLoading: true,
      };
    case constants.CREATE_JOURNEY_SUCCESS:
      return {
        ...state,
        isCreatejourneyLoading: false,
      };
    case constants.CREATE_JOURNEY_FAILURE:
      return {
        ...state,
        isCreatejourneyLoading: false,
        journeyError: action.payload?.slug,
      };
    case constants.DELETE_JOURNEY_REQUEST:
      return {
        ...state,
        isUpdatejourneyLoading: true,
      };
    case constants.DELETE_JOURNEY_SUCCESS:
      return {
        ...state,
        isUpdatejourneyLoading: false,
      };
    case constants.DELETE_JOURNEY_FAILURE:
      return {
        ...state,
        isUpdatejourneyLoading: false,
      };

    case constants. GET_SELECTED_JOURNEY_ID:
      return {
        ...state,
        setjourneyId: action.payload,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
