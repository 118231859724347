import API from "../Utils/API";
export const getPublicActivityAction = (Page) => (dispatch) => {
  dispatch({ type: "GET_PUBLIC_ACTIVITY_REQUEST" });
  API.get(`/api/public_activity?page=${Page}`)
    .then((response) => {
      dispatch({ type: "GET_PUBLIC_ACTIVITY_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_PUBLIC_ACTIVITY_FAILURE",
      });
    });
};
