import { falseyValueCases } from "../Screens/CommonLogics/CommonMethods";
import API from "../Utils/API";
export const createFamilyMember =
  (data, successCreateMember, IsEmpty, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_FAMILY_MEMBER_REQUEST", payload: data });
    let formData = new FormData();
    if (!IsEmpty) {
      formData.append("user[user_image]", data.user_image);
    }

    formData.append("user[first_name]", data.first_name);
    formData.append("user[birth_date]", data.birth_date);
    formData.append("user[birth_month]", data.birth_month);
    formData.append("user[birth_year]", data.birth_year);

    formData.append("user[education_id]", data.education_id);
    formData.append("user[industry_id]", data.industry_id);

    formData.append("user[gender]", data.gender);
    formData.append("family_id", data.family_id);
    formData.append("user[last_name]", data.last_name);
    formData.append("community_id", data.community_id);

    if (!falseyValueCases(data.phone)) {
      formData.append("user[phone]", data.phone);
    }
    if (!falseyValueCases(data.email)) {
      formData.append("user[email]", data.email);
    }
    formData.append("user[country_code]", data.country_code);
    formData.append("user_type", data.user_type);

    API.post("api/members", formData)

      .then((response) => {
        dispatch({
          type: "CREATE_FAMILY_MEMBER_SUCCESS",
          payload: response.data,
        });
        successCreateMember && successCreateMember();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_FAMILY_MEMBER_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const deleteFamilyMember = (data, deleteCall) => (dispatch) => {
  dispatch({ type: "DELETE_FAMILY_MEMBER_REQUEST" });
  API.delete(`api/members/${data}`)
    .then((response) => {
      dispatch({ type: "DELETE_FAMILY_MEMBER_SUCCESS" });
      deleteCall();
    })
    .catch((error) => {
      dispatch({ type: "DELETE_FAMILY_MEMBER_FAILURE" });
    });
};
export const updateFamilyMember =
  (data, successUpdateMember, IsEmpty, failureUpdateCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_FAMILY_MEMBER_REQUEST" });
    let formData = new FormData();

    if (!IsEmpty) {
      formData.append("user[user_image]", data.user_image);
    }

    formData.append("user[first_name]", data.first_name);
    formData.append("user[birth_date]", data.birth_date);
    formData.append("user[birth_month]", data.birth_month);
    formData.append("user[birth_year]", data.birth_year);
    formData.append("user[education_id]", data.education_id);
    formData.append("user[industry_id]", data.industry_id);
    formData.append("user[gender]", data.gender);
    formData.append("family_id", data.family_id);
    formData.append("user[last_name]", data.last_name);
    if (!falseyValueCases(data.phone)) {
      formData.append("user[phone]", data.phone);
    }
    if (!falseyValueCases(data.email)) {
      formData.append("user[email]", data.email);
    }
    if (!falseyValueCases(data.country_code)) {
      formData.append("user[country_code]", data.country_code);
    }
    API.put(`api/members/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_FAMILY_MEMBER_SUCCESS",
          payload: response.data,
        });
        successUpdateMember && successUpdateMember();
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_FAMILY_MEMBER_FAILURE" });
        failureUpdateCallback && failureUpdateCallback(error.response.data);
      });
  };
