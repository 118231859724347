import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  DatePicker,
  Drawer,
  message,
  Input,
  Select,
} from "antd";
import _ from "lodash";
import moment from "moment";

import { Controller, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import {
  getTemplatesElementsAction,
  createTemplatesElementsAction,
} from "../../../Actions/TemplateElementsAction";
// import { getTemplatesById } from "../../../Actions/TemplatesAction";
import { useDispatch, useSelector } from "react-redux";
import CongratulationsCard from "./CongratulationsCard";
import DeathCard from "./DeathCard";
import NewBirthCard from "./NewBirthCard";
import AchievementsCard from "./AchievementsCard";
import MarriageCard from "./MarriageCard";

const ElementModalForm = (props) => {
  const {
    selectedTemplates,
    // setIsCreateCommunityCardVisible,
    isElementModalVisible,
    setElementIsModalVisible,
  } = props;
  // const [TypedData, setTypedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  // const [communityDetailId, setCommunityDetailId] = useState("");
  const [preloadedDesignationValue, setPreloadedDesignationsValue] =
    useState("");
  // const [editCommunityCardId, setEditCommunityCardId] = useState("");
  const [editId, setEditId] = useState("");

  const dispatch = useDispatch();

  const [OptionSelected, setOptionSelected] = useState(
    selectedTemplates?.template_id
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    // resolver: yupResolver(
    //   OptionSelected === "Video" ? communityCardSchemaNew : communityCardSchema
    // ),
    defaultValues: preloadedDesignationValue,
  });
  const watching = watch();
  const {
    setTempleteElementId,
    setTempleteId,
    allTemplates,
    allTemplatesElements,
  } = useSelector((state) => {
    const { setTempleteId, allTemplates } = state.templates;
    const { setTempleteElementId, allTemplatesElements } =
      state.templatesElements;

    return {
      setTempleteElementId,
      setTempleteId,
      allTemplates,
      allTemplatesElements,
    };
  });
  //   const selectedTemplates = _.filter(
  //     allTemplates.templates,
  //     (Cards) => {
  //       return Cards.id === editCommunityCardId;
  //     }
  //   );
  const handleClose = () => {
    setEditId("");
    setElementIsModalVisible(false);
    setValue("title", "");
    setValue("date", "");
    setValue("element_name", "");
    setValue("element_type", "");
    setValue("description", "");
    clearErrors();
    // setNewFiles([]);
    // setShowUploadImage(false);
    // setIsDefaultImageVisible(true);
  };
  const the_elements = _.filter(
    allTemplatesElements.templatesElements,
    function (o) {
      return o.id === editId;
    }
  );
  useEffect(() => {
    if (editId) {
      setPreloadedDesignationsValue(the_elements[0]);
      reset(the_elements[0]);
    }
  }, [the_elements[0], reset]);

  // useEffect(() => {
  //   dispatch(
  //     getTemplatesElementsAction(
  //       `api/elements?template_id=${setTempleteElementId}&page=${currentPage}`
  //     )
  //   );
  // }, [dispatch, setTempleteElementId, currentPage]);
  // const handleOpenCommunity = (Id) => {
  //   setCommunityDetailId(Id);
  //   setOptionSelected(Id);
  //   //  setSelectedCardId(Id);
  //   dispatch(getTemplatesById(Id));

  //   dispatch({
  //     type: "GET_SELECTED_TEMPLATES_ID",
  //     payload: Id,
  //   });
  // };
  // const templeteelement = allTemplates.templateElements;
  const handleCreateCommunityCardsMethod = (data) => {
    // data.template_id = setTempleteId;
    //  data.template_id = setTempleteElementId;

    // data.template_id=selectedTemplates?.template_id;
    data.expiry_date = moment(Date._d).format("yyyy/MM/DD");
    dispatch(
      createTemplatesElementsAction(
        data,
        createCommunitySuccessCallBackMethod,
        createCommunityFailureCallBackMethod
      )
    );
    // }
  };
  const createCommunitySuccessCallBackMethod = () => {
    setCurrentPage(1);
    setEditId("");
    setElementIsModalVisible(false);
    reset();
    setValue("title", "");
    setValue("date", "");
    setValue("element_name", "");
    setValue("element_type", "");
    setValue("description", "");
    setElementIsModalVisible(false);
    dispatch(
      getTemplatesElementsAction(
        `api/elements?template_id=${setTempleteElementId}&page=${currentPage}`
      )
    );
    // dispatch(
    //     getTemplatesAction(`api/templates?template_id=${setTempleteId}&page=${currentPage}`)
    // );
    // dispatch(
    //     getTemplatesById(process.env.GET_SELECTED_TEMPLATES_ID)
    // );
    clearErrors();
    setOptionSelected("");
    setTimeout(() => {
      message.success("Your community card added successfully");
    }, 1000);
  };
  const createCommunityFailureCallBackMethod = () => {};
  return (
    <Drawer
      open={isElementModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={"50%"}
      className="drawer"
      title={editId ? "Edit Element" : "Add Element"}
    >
      <Row>
        <Col span={11}>
          {/* <Typography className="content">Selected Template : {setTempleteId}</Typography> */}

          <form onSubmit={handleSubmit(handleCreateCommunityCardsMethod)}>
            <Col span={24}>
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    Templete Type
                  </Typography>

                  <Controller
                    name="template_id"
                    as={
                      <Select
                        placeholder="select Template"
                        style={{ width: "100%", borderRadius: "4px" }}
                        showSearch={true}
                        options={
                          allTemplates.templates &&
                          allTemplates.templates?.length > 0 &&
                          allTemplates.templates?.map((val, i) => {
                            return {
                              label: val.template_title,
                              value: val.id,
                            };
                          })
                        }
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        size="large"
                      ></Select>
                    }
                    control={control}
                  />
                </Col>

                <Col
                // span={
                //     setTempleteId === "Marriage" ||
                //     setTempleteId === "New Births"
                //     ? 12
                //     : 24
                // }
                >
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    title
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="title"
                    control={control}
                    // onChange={(e) => console.log({ e })}
                  />
                  {/* {errors.name && (
                          <p style={{ color: "red" }}>{errors.name.message}</p>
                        )} */}
                </Col>
                <Col
                // span={
                //     setTempleteId === "Marriage" ||
                //     setTempleteId === "New Births"
                //     ? 12
                //     : 24
                // }
                >
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    element_name
                  </Typography>
                  <Controller
                    as={<Input className="inputLabel" />}
                    name="element name"
                    control={control}
                    // onChange={(e) => console.log({ e })}
                  />
                  {/* {errors.name && (
                          <p style={{ color: "red" }}>{errors.name.message}</p>
                        )} */}
                </Col>
                {/* {(setTempleteId === "Marriage" ||
                        setTempleteId === "New Births") && ( */}
                <Col span={12}>
                  <Typography
                    className="label"
                    style={{ paddingBottom: "4px" }}
                  >
                    element_type
                  </Typography>
                  <Controller
                    as={
                      <Input
                        className="inputLabel"
                        // onClick={() => handleClearErrorMethod()}
                      />
                    }
                    name="element_type"
                    control={control}
                  />
                  {/* {videoLinkRequired && (
                            <p style={{ color: "red" }}>{videoLinkRequired}</p>
                          )}
                          {(OptionSelected === "Video" ||
                            OptionSelected === "Link") &&
                            isValidUrl === false && (
                              <p style={{ color: "red" }}>Link is not valid</p>
                            )} */}
                </Col>
                {/* )} */}
              </Row>
            </Col>

            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                Date on Card
              </Typography>
              <Controller
                as={
                  <DatePicker
                    placeholder="Select Date"
                    className="inputLabel"
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current < moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                }
                name="date"
                control={control}
                style={{ width: "100%" }}
              />
              {/* {errors.expiry_date && (
                      <p style={{ color: "red" }}>
                        {errors.expiry_date.message}
                      </p>
                    )} */}
            </Col>
            <Col span={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                Card Description
              </Typography>
              <Controller
                as={<Input.TextArea className="inputLabel" />}
                name="description"
                control={control}
              />
              {errors.description && (
                <p style={{ color: "red" }}>{errors.description.message}</p>
              )}
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[10, 0]}>
                <Col>
                  <Button
                    //   onClick={
                    //     isEditCommunityCardVisible
                    //       ? closeEditCommunityCardMethod
                    //       : closeCreateCommunityCardMethod
                    //   }
                    className="allButtonsCancel"
                  >
                    cancel{" "}
                  </Button>
                </Col>
                <Col>
                  <Button htmlType="submit" className="allButtons">
                    Add
                    {/* {isEditCommunityCardVisible ? "Edit" : "Save"} */}
                  </Button>
                </Col>
              </Row>
            </Col>
          </form>
        </Col>
        <Col span={11}>
          {setTempleteId === "Congratulations" ? (
            <Row>
              <h2>Preview of Element card</h2>
              <CongratulationsCard
                mappedData={watching}
                isCommunityCardModal={true}
              />
            </Row>
          ) : setTempleteId === "Marriage" ? (
            <Row>
              <h2>Preview of Element card</h2>
              <MarriageCard mappedData={watching} isCommunityCardModal={true} />
            </Row>
          ) : setTempleteId === "Achievements" ? (
            <Row>
              <h2>Preview of Element card</h2>
              <AchievementsCard
                mappedData={watching}
                isCommunityCardModal={true}
              />
            </Row>
          ) : setTempleteId === "New Births" ? (
            <Row>
              <h2>Preview of Element card</h2>
              <NewBirthCard mappedData={watching} isCommunityCardModal={true} />
            </Row>
          ) : setTempleteId === "Death" ? (
            <Row>
              <h2>Preview of Element card</h2>
              <DeathCard mappedData={watching} isCommunityCardModal={true} />
            </Row>
          ) : null}
        </Col>
      </Row>
    </Drawer>
  );
};

export default ElementModalForm;
