import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin, Empty, Typography } from "antd";
import "../../Events/Events.css";
import Trusteecard from "./Trusteecard";
import { getTrustees } from "../../Actions/trusteesAction";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import TrusteeModal from "./trusteeModal";
import TrusteeEmptyScreen from "../../assets/TrusteeEmptyScreen.svg";
import "./Trusteecard.css";
import { useTranslation } from "react-i18next";
import "../../FindThePeoplePage/FindOtherPeople.css";
import "./../AddFamilymember/Addfamily.css";

import { history } from "../../Utils/history";
import { getCommunityById } from "../../Actions/communitiesAction";

const Trustee = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    allTrustees,
    isGetTrusteesLoading,
    isCreateTrusteesLoading,
    isUpdateTrusteesLoading,

    setCommunityId,
    currentUser,
    community,
  } = useSelector((state) => {
    const {
      allTrustees,
      isGetTrusteesLoading,
      isCreateTrusteesLoading,
      isUpdateTrusteesLoading,
    } = state.trustees;
    const { setCommunityId } = state.communities;
    const { currentUser } = state.authentication;
    const { community } = state.communities;
    return {
      allTrustees,
      isGetTrusteesLoading,
      isCreateTrusteesLoading,
      isUpdateTrusteesLoading,
      setCommunityId,
      currentUser,

      community,
    };
  });

  const isTrusteeVisibleByAdmin = community?.show_trustee;
  const redirectMethod = () => {
    history.push("/addfamily");
  };
  useEffect(() => {
    dispatch(getTrustees(process.env.REACT_APP_COMMUNITY_ID));
    dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
    if (isTrusteeVisibleByAdmin === false) {
      redirectMethod();
    }
  }, [dispatch, isTrusteeVisibleByAdmin]);
  const trustee = _.map(allTrustees, _.property("user"));
  const height = window.innerHeight - 64;
  const [isCreateTrusteeModalVisible, setIsCreateTrusteeModalVisible] =
    useState(false);
  const { users_role , users_types } = currentUser || {};
  
  
  
  
  
  const isUserTrustee = users_types.includes("trustee")
  const isUserAdmin = users_types.includes("admin")
  return (
    <>
      <Spin spinning={isGetTrusteesLoading} size="large">
        <div
          style={{
            height: height,
            overflow: "hidden",
            overflowY: "scroll",
            backgroundColor: "#FFFFFF",
            // margin: "10px",
          }}
          className="customLayout"
        >
          <Row gutter={[48, 40]} 
          // style={{ padding: "20px 20px" }}
          >
            <Col span={24}>
              <Row justify="end">
                <Col>
                  {(isUserTrustee ||
                    isUserAdmin) && (
                    <Button
                      onClick={() => setIsCreateTrusteeModalVisible(true)}
                      // className="buttonsAll"
                      type="primary"
                      style={{ borderRadius: "4px" }}
                      className="button-search"
                    >
                      {t("btn.16")}
                    </Button>
                  )}
                  <TrusteeModal
                    isCreateTrusteeModalVisible={isCreateTrusteeModalVisible}
                    setIsCreateTrusteeModalVisible={
                      setIsCreateTrusteeModalVisible
                    }
                    trustee={trustee}
                    isCreateTrusteesLoading={isCreateTrusteesLoading}
                    setCommunityId={setCommunityId}
                  />
                </Col>
              </Row>
            </Col>
            {allTrustees.length <= 0 ? (
              <>
                <Col span={24} />
                <Col span={24} />
                <Col span={24} />

                <Col span={24}>
                  <Row justify="center">
                    <Empty
                      description={
                        <Typography className="empty-details">
                          {t("emptyScreen.0")}
                        </Typography>
                      }
                      image={TrusteeEmptyScreen}
                      imageStyle={{
                        height: 38,
                      }}
                    />
                  </Row>
                </Col>
              </>
            ) : (
              <>
                {allTrustees &&
                  allTrustees.map((val, i) => {
                    return (
                      <Col xl={12} lg={16} md={16} sm={20} key={i}>
                        <Trusteecard
                          trustee={val?.user}
                          trusteeArray={val}
                          isUpdateTrusteesLoading={isUpdateTrusteesLoading}
                          setCommunityId={setCommunityId}
                          users_role={users_role}
                          i={i}
                          users_types={users_types}
                        />
                      </Col>
                    
                    );
                  })}
              </>
            )}
          </Row>
        </div>
      </Spin>
    </>
  );
};
export default Trustee;
