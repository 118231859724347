import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Button,
  message,
  Pagination,
  Tooltip,
  Popconfirm,
  Avatar,
  Typography,
  Select,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../Utils/history";
import {
  getTemplatesAction,
  updateTemplatesAction,
  deleteTemplatesAction,
} from "../../../Actions/TemplatesAction";
import {
  getTemplatesElementsAction,
} from "../../../Actions/TemplateElementsAction";
import { useForm } from "react-hook-form";
import TemplatesModalForm from "./TemplatesModalForm";
import { getCommunityById } from "../../../Actions/communitiesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Delete from "../../../assets/delete.svg";
import Edit2 from "../../../assets/Edit2.svg";
import ElementModalForm from "./ElementModalForm";
import { ImageEnv } from "../../CommonLogics/CommonMethods";
import ViewElements from "./ViewElements";

const Templates = () => {
  const {
    setTempleteElementId,
    isGetTemplatesLoading,
    setTempleteId,
    allTemplates,
    isCreateTemplatesLoading,
    isUpdateTemplatesLoading,
  } = useSelector((state) => {
    const {
      isGetTemplatesLoading,
      setTempleteId,
      allTemplates,
      isCreateTemplatesLoading,
      isUpdateTemplatesLoading,
    } = state.templates;
    const { setTempleteElementId, allTemplatesElements } =
      state.templatesElements;
    return {
      setTempleteElementId,
      allTemplatesElements,
      isGetTemplatesLoading,
      setTempleteId,
      allTemplates,
      isCreateTemplatesLoading,
      isUpdateTemplatesLoading,
    };
  });
  const { total_length } = allTemplates || {};

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isElementModalVisible, setElementIsModalVisible] = useState(false);

  const [editId, setEditId] = useState("");
  const [educationId, setEducationId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedEducation, setSelectedEducation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [communityDetailId, setCommunityDetailId] = useState("");
  const [editCommunityCardId, setEditCommunityCardId] = useState("");

  
  const selectedTemplates = _.filter(allTemplates.templates, (Cards) => {
    return Cards.id === editCommunityCardId;
  });
  const [OptionSelected, setOptionSelected] = useState(
    selectedTemplates?.template_type
  );
  const dispatch = useDispatch();
  const { reset } = useForm({});
  let UnpublishData = {};
  let PublishData = {};

  useEffect(() => {
    dispatch(
      getTemplatesElementsAction(
        `api/elements?template_id=${setTempleteElementId}&page=${currentPage}`
      )
    );
    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&template_type=${setTempleteId}`
      )
    );
  }, [currentPage, setTempleteId, setTempleteElementId]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const onClickEducation = () => {
    setSelectedEducation("");
    setValName("");
    reset();
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteEducations = (Id) => {
    dispatch(deleteTemplatesAction(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success("Your Template Deleted Successfully");
    }, 1000);
    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&template_type=${setTempleteId}`
      )
    );
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  // const publishedTemplates = _.filter(allTemplates.templates, (val) => {
  //   return val.published === true;
  // });
  

  // const UnpublishedTemplates = _.filter(allTemplates.templates, (val) => {
  //   return val.published === false;
  // });
  // const handleUnpublishEducationMethod2 = (Id) => {
  //   UnpublishedTemplates.id = Id;
  //   dispatch(
  //     getTemplatesAction(
  //       `api/templates?page=${currentPage}&template_type=${setTempleteId}`
  //     )
  //   );
  // };
  const handlePublishEducationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateTemplatesAction(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishEducationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateTemplatesAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Templates Published Successfully");
    }, 1000);
    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&published=false&template_type=${setTempleteId}`
      )
    );
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Templates Unpublished Successfully");
    }, 1000);
    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&published=false&template_type=${setTempleteId}`
      )
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const templeteArray = [
    { label: "Congratulations", value: "Congratulations" },
    { label: "Marriage", value: "Marriage" },
    { label: "Achievements", value: "Achievements" },
    { label: "New Births", value: "New Births" },
    { label: "Death", value: "Death" },
  ];
  //   const the_name = _.filter(allEducations.educations, function (o) {
  //     return o.id === valName;
  //   });
  //   const the_elements = _.filter(allTemplates.templates, function (o) {
  //     return o.id === valName;
  //   });
  const handleOpenCommunity = (Id) => {
    setCommunityDetailId(Id);
    setOptionSelected(Id);
    //  setSelectedCardId(Id);
    // dispatch(getTemplatesById(Id));

    dispatch({
      type: "GET_SELECTED_TEMPLATES_ID",
      payload: Id,
    });
  };
  // const handleOpenTemplateElement = (Id) => {
  //   // setCommunityDetailId(Id);
  //   // setOptionSelected(Id);
  //   setElementIsModalVisible(true);
  //   //  setSelectedCardId(Id);
  //   // dispatch(getTemplatesById(Id));

  //   dispatch({
  //     type: "GET_SELECTED_TEMPLATES_ID",
  //     payload: Id,
  //   });
  // };
  const handleViewElement = (Id) => {
    setEditId(Id);
    dispatch(
      getTemplatesElementsAction(
        `api/elements?template_id=${setTempleteElementId}&page=${currentPage}`
      )
    );
    dispatch(getTemplatesElementsAction(Id));
    dispatch({
      type: "GET_SELECTED_ELEMENTS_TEMPLATES_ID",
      payload: Id,
    });
  };
  const Columns = [
    {
      title: "Image",
      dataIndex: "template_image",
      width: "10%",
      render: (id, { template_image, template_type }) => {
        return (
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Avatar
                shape="square"
                size={45}
                className="avtar"
                src={template_image && ImageEnv(template_image)}
              >
                <Typography style={{ fontSize: "20px", color: "white" }}>
                  {template_type?.[0]}
                </Typography>
              </Avatar>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Template Name",
      dataIndex: "template_title",
      width: "20%",
      sorter: (a, b) => a.template_title.localeCompare(b.template_title),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Template Type",
      dataIndex: "template_type",
      width: "20%",
      sorter: (a, b) => a.template_type.localeCompare(b.template_type),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Status",
      dataIndex: "id",
      width: "30%",
      sorter: (a, b) => a.id.localeCompare(b.id),
      sortDirections: ["descend", "ascend"],
      render: (id, { ticket_status }) => {
        return (
          <Row gutter={[16, 16]}>
            <Col>
              <Button onClick={() => handleViewElement(id)}>
                View Elements
              </Button>
            </Col>
            <Col>
              <Button onClick={() => setElementIsModalVisible(true)}>
                Add Elements
              </Button>
              <ElementModalForm
                isElementModalVisible={isElementModalVisible}
                setElementIsModalVisible={setElementIsModalVisible}
              ></ElementModalForm>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "20%",
      render: (id, { published }) => {
        return (
          <Row justify="space-around">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} alt="" />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
              {/* <EditColumnAction id={id} customMethod={handleEdit} /> */}
            </Col>
            <Col>
              {published ? (
                <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handlePublishEducationMethod(id)}
                    icon={<img src={PublishedIcon} alt=""  />}
                    type="text"
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handleUnpublishEducationMethod(id)}
                    icon={<img src={UnpublishedIcon} alt="" />}
                    type="text"
                  ></Button>
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteEducations(id)}
                  okText="Ok"
                  cancelText="Cancel"
                >
                  <Button icon={<img src={Delete} alt="" />} type="text" />
                </Popconfirm>
              </Tooltip>
              {/* <DeleteColumnAction
                id={id}
                actionArray={[deleteUserEducationAction]}
                customMethod={successDelete}
              /> */}
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isGetTemplatesLoading}>
      {editId ? (
        <ViewElements editId={editId} setEditId={setEditId} />
      ) : (
        <Row gutter={[32, 32]}  className="community">
          <Col span={24}></Col>
          <Col xl={16} lg={16} md={16} sm={20} xs={20}>
            <Breadcrumb 
            // style={{ margin: 10 }}
            >
              <Breadcrumb.Item
                onClick={() => onClickCommunity()}
                className="breadcrumCommunity"
              >
                Communities
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => onClickEducation()}
                className="breadcrumUsers"
              >
                Templates
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.name}
            </Breadcrumb.Item> */}
            </Breadcrumb>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Row justify="end" gutter={[8, 8]}>
              <Col xl={0} lg={0} md={0} sm={6} xs={6}></Col>
              {/* <Col xl={12} lg={12} md={12} sm={9} xs={9}>
            <Controller
              name="user_type"
              as={
                <Select
                  placeholder="Search Educations"
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="inputLabel"
                  showSearch={true}
                  options={
                    allEducations &&
                    total_length > 0 &&
                    allEducations.educations.map((val, i) => {
                      return {
                        label: val.name,
                        value: val.id,
                      };
                    })
                  }
                  onSelect={(Id) => handleOpenEducation(Id)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                ></Select>
              }
              control={control}
              className="inputLabel"
            />
          </Col> */}
              <Col xl={12} lg={12} md={18} sm={24} xs={24}>
                <Button
                  className="editDesignationButton"
                  style={{ width: "100%" }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Add Template
                </Button>
              </Col>
            </Row>
            <Spin
              spinning={
                editId ? isUpdateTemplatesLoading : isCreateTemplatesLoading
              }
              size="large"
            >
              <TemplatesModalForm
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                editId={editId}
                setEditId={setEditId}
                educationId={educationId}
                setEducationId={setEducationId}
                setValName={setValName}
                valName={valName}
                setSelectedEducation={setSelectedEducation}
                selectedEducation={selectedEducation}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setIsDefaultImageVisible={setIsDefaultImageVisible}
                isDefaultImageVisible={isDefaultImageVisible}
                setShowUploadImage={setShowUploadImage}
                showUploadImage={showUploadImage}
              ></TemplatesModalForm>
            </Spin>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Col></Col>
            </Row>
            <Row gutter={[32, 32]} justify="space-between">
              <Col xl={4} lg={4} md={4} sm={24} xs={24}>
                <Select
                  placeholder="Select Template"
                  name="template_type"
                  // style={{ width: "100%" }}
                  onSelect={(id) => handleOpenCommunity(id)}
                  size="large"
                  options={templeteArray}
                />
              </Col>
             
              <Col xl={12} lg={12} md={12} sm={24} xs={24}></Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <CustomTable
                  columns={Columns}
                  data={allTemplates?.templates}
                  // data={filteredAllBusinessRecords}
                  // data={  tabKey === "1" ? allTemplates?.templates : tabKey === "2" ? publishedTemplates : tabKey === "3" ? UnpublishedTemplates : []}

                  // data={  tabKey === "1" ? allTemplates?.templates : filteredAllBusinessRecords}
                />
              </Col>
            </Row>
          </Col>
          {allTemplates && total_length > 0 ? (
          <Col span={24}>
            <Row justify="center" 
            // style={{ margin: "20px 0px" }}
            >
              <Pagination
                total={allTemplates?.total_length}
                onChange={(e) => setCurrentPage(e)}
                responsive={true}
                size="large"
                current={currentPage}
                showSizeChanger={false}
              />
            </Row>
          </Col>
          ):""}
          {/* <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <EducationTable
          setIsModalVisible={setIsModalVisible}
          allEducations={allEducations}
          editId={editId}
          setEditId={setEditId}
          viewEducationDetails={viewEducationDetails}
          setViewEducationDetails={setViewEducationDetails}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setEducationId={setEducationId}
          setDesinationsDetailId={setDesinationsDetailId}
        />
      </Col> */}
        </Row>
      )}
    </Spin>
  );
};

export default Templates;
