import constants from "../Utils/constants";
const initialState = {
  currentFamilyId: null,
  isCreateFamilyLoading: false,
  isFamiliesLoading: false,
  isDeleteFamilyLoading: false,
  isUpdateFamilyLoading: false,
  allFamilies: [],
  setEducationId: "",
  isGetFamilyByIdLoading: false,
  Family: {},
  isCreateAddMemberLoading: false,
};
export const familyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_FAMILY_REQUEST:
      return {
        ...state,
        isCreateFamilyLoading: true,
      };
    case constants.CREATE_FAMILY_SUCCESS:
      return {
        ...state,
        isCreateFamilyLoading: false,
      };
    case constants.CREATE_FAMILY_FAILURE:
      return {
        ...state,
        isCreateFamilyLoading: false,
      };
    case constants.GET_FAMILIES_REQUEST:
      return {
        ...state,
        isFamiliesLoading: true,
      };
    case constants.GET_FAMILIES_SUCCESS:
      return {
        ...state,
        isFamiliesLoading: false,
        allFamilies: action.payload,
      };
    case constants.GET_FAMILIES_FAILURE:
      return {
        ...state,
        isFamiliesLoading: false,
      };

    case constants.GET_FAMILY_BY_ID_REQUEST:
      return {
        ...state,
        isGetFamilyByIdLoading: true,
        Family: {},
      };
    case constants.GET_FAMILY_BY_ID_SUCCESS:
      return {
        ...state,
        isGetFamilyByIdLoading: false,
        Family: action.payload,
      };
    case constants.GET_FAMILY_BY_ID_FAILURE:
      return {
        ...state,
        isGetFamilyByIdLoading: false,
      };

    case constants.DELETE_FAMILIES_REQUEST:
      return {
        ...state,
        isDeleteFamilyLoading: true,
      };
    case constants.DELETE_FAMILIES_SUCCESS:
      return {
        ...state,
        isDeleteFamilyLoading: false,
      };
    case constants.DELETE_FAMILIES_FAILURE:
      return {
        ...state,
        isDeleteFamilyLoading: false,
      };
    case constants.UPDATE_FAMILIES_REQUEST:
      return {
        ...state,
        isUpdateFamilyLoading: true,
      };
    case constants.UPDATE_FAMILIES_SUCCESS:
      return {
        ...state,
        isUpdateFamilyLoading: false,
      };
    case constants.UPDATE_FAMILIES_FAILURE:
      return {
        ...state,
        isUpdateFamilyLoading: false,
      };
    case constants.CREATE_ADD_MEMBER_REQUEST:
      return {
        ...state,
        isCreateAddMemberLoading: true,
      };
    case constants.CREATE_ADD_MEMBER_SUCCESS:
      return {
        ...state,
        isCreateAddMemberLoading: false,
      };
    case constants.CREATE_ADD_MEMBER_FAILURE:
      return {
        ...state,
        isCreateAddMemberLoading: false,
      };
    case "SET_CURRENT_FAMILY_ID":
      return {
        ...state,
        currentFamilyId: action.payload.currentFamilyId,
      };
    case constants.GET_SELECTED_FAMILY_ID:
      return {
        ...state,
        setEducationId: action.payload,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
