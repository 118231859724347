import API from "../Utils/API";
export const getEducationsAction = (url) => (dispatch) => {
  dispatch({ type: "GET_EDUCATIONS_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_EDUCATIONS_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_EDUCATIONS_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getEducationById = (Id) => (dispatch) => {
  dispatch({ type: "GET_EDUCATION_BY_ID_REQUEST" });
  API.get(`api/educations/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_EDUCATION_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_EDUCATION_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const createEducationAction = (data, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "CREATE_EDUCATIONS_REQUEST", payload: data });

  API.post("api/educations", data)
    .then((response) => {
      dispatch({ type: "CREATE_EDUCATIONS_SUCCESS", payload: response.data });
      successCallback && successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "CREATE_EDUCATIONS_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);

    });
};

export const updateEducationAction = (data, successCallback,failureCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_EDUCATIONS_REQUEST" });
  API.put(`api/educations/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_EDUCATIONS_SUCCESS", payload: response.data });
      successCallback();
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_EDUCATIONS_FAILURE",
        payload: error.response.data,
      });
      failureCallback && failureCallback(error.response.data);

    });
};


export const deleteUserEducationAction =(Id, successCallback,failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_EDUCATIONS_REQUEST" });
    API.delete(`api/educations/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_EDUCATIONS_SUCCESS",
          payload: response.data,
        });
        // dispatch(getEducationsAction());
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_EDUCATIONS_FAILURE",
          payload: error.response.data,
        });
      failureCallback();
        
      });
  };
