import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Button,
  Empty,
  Spin,
  Tooltip,
  message,
  Popconfirm,
} from "antd";
import "../FindOtherPeople.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteBusiness, getBusiness } from "../../Actions/businessAction";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from "react-hook-form";
import { history } from "../../Utils/history";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import BusinessEmpty from "../../assets/BusinessEmpty.svg";
import { useTranslation } from "react-i18next";
import { getCommunityById } from "../../Actions/communitiesAction";
// const userSchema = yup.object().shape({});

const ViewBusinessProfile = (props) => {
  const { t } = useTranslation();
  const {
    setEditId,
    setVisible,
    setViewBusinessProfileId,
    business_profiles,
    isPeopleDetailsVisible,
    setSelectedBusinessProfileId,
    setIsSelectedBusinessProfileVisible,
  } = props;
  const { allBusiness, isBusinessLoading, community } = useSelector((state) => {
    const { allBusiness, isBusinessLoading } = state.business;
    const { community } = state.communities;

    return {
      allBusiness,
      isBusinessLoading,
      community,
    };
  });

  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [businessForm, setBusinessForm] = useState(true);
  const handleEdit = (val) => {
    setEditId(val);
    // setIsModalVisible(true);
    // setBusinessForm(true);
    setVisible(true);
  };

  // const {} = useForm({
  //   resolver: yupResolver(userSchema),
  // });
  const handleDeleteBusinessMethod = (businessId) => {
    dispatch(deleteBusiness(businessId, successDeleteBusinessMethod));
  };
  const successDeleteBusinessMethod = () => {
    setTimeout(() => {
      message.success("Your Business Profile Deleted Successfully");
    }, 1000);
  };
  const dispatch = useDispatch();
  const isBusinessProfileVisibleByAdmin = community?.show_business_profile;
  const redirectMethod = () => {
    history.push("/addfamily");
  };
  useEffect(() => {
    dispatch(getBusiness());
    dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
    if (isBusinessProfileVisibleByAdmin === false) {
      redirectMethod();
    }
  }, [dispatch, isBusinessProfileVisibleByAdmin]);

  const handleClick = (businessProfileId) => {
    if (isPeopleDetailsVisible) {
      setSelectedBusinessProfileId(businessProfileId);

      setIsSelectedBusinessProfileVisible(true);
    } else {
      setViewBusinessProfileId(businessProfileId);
    }
  };
  const allBusinessData = allBusiness.filter((o) => {
    return o.is_business === true;
  });
  return (
    <Spin spinning={!isPeopleDetailsVisible && isBusinessLoading} size="large">
      {allBusiness && allBusinessData.length > 0 ? (
        <Row gutter={[32, 32]}>
          {isPeopleDetailsVisible
            
            
            
            ? business_profiles?.map((val, i) => {
                return (
                  <Col xl={12} lg={17} md={16} sm={21} xs={24} key={i}>
                    <Card className="MatchesCard">
                      <Row gutter={[0, 16]}>
                        <Col xl={20} lg={24} md={18} sm={18} xs={24}>
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <Typography className="profile-name">
                                {val?.company_name}
                              </Typography>
                            </Col>
                            <Col>
                              <Typography className="BusinessEstablishedDate">
                                {t("content.53")}:{" "}
                                <Typography
                                  component="span"
                                  className="BusinessEstablishedDateData"
                                >
                                  10 the December 1991
                                </Typography>
                              </Typography>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={6}
                    style={{ textAlign: "end" }}
                  >
                    <Tooltip title={t("btn.27")}>
                      <Button
                        icon={<EditOutlined />}
                        type="text"
                        onClick={() => handleEdit(val.id)}
                      />
                    </Tooltip>
                    <Tooltip title={t("btn.28")}>
                      <Popconfirm
                        title={t("btn.29")}
                        onConfirm={() => handleDeleteBusinessMethod(val.id)}
                        okText={t("btn.28")}
                        cancelText={t("btn.19")}
                      >
                        <Button icon={<DeleteOutlined />} type="text" />
                      </Popconfirm>
                    </Tooltip>
                  </Col> */}
                      </Row>
                      <Row gutter={[0, 16]}>
                        <Divider className="JobProfileCardDivider" />
                      </Row>
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Typography className="profile-address">
                            {val.business_location}
                          </Typography>
                        </Col>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{ textAlign: "end" }}
                        >
                          <Button
                            className=" View-fullProfile"
                            onClick={() => handleClick(val.id)}
                          >
                            {t("btn.26")}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })
            : allBusinessData?.map((val, i) => {
                return (
                  <Col xl={12} lg={12} md={18} sm={20} xs={24} key={i}>
                    <Card className="MatchesCard">
                      <Row gutter={[0, 4]}>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Row gutter={[16, 16]} justify="space-between">
                            <Col>
                              <Typography className="profile-name">
                                {val?.company_name}
                              </Typography>
                            </Col>
                            <Col style={{ textAlign: "end" }}>
                              <Tooltip title={t("btn.27")}>
                                <Button
                                  icon={<EditOutlined />}
                                  type="text"
                                  onClick={() => handleEdit(val.id)}
                                />
                              </Tooltip>
                              <Tooltip title={t("btn.28")}>
                                <Popconfirm
                                  title={t("btn.29")}
                                  onConfirm={() =>
                                    handleDeleteBusinessMethod(val.id)
                                  }
                                  okText={t("btn.28")}
                                  cancelText={t("btn.19")}
                                  placement="topLeft"
                                >
                                  <Button
                                    icon={<DeleteOutlined />}
                                    type="text"
                                  />
                                </Popconfirm>
                              </Tooltip>
                            </Col>
                            {/* <Col span={24}>
                              <Typography className="BusinessEstablishedDate">
                                {t("content.53")}:{" "}
                                <Typography
                                  component="span"
                                  className="BusinessEstablishedDateData"
                                >
                                  10 the December 1991
                                </Typography>
                              </Typography>
                            </Col> */}
                          </Row>
                        </Col>
                        {/* <Col
                          xl={4}
                          lg={5}
                          md={6}
                          sm={6}
                          xs={6}
                          style={{ textAlign: "end" }}
                        >
                          <Tooltip title={t("btn.27")}>
                            <Button
                              icon={<EditOutlined />}
                              type="text"
                              onClick={() => handleEdit(val.id)}
                            />
                          </Tooltip>
                          <Tooltip title={t("btn.28")}>
                            <Popconfirm
                              title={t("btn.29")}
                              onConfirm={() =>
                                handleDeleteBusinessMethod(val.id)
                              }
                              okText={t("btn.28")}
                              cancelText={t("btn.19")}
                              placement="topLeft"
                            >
                              <Button icon={<DeleteOutlined />} type="text" />
                            </Popconfirm>
                          </Tooltip>
                        </Col> */}
                        <Col span={24}>
                          <Typography className="BusinessEstablishedDate">
                            Established Since:&nbsp;
                            <Typography
                              component="span"
                              className="BusinessEstablishedDateData"
                            >
                              {val?.working_here_since}
                            </Typography>
                          </Typography>
                        </Col>
                      </Row>
                      {/* <Row gutter={[0, 16]}> */}
                      <Divider className="JobProfileCardDivider" />

                      {/* </Row> */}
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <Typography className="profile-address">
                            {val.business_location}
                          </Typography>
                        </Col>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          style={{ textAlign: "end" }}
                        >
                          <Button
                            className=" View-fullProfile"
                            onClick={() => handleClick(val.id)}
                          >
                            {t("btn.26")}
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                );
              })}
        </Row>
      ) : (
        <>
          {!isPeopleDetailsVisible && (
            <Row justify="center" style={{ paddingTop: "150px" }}>
              <Col>
                <Empty
                  image={BusinessEmpty}
                  imageStyle={{
                    height: 30,
                  }}
                  description={
                    <span className="empty-details">{t("emptyScreen.4")}</span>
                  }
                ></Empty>
              </Col>
            </Row>
          )}
        </>
      )}
    </Spin>
  );
};
export default ViewBusinessProfile;
