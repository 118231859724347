import API from "../Utils/API";
export const getJourney = (url) => (dispatch) => {
  dispatch({ type: "GET_JOURNEY_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_JOURNEY_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_JOURNEY_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getJourneyById = (Id) => (dispatch) => {
  dispatch({ type: "GET_JOURNEY_BY_ID_REQUEST" });
  API.get(`api/journeys/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_JOURNEY_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_JOURNEY_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};

export const createJourneyAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "CREATE_JOURNEY_REQUEST" });
    let formData = new FormData();
    formData.append("journey[title]", data.title);
    formData.append("journey[url]", data.url);
    formData.append("journey[description]", data.description);
    formData.append("journey[published]", data.published);
    {
      IsEmpty
        ? formData.append("journey_image", data.journey_image)
        : formData.append("journey[journey_image]", data.journey_image);
    }
    API.post("api/journeys", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_JOURNEY_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_JOURNEY_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateJourneyAction =
  (data, successCallback, failureCallback, IsEmpty) => (dispatch) => {
    dispatch({ type: "UPDATE_JOURNEY_REQUEST" });
    let formData = new FormData();
    formData.append("journey[title]", data.title);
    formData.append("journey[url]", data.url);
    formData.append("journey[description]", data.description);
    formData.append("journey[published]", data.published);
    {
      IsEmpty
        ? formData.append("journey_image", data.journey_image)
        : formData.append("journey[journey_image]", data.journey_image);
    }
    API.put(`api/journeys/${data.id}`, formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_JOURNEY_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_JOURNEY_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const deleteJourney =
  (Id, successDeleteCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_JOURNEY_REQUEST" });
    API.delete(`api/journeys/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_JOURNEY_SUCCESS",
          payload: response.data,
        });
        dispatch(getJourney());
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_JOURNEY_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };

export const updatePublishJourney = (data, successCallback) => (dispatch) => {
  dispatch({ type: "UPDATE_PUBLISH_JOURNEY_REQUEST" });

  API.put(`/api/published_community/${data.id}`, data)
    .then((response) => {
      dispatch({
        type: "UPDATE_PUBLISH_JOURNEY_SUCCESS",
        payload: response.data,
      });
      successCallback(response.data);
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_PUBLISH_JOURNEY_FAILURE",
        payload: error.response.data,
      });
      successCallback();
    });
};

export const updateUserCommunityAction =
  (data, successCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_USER_JOURNEY_REQUEST" });

    API.put("/api/change_user_community/", data)
      .then((response) => {
        dispatch({
          type: "UPDATE_USER_JOURNEY_SUCCESS",
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_USER_JOURNEY_FAILURE",
          payload: error.response.data,
        });
      });
  };
