import jwtDecode from "jwt-decode";
import moment from "moment";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export const phoneFormatterAccordingToCountryCode = (
  countryFormat,
  phoneNumberArray
) => {
  var pattern = /[.](?!\d*$.)/g;
  var replacement = " ";
  return countryFormat.replace(pattern, function (match) {
    replacement = phoneNumberArray.shift();
    if (typeof replacement == "number" || typeof replacement == "string") {
      return replacement;
    }
    return "";
  });
};
const text = "(...) ... ....";
const blankData = ["7", "8", "1", "9", "9", "1", "2", "5", "1", "9"];
// output: And also an array like this

export const CheckTokenExpired = () => {
  const token = localStorage.getItem("access_token");
  const decodedToken = token ? jwtDecode(token) : null;

  const newDate = new Date(decodedToken.exp * 1000);
  const formattedNewDate = moment(newDate).format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");
  const differenceDate = moment(currentDate).diff(formattedNewDate, "days");
  const isTokenExpired = differenceDate > 0;

  return isTokenExpired;
};

export const CountryViseFormat = (CountryNumber) => {
  const phoneNumber = parsePhoneNumber(CountryNumber);
  if (isValidPhoneNumber(CountryNumber)) {
    return phoneNumber.formatInternational();
  } else if (phoneNumber.isValid()) {
    return phoneNumber.formatInternational();
  } else {
    return CountryNumber;
  }
};

export const falseyValueCases = (data) => {
  switch (data) {
    case null:
      return true;
    case "null":
      return true;
    case "":
      return true;
    case undefined:
      return true;
    case "undefined":
      return true;
    case NaN:
      return true;
    case "NaN":
      return true;
    default:
      return false;
  }
};

export const ImageEnv = (image) => {
  const image_url =
    process.env.REACT_APP_ENV === "production"
      ? `${process.env.REACT_APP_PROD_IMAGE_URL}/${image}`
      : `${process.env.REACT_APP_DEV_IMAGE_URL}/${image}`;
  return image_url;
};
