import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Spin,
  Pagination,
  Tooltip,
  Popconfirm,
  message,
  Typography,
} from "antd";
import _ from "lodash";
import CommunityTrusteeModalForm from "./CommunityTrusteeModalForm";
import { history } from "../../../Utils/history";
import { useDispatch, useSelector } from "react-redux";
import { getCommunityById } from "../../../Actions/communitiesAction";
import { getSearchUser } from "../../../Actions/searchUserAction";
import { getDesignations } from "../../../Actions/designationsAction";
import { useForm } from "react-hook-form";
import { getTrustees } from "../../../Actions/trusteesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import Edit2 from "../../../assets/Edit2.svg";
import Delete from "../../../assets/delete.svg";
import { deleteTrustee, getTrusteeById } from "../../../Actions/trusteesAction";

const CommunityTrustee = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState("");
  const [trusteeId, setTrusteeId] = useState("");
  const [valName, setValName] = useState("");
  const [selectedTrustee, setSelectedTrustee] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUsersDrawerVisible, setIsUsersDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const {
    setCommunityId,
    allTrustees,
    isUpdateTrusteesLoading,
    isCreateTrusteesLoading,
    isGetTrusteesLoading,
  } = useSelector((state) => {
    const { setCommunityId } = state.communities;
    const { searchUsers } = state.searchUser;
    const {
      allTrustees,
      isUpdateTrusteesLoading,
      isCreateTrusteesLoading,
      isGetTrusteesLoading,
    } = state.trustees;
    return {
      isUpdateTrusteesLoading,
      isCreateTrusteesLoading,
      isGetTrusteesLoading,
      setCommunityId,
      searchUsers,
      allTrustees,
    };
  });
  const { total_length } = allTrustees || {};

  const { reset } = useForm({});
  const openEditDrawer = (Id) => {
    setIsUsersDrawerVisible(true);
    setEditId(Id);
    // history.push(`/communitytrustee/${Id}`, { Id: Id });
    history.push(`/users/${Id}`, { id: Id });
    // history.push(`/communities/users/${Id}`, { id: Id });
  };
  useEffect(() => {
    dispatch(
      getSearchUser(
        `api/user_search?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    dispatch(
      getTrustees(
        `api/community_trustees?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
    dispatch(
      getDesignations(
        `api/designations?community_id=${setCommunityId}&page=${currentPage}`
      )
    );
  }, [dispatch, setCommunityId, currentPage]);
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };

  const handleOpenCommunity2 = (Id) => {
    setValName(Id);
    setSelectedTrustee(true);
  };
  const the_name = _.filter(allTrustees.community_trustees, function (o) {
    return o.id === valName;
  });

  const openIndustry = () => {
    setIsModalVisible(true);
    dispatch(getSearchUser(`api/user_search?community_id=${setCommunityId}`));
    dispatch(
      getDesignations(`api/designations?community_id=${setCommunityId}`)
    );
  };

  const onClickTrustee = () => {
    setSelectedTrustee("");
    setValName("");
    reset();
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleDeleteTrustee = (Id) => {
    dispatch(deleteTrustee(Id, successDelete, failureDelete));
  };
  const successDelete = () => {
    dispatch(
      getTrustees(`api/community_trustees?community_id=${setCommunityId}`)
    );
    setTimeout(() => {
      message.success("Your Trustee Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleOpenCommunity = (Id) => {
    setTrusteeId(Id);
    dispatch(getTrusteeById(Id));
    dispatch({
      type: "GET_SELECTED_TRUSTEES_ID",
      payload: Id,
    });
  };
  const Columns = [
    {
      title: " User Name",
      dataIndex: "user",
      width: "15%",
      render: (Id, { id, user }) => {
        // return user?.first_name;
        return (
          <Row>
            <Col xl={16} lg={16} md={16} sm={24} xs={24}>
              <Tooltip title="View Profile">
                <Typography
                  // className="community-Title"
                  onClick={() => openEditDrawer(user.id)}
                  // onClick={() => handleOpenCommunity(id)}
                >
                  {user?.first_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
      sorter: (a, b) => a.user?.first_name.localeCompare(b.user?.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: " Designation",
      dataIndex: "designation",
      width: "15%",
      render: (designation) => {
        return designation?.designation_title;
      },
      sorter: (a, b) =>
        a.designation?.designation_title.localeCompare(
          b.designation?.designation_title
        ),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: " From Year",
      dataIndex: "from_year",
      width: "15%",
      sorter: (a, b) => a.from_year.localeCompare(b.from_year),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: " To Year",
      dataIndex: "to_year",
      width: "15%",
      sorter: (a, b) => a.to_year.localeCompare(b.to_year),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Action",
      dataIndex: "id",
      width: "10%",
      render: (id) => {
        return (
          <Row justify="space-between">
            <Col>
              <Tooltip title="Edit">
                <Button
                  icon={<img src={Edit2} />}
                  type="text"
                  onClick={() => handleEdit(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() => handleDeleteTrustee(id)}
                  okText="Ok"
                  cancelText="cancel"
                >
                  <Button icon={<img src={Delete} />} type="text" />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isGetTrusteesLoading}>
      <Row gutter={[32, 32]} 
      className="community"
      // style={{ margin: 10 }}
      >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => onClickTrustee()}
              className="breadcrumUsers"
            >
              Trustee
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {the_name[0]?.user.first_name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
          <Row justify="end" gutter={[8, 8]}>
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                style={{ width: "100%" }}
                onClick={() => openIndustry()}
              >
                Add Trustee
              </Button>
            </Col>
          </Row>

          <Spin
            spinning={
              editId ? isUpdateTrusteesLoading : isCreateTrusteesLoading
            }
            size="large"
          >
            <CommunityTrusteeModalForm
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              trusteeId={trusteeId}
              setTrusteeId={setTrusteeId}
              setValName={setValName}
              valName={valName}
              setSelectedTrustee={setSelectedTrustee}
              selectedTrustee={selectedTrustee}
              isModalVisible={isModalVisible}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></CommunityTrusteeModalForm>
          </Spin>
        </Col>

        <Col span={24}>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <CustomTable
                columns={Columns}
                data={allTrustees?.community_trustees}
              />
            </Col>
          </Row>
        </Col>
         {allTrustees && total_length > 0 ? (
        <Col span={24}>
          <Row justify="center" 
          // style={{ margin: "20px 0px" }}
          >
            <Pagination
              total={allTrustees?.total_length}
              onChange={(e) => setCurrentPage(e)}
              responsive={true}
              size="large"
              current={currentPage}
              showSizeChanger={false}
            />
          </Row>
        </Col>
         ):""}
      </Row>
    </Spin>
  );
};

export default CommunityTrustee;
