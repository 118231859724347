import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./FindOtherPeople.css";
import { Table, Typography, Row, Col, Button, AutoComplete, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getSearchUser } from "../Actions/searchUserAction";
import "../Events/Events.css";
import ViewMemberDetails from "../Screens/AddFamilymember/familyDetails";
import { useTranslation } from "react-i18next";
import "../Screens/AddFamilymember/Addfamily.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getCommunityById } from "../Actions/communitiesAction";

import { history } from "../Utils/history";
const PeopleTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPeopleDetailsVisible, setIsPeopleDetailsVisible] = useState(false);
  const [peopleDetailsId, setPeopleDetailsId] = useState("");
  const searchDependancy = firstName !== "" || lastName !== "";
  const { searchUsers, isSearchUserLoading, community } = useSelector(
    (state) => {
      const { searchUsers, isSearchUserLoading } = state.searchUser;

      const { community } = state.communities;

      return {
        searchUsers,
        isSearchUserLoading,
        community,
      };
    }
  );
  const isFindOtherPeopleVisibleByAdmin = community?.show_find_other_people;
  const redirectMethod = () => {
    history.push("/addfamily");
  };
  useEffect(() => {
    dispatch(getSearchUser(`api/user_search`));
    dispatch(getCommunityById(process.env.REACT_APP_COMMUNITY_ID));
    if (isFindOtherPeopleVisibleByAdmin === false) {
      redirectMethod();
    }
  }, [
    searchDependancy,
    peopleDetailsId,
    dispatch,
    isFindOtherPeopleVisibleByAdmin,
  ]);

  const Firstname = _.capitalize(firstName && firstName);
  const Lastname = _.capitalize(lastName && lastName);
  const peopleDetail = _.filter(searchUsers, (users) => {
    return users.id === peopleDetailsId;
  });
  const filteredUsers = searchUsers?.filter((val) => {
    return val.native !== null;
  });
  const columns = [
    {
      title: t("content.15"),
      dataIndex: "first_name",
      width: "20%",
    },

    {
      title: t("placeholder.5"),
      dataIndex: "last_name",
      width: "10em",
    },

    {
      title: t("content.151"),
      dataIndex: "native",
      width: "20%",
      filters: _.uniqBy(filteredUsers, (users) => {
        return users.native;
      })?.map((val) => {
        return (
          val.native !== null && {
            text: val?.native,
            value: val?.native,
          }
        );
      }),
      onFilter: (value, record) => record?.native?.includes(value),
    },
    {
      title: t("content.17"),
      dataIndex: "phone",
      width: "12em",
    },
    {
      // title: 'Action',
      dataIndex: "id",
      render: (id) => (
        <ActionComponent
          id={id}
          setIsPeopleDetailsVisible={setIsPeopleDetailsVisible}
          setPeopleDetailsId={setPeopleDetailsId}
          isPeopleDetailsVisible={isPeopleDetailsVisible}
        />
      ),
      width: "3em",
    },
  ];

  // })
  // }

  const height = window.innerHeight - 64;
  const SearchUsersByFirstName = _.uniqBy(searchUsers, (users) => {
    return users.first_name;
  });
  const SearchFirstName = SearchUsersByFirstName.map((val) => {
    return { value: val.first_name };
  });
  const SearchUsersByLastName = _.uniqBy(searchUsers, (users) => {
    return users.last_name;
  });

  const SearchLastName = SearchUsersByLastName.map((val) => {
    return { value: val.last_name };
  });
  const searchUserMethod = () => {
    let url = `api/user_search`;
    if (firstName && lastName) {
      url = `api/user_search?first_name=${Firstname}&last_name=${Lastname}`;
    } else if (firstName) {
      url = `api/user_search?first_name=${Firstname}`;
    } else if (lastName) {
      url = `api/user_search?last_name=${Lastname}`;
    } else {
      url = `api/user_search`;
    }
    dispatch(getSearchUser(url));
  };
  const hidePeopleDetails = () => {
    setIsPeopleDetailsVisible(false);
    setPeopleDetailsId("");
  };
  return (
    <>
      <Spin spinning={isSearchUserLoading} size="large">
        <div
          style={{
            height: height,
            overflow: "hidden",
            overflowY: "scroll",
            backgroundColor: "#FFFFFF",
            // margin: "10px",
          }}
          className="customLayout"
        >
          {isPeopleDetailsVisible ? (
            <Row gutter={[0, 33]}>
              <Col span={24} />
              <Col
                xl={1}
                lg={1}
                md={1}
                sm={2}
                xs={2}
                style={{ marginBottom: "30px", marginLeft: "22px" }}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => hidePeopleDetails()}
                />
              </Col>
              <Col>
                <Typography
                  className="newMemberSubDetails"
                  style={{ marginTop: "-2px" }}
                >
                  {t("content.152")}
                </Typography>
              </Col>
            </Row>
          ) : (
            <Row justify="space-around" align="middle" gutter={[0, 10]}>
              <Col span={24} />
              <Col xl={6} lg={6} md={6} sm={22} xs={22}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Typography className="firstname">
                      {t("content.70")}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <AutoComplete
                      style={{ width: "100%" }}
                      // value={SearchUsersByFirstName}

                      options={SearchFirstName}
                      filterOption={SearchFirstName}
                      onChange={(event) => setFirstName(event)}
                      //  onSelect={() => searchUserMethod()}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={6} md={6} sm={22} xs={22}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Typography className="firstname">
                      {t("content.71")}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <AutoComplete
                      style={{ width: "100%" }}
                      options={SearchLastName}
                      filterOption={SearchLastName}
                      onChange={(event) => setLastName(event)}
                      //  onSelect={() => searchUserMethod()}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xl={6} lg={6} md={6} sm={22} xs={22}>
                <Row gutter={[10, 18]}>
                  <Col span={24}></Col>
                  <Col span={24}></Col>

                  <Col span={24}>
                    <Button
                      type="primary"
                      className="button-search"
                      onClick={() => searchUserMethod()}
                    >
                      {t("btn.35")}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={24} xs={24}></Col>
            </Row>
          )}

          {isPeopleDetailsVisible ? (
            <Row justify="space-around">
              <Col span={23}>
                <ViewMemberDetails
                  peopleDetail={peopleDetail?.[0]}
                  isPeopleDetailsVisible={isPeopleDetailsVisible}
                />
                {/* <BusinessProfileDetails/> */}
              </Col>
            </Row>
          ) : (
            <Row justify="space-around">
              <Col xl={23} lg={23} md={23} sm={22} xs={22}>
                <Typography className="totalPeople">
                  {t("content.61")}{" "}
                  <Typography component="span" style={{ fontWeight: 700 }}>
                    {searchUsers.length}
                  </Typography>{" "}
                  {t("content.72")}
                </Typography>
              </Col>
              <Col xl={23} lg={23} md={23} sm={22} xs={22}>
                <Table
                  columns={columns}
                  dataSource={searchUsers}
                  size="middle"
                  className="table"
                />
              </Col>
            </Row>
          )}
        </div>
      </Spin>
    </>
  );
};
export default PeopleTable;

const ActionComponent = (props) => {
  const { t } = useTranslation();

  const { id, setIsPeopleDetailsVisible, setPeopleDetailsId } = props;
  const handleOpenDetails = (id) => {
    setIsPeopleDetailsVisible(true);
    setPeopleDetailsId(id);
  };
  return (
    <Button
      type="primary"
      onClick={() => handleOpenDetails(id)}
      className="button-search"
    >
      {t("btn.36")}
    </Button>
  );
};
