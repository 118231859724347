import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18next";
import "antd/dist/antd.css";
import "./style/custom-antd.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
if (process.env.NODE_ENV !== "development") {
  // Sentry.init({
  //   dsn: "https://6a211595fdc14b42a05b7d94b7ade870@o518363.ingest.sentry.io/6401700",
  //   integrations: [new BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // })
  Sentry.init({
    dsn: "https://412ec65ba4dc4b088370179aa0b24af6@o4504314914603008.ingest.sentry.io/4504384336166912",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div></div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
