import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Avatar,
  PageHeader,
  Spin,
} from "antd";
import "../FindOtherPeople.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBusiness,
  businessProfileById,
} from "../../Actions/businessAction";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useForm } from "react-hook-form";
// import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";
// const userSchema = yup.object().shape({});
const BusinessProfileDetails = (props) => {
  const { t } = useTranslation();
  const {
    setViewBusinessProfileId,
    viewBusinessProfileId,
    selectedBusiness,
    isSelectedBusinessProfileVisible,
  } = props;
  const { businessProfile, isBusinessLoading } = useSelector((state) => {
    const { businessProfile, isBusinessLoading } = state.business;
    return {
      businessProfile,
      isBusinessLoading,
    };
  });
  const {
    company_name,
    business_location,
    number_of_staff,
    business_details,
    service_provide,
    is_retail,
  } = businessProfile || {};

  // const [isModalVisible] = useState(false);
  const handleDeleteBusiness = (businessId) => {
    dispatch(deleteBusiness(businessId, successDeleteCallback));
  };
  const successDeleteCallback = () => {
    setViewBusinessProfileId("");
  };
  const handleCloseBusinessProfile = () => {
    setViewBusinessProfileId("");
  };
  // const {} = useForm({
  //   resolver: yupResolver(userSchema),
  // });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(businessProfileById(viewBusinessProfileId));
  }, []);
  const secondColor = "#" + Math.floor(Math.random() * 1677).toString(14);
  return (
    <Spin spinning={isBusinessLoading} size="large">
      <Row justify="space-around">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {!isSelectedBusinessProfileVisible && (
            <ArrowLeftOutlined
              style={{ paddingBottom: "20px", fontSize: "20px" }}
              onClick={() => handleCloseBusinessProfile()}
              handleDeleteBusiness={handleDeleteBusiness}
            />
          )}
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="profileDetails-card">
            <Row justify="space-between">
              <Col xl={4} lg={4} md={4} sm={4} xs={24}>
                <Avatar
                  shape="square"
                  size="large"
                  style={{
                    width: "127px",
                    height: "125px",
                    background: secondColor,
                  }}
                  className="BusinessProfileAvatar"
                >
                  {isSelectedBusinessProfileVisible
                    ? selectedBusiness?.company_name
                    : company_name}
                </Avatar>
              </Col>

              <Col xl={20} lg={19} md={18} sm={17} xs={24}>
                <Row gutter={[0, 10]}>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Row gutter={[0, 10]}>
                      <Col xs={24} />
                      <Col>
                        <Typography className="profile-name">
                          {isSelectedBusinessProfileVisible
                            ? selectedBusiness?.company_name
                            : company_name}
                        </Typography>
                      </Col>
                      <Col>
                        <Typography
                          className="profile-address"
                          style={{ marginTop: "3px" }}
                        >
                          {selectedBusiness?.is_retail
                            ? "(Retail Business)"
                            : selectedBusiness?.is_wholesale
                            ? "(Wholesale Business)"
                            : is_retail
                            ? "(Retail Business)"
                            : "(Wholesale Business)"}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Typography className="BusinessEstablishedDate">
                      {t("content.53")}:{" "}
                      <Typography
                        component="span"
                        className="BusinessEstablishedDateData"
                      >
                        10 the December 1991
                      </Typography>
                    </Typography>
                  </Col>
                </Row>
                <Divider className="JobProfileDivider" />
                {/* <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                  <Typography className="profile-address">
                    {isSelectedBusinessProfileVisible ? selectedBusiness?.business_location : business_location}
                  </Typography>
                </Col> */}
                <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                  <Typography className="BusinessLocation">
                    {t("content.51")}: &nbsp;
                    <Typography
                      component="span"
                      className="BusinessLocationData"
                    >
                      {business_location || ""}
                    </Typography>
                    {/* 234 Ambakunj Socity, Near Ghatlodia policestation , Bhuyangdev Char Rasta Ahmedabad, 394015 */}
                  </Typography>
                </Col>
              </Col>
            </Row>

            <Row justify="space-around" gutter={[24, 32]}>
              <Col span={24}></Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <PageHeader
                  title={t("content.60")}
                  className="pageHeaderDetails"
                  extra={[
                    <Typography className="pageHeaderSubDetails">
                      {t("content.61")}{" "}
                      <Typography className="BusinessLocation" component="span">
                        {" "}
                        {isSelectedBusinessProfileVisible
                          ? selectedBusiness?.number_of_staff
                          : number_of_staff}
                      </Typography>{" "}
                      {t("content.62")}
                    </Typography>,
                  ]}
                ></PageHeader>
                <Typography
                  className="pageHeaderSubDetails"
                  // style={{ padding: "2px 20px" }}
                >
                  {isSelectedBusinessProfileVisible
                    ? selectedBusiness?.business_details
                    : business_details}
                </Typography>
              </Col>
            </Row>

            <Row justify="space-around" gutter={[24, 16]}>
              <Col span={24}></Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <PageHeader
                  title={t("content.63")}
                  className="pageHeaderDetails"
                  extra={[
                    <Typography className="pageHeaderSubDetails">
                      {isSelectedBusinessProfileVisible
                        ? selectedBusiness?.service_provide
                        : service_provide}
                    </Typography>,
                  ]}
                ></PageHeader>
                {/* <Row gutter={[32, 32]} justify="space-between">
                  <Col span={24}></Col>
                  <Col xl={11} lg={11} md={24} sm={24} xs={24}>
                    <Card className="MatchesCard">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Typography className="profile-name">
                            {t("content.64")}
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className="businessInformation" style={{textAlign: 'justify'}}>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.{" "}
                          </Typography>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xl={11} lg={11} md={24} sm={24} xs={24}>
                    <Card className="MatchesCard">
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Typography className="profile-name">
                            {t("content.65")}
                          </Typography>
                        </Col>
                        <Col>
                          <Typography className="businessInformation" style={{textAlign: 'justify'}}>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy{" "}
                          </Typography>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={24} xs={24}></Col>
                </Row> */}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
export default React.memo(BusinessProfileDetails);
