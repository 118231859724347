import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Typography,
  Drawer,
  message,
  Input,
  Upload,
} from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
// import { EducationSchema } from "../../../Utils/Schema";
import {
  getTemplatesAction,
  createTemplatesAction,
  // getTemplatesById,
  updateTemplatesAction,
} from "../../../Actions/TemplatesAction";
import { PlusOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
// import { ImageEnv } from "../../CommonLogics/CommonMethods";
const TemplatesModalForm = (props) => {
  const {
    editId,
    setEditId,
    currentPage,
    setCurrentPage,
    isModalVisible,
    setIsModalVisible,
    isDefaultImageVisible,
    setShowUploadImage,
    setIsDefaultImageVisible,
    showUploadImage,
  } = props;
  const [preloadedEducationValue, setPreloadedEducationValue] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [editCommunityCardId, setEditCommunityCardId] = useState("");
  const [communityDetailId, setCommunityDetailId] = useState("");

  
  const IsEmpty = _.isEmpty(newFiles);
  const dispatch = useDispatch();
  //   const { allEducations, isUpdateEducationLoading, isCreateEducationLoading } =
  //     useSelector((state) => {
  //       const { currentUser } = state.authentication;
  //       const {
  //         allEducations,
  //         isUpdateEducationLoading,
  //         isCreateEducationLoading,
  //       } = state.educations;
  //       return {
  //         isUpdateEducationLoading,
  //         isCreateEducationLoading,
  //         currentUser,
  //         allEducations,
  //       };
  //     });
  const {
    setTempleteId,
    allTemplates,
    isCreateTemplatesLoading,
    isUpdateTemplatesLoading,
  } = useSelector((state) => {
    const {
      setTempleteId,
      allTemplates,
      isCreateTemplatesLoading,
      isUpdateTemplatesLoading,
    } = state.templates;
    return {
      setTempleteId,
      allTemplates,
      isCreateTemplatesLoading,
      isUpdateTemplatesLoading,
    };
  });
  const {
    handleSubmit,
    // formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: { preloadedEducationValue },
    // resolver: yupResolver(EducationSchema),
  });
  const handleOpenCommunity = (Id) => {
    setCommunityDetailId(Id);
    setOptionSelected(Id);
    //  setSelectedCardId(Id);
    // dispatch(getTemplatesById(Id));

    dispatch({
      type: "GET_SELECTED_TEMPLATES_ID",
      payload: Id,
    });
  };
  const templeteArray = [
    { label: "Congratulations", value: "Congratulations" },
    { label: "Marriage", value: "Marriage" },
    { label: "Achievements", value: "Achievements" },
    { label: "New Births", value: "New Births" },
    { label: "Death", value: "Death" },
  ];
  // useEffect(() => {
  //   dispatch(getTemplatesAction(`api/templates?page=${currentPage}&published=true&template_type=${setTempleteId}`));
  // }, [currentPage,setTempleteId]);
  const the_template = _.filter(allTemplates.templates, function (o) {
    return o.id === editId;
  });
  const selectedTemplates = _.filter(allTemplates.templates, (Cards) => {
    return Cards.id === editCommunityCardId;
  });
  const [OptionSelected, setOptionSelected] = useState(
    selectedTemplates?.template_type
  );
  useEffect(() => {
    if (editId) {
      setPreloadedEducationValue(the_template[0]);
      reset(the_template[0]);
    }
  }, [the_template[0], reset]);
  const handleClose = () => {
    setEditId("");
    setIsModalVisible(false);
    setValue("template_type", "");
    setValue("template_image", "");
    setValue("published", "");
    setValue("template_title", "");
    clearErrors();
    setNewFiles([]);
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
  };

  const handleCreateTemplate = (data) => {
    data.template_image = newFiles;
    data.template_type = setTempleteId;
    dispatch(
      createTemplatesAction(
        data,
        successCreateEducations,
        failureEducations,
        IsEmpty
      )
    );
  };
  const successCreateEducations = () => {
    setCurrentPage(1);
    setEditId("");
    setIsModalVisible(false);
    setIsDefaultImageVisible(false);
    setShowUploadImage(false);
    reset();
    setValue("template_type", "");
    setValue("template_image", "");
    setValue("published", "");
    setValue("template_title", "");

    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&template_type=${setTempleteId}`
      )
    );
    clearErrors();
    // setOptionSelected("");
    setTimeout(() => {
      message.success("Your Template Created Successfully");
    }, 1000);
  };

  const failureEducations = (failureResponse) => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };

  const handleEditTemplates = (data) => {
    data.id = editId;
    data.template_image = IsEmpty ? null : newFiles;
    dispatch(
      updateTemplatesAction(data, UpdateCallBack, faliureUpdate, IsEmpty)
    );
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setShowUploadImage(false);
    setIsDefaultImageVisible(false);
    setEditId("");
    reset();
    setValue("template_type", "");
    setValue("template_image", "");
    setValue("published", "");
    setValue("template_title", "");
    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&template_type=${setTempleteId}`
      )
    );

    clearErrors();
    setTimeout(() => {
      message.success("Your Template Updated Successfully");
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name[0] : "Something went wrong."
      );
    }, 1000);
  };
  const [width, setWidth] = useState(window.innerWidth);
  const getDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", getDimensions);
  }, [width]);

  const propsForUpload = {
    action: handleCreateTemplate || handleEditTemplates,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };
  const handleUploadMethod = (e) => {
    setShowUploadImage(true);
    setIsDefaultImageVisible(false);
    // setSelectedFileList(e.fileList.slice(-1));
  };
  useEffect(() => {
    if (!editId) {
      setEditId("");
    }
  }, [setValue, !editId]);
  return (
    <Drawer
      open={isModalVisible}
      maskClosable={false}
      onClose={handleClose}
      width={width > 370 ? "370px" : "100%"}
      className="drawer"
      title={editId ? "Edit Template" : "Add Template"}
    >
      <Spin
        spinning={editId ? isUpdateTemplatesLoading : isCreateTemplatesLoading}
        size="large"
      >
        <Typography className="content">
          Selected Template : {setTempleteId}
        </Typography>
        <form
          onSubmit={handleSubmit(
            editId ? handleEditTemplates : handleCreateTemplate
          )}
        >
          {/* <Row gutter={[0, 10]}>
            <Col span={24} className="formRows">
              <Typography className="details" style={{ paddingBottom: "4px" }}>
                template Type
              </Typography>
              <Controller
               as={
                <Select
                  className="inputLabel"
                  options={templeteArray}
                //   onSelect={(e) => setOptionSelected(e)}
                onSelect={(id) => handleOpenCommunity(id)}
                />
            }
             name="template_type"
             control={control}
             style={{ width: "100%" }}
              />

              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}
            </Col>
          </Row> */}
          <Row justify="center">
            <Col>
              {editId &&
                isDefaultImageVisible &&
                the_template[0]?.template_image && (
                  <Upload listType="picture-card" openFileDialogOnClick={false}>
                    <img
                      src={the_template[0]?.template_image}
                      // src={ImageEnv(the_template[0]?.template_image)}
                      width={102}
                    ></img>
                  </Upload>
                )}
            </Col>
            <Col>
              <Upload
                // fileList={selectedFileList}
                {...propsForUpload}
                listType="picture-card"
                maxCount={1}
                showUploadList={showUploadImage}
                onChange={(e) => handleUploadMethod(e)}
                className="details"
                accept="image/png,image/jpeg"
              >
                <PlusOutlined size={44} />
                Upload Logo
              </Upload>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Typography className="label" style={{ paddingBottom: "4px" }}>
                template title
              </Typography>
              <Controller
                as={<Input className="inputLabel" />}
                name="template_title"
                control={control}
                // onChange={(e) => console.log({ e })}
              />
              {/* {errors.name && (
                          <p style={{ color: "red" }}>{errors.name.message}</p>
                        )} */}
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ paddingTop: "20px" }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              {editId ? (
                <Button size="large" className="editButton" htmlType="submit">
                  Update
                </Button>
              ) : (
                <Button size="large" className="editButton" htmlType="submit">
                  Add
                </Button>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                className="cancelButton"
                onClick={handleClose}
                size="large"
                // style={{ margin: "0 8px" }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </form>
      </Spin>
    </Drawer>
  );
};

export default TemplatesModalForm;
