import React, { useState, useEffect } from "react";
import {Button, Row, Col, Typography, Tabs , Card , Input , Collapse} from "antd"
import LandingPageHeader from "./LandingPageHeader";
import "./LandingPage.css"
import "../TermsAndConditions/TermsAndConditions.css"
// import { useBreakpoints } from "react-responsive-hooks";
import {useBreakpoints} from "../../Screens/CommonLogics/ResponsiveBreakPoints"

import {ArrowRightOutlined , CalendarOutlined , ShoppingOutlined , FacebookFilled , TwitterOutlined , InstagramOutlined , PhoneOutlined , MailOutlined , EnvironmentOutlined , PlusOutlined , MinusOutlined} from "@ant-design/icons";


import {history} from "../../Utils/history"
import "./Faqs.css"
import ContactResponsiveImage from "../../assets/ContactResponsiveImage.png"
import Footer from "./Footer";

const AboutUs = () => {

    const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();

    useEffect(() => {
        window.scrollTo(0, 0)
   
    },[])
    const cardArray = [
        
        {
           
        
            name:"Events",
            Icon:<CalendarOutlined style={{"fontSize":"27px"}}/>,
      
            Description:"Create Events for your community and enjoy together"
        },
        
      
        {
            name:"Business",
            Icon:<ShoppingOutlined style={{"fontSize":"27px"}}/>,
            Description:"Know each other business or job and reach out to theme"
        },
        
        {

            name:"Matrimonials",
            Icon:<ShoppingOutlined style={{"fontSize":"27px"}}/>,
            Description:"Find the best life partner and bond relation to eachother"
        }
    ]
    
    
    
    
    
    // const {IsMD , IsSM , IsXS , IsXL , IsLG} = useBreakpoints();
    return(
<div style={{"backgroundColor":"#FAFAFA"}}>
<div className="TermsAndConditionsImageContainer" style={{"height": (IsMD || IsLG || IsXL) ? "75vh" : "100%"}}>
                <LandingPageHeader/>
            
              
                <Row style={{"height":"100%" , "padding":(IsXS || IsSM) && "100px"}} justify="center" align="middle"> 
                           <Typography className="TermsAndConditionsH1">                            
                                About Us    
   
                    </Typography>
                </Row>
{(IsXS || IsSM) &&
                <div className="LandingPageCardResponsive">
                    <Row justify="center" gutter={[30,{xs:30, sm:30}]} style={{
                        "marginTop":"4%"
                        }}>
                    <Col span={24}/>

                {
                    cardArray?.map((val , i) => {
                      
                        return(
                        <Col xs={20} xl={6} lg={7} md={7} sm={15} key={i}>
                            <Card className="landingPageCard">
                                <Row gutter={[0,10]}>
                                    <Col span={24}>
                                        {val.Icon}
                                    </Col>
                                    <Col span={24}>
                                     
                                        <Typography className="cardTitle">{val.name}</Typography>
                                    </Col>
                                    <Col>
                                        <Typography className="cardTitle2">{val.Description}</Typography>
                                    </Col>
                                </Row>
                            
                            </Card>
                        </Col>
                        )
                    })
            
            
            }
            
            </Row>
            </div>          
}
            </div>
           {        (IsMD || IsLG || IsXL) &&
                           <div className="LandingPageCardResponsive" style={{"height": (IsMD || IsLG || IsXL) && "75vh"}}>
                    <Row justify="center" gutter={[30,{xs:30, sm:30}]} style={{
                        "marginTop": (IsMD || IsLG || IsXL) && "4%"
                        }}>
                    <Col span={24}/>

                {
                    cardArray?.map((val , i) => {
                      
                       
                       
                       
                        return(
                        <Col xs={20} xl={6} lg={7} md={7} sm={15} key={i}>
                            <Card className="landingPageCard">
                                <Row gutter={[0,10]}>
                                    <Col span={24}>
                                        {val.Icon}
                                    </Col>
                                    <Col span={24}>
                                     
                                        <Typography className="cardTitle">{val.name}</Typography>
                                    </Col>
                                    <Col>
                                        <Typography className="cardTitle2">{val.Description}</Typography>
                                    </Col>
                                </Row>
                            
                            </Card>
                        </Col>
                        )
                    })
            
            
            }
            
            </Row>
            </div>
}       
 
 
            <div style={{
                "marginTop":"164px"
                }}>
                        <Row justify="center">
<Col span={19}>
<Row justify="space-around" gutter={[0,40]}>
                               <Col xl={7} lg={7} md={7}  sm={24} xs={24}>
                    <img src={ContactResponsiveImage} style={{"width":"100%"}}></img>
                        </Col>
<Col xl={15} lg={15} md={15} sm={24} xs={24}>



<Typography className="AboutUsTitle">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </Typography>
                        </Col> 
                        </Row>
                        </Col>   
                    </Row>                
 
            </div>
            <div style={{
                "marginTop":"224px"
                }}>
                        <Row justify="center">
<Col span={19}>
                        
                        <Row justify="space-around" gutter={[0,40]}>
                        


<Col xl={15} lg={15} md={15} sm={24} xs={24}>

<Typography className="AboutUsTitle">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </Typography>
                        </Col> 
                        <Col xl={7} lg={7} md={7}  sm={24} xs={24}>

                    <img src={ContactResponsiveImage} style={{"width":"100%"}}></img>
                        </Col>
                        </Row>
                    
                    
                        </Col>   
                    </Row>                
            </div>

<Footer/>
                        </div>



                    )
}



export default AboutUs;