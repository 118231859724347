import React, { useState ,useEffect} from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Table,
  message,
  Empty,
  Typography,
  Pagination,
  Checkbox,
  Breadcrumb,Tooltip,Popconfirm
} from "antd";
import "../../../FindThePeoplePage/FindOtherPeople.css";
// import "../../Admin.css";
import { useDispatch,useSelector } from "react-redux";
import _ from "lodash";
import { useBreakpoints } from "../../CommonLogics/ResponsiveBreakPoints";
import { useTranslation } from "react-i18next";
import {
  getCommunityCardsAction,
  updateCommunityCardsAction,
  deleteCommunityCardsAction
} from "../../../Actions/communityCardsAction";
import {
  getTemplatesAction,
  deleteTemplatesAction
} from "../../../Actions/TemplatesAction";
import CommunityCardsModal from "./CommunityCardsModal";
import { history } from "../../../Utils/history";
import { getCommunityById } from "../../../Actions/communitiesAction";
import CustomTable from "../../ReuseableComponents/CustomTable/CustomTable";
import DesignationEmptyScreen from "../../../assets/DesignationEmptyScreen.svg";
import PublishedIcon from "../../../assets/PublishedIcon.svg";
import UnpublishedIcon from "../../../assets/UnpublishedIcon.svg";
import Edit2 from "../../../assets/Edit2.svg";
import Delete from "../../../assets/delete.svg";

const CommunityCards = (props) => {
  const {
    setCommunityCardPage,
    communityCardPage,
    // setIsEditCommunityCardVisible,
    // setEditCommunityCardId,
    // setIsCommunityCardPublished,
  } = props;
  const [isCreateCommunityCardVisible, setIsCreateCommunityCardVisible] =
  useState(false);
const [isEditCommunityCardVisible, setIsEditCommunityCardVisible] =
  useState(false);
  const [currentPage, setCurrentPage] = useState(1);
const [editCommunityCardId, setEditCommunityCardId] = useState("");
const [isCommunityCardPublished, setIsCommunityCardPublished] =
    useState(false);
  const { IsMD, IsSM, IsXS, IsLG, IsXL, IsXXL } = useBreakpoints();
  const {
    allTemplates,
    allCommunityCards,
    isCreateCommunityCardsLoading,
    isUpdateCommunityCardsLoading,
    isGetCommnunityCardsLoading,
    setCommunityId,
    template,setTempleteId
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { setCommunityId } = state.communities;
    const {
     
        allCommunityCards,
        isCreateCommunityCardsLoading,
        isUpdateCommunityCardsLoading,
        isGetCommnunityCardsLoading
      } = state.communityCards;
      const {
        template,
        setTempleteId,
        allTemplates,
      } = state.templates;
    return {
       template,
       setTempleteId,
      allTemplates,
      allCommunityCards,
        isGetCommnunityCardsLoading,
        setCommunityId,
        isCreateCommunityCardsLoading,
        isUpdateCommunityCardsLoading,
    };
  });
  let UnpublishData = {};
  let PublishData = {};
  const { t } = useTranslation();
  const { total_length } = allCommunityCards || {};
  // const { total_length } = allTemplates || {};
  const dispatch = useDispatch();
 
  const onClickCommunity = () => {
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };
  const selectedCommunityCard = _.filter(
    allCommunityCards.community_cards,
    (Cards) => {
      return Cards.id === editCommunityCardId;
    }
  );
  const selectedTemplates = _.filter(
    allTemplates.templates,
    (Cards) => {
      return Cards.id === editCommunityCardId;
    }
  );
  useEffect(() => {
    dispatch(
        getCommunityCardsAction(
        `api/community_cards?community_id=${setCommunityId}`
      )
    );
  }, [dispatch, setCommunityId]);
  useEffect(() => {
    dispatch(getTemplatesAction(`api/templates?page=${currentPage}&published=true&template_type=${setTempleteId}`));
}, [currentPage,setTempleteId]);
  const handleDeleteDesignation = (Id) => {
    dispatch(deleteCommunityCardsAction(Id, successDelete, failureDelete));
  };

  const successDelete = () => {
    dispatch(
        getCommunityCardsAction(
        `api/community_cards?community_id=${setCommunityId}`
      )
    );
    setTimeout(() => {
      message.success("Your Designation Deleted Successfully");
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleDeleteTemplate = (Id) => {
    dispatch(deleteTemplatesAction(Id, successDeleteTemplate, failureDeleteTemplates));
  };

  const successDeleteTemplate = () => {
    dispatch(
      getTemplatesAction(
        `api/templates?page=${currentPage}&template_type=Congratulations&published=true`
      )
    );
    setTimeout(() => {
      message.success("Your Designation Deleted Successfully");
    }, 1000);
  };
  const failureDeleteTemplates = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handlePublishDesignationMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
        updateCommunityCardsAction(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishdesignationMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
        updateCommunityCardsAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success("Your Designation Published Successfully");
    }, 1000);
    dispatch(
        getCommunityCardsAction(
        `api/community_cards?community_id=${setCommunityId}`
      )
    );
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success("Your Designation Unpublished Successfully");
    }, 1000);
    dispatch(
        getCommunityCardsAction(
        `api/community_cards?community_id=${setCommunityId}`
      )
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error("Something went wrong.");
    }, 1000);
  };
  const handleEditCommunityCardMethod = (Id) => {
    setIsEditCommunityCardVisible(true);
    setEditCommunityCardId(Id);
  };
  const handleEditCommunityCardMethod2 = (Id) => {
    setIsEditCommunityCardVisible(true);
    setEditCommunityCardId(Id);
  };
  const handlePublishCommunityCardMethod = (ID) => {
    const SelectedRecord = _.filter(
      allCommunityCards.community_cards,
      (val) => {
        return val?.id === ID;
      }
    );
    SelectedRecord[0].published = true;
    dispatch(
      updateCommunityCardsAction(SelectedRecord[0], SuccessPublishMethod, true)
    );
  };
  const handleUnpublishCommunityCardMethod = (ID) => {
    const SelectedRecord = _.filter(
      allCommunityCards.community_cards,
      (val) => {
        return val.id === ID;
      }
    );
    SelectedRecord[0].published = false;
    dispatch(
      updateCommunityCardsAction(
        SelectedRecord[0],
        SuccessUnPublishMethod,
        true
      )
    );
  };
  const SuccessPublishMethod = () => {
    dispatch(getCommunityCardsAction(`api/community_cards?community_id=${setCommunityId}`));
    setTimeout(() => {
      message.success("Community card published successfully");
    }, 1000);
  };
  const SuccessUnPublishMethod = () => {
    dispatch(getCommunityCardsAction(`api/community_cards?community_id=${setCommunityId}`));
    setTimeout(() => {
      message.success("Community card Unpublished successfully");
    }, 1000);
  };
  
  const Columns = [
    
    {
      title: "Card Name",
      dataIndex: "name",
      width: IsXS || IsSM ? "63%" : "20%",

      sorter: (a, b) => (a.name.localeCompare(b.name)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Card Type",
      dataIndex: "card_type",
      width: IsXS || IsSM ? "63%" : "20%",
      sorter: (a, b) => (a.card_type.localeCompare(b.card_type)),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: IsXS || IsSM ? "63%" : "45%",
    },
    {
        title: "Action",
        dataIndex: "id",
        width: "20%",
        render: (id, { published }) => {
          return (
            <Row justify="space-around">
              <Col>
                <Tooltip title="Edit">
                  <Button
                    icon={<img src={Edit2} />}
                    type="text"
                    onClick={() => handleEditCommunityCardMethod(id)}
                  />
                </Tooltip>
                {/* <EditColumnAction id={id} customMethod={handleEdit} /> */}
              </Col>
              <Col>
                {published ? (
                     <Tooltip title="Click for Unpublish">
                  <Button
                    onClick={() => handleUnpublishCommunityCardMethod(id)}
                    icon={<img src={PublishedIcon} />}
                    type="text"
                  ></Button>
                  </Tooltip>
                ) : (
                    <Tooltip title="Click for Publish">
                  <Button
                    onClick={() => handlePublishCommunityCardMethod(id)}
                    icon={<img src={UnpublishedIcon} />}
                    type="text"
                  ></Button>
                  </Tooltip>
                )}
              </Col>
              <Col>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => handleDeleteDesignation(id)}
                    okText="Ok"
                    cancelText="cancel"
                  >
                    <Button icon={<img src={Delete} />} type="text" />
                  </Popconfirm>
                </Tooltip>
                {/* <DeleteColumnAction
                  id={id}
                  actionArray={[deleteIndustryAction]}
                  customMethod={successDelete}
                /> */}
              </Col>
            </Row>
          );
        },
      },
    // {
    //  dataIndex: "id",
    //  render: (id, AllCommunityCardsData) => {
    //    return (
    //      <ActionComponent
    //        id={id}
    //        setIsEditCommunityCardVisible={setIsEditCommunityCardVisible}
    //        setEditCommunityCardId={setEditCommunityCardId}
    //        AllCommunityCardsData={AllCommunityCardsData}
    //        allCommunityCards={allCommunityCards}
    //        communityCardPage={communityCardPage}
    //      />
    //    );
    //  },
    //  width: "15em",
    //  },
  ];
  return (
    <Spin spinning={isGetCommnunityCardsLoading}>

    <div>
    
      {/* <Table
        dataSource={allCommunityCards?.community_cards}
        columns={columns}
        size="middle"
        className="table"
        rowKey={(record) => {
          return record.id;
        }}
        pagination={false}
      /> */}
       <Row gutter={[32, 32]}
        className="community" >
        <Col span={24}></Col>
        <Col xl={16} lg={16} md={16} sm={20} xs={20}>
          <Breadcrumb 
          // style={{ margin: 10 }}
          >
            <Breadcrumb.Item
              onClick={() => onClickCommunity()}
              className="breadcrumCommunity"
            >
              Communities
            </Breadcrumb.Item>
            <Breadcrumb.Item
            //   onClick={() => onClickEducation()}
              className="breadcrumUsers"
            >
              Community Cards
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">
              {/* {the_name[0]?.name} */}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <Row justify="end" gutter={[8, 8]}>
            
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <Button
                className="editDesignationButton"
                // style={{ width: "100%" }}
                onClick={() => setIsCreateCommunityCardVisible(true)}
              >
                Add communityCard
              </Button>
            </Col>
          </Row>
          <Spin
            spinning={
                editCommunityCardId ? isUpdateCommunityCardsLoading : isCreateCommunityCardsLoading
            }
            size="large"
          >
            <CommunityCardsModal
                    isCreateCommunityCardVisible={isCreateCommunityCardVisible}
                    setIsCreateCommunityCardVisible={
                      setIsCreateCommunityCardVisible
                    }
                    selectedCommunityCard={selectedCommunityCard}
                    selectedTemplates={selectedTemplates}
                    isEditCommunityCardVisible={isEditCommunityCardVisible}
                    setIsEditCommunityCardVisible={
                      setIsEditCommunityCardVisible
                    }
                    editCommunityCardId={editCommunityCardId}
                    setEditCommunityCardId={setEditCommunityCardId}
                    communityCardPage={communityCardPage}
                    isUpdateCommunityCardsLoading={
                      isUpdateCommunityCardsLoading
                    }
                    isCreateCommunityCardsLoading={
                      isCreateCommunityCardsLoading
                    }
                    isCommunityCardPublished={isCommunityCardPublished}
                  />
          </Spin>
        </Col>
        
        <Col span={24}>
      {/* {allCommunityCards && total_length > 0 ? ( */}
              <Row gutter={[32, 32]}>
      <Col span={24}>
      <CustomTable
             data={allCommunityCards?.community_cards}
             columns={Columns}
             size="middle"
             className="table"
             rowKey={(record) => {
               return record.id;
             }}
             pagination={false}
          />
         
          </Col>
        </Row>
      {/* ) : (
        <Row justify="center" style={{ paddingTop: "150px" }}>
          <Col>
            <Empty
              image={DesignationEmptyScreen}
              imageStyle={{
                height: 38,
              }}
              description={
                <span className="empty-details">No Community Card yet</span>
              }
            ></Empty>
          </Col>
        </Row>
      )} */}
       </Col>
       </Row>
       {allCommunityCards && total_length > 0 ? (
      <Row justify="center" 
      // style={{ margin: "20px 0px" }}
      >
        <Pagination
          total={allCommunityCards?.total_length}
          onChange={(e) => setCommunityCardPage(e)}
          showSizeChanger={false}
        />
      </Row>
       ):""}
    </div>
    </Spin>
  );
};
export default CommunityCards;

const ActionComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    // allCommunityCards,
    isCreateCommunityCardsLoading,
    isUpdateCommunityCardsLoading,
    isGetCommnunityCardsLoading,
    setCommunityId
  } = useSelector((state) => {
    const { currentUser } = state.authentication;
    const { setCommunityId } = state.communities;
    const {
        allCommunityCards,
        isCreateCommunityCardsLoading,
        isUpdateCommunityCardsLoading,
        isGetCommnunityCardsLoading
      } = state.communityCards;
    return {
        allCommunityCards,
        isGetCommnunityCardsLoading,
        setCommunityId,
        isCreateCommunityCardsLoading,
        isUpdateCommunityCardsLoading,
    };
  });
  const {
    id,
    setIsEditCommunityCardVisible,
    setEditCommunityCardId,
    AllCommunityCardsData,
    allCommunityCards,
    communityCardPage,
    // setCommunityId
  } = props;
  const handleEditCommunityCardMethod = (Id) => {
    setIsEditCommunityCardVisible(true);
    setEditCommunityCardId(Id);
  };
  const handlePublishCommunityCardMethod = (ID) => {
    const SelectedRecord = _.filter(
      allCommunityCards.community_cards,
      (val) => {
        return val?.id === ID;
      }
    );
    SelectedRecord[0].published = true;
    dispatch(
      updateCommunityCardsAction(SelectedRecord[0], SuccessPublishMethod, true)
    );
  };
  const handleUnpublishCommunityCardMethod = (ID) => {
    const SelectedRecord = _.filter(
      allCommunityCards.community_cards,
      (val) => {
        return val.id === ID;
      }
    );
    SelectedRecord[0].published = false;
    dispatch(
      updateCommunityCardsAction(
        SelectedRecord[0],
        SuccessUnPublishMethod,
        true
      )
    );
  };
  const SuccessPublishMethod = () => {
    dispatch(getCommunityCardsAction(`api/community_cards?community_id=${setCommunityId}`));
    setTimeout(() => {
      message.success("Community card published successfully");
    }, 1000);
  };
  const SuccessUnPublishMethod = () => {
    dispatch(getCommunityCardsAction(`api/community_cards?community_id=${setCommunityId}`));
    setTimeout(() => {
      message.success("Community card Unpublished successfully");
    }, 1000);
  };
  return (
    <div>
      <Row gutter={[40, 0]}>
        <Col span={10}>
          <Button
            onClick={() => handleEditCommunityCardMethod(id)}
            type="primary"
            className="editDesignationButton"
          >
            {t("btn.27")}
          </Button>
        </Col>

        <Col span={10}>
          {AllCommunityCardsData?.published ? (
            <Button
              type="primary"
              className="unPublishButton"
              onClick={() => handleUnpublishCommunityCardMethod(id)}
            >
              {t("content.164")}
            </Button>
          ) : (
            <Button
              type="primary"
              className="publishButton"
              onClick={() => handlePublishCommunityCardMethod(id)}
            >
              {t("content.163")}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
