import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Spin,
  Avatar,
  Button,
  PageHeader,
  Breadcrumb,
  Divider,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCommunityById } from "../../../Actions/communitiesAction";
import { ArrowLeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import Edit from "../../../assets/Edit.svg";
import { history } from "../../../Utils/history";
import { falseyValueCases, ImageEnv } from "../../CommonLogics/CommonMethods";

const CommunityDetails = (props) => {
  const {
    setCommunityDetailId,
    communityDetailId,
    editId,
    setEditId,
    setIsModalVisible,
    setIsDefaultImageVisible,
    setShowUploadImage,
    currentPage,
  } = props;

  const { allCommunities, isCommumitiesloading, community } = useSelector(
    (state) => {
      const { allCommunities, isCommumitiesloading, community } =
        state.communities;

      return {
        allCommunities,
        isCommumitiesloading,
        community,
      };
    }
  );
  const {
    name,
    description,
    slug,
    community_logo_key,
    community_natives,
    show_find_other_people,
    show_business_profile,
    show_event,
    show_matrimonial,
    show_trustee,
  } = community || {};
  const { length } = community_natives || {};

  const dispatch = useDispatch();

  const handleCloseCommunity = () => {
    setCommunityDetailId("");
    history.push("/communities");
    dispatch(getCommunityById(""));
    dispatch({
      type: "GET_SELECTED_COMMUNITY_ID",
      payload: "",
    });
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setIsDefaultImageVisible(true);
    setShowUploadImage(false);
  };

  return (
    // <Spin spinning={isCommumitiesloading}>
    <>
      <Row justify="space-around" style={{ 
        // margin: 20
         }}>
        <Col xl={1} lg={1} md={1} sm={1} xs={1}>
          <ArrowLeftOutlined
            style={{ paddingBottom: "20px" }}
            onClick={() => handleCloseCommunity()}
          />
        </Col>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => handleCloseCommunity()}
              className="breadcrumCommunity"
            >
              All communities
            </Breadcrumb.Item>
            <Breadcrumb.Item className="breadcrumUsers">{name}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Card className="profileDetails-card">
            <Row justify="space-between">
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className={
                  community?.published
                    ? "true"
                      ? "unpublishedColor"
                      : "false"
                    : "publishedColor"
                }
              >
                {community.published
                  ? "true"
                    ? "Published"
                    : "false"
                  : "Unpblished"}
              </Col>
              <Divider />
              <Col xl={1} lg={1} md={2} sm={4} xs={4}>
                <Avatar
                  shape="square"
                  size={85}
                  className="avtar"
                  src={community_logo_key && ImageEnv(community_logo_key)}
                >
                  <Typography style={{ fontSize: "40px", color: "white" }}>
                    {name?.[0]}
                  </Typography>
                </Avatar>
              </Col>
              <Col xl={0} lg={0} md={2} sm={4} xs={4}></Col>
              <Col xl={14} lg={14} md={14} sm={8} xs={8}>
                <Row>
                  <Typography className="detailsPage">{name}</Typography>
                </Row>
                <Row>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>
                  <Col span={24}></Col>

                  <Col>
                    <Typography className="detailsPage2">{slug}</Typography>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={6}
                lg={6}
                md={6}
                sm={8}
                xs={8}
                style={{ textAlign: "end" }}
              >
                <Button
                  icon={<img src={Edit} />}
                  type="text"
                  onClick={() => handleEdit(communityDetailId, community)}
                />
              </Col>
              <Row style={{ display: "contents" }}>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ marginTop: 20 }}
                >
                  <PageHeader
                    title="Details"
                    className="pageHeaderDetails"
                  ></PageHeader>
                </Col>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  // style={{ margin: 20 }}
                >
                  <Typography className="detailsPage2">
                    {description}
                  </Typography>
                </Col>
              </Row>

              <Row style={{ display: "contents" }}>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ marginTop: 20 }}
                >
                  <PageHeader
                    title="Displayed pages for community"
                    className="pageHeaderDetails"
                  ></PageHeader>
                </Col>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  // style={{ margin: 10 }}
                >
                  <Typography>
                    <ul>
                      <li
                        className={
                          show_find_other_people
                            ? "true"
                              ? "unpublishedColor"
                              : "false"
                            : "publishedColor"
                        }
                      >
                        {show_find_other_people
                          ? "true"
                            ? "find other people"
                            : "false"
                          : "find other people"}{" "}
                      </li>
                    </ul>
                    <ul>
                      <li
                        className={
                          show_event
                            ? "true"
                              ? "unpublishedColor"
                              : "false"
                            : "publishedColor"
                        }
                      >
                        {show_event ? ("true" ? "Event" : "false") : "Event"}{" "}
                      </li>
                    </ul>
                    <ul>
                      <li
                        className={
                          show_matrimonial
                            ? "true"
                              ? "unpublishedColor"
                              : "false"
                            : "publishedColor"
                        }
                      >
                        {show_matrimonial
                          ? "true"
                            ? "Matrimonial"
                            : "false"
                          : "Matrimonial"}{" "}
                      </li>
                    </ul>
                    <ul>
                      <li
                        className={
                          show_business_profile
                            ? "true"
                              ? "unpublishedColor"
                              : "false"
                            : "publishedColor"
                        }
                      >
                        {show_business_profile
                          ? "true"
                            ? "Business Profile"
                            : "false"
                          : "Business Profile"}
                      </li>
                    </ul>
                    <ul>
                      <li
                        className={
                          show_trustee
                            ? "true"
                              ? "unpublishedColor"
                              : "false"
                            : "publishedColor"
                        }
                      >
                        {" "}
                        {show_trustee
                          ? "true"
                            ? "Trustee"
                            : "false"
                          : "Trustee"}
                      </li>
                    </ul>
                  </Typography>
                </Col>
              </Row>
            </Row>

            {community_natives && length > 0 ? (
              <>
                <Row style={{ display: "contents" }}>
                  <Col
                    xl={24}
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ marginTop: 20 }}
                  >
                    <PageHeader
                      title={[
                        <Typography className="pageHeaderDetails">
                          Native
                        </Typography>,
                      ]}
                      extra={[
                        <Typography className="pageHeaderDetails">
                          Published?
                        </Typography>,
                      ]}
                      className="pageHeaderDetails"
                    ></PageHeader>
                  </Col>
                </Row>

                <Row 
                // style={{ margin: 10 }}
                 gutter={[16, 16]}>
                  <Col span={24}></Col>
                  <Col xl={18} lg={18} md={18} sm={16} xs={16}>
                    {community_natives &&
                      community_natives.map((val, i) => {
                        return (
                          <Typography className="publishDetail" key={i}>
                            <ul>
                              <li>{val.name}</li>
                            </ul>
                          </Typography>
                        );
                      })}
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={8}
                    xs={8}
                    style={{ textAlign: "end" }}
                  >
                    {community_natives &&
                      community_natives.map((val, i) => {
                        return (
                          <Typography
                            key={i}
                            className={
                              val.is_published
                                ? "true"
                                  ? "unpublishedColor"
                                  : "false"
                                : "publishedColor"
                            }
                          >
                            <ul>
                              {val.is_published
                                ? "true"
                                  ? "Yes"
                                  : "false"
                                : "No"}
                            </ul>
                          </Typography>
                        );
                      })}
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Card>
        </Col>
      </Row>
      </>
    //  </Spin> 
  );
};
export default CommunityDetails;
