import API from "../Utils/API";
export const getCountriesAction = (url) => (dispatch) => {
  dispatch({ type: "GET_COUNTRIES_REQUEST" });
  API.get(url)
    .then((response) => {
      dispatch({ type: "GET_COUNTRIES_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COUNTRIES_FAILURE",
        payload: error.response.data,
      });
    });
};
export const getCountriesById = (Id) => (dispatch) => {
  dispatch({ type: "GET_COUNTRIES_BY_ID_REQUEST" });
  API.get(`api/countries/${Id}`)
    .then((response) => {
      dispatch({ type: "GET_COUNTRIES_BY_ID_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "GET_COUNTRIES_BY_ID_FAILURE",
        payload: error.response.data,
      });
    });
};
export const createCountriesAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "CREATE_COUNTRIES_REQUEST", payload: data });

    API.post("api/countries", data)
      .then((response) => {
        dispatch({ type: "CREATE_COUNTRIES_SUCCESS", payload: response.data });
        successCallback && successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_COUNTRIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const updateCountriesAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "UPDATE_COUNTRIES_REQUEST" });
    API.put(`api/countries/${data.id}`, data)
      .then((response) => {
        dispatch({ type: "UPDATE_COUNTRIES_SUCCESS", payload: response.data });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_COUNTRIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };

export const deleteUserCountriesAction =
  (Id, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: "DELETE_COUNTRIES_REQUEST" });
    API.delete(`api/countries/${Id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_COUNTRIES_SUCCESS",
          payload: response.data,
        });
        successCallback();
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_COUNTRIES_FAILURE",
          payload: error.response.data,
        });
        failureCallback();
      });
  };
